import React, { Component } from 'react';
// import $ from 'jquery';
import { Link } from 'react-router-dom';
// import { Trans } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage';

//import components
import routeCodes from '../../Paths';

class Sidebar extends Component {
  state = {
    fullname:secureLocalStorage.getItem('FN_') + " " + secureLocalStorage.getItem('LN_')
  };


  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true});
      document.querySelector('.sidebar-offcanvas').classList.toggle('active');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });




    const dropdownPaths = [
      {path:'/apps', state: 'appsMenuOpen'},
      {path:'/basic-ui', state: 'basicUiMenuOpen'},
      {path:'/advanced-ui', state: 'advancedUiMenuOpen'},
      {path:'/form-elements', state: 'formElementsMenuOpen'},
      // {path:'/tables', state: 'tablesMenuOpen'},
      // {path:'/accounts', state: 'accountsMenuOpen'},
      // {path:'/settings', state: 'settingsMenuOpen'},
      {path:'/wallet', state: 'walletMenuOpen'},
      {path:'/transactions', state: 'transactionMenuOpen'},
      {path:'/usertransactions', state: 'usertransactionMenuOpen'},
      {path:'/maps', state: 'mapsMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
      {path:'/error-pages', state: 'errorPagesMenuOpen'},
      {path:'/general-pages', state: 'generalPagesMenuOpen'},
      {path:'/ecommerce', state: 'ecommercePagesMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      // if (this.isPathActive(obj.path)) {
      //   document.querySelector('#sidebar').classList.remove('active');
      //   this.setState({[obj.state] : true})
      // }
    }));
  }

  closeNav(e){
    document.querySelector('.sidebar-offcanvas').classList.remove('active');
    // this.isPathActive(e.target.getAttribute('href'))
  }

  isPathActive(path) {
    // this.closeNav();
    document.getElementsByClassName('sidebar').classList.remove('active');
    // console.log(path)
    // return this.props.location.pathname.startsWith(path);
    return path;
  }


  render () {
    return (
      <>
    <nav className="sidebar sidebar-offcanvas" id="sidebar"
      style={{borderRight: "1px solid #292c37"}}>

        <ul className="nav navbar__links--li">
        <li className="nav-item p-4 nav-profile">
            <a href="!#" className="w-100" onClick={evt =>evt.preventDefault()}>
              {/* <div className="nav-profile-image">
                <img src={ require("../../assets/images/faces/face1.jpg") } alt="profile" />
                <span className="login-status online"></span>
              </div> */}
              <div className=" nav-profile-text">
                <h5 className=''>Welcome back</h5>
                <h5 className="font-weight-bold mb-2 emailText">
                  {this.state.fullname ? secureLocalStorage.getItem('FN_') + " " + secureLocalStorage.getItem('LN_') : ""}
                </h5>
                <span className="text-small">{secureLocalStorage.getItem('EM_')}</span><i className="mdi mdi-account-check text-success nav-profile-badge mx-2"></i>
              </div>

            </a>
          </li>
          <li className= 'nav-item' onClick={this.closeNav}>
            <Link className="nav-link" to={routeCodes.DASHBOARD}>
              <span className="menu-title">Market Rates</span>
              <i className="mdi mdi-chart-line menu-icon"></i>
            </Link>
          </li>
          <li className= 'nav-item' onClick={this.closeNav}>
            <Link className="nav-link" to={routeCodes.VERIFY}>
              <span className="menu-title">Identity</span>
              <i className="mdi mdi-id-card menu-icon"></i>
            </Link>
          </li>
          <li className= 'nav-item' onClick={this.closeNav}>
            <Link className="nav-link" to={routeCodes.SETTINGS}>
              <span className="menu-title">My profile</span>
              <i className="mdi mdi-settings menu-icon"></i>
            </Link>
          </li>
          <li className= 'nav-item' onClick={this.closeNav}>
            <Link className="nav-link" to={routeCodes.SECURITY}>
              <span className="menu-title">Security</span>
              <i className="mdi mdi-security menu-icon"></i>
            </Link>
          </li>
          <li className= 'nav-item' onClick={this.closeNav}>
            <Link className="nav-link" to={routeCodes.NEWTRANSFER}>
              <span className="menu-title">Transactions</span>
              <i className="mdi mdi-cash menu-icon"></i>
            </Link>
          </li>

          <li className= 'nav-item' onClick={this.closeNav}>
            <Link className="nav-link" to={routeCodes.TRANSACTIONS}>
              <span className="menu-title">Transaction History</span>
              <i className="mdi mdi-history menu-icon"></i>
            </Link>
          </li>
          <li className= 'nav-item' onClick={this.closeNav}>
            <Link className="nav-link" to={routeCodes.REPORTS}>
              <span className="menu-title">Account Statement</span>
              <i className="mdi mdi-file-document-box menu-icon"></i>
            </Link>
          </li>
          <li className= 'nav-item' onClick={this.closeNav}>
            <Link className="nav-link" to={routeCodes.BANKDETAILS}>
              <span className="menu-title">Bank Details</span>
              <i className="mdi mdi-bank menu-icon"></i>
            </Link>
          </li>
        </ul>
      </nav>
      </>

    );
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
        el.classList.add('hover');
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
        el.classList.remove('hover');
      });
    });
  //   const linkele = document.querySelector('.navbar__links--li .nav-item .nav-link');
  //   document.querySelectorAll('.navbar__links--li .nav-item .nav-link').forEach((li) => {
  //     if(li.classList.contains('active')) {
  //       console.log(li)
  //       linkele.addEventListener("click", function(el) {
  //         console.log("#####################################")
  //           li.classList.remove('active');
  //           el.classList.add("active");
  //       });
  //     }
  // });
}
}

export default Sidebar;

