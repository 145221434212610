import React, { useState } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
// import * as Yup from 'yup';
import { FormProvider, RHFTextField } from '../components/hook-form';
import { IconButton, InputAdornment } from '@mui/material';
// import { Tabs, Tab, Form } from 'react-bootstrap';
import url_local from '../url_local';
import { LoadingButton } from '@mui/lab';

import routeCodes from '../../Paths';
import secureLocalStorage from 'react-secure-storage';

// import GetFiatAccounts from '../users/wallet/GetFiatAccounts';
// import GetCryptoAccounts from "../users/wallet/GetCryptoAccounts";
//components
import Iconify from '../components/Iconify';

// form
import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';

// import { Trans } from 'react-i18next';

export default function FiatTransfer(fiat=[]) {
    console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
    const history = useNavigate();

    const [previewData, setpreviewData] = useState([]);

    const [showPassword, setShowPassword] = React.useState(false);
    const [getdata, setGetdata] = useState(false);

    const [accountData,setAccountData] =  React.useState([])
    const [loading, setLoading] = React.useState(true);

    const [accountNo,setAccountNo] = React.useState("");
    const [currency,setCurrency] = React.useState("");

    const headers = {
        "Content-Type": "application/json",
        "Access-Token": secureLocalStorage.getItem('AT_'),
        "Refresh-Token": secureLocalStorage.getItem('RT_')
    };
    // const [viewpaymentsection, setviewpaymentsection] = useState(false);

    // const initialState = { alt: "", src: "" };
    // const [{ alt, src, profiledata }, setPreview] = React.useState(initialState);
    // const fileHandler = event => {
    //     var { files } = event.target;
    //     setPreview(
    //         files.length
    //             ? {
    //                 src: URL.createObjectURL(files[0]),
    //                 alt: files[0].name,
    //                 profiledata: files[0]
    //             }
    //             : initialState
    //     );
    // };

    React.useEffect(() => {
        var headers = {
            "Content-Type": "application/json",
            'Access-Token': secureLocalStorage.getItem('AT_'),
            "Refresh-Token": secureLocalStorage.getItem('RT_')
        };
        const payload = {publicid: secureLocalStorage.getItem('PI_')}
        if(secureLocalStorage.getItem("AN_", "") !== "" && secureLocalStorage.getItem("AN_") !== null){
            const curr = secureLocalStorage.getItem("CS_", "");
            const acc = secureLocalStorage.getItem("AN_", "");
            setAccountNo(acc);
            setCurrency(curr);
            // const symbol = secureLocalStorage.getItem("CV_", "");
            // const address = secureLocalStorage.getItem("AD_", "");
            // document.getElementById("mycrypto").value = symbol
            // document.getElementById("cryptoaddress").text = address;
            // document.getElementById("cryptoaddress").style.display = "block";
            console.log(acc);
            // setSelectedAddress(address);
            // setSelectedSymbol(symbol);
            secureLocalStorage.setItem("AN_", "");
            secureLocalStorage.setItem("CS_", "");
          }


        // axios.post(url_local.checkuserstatus,payload,{ headers })
        // .then(function (response) {
        //     console.warn(response);
        //     if (response.data.Status === 1) {
        //         secureLocalStorage.setItem('FN_', response.data.Data.user_name);
        //     }
        //     else if (response.data.Status === 0) {
        //         if (response.data.Code === 3 || response.data.code === 7 || response.data.Data.Status === 600) {
        //             $(".modal-title").html("");
        //             $(".modal-body").html("<p class=class_p>Your session expired.</p>2");
        //             $(".modal-footer").html("");
        //             $(".modal-footer").html("<button id=closesessionclosebtn1>ok</button>");
        //             $("body").addClass('no-select'); secureLocalStorage.clear();
        //             $("#closesessionclosebtn1").addClass('btn btn-block');
        //             $("#closesessionclosebtn1").on("click", function () {
        //                 $("#modalDialog").toggle('hide');
        //                 window.location.href = "/";
        //             });
        //             if($("#modalDialog" ).hasClass( "show" )){
        //                 $(".main-panel").css("filter","blur(8px)");
        //             }
        //             else{
        //                 $(".main-panel").css("filter","blur(8px)");
        //                 $("#modalDialog").toggle('show');
        //             }
        //         }
        //         else {
        //             $(".modal-title").html("");
        //             $(".modal-body").html(response.data.Message);
        //             $(".modal-footer").html("");
        //             $(".modal-footer").html("<button id=closebtn1>ok</button>");
        //             $("#closebtn1").addClass('btn btn-block');
        //             $("#closebtn1").on("click", function () {
        //                 $("#modalDialog").toggle('hide');
        //             });
        //             $("#modalDialog").toggle('show');
        //         }
        //     }
        //     else {
        //         $(".modal-body").html("<p class='text-danger'>Something went wrong. Try again.</p>");
        //         $(".modal-title").html("")
        //         $(".modal-footer").html("");
        //         $(".modal-footer").html("<button id=got_it1>Got it</button>");
        //         $("#got_it1").addClass("btn btn-block");
        //         $("#got_it1").on("click", function () {
        //             $("#modalDialog").toggle('hide');
        //         });
        //         $("#modalDialog").toggle('show');
        //     }
        //     return;
        // })
        // .catch(function () {
        //     return;
        // });
        // console.warn(`accdata`,accdata);
        if (fiat.accdata.length > 0){
            setAccountData(fiat.accdata);
            setLoading(false);
        }
        else{
            // setLoading(false);
            console.warn(`call accountlist`);
            axios.post(url_local.accountlist, payload, { headers })
            .then(function (response) {
            setLoading(false);
            if (response.data.Status === 1) {
                setAccountData(response.data.Data.fiat.list)
            }
            })
            .catch(function () {
            });
        }
        // loadData();
    }, [fiat]);
    // const transferSchema = Yup.object().shape({
    //     rxaccount: Yup.string()
    //         .required("Please enter a valid account number"),
    //     Transferamount: Yup.number()
    //         .typeError('Please enter a amount')
    //         .min(1, 'Amount should not be zero')
    //         .required("Please enter an amount"),
    // });

    const defaultValues = {
        rxaccount: '',
        Transferamount: '',
    };

    const methods = useForm({
        // resolver: yupResolver(transferSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const previewdetails = () => {
        // const headers = {
        //     "Content-Type": "application/json",
        //     "Access-Token": secureLocalStorage.getItem('AT_'),
        //     "Refresh-Token": secureLocalStorage.getItem('RT_')
        // };
        var amt = parseFloat(document.getElementById("Transferamount").value);
        const payload = {
            "publicid": secureLocalStorage.getItem('PI_'),
            "amount": amt,
            "currency": currency,
            "txaccount": accountNo,
            "rxaccount": document.getElementById("rxaccount").value,
            "bankname": "",
            "bankcode": "",
            "transactionref": "",
            "transferdate": "",
            "txnotes": document.getElementById("remarks1").value,
            "txtypeid": 202,
            "isconfirmed": false,
            "pin": 0
        }
        axios.post(url_local.fiattransfer,payload,{ headers })
            .then(function (response) {
                if (response.data.Status === 1) {
                    setpreviewData(response.data.Data);
                    // $("#myModal").toggle('show');
                    document.querySelector('#view-right-sidebar').classList.toggle('open');
                }
                else if (response.data.Status === 0) {
                    setGetdata(false);
                    if (response.data.Code === 3102) {
                        $(".modal-title").html("");
                        $(".modal-body").html('<p class=class_p>' + response.data.Message + '.</p>');
                        $(".modal-footer").html("");
                        $(".modal-footer").html("<button id=redirect>Activate now?</button><button id=cancelredirect>Cancel</button>");
                        $("#redirect").addClass("btn btn-block");
                        $("#cancelredirect").addClass("btn btn-block");
                        $("#redirect").on("click", function () {
                            history(routeCodes.SECURITY);
                            $("#modalDialog").toggle('hide');
                        });
                        $("#cancelredirect").on("click", function () {
                            $("#modalDialog").toggle('hide');
                        });
                        $("#modalDialog").toggle('show');
                    }
                    else if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
                        alert(response.data.Data.Status)
                        $(".modal-title").html("");
                        $(".modal-body").html("<p class=class_p>Your session expired</p>");
                        $(".modal-footer").html("");
                        $(".modal-footer").html("<button id=signoutbtn1>ok</button>");
                        $("body").addClass('no-select'); secureLocalStorage.clear();
                        $("#signoutbtn1").addClass('btn btn-block');
                        $("#signoutbtn1").on('click', function () {
                            secureLocalStorage.clear();
                            $("#modalDialog").toggle('hide');
                            window.location.href = "/";
                            return;
                            // var headers = {
                            //     "Content-Type": "application/json",
                            //     'Access-Token': secureLocalStorage.getItem('AT_'),
                            //     'Refresh-Token': secureLocalStorage.getItem('RT_')
                            // }
                            // axios
                            //     .post(url_local.logout, { publicid: secureLocalStorage.getItem('PI_') },
                            //     { headers })
                            //     .then(function (response) {
                            //     if (response.data.Status === 1) {
                            //         history(routeCodes.HOME);
                            //         secureLocalStorage.clear()
                            //     }
                            //     else if (response.data.Status === 0) {
                            //         $(".modal-title").html("")
                            //         $(".modal-body").html("<p class=class_p" > +response.data.Message + "</p>");
                            //         $(".modal-title").html("")
                            //         $(".modal-footer").html("");
                            //         $(".modal-footer").html("<button id=oknew>ok</button>");
                            //         $("#oknew").addClass("btn btn-block");
                            //         $("#oknew").on("click", function () {
                            //         $("#modalDialog").toggle('hide');
                            //         });
                            //         $("#modalDialog").toggle('show');
                            //     }
                            //     else {
                            //         $(".modal-title").html("")
                            //         $(".modal-body").html("<p class='class_p text-danger'>Something went wrong. Try again.</p>");
                            //         $(".modal-title").html("")
                            //         $(".modal-footer").html("");
                            //         $(".modal-footer").html("<button id=ok5>ok</button>");
                            //         $("#ok5").addClass("btn btn-block");
                            //         $("#ok5").on("click", function () {
                            //         $("#modalDialog").toggle('hide');
                            //         });
                            //         $("#modalDialog").toggle('show');
                            //     }
                            //     })
                            //     .catch(function (error) {
                            //     return;
                            //     });
                        });
                        $("#modalDialog").toggle('show');
                      }
                      else {
                        $(".modal-title").html("");
                        $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
                        $(".modal-footer").html("");
                        $(".modal-footer").html("<button id=cancelredirecttransfer>Got it</button>");
                        $("#cancelredirecttransfer").addClass("btn btn-block");
                        $("#cancelredirecttransfer").on("click", function () {
                            $("#modalDialog").toggle('hide');
                        });
                        $("#modalDialog").toggle('show');
                    }
                }
                else {
                    setGetdata(false);
                    $(".modal-title").html("");
                    $(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
                    $(".modal-footer").html("");
                    $(".modal-footer").html("<button id=cancelredirect>Got it</button>");
                    $("#cancelredirect").addClass("btn btn-block");
                    $("#cancelredirect").on("click", function () {
                        $("#modalDialog").toggle('hide');
                    });
                    $("#modalDialog").toggle('show');
                }
                return;
            })
            .catch(function (err) {
                setGetdata(true);
                if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
                // secureLocalStorage.clear();
                $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
                $(".modal-title").html("")
                $(".modal-footer").html("<button id=redirect>Got it</button>");
                $("#redirect").addClass("btn btn-block");
                $("#redirect").on("click", function () {
                    $("#modalDialog").toggle('hide');
                });
                $("#modalDialog").toggle('show');
                }
                else{
                    console.warn(err,"FiatTransfer");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
                    $(".modal-title").html("")
                    $(".modal-footer").html("");
                    $(".modal-footer").html("<button id=closebtn1>ok</button>");
                    $("#closebtn1").addClass('btn btn-block');
                    $("#closebtn1").on("click", function () {
                        $("#modalDialog").toggle('hide');
                    });
                    $("#modalDialog").toggle('show');
                }
                return;
            });
    }

    const submitTransactiondetails = (event) => {
        // var headers = {
        //     "Content-Type": "application/json",
        //     "Access-Token": secureLocalStorage.getItem('AT_'),
        //     "Refresh-Token": secureLocalStorage.getItem('RT_')
        // };
        var amt = parseFloat(document.getElementById("Transferamount").value);
        var data = {
            "publicid": secureLocalStorage.getItem('PI_'),
            "amount": amt,
            "currency": currency,
            "txaccount": accountNo,
            "rxaccount": document.getElementById("rxaccount").value,
            "bankname": "",
            "bankcode": "",
            "transactionref": "",
            "transferdate": "",
            "txnotes": document.getElementById("remarks1").value,
            "txtypeid": 202,
            "isconfirmed": true,
            "pin": document.getElementById("fiat_pin").value
        }

        axios
            .post(url_local.fiattransfer,
                data,
                { headers })
            .then(function (response) {
// console.log("fiat_transfer", response.data);
                if (response.data.Status === 1) {
                    document.querySelector('#view-right-sidebar').classList.remove('open');
                    document.getElementById("errMsg").style.display = "none";
                    $(".modal-title").html("");
                    $(".modal-body").html('<p class=class_p>' + response.data.Message + '.</p>');
                    $(".modal-footer").html("");
                    $(".modal-footer").html("<button id=cancelredirect>Got it</button>");
                    $("#cancelredirect").addClass("btn btn-block");
                    $("#cancelredirect").on("click", function () {
                        history(routeCodes.CREATEFIATACCOUNT);
                        $("#modalDialog").toggle('hide');
                    });
                    $("#modalDialog").toggle('show');
                }
                else if (response.data.Status === 0) {
                    setGetdata(false);
                    document.querySelector('#view-right-sidebar').classList.remove('open');
                    if (response.data.Code === 3102) {
                        document.getElementById("errMsg").style.display = "block";
                        document.getElementById("errMsg").innerHTML = response.data.Message;
                    }
                    else if (response.data.Status === 0) {
                        if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
                          $(".modal-title").html("");
                          $(".modal-body").html("<p class=class_p>Your session expired</p>");
                          $(".modal-footer").html("");
                          $(".modal-footer").html("<button id=signoutbtn>ok</button>");
                          $("body").addClass('no-select'); secureLocalStorage.clear();
                          $("#signoutbtn").addClass('btn btn-block');
                          $("#modalDialog").toggle('show');
                          $("#signoutbtn").on('click', function () {
                            $("#modalDialog").toggle('hide');
                            window.location.href = "/";
                          });
                        }
                       else{
                        $(".modal-title").html("");
                        $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
                        $(".modal-footer").html("");
                        $(".modal-footer").html("<button id=cancelredirect1>Got it</button>");
                        $("#cancelredirect1").addClass("btn btn-block");
                        $("#cancelredirect1").on("click", function () {
                          $("#modalDialog").toggle('hide');
                        });
                        $("#modalDialog").toggle('show');
                       }
                      }
                    else {
                        document.getElementById("errMsg").style.display = "block";
                        document.getElementById("errMsg").innerHTML = response.data.Message;
                    }
                }
                else {
                    setGetdata(false);
                    document.getElementById("errMsg").style.display = "block";
                    document.getElementById("errMsg").innerHTML = "Something went wrong. Try again.";
                }
                return;
            })
            .catch(function (err) {
                setGetdata(true);
                if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
                // secureLocalStorage.clear();
                $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
                $(".modal-title").html("")
                $(".modal-footer").html("<button id=redirect>Got it</button>");
                $("#redirect").addClass("btn btn-block");
                $("#redirect").on("click", function () {
                    $("#modalDialog").toggle('hide');
                });
                $("#modalDialog").toggle('show');
                }
                else{
                    console.warn(err,"FiatTransfer");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
                    $(".modal-title").html("")
                    $(".modal-footer").html("");
                    $(".modal-footer").html("<button id=closebtn1>ok</button>");
                    $("#closebtn1").addClass('btn btn-block');
                    $("#closebtn1").on("click", function () {
                        $("#modalDialog").toggle('hide');
                    });
                    $("#modalDialog").toggle('show');
                }
                return;
            });
    }
    const viewcloseRightSidebar = () => {
        document.querySelector('#view-right-sidebar').classList.remove('open');
        document.getElementById('pinsection').style.display = "none";
        document.getElementById('btnsection').style.display = "block";
    }

    const getPinsection = () => {
        document.getElementById("errMsg").style.display = "none";
        document.getElementById('pinsection').style.display = "block";
        document.getElementById('btnsection').style.display = "none";
        document.getElementById("fiat_pin").value = "";
    }


    const ContinueTransfer = () => {
        document.getElementById("receipient").innerHTML = "";
        document.getElementById("from_account").innerHTML = "";
        document.getElementById("to_account").innerHTML = "";

        // var headers = {
        //     "Content-Type": "application/json",
        //     "Access-Token": secureLocalStorage.getItem('AT_'),
        //     "Refresh-Token": secureLocalStorage.getItem('RT_')
        // };

        var data = {
            "publicid": secureLocalStorage.getItem('PI_'),
            "accountno": document.getElementById("rxaccount").value,
        }
        axios.post(url_local.getaccountowner,data,{ headers })
            .then(function (response) {
                if (response.data.Status === 1) {
                    document.getElementById("receipient").innerHTML = response.data.Data.account_owner;
                    document.getElementById("from_account").innerHTML = accountNo + " (" + currency + ")";
                    document.getElementById("to_account").innerHTML = response.data.Data.accountno + " ( " + response.data.Data.currency + ")";
                    document.getElementById("selectidsection").style.display = "none";
                    document.getElementById("submitdetails").style.display = "block";
                }
                else if (response.data.Status === 0) {
                    document.getElementById("selectidsection").style.display = "block";
                    document.getElementById("submitdetails").style.display = "none";
                    if (response.data.Code === 3 || response.data.Code === 7) {
                        $(".modal-title").html("");
                        $(".modal-body").html("<p class=class_p>Your session expired.</p>3");
                        $(".modal-footer").html("");
                        $(".modal-footer").html("<button id=closesessionclosebtn>ok</button>");
                        $("body").addClass('no-select'); secureLocalStorage.clear();
                        $("#closesessionclosebtn").addClass('btn btn-block');
                        $("#closesessionclosebtn").on("click", function () {
                            $("#modalDialog").toggle('hide');
                            window.location.href = "/";
                        });
                        if($("#modalDialog" ).hasClass( "show" )){
                         $(".main-panel").css("filter","blur(8px)");
                         $("#modalDialog").toggle('show');
                        }
                        else{
                            $(".main-panel").css("filter","blur(8px)");
                            $("#modalDialog").toggle('show');
                        }
                    }
                    else {
                        $(".modal-body").html("<p class=class_p>" + response.data.Response + "</p>");
                        $(".modal-title").html("")
                        $(".modal-footer").html("");
                        $(".modal-footer").html("<button id=closebtn>ok</button>");
                        $("#closebtn").addClass('btn btn-block');
                        $("#closebtn").on("click", function () {
                            $("#modalDialog").toggle('hide');
                        });
                        $("#modalDialog").toggle('show');
                    }
                }
                else {
                    console.warn("FiatTransfer");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
                    $(".modal-title").html("")
                    $(".modal-footer").html("");
                    $(".modal-footer").html("<button id=closebtn>ok</button>");
                    $("#closebtn").addClass('btn btn-block');
                    $("#closebtn").on("click", function () {
                        $("#modalDialog").toggle('hide');
                    });
                    $("#modalDialog").toggle('show');
                }

                return;
            })
            .catch(function (err) {
                setGetdata(true);
                if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
                // secureLocalStorage.clear();
                $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
                $(".modal-title").html("")
                $(".modal-footer").html("<button id=redirect>Got it</button>");
                $("#redirect").addClass("btn btn-block");
                $("#redirect").on("click", function () {
                    $("#modalDialog").toggle('hide');
                });
                $("#modalDialog").toggle('show');
                }
                else{
                    console.warn(err,"FiatTransfer");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
                    $(".modal-title").html("")
                    $(".modal-footer").html("");
                    $(".modal-footer").html("<button id=closebtn1>ok</button>");
                    $("#closebtn1").addClass('btn btn-block');
                    $("#closebtn1").on("click", function () {
                        $("#modalDialog").toggle('hide');
                    });
                    $("#modalDialog").toggle('show');
                }
                return;
            });

    }

    const BackToTransfer = () => {
        document.getElementById("selectidsection").style.display = "block";
        document.getElementById("submitdetails").style.display = "none";
        // document.getElementById("submituploadbtn").disabled = true;
    }


    var array = [];
    array.push(previewData);

    var transactionData = array.map((item, index) => {
        return (
            <div key={index} id={index} className=" pt-4">
                <h4 className='mb-3'>Confirm payment</h4>
                <div className='row mt-5'>
                    <div className='col-md-4 col-xs-12'>
                        <p className='text-center'>{item.Debit_Currency}</p>
                        <p className='class_p'>{accountNo}</p>
                        <div className='mt-2 text-left'>
                            <p>Amount debited : {item.Debited_Amount}</p>
                            <p>Service Charge : {item.Service_Charge}</p>
                        </div>
                    </div>
                    <div className='col-md-3 col-xs-12 text-center'>
                        <i className='mdi mdi-arrow-right'></i>
                    </div>
                    <div className='col-md-4 col-xs-12'>
                        <p className='text-center'>{item.Credit_Currency}</p>
                        <p className='class_p'>{item.Credit_Account}</p>
                        <div className='mt-2 text-left'>
                            <p>Credited Amount : {item.Credit_Amount}</p>
                        </div>
                    </div>
                </div>
                <h4 style={{ borderTop: "1px solid #242736" }} className="mb-3 pt-3">Total : {item.Debited_Amount}</h4>
            </div>
        )
    })


    // var storedfiat_datas = JSON.parse(secureLocalStorage.getItem("FL_"));
    function getFiatAccounts() {
        return accountData.length > 0 && accountData.map((item, i) => {
            if (item.account === undefined || item.account === "" || item.account === null) {
                return (
                    <option key={i} disabled>No accounts created</option>
                )
            }
            else {
                let text = item.type;
                const type = String(text.split("account")).replace(',', '').toUpperCase();
                return (
                    <option key={i} value={type} id={item.account}>
                        {type}
                    </option>
                )
            }
        });
    }

    const handleonChange = (e) => {
        // secureLocalStorage.setItem("CS_","");
        // secureLocalStorage.setItem("AN_","");
        const index = e.target.selectedIndex;
        const el = e.target.childNodes[index]
        const option = el.getAttribute('id');
        // secureLocalStorage.setItem("CS_", e.target.value);
        // secureLocalStorage.setItem("AN_", option);
        setAccountNo(option);
        setCurrency(e.target.value);
        document.getElementById("acnofiat.").innerHTML = "Account No. :" + option;
        document.getElementById("acnofiat.").style.display = "block";
    }
    return (
        <>
            <div className='px-2 mx-3 rounded-legend legend-bottom-left'>
                <p className='card-description pt-3 px-4'>Start a new transfer with Meezan</p>
                <div className="mt-2 px-4" id="accountTransfer">
                    <div id="selectidsection">
                        <div className="row">
                            <div className="col-md-6 col-xs-12 mb-3">
                                <label className="form-label">Select account</label>
                                {
                                    loading?<div className="spinner-border spinner-border-sm " role="status"></div>:
                                    <select defaultValue={currency}
                                        className="form-select"
                                        aria-label="Floating label select example"
                                        onChange={handleonChange}>
                                        <option value="" disabled >
                                            -- Choose one --
                                        </option>
                                        {getFiatAccounts()}
                                    </select>
                                }
                                {accountNo === ""?
                                    <span id="acnofiat." className='class_p card-description' style={{ display: 'none' }}></span>
                                    :
                                    <span id="acnofiat." className='class_p card-description' style={{ display: 'block' }}>Account No. : {accountNo}</span>
                                }
                                <a className='auth-link text-white' href={routeCodes.CREATEFIATACCOUNT}>View all accounts
                                    <i className='mdi mdi-open-in-new mx-2'></i></a>
                            </div>
                        </div>
                        <p>Enter Transaction details</p>
                        <div className="pb-3 mt-2">
                            <FormProvider methods={methods} onSubmit={handleSubmit(ContinueTransfer)}>
                                <div className='row'>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label class_p">Beneficiary Account *</label>
                                        <div className="col-sm-6">
                                            {/* <RHFTextField name="rxaccount" id='rxaccount' placeholder="" /> */}
                                            <input type='text' name="rxaccount" id='rxaccount' className='form-control' placeholder="" maxLength={35} minLength={30} pattern="([0-9-]){30,35}" title='Must be 30-35 numbers long with hyphens!'/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label class_p">Enter Amount *</label>
                                        <div className="col-sm-6">
                                            {/* <RHFTextField name="Transferamount" id='Transferamount' placeholder="" /> */}
                                            <input type='number' name="Transferamount" id='Transferamount' className='form-control' placeholder="" min={1} max={9999} title='Amount should be from 1-9999'/>

                                        </div>
                                    </div>
                                </div>
                                {!getdata ?
                                    <div className='text-center'>
                                        <LoadingButton size="small" type="submit" variant="contained" loading={isSubmitting} className="btn-sm btn-yodigi-buy text-center">
                                            Next
                                        </LoadingButton>
                                    </div>
                                    :
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }
                            </FormProvider>
                        </div>
                    </div>

                    <div className="auth-form pb-3 " id="submitdetails" style={{ display: 'none' }}>
                        <div className="row">
                            <label className="card-description text-label font-bold mr-1">Receipient Name :
                                <span id="receipient" className='mx-2 card-description text-label font-bold'></span></label>

                            <label className="card-description text-label font-bold mr-1"> Beneficiary account :
                                <span id="to_account" className='mx-2 card-description text-label font-bold'></span></label>

                            <label className="card-description text-label font-bold mr-1"> Sender Account :
                                <span id="from_account" className='mx-2 card-description text-label font-bold'></span></label>

                            {/* <label className="card-description text-label font-bold mr-1">  Account Type :
                                            <span id="from_account_type" className='mx-2 card-description text-label font-bold'></span></label> */}
                            <div className="col-12 mb-3">
                                <label className="card-description text-label font-bold">Remarks(optional)</label>
                                <textarea col="10" rows="4" type="text" id="remarks1" className="form-control form-control-sm" required maxLength={200} ></textarea>
                            </div>
                        </div>
                        <div className="text-center mt-3">
                            <button className="btn btn-yodigi-buy ps-5 pe-5" id="203" onClick={previewdetails}>Submit</button>
                            <button className="btn btn-sm " onClick={BackToTransfer}>Back</button>
                        </div>
                    </div>
                </div>
            </div>


            <div id="view-right-sidebar"
                className="px-4 settings-panel right-sidebar">
                <i className="settings-close mdi mdi-arrow-left justify-content-left"
                    onClick={viewcloseRightSidebar}></i>
                <div className="" id="uncontrolled-tab-example ">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="px-3">
                                <ul >
                                    {transactionData}
                                </ul>
                                <div id="btnsection" className='p-2' style={{ display: 'block' }}>
                                    <button className='btn-sm btn-default' onClick={viewcloseRightSidebar}>Cancel</button>
                                    <button className='btn-sm btn-yodigi-buy' onClick={getPinsection}>Confirm payment</button>
                                </div>
                                <div id="pinsection" style={{ display: 'none' }} className="mt-3">
                                    <p className='class_p'>Enter your Security PIN</p>
                                    <FormProvider methods={methods} onSubmit={handleSubmit(ContinueTransfer)}>
                                        <div className="col-12">
                                            <RHFTextField name="pin"
                                                id="fiat_pin" placeholder=""
                                                type={showPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: (<InputAdornment position="end">
                                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                            <Iconify style={{ color: '#fff' }} icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                        </IconButton>
                                                    </InputAdornment>)
                                                }} />
                                        </div>
                                    </FormProvider>
                                    <div id="btnsection" className='p-2 text-center'>
                                        <button className='btn-sm btn-default mx-2' onClick={viewcloseRightSidebar}>Cancel</button>
                                        <button type="submit" className="btn-sm btn-yodigi-buy mx-2" id="202"
                                            onClick={submitTransactiondetails}>Send</button>
                                    </div>
                                    <p className='text-danger mt-1' id="errMsg" style={{ display: 'none' }}></p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

