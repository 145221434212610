import React from 'react';
import * as Yup from 'yup';
import $ from 'jquery';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../components/Iconify';
import { FormProvider, RHFTextField } from '../components/hook-form';
// import image from '../../assets/images/web/logo.svg';
import logo from '../../assets/images/web/logo.svg';
import logo_black from '../../assets/images/web/logo_black.svg';
import routeCodes from '../../Paths';
import url_local from '../url_local';
import { GlobalContext } from '../shared/useForm';
import secureLocalStorage from 'react-secure-storage';
//css
// import '../web/web.scss';

// ----------------------------------------------------------------------

export default function LoginForm() {
	const gContext = React.useContext(GlobalContext);
	const history = useNavigate();
	const [getdata, setGetdata] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [img_logo, setImg_logo] = React.useState(logo);
	const mousedown = React.useRef(false);
	const mouseover = React.useRef(false);
	React.useEffect(() => {
		let current = localStorage.getItem("theme")
		const body = document.body
		if (current === "light-theme"){
      		setImg_logo(logo_black);
			body.classList.remove('dark-mode');
		}
		else{
			setImg_logo(logo);
			body.classList.add('dark-mode');
		}
	},[])

	const LoginSchema = Yup.object().shape({
		email: Yup.string().email('Email must be a valid email address').required('Email is required'),
		password: Yup.string().required('Password is required').matches(/^\S*$/, 'Whitespace is not allowed'),
	});

	const defaultValues = {
		email: '',
		password: '',
	};

	const methods = useForm({
		resolver: yupResolver(LoginSchema),
		defaultValues,
	});

	const {
		handleSubmit,
		formState: { isSubmitting },
	} = methods;

	const onSubmit = () => {
		// VERSION 1 of Calling API via axios ReactJS
		var loginHeaders = { "Content-Type": "application/json" };
		setGetdata(true);
		axios.post(url_local.login,
			{
				"email": document.getElementById('email').value,
				"password": document.getElementById('password').value,
			},
			{ loginHeaders })
			.then(function (response) {
				// console.warn("login---------",response.data)
				if (response.data.Status === 1) {
					secureLocalStorage.setItem('PI_', response.data.Data.publicid);
					secureLocalStorage.setItem('AT_', response.data.Data['Access-Token']);
					secureLocalStorage.setItem('EM_', response.data.Data.email);
					secureLocalStorage.setItem('LS_', true);
					if (gContext) gContext.user(response.data, true);

					// setting last login time
					var d = new Date(response.data.Data.last_loggedon);
					let Offset = (new Date().getTimezoneOffset()) * -1;
					var hours = (Offset / 60);
					var rhours = Math.floor(hours);
					var minutes = (hours - rhours) * 60;
					//   var rminutes = Math.round(minutes);
					d.setHours(d.getHours() + rhours, d.getMinutes() + minutes, d.getSeconds());
					var dateString = d.toLocaleString().split('t')[0];
					secureLocalStorage.setItem('DT_', dateString);

					secureLocalStorage.setItem('RT_', response.data.Data['Refresh-Token']);
					var fAccounts = []; var CrAccounts = [];
					for (let i = 0; i < response.data.Data.supported_currencies.fiat.length; i++) {
						fAccounts.push(response.data.Data.supported_currencies.fiat[i])
					}
					for (let j = 0; j < response.data.Data.supported_currencies.crypto.length; j++) {
						CrAccounts.push(response.data.Data.supported_currencies.crypto[j])
					}

					secureLocalStorage.setItem('FA_', JSON.stringify(fAccounts));
					secureLocalStorage.setItem('CRA_', JSON.stringify(CrAccounts));
					document.getElementById('email').value = "";
					document.getElementById('password').value = "";
					setGetdata(false);

					// let headers = {
					// 	'Access-Token': response.data.Data['Access-Token'],
					// 	"Refresh-Token": response.data.Data['Refresh-Token']
					// };
					// let payload = { publicid: response.data.Data.publicid }
					// axios.post(url_local.accountlist, payload, { headers })
					// .then(function (response) {
					// 	if (response.data.Status === 1) {
					// 		secureLocalStorage.setItem("FL_", JSON.stringify(response.data.Data.fiat.list));
					// 		secureLocalStorage.setItem("CRL_", JSON.stringify(response.data.Data.crypto.list));
					// 	}
					// 	else {
					// 		secureLocalStorage.setItem("FL_", JSON.stringify({}));
					// 		secureLocalStorage.setItem("CRL_", JSON.stringify({}));
					// 		secureLocalStorage.setItem('LS_', false);
					// 	}
					// })
					// .catch(function () {
					// });
					history(routeCodes.OVERVIEW, secureLocalStorage.getItem('DT_'));
				}
				else if (response.data.Status === 0) {
					setGetdata(false);
					secureLocalStorage.setItem('LS_', false);
					$(".modal-title").html("");
					$(".modal-body").html("");
					secureLocalStorage.setItem('EM_', document.getElementById('email').value);
					if (response.data.Code === 1100 || response.data.Code === 1006) {
						$(".modal-title").html("");
						$(".modal-body").html('<p class=class_p>' + response.data.Message + '<br />Please activate your account.</p>');
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=redirect>Activate now ?</button><button id=cancelredirect>Cancel</button>");
						$("#redirect").addClass("btn btn-block");
						$("#cancelredirect").addClass("btn btn-block");
						$("#redirect").on("click", function () {
							ActivateCode(response.data.Code);
							$("#modalDialog").toggle('hide');
						});
						$("#cancelredirect").on("click", function () {
							$("#modalDialog").toggle('hide');
						});
						$("#modalDialog").toggle('show');
					}
					else if (response.data.Code === 1101) {
						$(".modal-title").html("");
						$(".modal-body").html('<p class=class_p>' + response.data.Message + '<br />If you want to continue, Please do reset your password and verify your account again.</p>');
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=redirect>Reset password</button><button id=cancelredirect>Cancel</button>");
						$("#redirect").addClass("btn btn-block");
						$("#cancelredirect").addClass("btn btn-block");
						$("#redirect").on("click", function () {
							window.location.href = routeCodes.RESET;
							$("#modalDialog").toggle('hide');
						});
						$("#cancelredirect").on("click", function () {
							$("#modalDialog").toggle('hide');
						});
						$("#modalDialog").toggle('show');
					}
					else if (response.data.Code === 1104) {
						$(".modal-title").html("");
						$(".modal-body").html('<p class=class_p>' + response.data.Message + '<br />Please verify your Email now.</p>');
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=redirect>Verify now</button><button id=cancelredirect>Cancel</button>");
						$("#redirect").addClass("btn btn-block");
						$("#cancelredirect").addClass("btn btn-block");
						$("#redirect").on("click", function () {
							ActivateCode(response.data.Code);
							$("#modalDialog").toggle('hide');
						});
						$("#cancelredirect").on("click", function () {
							$("#modalDialog").toggle('hide');
						});
						$("#modalDialog").toggle('show');
					}
					else if (response.data.Code === 1105) {
						$(".modal-title").html("");
						$(".modal-body").html('<p class=class_p>' + response.data.Message + '<br />Please verify your Mobile number now.</p>');
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=redirect>Verify now</button><button id=cancelredirect>Cancel</button>");
						$("#redirect").addClass("btn btn-block");
						$("#cancelredirect").addClass("btn btn-block");
						$("#redirect").on("click", function () {
							ActivateCode(response.data.Code);
							$("#modalDialog").toggle('hide');
						});
						$("#cancelredirect").on("click", function () {
							$("#modalDialog").toggle('hide');
						});
						$("#modalDialog").toggle('show');
					}
					else {
						$(".modal-title").html("");
						$(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=cancelredirect>Got it</button>");
						$("#cancelredirect").addClass("btn btn-block");
						$("#cancelredirect").on("click", function () {
							$("#modalDialog").toggle('hide');
						});
						$("#modalDialog").toggle('show');
					}
				}
				else {
					secureLocalStorage.getItem('LS_', false);
					setGetdata(false);
					$(".modal-title").html("");
					$(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=cancelredirect>Got it</button>");
					$("#cancelredirect").addClass("btn btn-block");
					$("#cancelredirect").on("click", function () {
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
				}
			})
			.catch(function (err) {
				if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
				// secureLocalStorage.clear();
				$(".modal-body").html("<p className='text-danger'>Network Error!</p>");
				$(".modal-title").html("")
				$(".modal-footer").html("<button id=redirect>Got it</button>");
				$("#redirect").addClass("btn btn-block");
				$("#redirect").on("click", function () {
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
				}
				else{
					console.warn(err,"LoginForm");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
					$(".modal-title").html("")
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=closebtn1>ok</button>");
					$("#closebtn1").addClass('btn btn-block');
					$("#closebtn1").on("click", function () {
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
				}
				return;
			});
	}

	const ActivateCode = (code) => {
		setGetdata(true);
		var headers = { "Content-Type": "application/json" };

		axios
			.post(url_local.activateCode,
				{
					"email": secureLocalStorage.getItem('EM_')
				},
				{ headers })
			.then(function (response) {
// console.log(response.data)
				if (response.data.Status === 1) {
					setGetdata(false);
// console.log(code)
					if (code === 1100 || code === 1104 || code === 1006) {
						history(routeCodes.ACTIVE_EMAILOTP, secureLocalStorage.getItem('EM_'));
					}
					else if (code === 1105) {
						history(routeCodes.ACTIVE_MOBILEOTP, secureLocalStorage.getItem('EM_'));
					}
					else if (code === 1101) {
						history(routeCodes.RESET, secureLocalStorage.getItem('EM_'));
					}
					else {
						history(routeCodes.LOGIN);
					}
				}
				else if (response.data.Status === 0) {
					setGetdata(false);
					$(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
					$(".modal-title").html("")
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=redirect>Got it</button>");
					$("#redirect").addClass("btn btn-block");
					$("#redirect").on("click", function () {
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
				}
				else {
					setGetdata(false);
					$(".modal-body").html("<p className='text-danger'>Something went wrong. Try again.</p>");
					$(".modal-title").html("")
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=redirect>Got it</button>");
					$("#redirect").addClass("btn btn-block");
					$("#redirect").on("click", function () {
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
				}
				return;
			})
			.catch(function () {
				$(".modal-body").html("<p className='text-danger'>Something went wrong. Try again.</p>");
				$(".modal-title").html("")
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=redirect>Got it</button>");
				$("#redirect").addClass("btn btn-block");
				$("#redirect").on("click", function () {
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
				return;
			});
	}

	const handleEvent = (event) => {
		// console.log(event.type);
		if (event.type === "mousedown") {
			mousedown.current = true;
		}
		else if (event.type === "mouseup") {
			mousedown.current = false;
		}

		if (event.type === "mouseover") {
			mouseover.current = true;
		}
		else if (event.type === "mouseout") {
			mouseover.current = false;
			mousedown.current = false;
		}

		if (mousedown.current && mouseover.current) {
			setShowPassword(true);
		}
		else {
			setShowPassword(false);
		}
	}
	return (
		<div className="d-flex align-items-center auth px-0 pt-5 mt-5">
			<div className="row w-100 mx-0">
				<div className="brand-logo text-center mb-3" >
					{/* <div onClick={CancelReset} style={{curspor:'pointer'}}>
							<img className='image' src={image} alt="Meezan" />
						</div> */}
					<Link className="navbar-brand" to={routeCodes.HOME}>
						<img className='image' src={img_logo} alt="Meezan" />
					</Link>
				</div>
				<div className="col-md-6 col-xs-12 mx-auto card">
					<div className="card-body text-left py-3 ">
						<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
							<div className="d-flex flex-column justify-content-center mb-3">
								<h4 className='text-center text-white'>Hello! let's get started</h4>
								<h6 className="text-center font-weight-light class_p">Sign in to continue.</h6>
							</div>
							<Stack spacing={3}>
								<RHFTextField name="email" id='email' placeholder="Email address" inputProps={{ className: "text-lowercase" }} />
								<RHFTextField
									name="password"
									id="password"
									placeholder="Password"
									type={showPassword ? 'text' : 'password'}
									InputProps={{
										endAdornment: (
											// <InputAdornment position="end">
											// 	<IconButton onClick={() => { setShowPassword(!showPassword); }} edge="end">
											// 		<Iconify style={{ color: '#fff' }} icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
											// 	</IconButton>
											// </InputAdornment>
											<InputAdornment position="end">
												<IconButton onMouseDown={handleEvent} onMouseUp={handleEvent} onMouseOver={handleEvent} onMouseOut={handleEvent} edge="end">
													<Iconify style={{ color: '#fff' }} icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Stack>
							<div className="text-center mt-3">
								{!getdata ?
									<LoadingButton size="medium" type="submit" variant="contained" loading={isSubmitting} className="btn-sm btn-yodigi-buy">
										Login
									</LoadingButton>
									:
									<div className="spinner-border" role="status">
										<span className="sr-only">Loading...</span>
									</div>
								}
							</div>
						</FormProvider>
						<div className="mt-3 d-flex flex-sm-row flex-column">
							<div className="mr-auto p-2"><p className='class_p'>New to Meezan? <a
								href={routeCodes.SIGNUP}> Create an account.</a></p></div>
							<div className="p-2 ms-auto"><Link to={routeCodes.RESET}
								className="auth-link">
								Forgot password?</Link>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	);
}
