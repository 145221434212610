import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Card, CardHeader } from '@mui/material';
import Avatar from '@mui/material/Avatar';

import secureLocalStorage from 'react-secure-storage';

//import components
import url_local from '../../url_local';
import routeCodes from '../../../Paths';
import KycIsSubmitted from '../KycIsSubmitted';

//import css
import '../../web/web.scss';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Overview() {
  console.info(`-->`,`src\\app\\users\\wallet\\Overview.js`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  // const gContext = React.useContext(GlobalContext);
  const [srcCode, setsrcCode] = useState("*******");
  const [getdata, setGetdata] = useState(true);
  const [isActive, setisActive] = useState(true);
  const [ShowaccountActivateDiv, setShowAccountActivateDiv] = useState("none");
  const [fiatbalance, setFiatbalance] = useState("0.00");
  const [cryptobalance, setCryptobalance] = useState("0.00");
  const [Copy, setCopy] = useState(false);
  const history = useNavigate();
  const options = {
    responsive: true,
    aspectRatio:2|4,
    plugins: {
      legend: {
        display:false,
      },
      title: {
        display: true,
        text: 'Digital Asset Portfolio Indicator',
      },
      tooltip: {
          // enabled:false,
          afterLabel:'test',
          callbacks: {
              label: function(context) {
                  let label = context.dataset.label || '';

                  if (label) {
                      label += ': ';
                  }
                  if (context.parsed.y !== null) {
                      label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                  }
                  return label;
              }
          }
      },
    },
    maintainAspectRatio: false,
    scales:{
      x:{
        backdropColor: '#3EE278',
        border:{ color: '#3c3c3c', width: 2},
        grid:{
          display:false,
        }
      },
      y:{
        color: '#3EE278',
        border:{ color: '#3c3c3c', width: 2},
        grid:{
          color: '#3c3c3c',
        }
      }
    },
  };
  // const labels = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];
  const [chartdata, setChartdata] = useState({
    labels:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
    datasets: [
      {
        label: 'Rate',
        data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
        borderColor: '#3EE278',
        backgroundColor: 'white',
        tension: 0.3
      },
    ],
  })


  const format = num =>
    String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      "Access-Token": secureLocalStorage.getItem('AT_'),
      "Refresh-Token": secureLocalStorage.getItem('RT_')
    };
    const payload = {
      publicid: secureLocalStorage.getItem('PI_')
    };
    setGetdata(false);
    axios.post(url_local.accountlist,payload,{ headers })
    .then(function (response) {
      setGetdata(true);
      // console.log(response.data, "overview........................");
      if (response.data.Status === 1) {
        setShowAccountActivateDiv("none");
        //document.getElementById("accountActivateDiv").style.display = "none";
        // document.getElementById("fundAccountDiv").style.display = "block";
        // loadData();
        if (response.data.Data.fiat.totalinusd === 0) {
          setFiatbalance("0.00");
          // document.getElementById("fiatbalance").innerHTML = "0.00";
        }
        else {
          let fbalance = new Intl.NumberFormat().format(parseFloat(response.data.Data.fiat.totalinusd));
          // let fbalance = parseFloat(response.data.Data.fiat.totalinusd).toFixed(2);
          setFiatbalance(fbalance);
          // document.getElementById("fiatbalance").innerHTML = parseFloat(response.data.Data.fiat.totalinusd).toFixed(2);
        }

        if (response.data.Data.crypto.totalinusd === 0) {
          setCryptobalance("0.00");
          // document.getElementById("cryptobalance").innerHTML = "0.00";
        }
        else {
          let cbalance = format(response.data.Data.crypto.totalinusd);
          setCryptobalance(cbalance);
          // document.getElementById("cryptobalance").innerHTML = format(response.data.Data.crypto.totalinusd);
        }
      }
      else if (response.data.Status === 0) {
        setShowAccountActivateDiv("none");
        // document.getElementById("accountActivateDiv").style.display = "none";
        if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 6) {
          $(".modal-title").html("");
          $(".modal-body").html("<p class=class_p>Your session expired</p>");
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=signoutbtnow>ok</button>");
          $("#signoutbtnow").addClass('btn btn-block');
          $("#signoutbtnow").on('click', function () {
            var headers = {
              "Content-Type": "application/json",
              'Access-Token': secureLocalStorage.getItem('AT_'),
              'Refresh-Token': secureLocalStorage.getItem('RT_')
            }
            axios.post(url_local.logout, { publicid: secureLocalStorage.getItem('PI_') },{ headers })
            .then(function (response) {
              // console.log("overview", response.data)
              if (response.data.Status === 1) {
                history(routeCodes.HOME);
                secureLocalStorage.clear()
              }
              else if (response.data.Status === 0) {
                $(".modal-title").html("")
                $(".modal-body").html("<p class=class_p" > +response.data.Message + "</p>");
                $(".modal-title").html("")
                $(".modal-footer").html("");
                $(".modal-footer").html("<button id=ok>OK</button>");
                $("#ok").addClass("btn btn-block");
                $("#ok").on("click", function () {
                  $("#modalDialog").toggle('hide');
                });
                $("#modalDialog").toggle('show');
              }
              else {
                $(".modal-title").html("")
                $(".modal-body").html("<p class='class_p text-danger'>Something went wrong. Try again.</p>");
                $(".modal-title").html("")
                $(".modal-footer").html("");
                $(".modal-footer").html("<button id=ok1>ok</button>");
                $("#ok1").addClass("btn btn-block");
                $("#ok1").on("click", function () {
                  $("#modalDialog").toggle('hide');
                });
                $("#modalDialog").toggle('show');
              }
            })
            .catch(function (error) {
              return;
            });
          });
          $("#modalDialog").toggle('show');
        }
        else if (response.data.Code === 4101) {
          setisActive(false);
          setGetdata(true);
        }
        else {
          $(".modal-title").html("");
          $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=redirect1>Got it</button>");
          $("#redirect1").addClass("btn btn-block");
          $("#redirect1").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
      }
      else {
        $(".modal-title").html("");
        $(".modal-body").html("<p class=class_p>Something went wrong. Try again</p>");
        $(".modal-footer").html("");
        $(".modal-footer").html("<button id=redirectto>Got it</button>");
        $("#redirectto").addClass("btn btn-block");
        $("#redirectto").on("click", function () {
          $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
      }
    })
    .catch(function (err) {
      setGetdata(true);
      if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
      // secureLocalStorage.clear();
      $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
      $(".modal-title").html("")
      $(".modal-footer").html("<button id=redirect>Got it</button>");
      $("#redirect").addClass("btn btn-block");
      $("#redirect").on("click", function () {
          $("#modalDialog").toggle('hide');
      });
      $("#modalDialog").toggle('show');
      }
      else{
          console.warn(err,"Overview");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
          $(".modal-title").html("")
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=closebtn1>ok</button>");
          $("#closebtn1").addClass('btn btn-block');
          $("#closebtn1").on("click", function () {
              $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
      }
      return;
    });
    setGetdata(false);
    // FIXME Error from API side
    axios.post(url_local.cr_portfolio_indicator,payload,{ headers })
    .then(function (response) {
      setGetdata(true);
      if (response.data.Status === 1) {
        setChartdata({
          labels:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
          datasets: [
            {
              label: 'Rate',
              data: response.data.Data.crpfvalue,
              borderColor: '#3EE278',
              backgroundColor: 'white',
              tension: 0.3
            },
          ],
        });
      }
      else if (response.data.Status === 0) {
        $(".modal-title").html("")
        $(".modal-body").html("<p class=class_p" > +response.data.Message + "</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("");
        $(".modal-footer").html("<button id=ok>OK</button>");
        $("#ok").addClass("btn btn-block");
        $("#ok").on("click", function () {
          $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
      }
      else {
        $(".modal-title").html("");
        $(".modal-body").html("<p class=class_p>Something went wrong. Try again</p>");
        $(".modal-footer").html("");
        $(".modal-footer").html("<button id=redirectto>Got it</button>");
        $("#redirectto").addClass("btn btn-block");
        $("#redirectto").on("click", function () {
          $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
      }
    })
    .catch(function (err) {
      setGetdata(true);
      if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
        // secureLocalStorage.clear();
        $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("<button id=redirect>Got it</button>");
        $("#redirect").addClass("btn btn-block");
        $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
      }
      else{
          console.warn(err,"Overview");
          // $(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
          // $(".modal-title").html("")
          // $(".modal-footer").html("");
          // $(".modal-footer").html("<button id=closebtn1>ok</button>");
          // $("#closebtn1").addClass('btn btn-block');
          // $("#closebtn1").on("click", function () {
          //     $("#modalDialog").toggle('hide');
          // });
          // $("#modalDialog").toggle('show');
      }
      return;
    });

  }, [history])


  const RegisterAccount = () => {
    var headers = {
      "Content-Type": "application/json",
      "Access-Token": secureLocalStorage.getItem('AT_'),
      "Refresh-Token": secureLocalStorage.getItem('RT_')
    };

    axios
      .post(url_local.registeraccount,
        {
          publicid: secureLocalStorage.getItem('PI_'),
        },
        { headers })
      .then(function (response) {
        // console.log("wallet overview", response.data)

        if (response.data.Status === 1) {
          setisActive(true);
          setsrcCode(response.data.Data.Mnemoncics);
          document.getElementById("secretcode").innerHTML = response.data.Data.Signkey;
          document.getElementById("mnemonics").innerHTML = response.data.Data.Bitcoinaddress;
          document.getElementById("warning").innerHTML = response.data.Data.Warning;
          if (response.data.Data.Status === 701) {
            setShowAccountActivateDiv("block");
            // document.getElementById("accountActivateDiv").style.display = "block";
          }
          else {
            setShowAccountActivateDiv("none");
            // document.getElementById("accountActivateDiv").style.display = "none";
          }

          if (response.data.Data.fiat.totalinusd === 0) {
            setFiatbalance("0.00");
            // document.getElementById("fiatbalance").innerHTML = 0.00;
          }
          else {
            let fbalance = new Intl.NumberFormat().format(parseFloat(response.data.Data.fiat.totalinusd));
            // let fbalance = parseFloat(response.data.Data.fiat.totalinusd).toFixed(2);
            setFiatbalance(fbalance);
            // document.getElementById("fiatbalance").innerHTML = parseFloat(response.data.Data.fiat.totalinusd).toFixed(2);

            // document.getElementById("fiatbalance").innerHTML =  Math.round((response.data.Data.fiat.totalinusd + Number.EPSILON) * 100) / 100;

          }

          if (response.data.Data.crypto.totalinusd === 0) {
            setCryptobalance("0.00");
            document.getElementById("cryptobalance").innerHTML = 0.00;
          }
          else {
            let cbalance = format(response.data.Data.crypto.totalinusd);
            setCryptobalance(cbalance);
            // document.getElementById("cryptobalance").innerHTML = response.data.Data.crypto.totalinusd;
          }
          setGetdata(true);
          // loadData();
        }
        else if (response.data.Status === 0) {
          setGetdata(false);

          // document.getElementById("enableWalletsection").style.display = "none";
          setisActive(false);
          setShowAccountActivateDiv("none");
          // document.getElementById("accountActivateDiv").style.display = "none";
          $(".modal-title").html("");
          $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=redirect>Got it</button>");
          $("#redirect").addClass("btn btn-block");
          $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        return;
      })
      .catch(function (err) {
        setGetdata(true);
        if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
        // secureLocalStorage.clear();
        $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("<button id=redirect>Got it</button>");
        $("#redirect").addClass("btn btn-block");
        $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
        }
        else{
            console.warn(err,"Overview");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn1>ok</button>");
            $("#closebtn1").addClass('btn btn-block');
            $("#closebtn1").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
        }
        return;
      });
  }

  const showBalance = () => {
    document.getElementById('off').style.display = "block";
    document.getElementById('offpswd').style.display = "block";
    document.getElementById('on').style.display = "none";
    document.getElementById('walletbalance').style.display = "none";
  }

  const hideBalance = () => {
    document.getElementById('off').style.display = "none";
    document.getElementById('offpswd').style.display = "none";

    document.getElementById('on').style.display = "block";
    document.getElementById('walletbalance').style.display = "block";
  }

  // if(loading)
  // {
  //   return <Spinner />
  // }
  // else{
  return (
    <>
      <div className="row mb-3">
        <div className="col-md-12">
          <div className="card px-4">
            <div className="page-header w-100">
              <h3 className="page-title w-100 row">
                <div className='col-5' id="Header_Title">
                  <span className="page-title-icon bg-success me-2" >
                    <i className="mdi mdi-wallet"></i>
                  </span> My Wallet
                </div>
                <div className='col'>
                  <div className="float-end">
                    <div className='text-lastlogin text-end fs-6'>
                      <i className="mdi mdi-account px-2"></i>Last login time
                      <label className='d-block'>{secureLocalStorage.getItem('DT_')}</label>
                    </div>
                  </div>
                </div>
              </h3>
            </div>
            {/* here content is placed */}
            <div>
              <div className="d-flex flex-sm-row flex-column">
                <div className="mr-auto px-2 mt-2">
                  <h3 className="">Overview</h3>
                  <p className='class_p mt-3 mb-2'>Your crypto Journey starts now.
                    Activate your wallet and create meezan accounts for your crypto transfers.
                  </p>
                </div>
                <div className="px-2 mt-2 ms-auto"><KycIsSubmitted /></div>
              </div>
              {
                isActive === false ?
                  <button className='btn btn-sm btn-yodigi-buy mt-1 mb-1' onClick={RegisterAccount}>Activate wallet</button>
                : <></>
              }
              {getdata ?
                <>
                  <div className='col-12' id="accountActivateDiv" style={{ display: ShowaccountActivateDiv }}>
                    <div className="card-body ">
                      <div className="">
                        <label className='text-label' id="warning"></label>
                      </div>
                      <div className='justify-content-left mb-5'>
                        <h5>Account secret code :  </h5>
                        <label id="secretcode" className='text-left'
                          style={{ fontStyle: "italic", color: "#f33155", width: 'auto', wordBreak: "break-all" }}>
                        </label>

                        {/* <h5>Mnemonics : </h5> */}
                        <label id="mnemonics" style={{ fontStyle: "italic", color: "#707cd2" }}>
                          {srcCode}
                        </label>
                        <CopyToClipboard text={srcCode} onCopy={() => setCopy(true)}>
                          <button className='mx-2 btn btn-sm settingsBtn'>
                            <i className='mdi mdi-content-copy mdi-32px'></i>
                          </button>
                        </CopyToClipboard>
                        {Copy ? <span className="class_p" style={{ color: 'red' }}>Copied.</span> : null}
                      </div>
                      <div className="important-info">
                        <h4 className="card-title">Security Tips : </h4>
                        <ul>
                          <li>
                            <i className="mdi mdi-checkbox-blank-circle"></i>
                            Never share your password with anyone.
                          </li>
                          <li>
                            <i className="mdi mdi-checkbox-blank-circle"></i>
                            Sharing sensitive information could compromise your account.
                            YOUR ACCOUNT IS YOUR RESPONSIBILITY.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 mt-1' id="fundAccountDiv" style={{display: ShowaccountActivateDiv==="none"? "block":"none"}}>
                    <div className='col-12 mt-2'>
                      <h5 id="on" className=''><i className="mdi mdi-wallet mx-1"></i>Balance Wallets
                        <i className="balanceicon mdi mdi-eye mx-1" onClick={showBalance} style={{ cursor: 'pointer' }}></i>
                      </h5>
                      <h5 id="off" style={{ display: 'none' }}>
                        <i className="mdi mdi-wallet mx-1"></i>Balance Wallets
                        <i className="balanceicon mdi mdi-eye-off mx-1" onClick={hideBalance} style={{ cursor: 'pointer' }}></i>
                      </h5>
                    </div>
                    <div className="row mt-1" >
                      <div id="walletbalance">
                        <div className='col-6 mb-3 theme-child'>
                          <Card sx={{ minWidth: 350 }}>
                            <CardHeader
                              avatar={
                                <Avatar aria-label="recipe">
                                  <i className='mdi mdi-cash'></i>
                                </Avatar>
                              }
                              action={
                                isActive === false ?
                                <Link to={"/"} className="text-success text-muted" onClick={(e) => {e.preventDefault();}} >View Accounts</Link>
                                :
                                <Link to={routeCodes.CREATEFIATACCOUNT} className="text-success">
                                  View Accounts
                                </Link>
                              }
                              title={<label className="class_heading mx-2">Fiat wallet</label>}
                              subheader={
                                <div>
                                  <label className='class_heading mx-2 class_p' id="fiatbalance">
                                    {fiatbalance}
                                  </label>
                                  <p className='class_p'>* Estimated approximate value in USD </p>
                                </div>
                              }
                            />

                          </Card>

                        </div>

                        <div className='col-6 mb-3 theme-child'>
                          <Card sx={{ minWidth: 350 }}>
                            <CardHeader
                              avatar={
                                <Avatar aria-label="recipe">
                                  <i className='mdi mdi-bitcoin'></i>
                                </Avatar>
                              }
                              action={
                                isActive === false ?
                                <Link to={"/"} className="text-success text-muted" onClick={(e) => {e.preventDefault();}} >View Accounts</Link>
                                :
                                <Link to={routeCodes.CREATECRYPTOACCOUNT} className="text-success">
                                  View Accounts
                                </Link>
                              }
                              title={<label className="class_heading mx-2">Crypto wallet</label>}
                              subheader={<label className='class_heading mx-2 class_p' id="cryptobalance">
                                {cryptobalance}
                              </label>}
                            />
                          </Card>
                        </div>
                      </div>

                      <div className='row' id="offpswd" style={{ display: 'none' }}>
                        <h5 className="" type="password" >**********</h5>
                      </div>

                      <label className='class_p card-desccription font-bold'
                        id="verifynotice" style={{ display: 'none' }}>
                        Your account does not currently have any assets,
                        complete identity verification in order to make deposits to your account.
                        <a href={routeCodes.SETTINGS} className='text-success mx-2'>Verify now</a>
                      </label>
                    </div>
                    <div className='chart-container' style={{ position: "relative", margin: "auto", width: "99%" }}>
                      <Line options={options} data={chartdata}  updateMode={"resize"} width={"99%"} height={250} />
                    </div>
                    <h3>Fund your Account </h3>
                    <div className="row">
                      <div className='col-xs-12 col-md-4 mt-2'>
                        <Link className="card1 p-4"
                          to={{
                            pathname: routeCodes.EXCHANGEDASHBOARD,
                            state: {
                              toggleValue: false
                            },
                          }}>
                          <h5>Buy Crypto</h5>
                          <p className="small">You can buy or sell crypto assets with our exchange.</p>
                          <div className="go-corner" href={routeCodes.EXCHANGEDASHBOARD}>
                            <div className="go-arrow">→</div>
                          </div>
                        </Link>
                      </div>
                      <div className='col-xs-12 col-md-4 mt-2'>
                        <Link className="card1 p-4"
                          to={{
                            pathname: routeCodes.NEWTRANSFER,
                            state: {
                              toggleValue: false
                            },
                            hash: '1'
                          }}>
                          <h5>Fund your wallet</h5>
                          <p className="small">
                            Meezan provides multiple options to fund your account.
                            You can start your crypto journey with us.</p>
                          <div className="go-corner" href={routeCodes.NEWTRANSFER}>
                            <div className="go-arrow">→</div>
                          </div>
                        </Link>
                      </div>
                      <div className='col-xs-12 col-md-4 mt-2'>
                        <Link className="card1 p-4"
                          to={{
                            pathname: routeCodes.NEWTRANSFER,
                            state: {
                              toggleValue: false
                            },
                            hash: '2'
                          }}>
                          <h5>Transactions</h5>
                          <p className="small">You may start your transactions on your fiat or crypto accounts
                            in your wallet.</p>
                          <div className="go-corner" href={routeCodes.NEWTRANSFER}>
                            <div className="go-arrow">→</div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
                :
                <div className='text-center'>
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
  // }
}

