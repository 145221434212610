import React, {  useState } from 'react';
import $ from 'jquery';
import {  useNavigate } from 'react-router-dom';
import axios from 'axios';
// import { Dropdown } from 'react-bootstrap';
// import * as Yup from 'yup';
import { FormProvider } from '../components/hook-form';
import { RHFTextField } from '../components/hook-form';
import { IconButton, InputAdornment } from '@mui/material';
// import { Tabs, Tab, Nav, Form } from 'react-bootstrap';

// form
import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';

// import { Trans } from 'react-i18next';
import url_local from '../url_local';
import routeCodes from '../../Paths';

//mui
import { LoadingButton } from '@mui/lab';

//components
import Iconify from '../components/Iconify';

// import GetFiatAccounts from '../users/wallet/GetFiatAccounts';
// import GetCryptoAccounts from "../users/wallet/GetCryptoAccounts";
import secureLocalStorage from 'react-secure-storage';

// var reader = "";
export default function NewTransaction(crypt=[]) {
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  const history = useNavigate();

  const [previewData, setpreviewData] = useState(
    {
        "status": "Waiting Confirmation",
        "transfer_from": "",
        "transfer_to": "",
        "account_balance": {
            "Status": 1,
            "address": "",
            "balance": "0",
            "float_balance": "0",
            "value": "0 $"
        },
        "symbol": "eth"
    }
);
  const [cryptoData,setCryptoData] =  React.useState([])
  const [loading, setLoading] = React.useState(true);
  const [selectAddress, setSelectedAddress] = React.useState("");
  const [selectSymbol, setSelectedSymbol] = React.useState("");
  const [confirmData, setConfirmData] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [askPin, setAskPin] = React.useState(false);
  const [getdata, setGetdata] = useState(false);
  const headers = {
    "Content-Type": "application/json",
    "Access-Token": secureLocalStorage.getItem('AT_'),
    "Refresh-Token": secureLocalStorage.getItem('RT_')
  };
  // const [viewpaymentsection, setviewpaymentsection] = useState(false);

  // const initialState = { alt: "", src: "" };
  // const [{ alt, src, profiledata }, setPreview] = React.useState(initialState);
  // const fileHandler = event => {
  //   var { files } = event.target;
  //   setPreview(
  //     files.length
  //       ? {
  //         src: URL.createObjectURL(files[0]),
  //         alt: files[0].name,
  //         profiledata: files[0]
  //       }
  //       : initialState
  //   );
  // };

  React.useEffect(() => {
      var headers = {
          "Content-Type": "application/json",
          'Access-Token': secureLocalStorage.getItem('AT_'),
          "Refresh-Token": secureLocalStorage.getItem('RT_')
      };
      const payload = {publicid: secureLocalStorage.getItem('PI_')}
      // console.warn(`crypt`,crypt.accdata);
      if(secureLocalStorage.getItem("AD_", "") !== "" && secureLocalStorage.getItem("AD_", "") !== null){
        const symbol = secureLocalStorage.getItem("CV_", "");
        const address = secureLocalStorage.getItem("AD_", "");
        // document.getElementById("mycrypto").value = symbol
        // document.getElementById("cryptoaddress").text = address;
        // document.getElementById("cryptoaddress").style.display = "block";
        console.log(address);
        setSelectedAddress(address);
        setSelectedSymbol(symbol);
        secureLocalStorage.setItem("AD_", "");
        secureLocalStorage.setItem("CV_", "");
      }
      if (crypt.accdata.length > 0){
        setCryptoData(crypt.accdata);
        setLoading(false);
      }
      else{
        axios.post(url_local.accountlist, payload, { headers })
        .then(function (response) {
        setLoading(false);
        if (response.data.Status === 1) {
          setCryptoData(response.data.Data.fiat.list)
        }
        })
        .catch(function () {
        });
      }

      // loadData();
  }, [crypt]);

  // const TransferSchema = Yup.object().shape({
  //   address: Yup.string()
  //     .required("Please enter an address"),
  //   cryptoValue: Yup.number()
  //     .typeError('Please enter a value')
  //     .min(0.001, 'Min value 0.001')
  //     .required("Please enter a value"),
  // });

  const defaultValues = {
    address: '',
    cryptoValue: '',
  };

  const methods = useForm({
    // resolver: yupResolver(TransferSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const transferCryptoAccount = () => {
    // var headers = {
    //   "Content-Type": "application/json",
    //   "Access-Token": secureLocalStorage.getItem('AT_'),
    //   "Refresh-Token": secureLocalStorage.getItem('RT_')
    // };
    var amt =  parseFloat(document.getElementById("cryptoValue").value);
    const payload = {
      "symbol": selectSymbol,
      "network": "",
      "from_address": selectAddress,
      "to_address": document.getElementById("to_address").value,
      "value": amt,
      "isconfirmed": false,
      "pin":"",
      "publicid": secureLocalStorage.getItem('PI_'),
    }
    setGetdata(true);
    axios.post(url_local.cryptotransaction,payload,{ headers })
      .then(function (response) {
        setGetdata(false);
        // console.log("We are HERE! ",response.data);
        if (response.data.Status === 1) {

          // document.getElementById("to_address").value = "";
          // document.getElementById("cryptoValue").value = "";

          if(response.data.Code === 4100){
            // console.log("Show Offcanvas");
            setConfirmData(true);
            setpreviewData(response.data.Data);
            document.getElementById('view-right-sidebar').classList.add('open');
          }
          else{
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=cancelled>Got it</button>");
            $("#cancelled").addClass("btn btn-block");
            $("#cancelled").on("click", function () {
              history(routeCodes.CREATECRYPTOACCOUNT);
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
        }
        else if (response.data.Status === 0) {
          setGetdata(false);
          if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>Your session expired</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=signoutbtncrypto>ok</button>");
            $("body").addClass('no-select'); secureLocalStorage.clear();
            $("#signoutbtncrypto").addClass('btn btn-block');
            $("#modalDialog").toggle('show');
            $("#signoutbtncrypto").on('click', function () {
              $("#modalDialog").toggle('hide');
              window.location.href = "/";
            });
          }
         else{
          $(".modal-title").html("");
          $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=cancelredirect_crypto>Got it</button>");
          $("#cancelredirect_crypto").addClass("btn btn-block");
          $("#cancelredirect_crypto").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
         }
        }
        else {
          setGetdata(false);
          $(".modal-title").html("");
          $(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=cancelredirect>Got it</button>");
          $("#cancelredirect").addClass("btn btn-block");
          $("#cancelredirect").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        return;
      })
      .catch(function (err) {
        setGetdata(true);
        if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
        // secureLocalStorage.clear();
        $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("<button id=redirect>Got it</button>");
        $("#redirect").addClass("btn btn-block");
        $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
        }
        else{
            console.warn(err,"CryptoTransaction");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn1>ok</button>");
            $("#closebtn1").addClass('btn btn-block');
            $("#closebtn1").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
        }
        return;
      });
  }

  // var storedcrypto_datas = JSON.parse(secureLocalStorage.getItem("CRL_"));
  function getCryptoAccounts() {
    return cryptoData.length > 0 && cryptoData.map((item, i) => {
      if (item.account === undefined || item.account === "" || item.account === null) {
        return (
          <option key={i} disabled>No accounts created</option>
        )
      }
      else {
        let text = item.type;
        const type = String(text.split("account")).replace(',', '').toUpperCase();
        return (
          <option key={i} value={type} id={item.account}>
            {type}
          </option>
        )
      }
    });
  }


  const handleCryptoChange = (e) => {
    secureLocalStorage.setItem("CV_", "");
    secureLocalStorage.setItem("AD_", "");
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index]
    const option = el.getAttribute('id');
    setSelectedAddress(option);
    setSelectedSymbol(e.target.value);
    // secureLocalStorage.setItem("CV_", e.target.value);
    // secureLocalStorage.setItem("AD_", option);
    // document.getElementById("cryptoaddress").innerHTML = "Account No. :" + option;
    // document.getElementById("cryptoaddress").style.display = "block";
    // document.getElementById('view-right-sidebar').classList.add('open');
    // setConfirmData(true);
  }

  // const getPinsection = () => {
  //   document.getElementById("errMsg").style.display = "none";
  //   document.getElementById('pinsection').style.display = "block";
  //   document.getElementById('btnsection').style.display = "none";
  //   document.getElementById("confirm_pin").value = "";
  // }

  const showPinConfirmation = () => {
    setAskPin(true);
  }
  const ContinueTransfer = () => {
    setAskPin(false);
    const amt =  parseFloat(document.getElementById("cryptoValue").value);
    const pin =  parseFloat(document.getElementById("confirm_pin").value);
    const to_account =  document.getElementById("to_address").value;
    const payload = {
      "symbol": selectSymbol,
      "network": "",
      "from_address": selectAddress,
      "to_address": to_account,
      "value": amt,
      "isconfirmed": confirmData,
      "pin":pin,
      "publicid": secureLocalStorage.getItem('PI_'),
    }
    console.log("Confirm",payload);
    setGetdata(true);
    setConfirmData(false);
    axios.post(url_local.cryptotransaction,payload,{ headers })
      .then(function (response) {
        document.getElementById("receipient").innerHTML = "";
        document.getElementById("from_account").innerHTML = "";
        document.getElementById("to_account").innerHTML = "";
        document.getElementById("confirm_pin").innerHTML = "";
        setGetdata(false);
        setConfirmData(false);
        // console.log(response);
        // console.log(response.data)
        if (response.data.Status === 1) {

          // document.getElementById("to_address").value = "";
          // document.getElementById("cryptoValue").value = "";

          // if(response.data.Code === 0){
          //   setConfirmData(true);
          //   setpreviewData(response.data.Data);
          //   // document.querySelector('#view-right-sidebar').classList.toggle('open');
          // }
          // else
          // {
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=cancelled>Got it</button>");
            $("#cancelled").addClass("btn btn-block");
            $("#cancelled").on("click", function () {
              // history(routeCodes.CREATECRYPTOACCOUNT);
              // secureLocalStorage.setItem("AD_", "");
              // secureLocalStorage.setItem("CV_", "");
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          // }
        }
        else if (response.data.Status === 0) {
          setGetdata(false);
          if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>Your session expired</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=signoutbtncrypto>ok</button>");
            $("body").addClass('no-select'); secureLocalStorage.clear();
            $("#signoutbtncrypto").addClass('btn btn-block');
            $("#modalDialog").toggle('show');
            $("#signoutbtncrypto").on('click', function () {
              $("#modalDialog").toggle('hide');
              window.location.href = "/";
            });
          }
         else{
          $(".modal-title").html("");
          $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=cancelredirect_crypto>Got it</button>");
          $("#cancelredirect_crypto").addClass("btn btn-block");
          $("#cancelredirect_crypto").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
         }
        }
        else {
          setGetdata(false);
          $(".modal-title").html("");
          $(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=cancelredirect>Got it</button>");
          $("#cancelredirect").addClass("btn btn-block");
          $("#cancelredirect").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        return;
      })
      .catch(function (err) {
        document.getElementById("receipient").innerHTML = "";
        document.getElementById("from_account").innerHTML = "";
        document.getElementById("to_account").innerHTML = "";
        document.getElementById("confirm_pin").innerHTML = "";
        setGetdata(true);
        if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
        // secureLocalStorage.clear();
        $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("<button id=redirect>Got it</button>");
        $("#redirect").addClass("btn btn-block");
        $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
        }
        else{
            console.warn(err,"CryptoTransaction");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn1>ok</button>");
            $("#closebtn1").addClass('btn btn-block');
            $("#closebtn1").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
        }
        return;
      });

  }
  const viewcloseRightSidebar = () => {
    setAskPin(false);
    setConfirmData(false);
    document.getElementById('view-right-sidebar').classList.remove('open');
    document.getElementById('pinsection').style.display = "none";
    document.getElementById('btnsection').style.display = "block";
  }

  var array = [];
  array.push(previewData);

  var transactionData = array.length > 0 &&  array.map((item, index) => {
      return (
          <div key={index} id={index} className=" pt-4">
              <h4 className='mb-3'>Confirm transaction</h4>
              <div className='row mt-5'>
                  <div className='col-md-4 col-xs-12'>
                      <p className='text-center'>{item.symbol}</p>
                      <p className='class_p'>{item.transfer_from}</p>
                      <div className='mt-2 text-left'>
                          <p>Balance : {item.account_balance.balance}</p>
                          <p>$ Value : {item.account_balance.value}</p>
                      </div>
                  </div>
                  <div className='col-md-3 col-xs-12 text-center'>
                      <i className='mdi mdi-arrow-right'></i>
                  </div>
                  <div className='col-md-4 col-xs-12'>
                      <p className='text-center'>{item.symbol}</p>
                      <p className='class_p'>{item.account_balance.address}</p>
                      <div className='mt-2 text-left'>
                          <p>Transfer Amount : {item.account_balance.balance}</p>
                      </div>
                  </div>
              </div>
              <h4 style={{ borderTop: "1px solid #242736" }} className="mb-3 pt-3">Total : {item.account_balance.balance}</h4>
          </div>
      )
  });

  return (
    <div className='px-2 mx-3 rounded-legend legend-bottom-left'>
      <p className='card-description pt-3 px-4'>Start a new trade with Meezan</p>
      <div className="mt-2 px-4" id="accountTransfer">
        <div className="row">
          <div className="col-md-6 col-xs-12 mb-3">
            <label className="form-label">Select account</label>
            {
              loading?<div className="spinner-border spinner-border-sm " role="status"></div>:
              <select defaultValue={selectSymbol}
                className="form-select"
                aria-label="Floating label select example"
                onChange={handleCryptoChange}>
                <option value="" disabled>
                  -- Select one --
                </option>
                {getCryptoAccounts()}
              </select>
            }
            {
            selectAddress !== ""?
            <span id="cryptoaddress" className='class_p card-description' style={{ display: 'block' }}>Account No. :{selectAddress}</span>
            :<></>
            }

            <a className='auth-link text-white' href={routeCodes.CREATECRYPTOACCOUNT}>View all accounts
              <i className='mdi mdi-open-in-new mx-2'></i></a>
          </div>
        </div>
        <p>Enter trading details</p>
        <div className="mb-3 mt-2" id="receiveCryptoAccount" >
          <FormProvider methods={methods} onSubmit={handleSubmit(transferCryptoAccount)}>
            <div className='row'>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label class_p">Provide address *</label>
                <div className="col-sm-6">
                  <input type='text' name="to_address" id='to_address' className='form-control' placeholder="" maxLength={50} minLength={10} pattern="([a-zA-Z0-9-]){10,50}" title='Must be 10-50 characters long and only contains letters and numbers!'/>
                  {/* <RHFTextField name="address" id='address' placeholder="" /> */}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label class_p">Enter Value *</label>
                <div className="col-sm-6">
                  {/* <RHFTextField name="cryptoValue" id='cryptoValue' placeholder="" /> */}
                  {/* <input type='number' name="cryptoValue" className='form-control' id='cryptoValue' placeholder="0" max={9999}/> */}
                  <input type='number' name="cryptoValue" id='cryptoValue' className='form-control' placeholder="0.001" min={0.001} step={0.001} max={9999.000} title='Amount should be from 1-9999'/>
                </div>
              </div>
            </div>
            <div className='text-center'>
              {!getdata ?
                <div>
                  <LoadingButton size="small" type="submit" variant="contained" loading={isSubmitting} className="btn-sm btn-yodigi-buy text-center">
                    Transfer
                  </LoadingButton>
                </div>
                :
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              }
            </div>
          </FormProvider>
        </div>
      </div>
      <div id="view-right-sidebar"  className={confirmData ? "px-4 settings-panel right-sidebar open" :"px-4 settings-panel right-sidebar"}>
        <i className="settings-close mdi mdi-arrow-left justify-content-left" onClick={viewcloseRightSidebar}></i>
        <div className="" id="uncontrolled-tab-example ">
            <div className="row">
                <div className="col-lg-12">
                    <div className="px-3">
                        <ul >
                            {transactionData}
                        </ul>
                        <div id="btnsection" className='p-2' style={{ display: 'block' }}>
                            <button className='btn-sm btn-default' onClick={viewcloseRightSidebar}>Cancel</button>
                            <button className='btn-sm btn-yodigi-buy' onClick={ showPinConfirmation  }>Confirm payment</button>
                        </div>
                        <div id="pinsection" style={ askPin ? {}: {display:'none'}} className="mt-3">
                            <p className='class_p'>Enter your Security PIN</p>
                            <FormProvider methods={methods} onSubmit={handleSubmit(ContinueTransfer)}>
                                <div className="col-12">
                                    <RHFTextField name="pin"
                                        id="confirm_pin" placeholder=""
                                        type={showPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (<InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                    <Iconify style={{ color: '#fff' }} icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>)
                                        }} />
                                </div>
                                <div id="btnsection" className='p-2 text-center'>
                                  <button className='btn-sm btn-default mx-2' onClick={viewcloseRightSidebar}>Cancel</button>
                                  <button type="submit" className="btn-sm btn-yodigi-buy mx-2" id="202">Send</button>
                                </div>
                            </FormProvider>

                            <p className='text-danger mt-1' id="errMsg" style={{ display: 'none' }}></p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
      </div>
    </div>
  )
}

