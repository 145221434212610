
import React,{ useEffect, useState } from 'react';
import $ from 'jquery';
// import { Link } from 'react-router-dom';
import axios from 'axios';
//import components
import currencyIcon from '../../CryptoIcons/iconsImage';
import routeCodes from '../../../Paths';
import url_local from '../../url_local';
import { useNavigate } from 'react-router-dom';


function Trade(){
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  const [accountData, setAccountdata] = useState([]);
  const [tradeLoading, setTradeLoading] = useState(true);
  const history = useNavigate();

  useEffect(() => {
    const source = axios.CancelToken.source();
    // setTradeLoading(true);
    var headers = { "Content-Type": "application/json"};
    axios.post(url_local.trans,{headers}, { cancelToken: source.token })
        .then(function(response) {
          setTradeLoading(false);
          if(response.data.Status === 1)
          {
            setAccountdata(response.data.Data);
          }
          else if(response.data.Data.Status === 0){
            $(".modal-body").html("<p class=class_p>"+response.data.Reponse+"</p>");
            $(".modal-title").html("")
            $("#modalDialog").toggle('show');
          }
          else{
            $(".modal-body").html("<p className=text-danger>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $("#modalDialog").toggle('show');
          }
        })
        .catch(function(err) {
          console.warn(err,"Trade");
          setTradeLoading(false);
        return;
    });
  },[]);

	let accountLists = accountData.length > 0 && accountData.map((item, index) => {
    let image = currencyIcon.map((cur, i) => {
        if(cur.name === item.symbol)
          {
            return(<img className='img3' key={i} src={cur.image} alt="currency" />)
          }
        else
          return(<img key={i} src="data:," alt="currency" hidden />)
    });
    let perc_value = getPerc(item.percentage);
    let idx = index;
      return (
          <div key={idx} className='col-md-6 col-lg-3 col-xs-12 mt-2' onClick={ () =>{ history(routeCodes.EXCHANGE);}}>
            <div className="card mainPageRateCard mb-4 p-2 w-100">
              <div className="card-block">
                <h6 className="card-title" data-toggle="tooltip" data-placement="top" title="Trade">
                { image }&nbsp;{item.symbol} <small className='float-right'>{ perc_value }</small>
                </h6>
                <h4 className="card-subtitle text-muted text-left ml-5">{item.previousClose}
                <a className='float-right' href={routeCodes.EXCHANGE} data-toggle="tooltip" data-placement="top" title="Trade">
                  <i className='mx-2 mdi mdi-finance rateicon'></i>
                </a>
                </h4>
              </div>
            </div>
          </div>
    );
	}, this);

  function getPerc(perc)
    {
      if(perc < 0)
        {
          return(<small className= "mx-1 text-danger"><i className='mdi mdi-arrow-down'></i>{perc} %</small>)}
        else{
          return(<small className= "mx-1 text-success"><i className='mdi mdi-arrow-up'></i>{perc} %</small>)
        }
    }
    return(
      <>
      {
      tradeLoading ?
        <div className='text-center'>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      :
        <div className='row'>
          {accountLists}
        </div>
      }
      </>
    )
}

export default Trade;