import React, {  useEffect } from "react";
import $ from 'jquery';
// import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

// form
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';

// components
import url_local from '../url_local';

// import '../../assets/styles/web/css/style.css';
// import routeCodes from "../../Paths";

// var kycdata = [];
// function padTo2Digits(num) {
//     return num.toString().padStart(2, '0');
// }

// function formatDate(date) {
//     var dateReceived = new Date(date);
//     return (
//         [
//             dateReceived.getFullYear(),
//             padTo2Digits(dateReceived.getMonth() + 1),
//             padTo2Digits(dateReceived.getDate()),
//         ].join('-')
//     );
// }


function IdCardSubmit() {
    console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
    const history = useNavigate();
    // const ref = useRef(null);
    // const [loading, setLoading] = React.useState(false);
    // const [userdata, setuserdata] = React.useState([]);
    const [accesslevel,SetAccesslevel] = React.useState(0);
    // const [show, setShow] = React.useState("false");
    // const handleShow = () => setShow(true);

    // const [show1, setShow1] = React.useState(false);

    useEffect(() => {
        SetAccesslevel(secureLocalStorage.getItem('AL_'));
        var headers = {
            "Content-Type": "application/json",
            'Access-Token': secureLocalStorage.getItem('AT_'),
            "Refresh-Token": secureLocalStorage.getItem('RT_')
        };
        try{
        axios.post(url_local.checkuserstatus,
                {
                    publicid: secureLocalStorage.getItem('PI_')
                },
                { headers })
            .then(function (response) {
                // console.log("userstatus", response.data)
                if (response.data.Status === 1) {
                    // setuserdata(response.data.Data);
                    secureLocalStorage.setItem('KYC_', response.data.Data.isKycApproved);
                    const kbc = document.getElementById('kycbreadcrumb');
                    if (kbc !== null){
                        if (response.data.Data.isidverified === false) {
                            document.getElementById('kycbreadcrumb').style.display = 'block';
                        }
                        else {
                            document.getElementById('kycbreadcrumb').style.display = 'none';
                        }
                    }
                }
                else if (response.data.Status === 0) {
                    if (response.data.Code === 3 || response.data.code === 7 || response.data.Data.Status === 600) {
                        $(".modal-title").html("");
                        $(".modal-body").html("<p class=class_p>Your session expired.</p>");
                        $(".modal-footer").html("");
                        $(".modal-footer").html("<button id=closesessionclosebtn>ok</button>");
                        $("body").addClass('no-select'); secureLocalStorage.clear();
                        $("#closesessionclosebtn").addClass('btn btn-block');
                        $("#closesessionclosebtn").on("click", function () {
                            $("#modalDialog").toggle('hide');
                            window.location.href = "/";
                        });
                        if($("#modalDialog" ).hasClass( "show" )){
                            $(".main-panel").css("filter","blur(8px)");
                            $("#modalDialog").toggle('show');
                        }
                        else{
                            $(".main-panel").css("filter","blur(8px)");
                            $("#modalDialog").toggle('show');
                        }
                    }
                    else {
                        $(".modal-title").html("");
                        $(".modal-body").html(response.data.Message);
                        $(".modal-footer").html("");
                        $(".modal-footer").html("<button id=closebtn>ok</button>");
                        $("#closebtn").addClass('btn btn-block');
                        $("#closebtn").on("click", function () {
                            $("#modalDialog").toggle('hide');
                        });
                        $("#modalDialog").toggle('show');
                    }
                }
                else {
                    $(".modal-body").html("<p class='text-danger'>Something went wrong. Try again.</p>");
                    $(".modal-title").html("")
                    $(".modal-footer").html("");
                    $(".modal-footer").html("<button id=got_it>Got it</button>");
                    $("#got_it").addClass("btn btn-block");
                    $("#got_it").on("click", function () {
                        $("#modalDialog").toggle('hide');
                    });
                    $("#modalDialog").toggle('show');
                }
                return;
            })
            .catch(function (error) {
                console.error(error);
                return;
              });
            } catch (error) {
                console.error(error.response.data);
                  if (error.response.status === 500) {
                    $(".modal-title").html("")
                    $(".modal-body").html("<p class=class_p><!-- KycUsSubmitted.js -->Sorry! We have encountered server issue. Try again later!</p>");
                    $(".modal-title").html("")
                    $(".modal-footer").html("");
                    $(".modal-footer").html("<button id=ok>OK</button>");
                    $("#ok").addClass("btn btn-block");
                    $("#ok").on("click", function () {
                      $("#modalDialog").toggle('hide');
                    });
                    $("#modalDialog").toggle('show');
                  }
              }
        // loadData();
    }, [history]);

    return (
             <>
            {
                accesslevel <= 1 ?
                    <nav aria-label="breadcrumb" id="kycbreadcrumb" style={{ display: 'none' }}>
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <div className="alert alert-warning mt-2" role="alert" id='formAlert'>
                                    Please complete KYC for full access.{accesslevel}</div>
                            </li>
                        </ul>
                    </nav>
                    :
                    accesslevel <= 2 ?
                    <nav aria-label="breadcrumb" id="kycbreadcrumb" style={{ display: 'none' }}>
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <div className="alert alert-warning mt-2" role="alert" id='formAlert'>
                                    KYC Pending for Approval</div>
                            </li>
                        </ul>
                    </nav>
                    :<>
                    </>
            }
            </>
            // <nav aria-label="breadcrumb" id="kycbreadcrumb" style={{ display: 'none' }}>
            //     <ul className="breadcrumb">
            //         <li className="breadcrumb-item active" aria-current="page">
            //             <div className="alert alert-warning mt-2" role="alert" id='formAlert'>
            //                 Please complete KYC for full access.<a className="mx-2" href={routeCodes.IDVERIFY}>Complete now?</a>
            //             </div>
            //         </li>
            //     </ul>
            // </nav>
    )
}

export default IdCardSubmit;