import React from 'react';
import * as Yup from 'yup';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import Iconify from '../components/Iconify';
import { FormProvider, RHFTextField } from '../components/hook-form';
import Trade from './layout/Trade';
import WebHeaderHome from './layout/WebHeaderHome';
import Testimonial from './element/testimonial';
import Bottom from './element/bottom';
import Footer1 from './layout/footer1';
import routeCodes from '../../Paths';
import url_local from '../url_local';
import Spinner from '../shared/Spinner';
import { GlobalContext } from '../shared/useForm';
import secureLocalStorage from 'react-secure-storage';


//import images
import image1 from '../../assets/images/web/Main/kinetic_logo.png';
import image2 from '../../assets/images/web/Main/Ditracon_logo.png';
import image3 from '../../assets/images/web/Main/yods.png';
import image4 from '../../assets/images/web/Main/cell7_logo.png';

import manage from '../../assets/images/web/Main/Manage_portfolio.jpg';
import buy from '../../assets/images/web/Main/recurringbuy.jpg';
import vault from '../../assets/images/web/Main/Vaultprotection.jpg';
import mobile_apps from '../../assets/images/web/Main/Mobile_apps.jpg';

import androidimg from '../../assets/images/web/Main/playstorelogo.png';
import appleimg from '../../assets/images/web/Main/app_store_icon.png';
import appimg from '../../assets/images/web/Main/Mobile_app.png';

import img1 from '../../assets/images/web/Main/create_user_icon.png';
import img2 from '../../assets/images/web/Main/fund_ac_icon.png';
import img3 from '../../assets/images/web/Main/verifyacicon.png';
import img4 from '../../assets/images/web/Main/tradingicon.png';

import logo1 from '../../assets/images/web/Main/wallet.png';
import logo2 from '../../assets/images/web/Main/decentralized.png';
import logo3 from '../../assets/images/web/Main/qr.png';

// import css
import './web.scss';
// import '../../assets/styles/web/css/bootstrap.min.css';
import '../../assets/styles/web/css/style.css';


export default function Main() {
	console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
	const gContext = React.useContext(GlobalContext);
	const [sectionopen, setSectionOpen] = React.useState(false);
	const [showPassword, setShowPassword] = React.useState(false);
	const [getdata, setGetdata] = React.useState(false);

	const history = useNavigate();
	const [loading, setLoading] = React.useState(true);
	// const loadData = async () => {
	// 	await new Promise((r) => setTimeout(r, 1000));
	// 	setLoading((loading) => !loading);
	// };
	const mousedown = React.useRef(false);
	const mouseover = React.useRef(false);
	React.useEffect(() => {
		setLoading(false);
		// localStorage.clear();
		// localStorage.setItem('referTodashboard', false);
		// loadData();
	}, [])

	const loginOpen = () => {
		document.getElementById("mainpassworddiv").style.display = 'block';
		setSectionOpen(true)
	}
	const regOpen = () => {
		document.getElementById("mainpassworddiv").style.display = 'none';
		setSectionOpen(false)
	}
	const getuserRegister = () => {
		// secureLocalStorage.setItem('EM_',document.getElementById('mainEmail').value);
		window.location.href = routeCodes.SIGNUP;
	}

	const LoginSchema = Yup.object().shape({
		email: Yup.string().email('Email must be a valid email address').required('Email is required'),
		password: Yup.string().required('Password is required').matches(/^\S*$/, 'Whitespace is not allowed'),
	});

	const defaultValues = {
		email: '',
		password: '',
	};

	const methods = useForm({
		resolver: yupResolver(LoginSchema),
		defaultValues,
	});

	const {
		handleSubmit,
		formState: { isSubmitting },
	} = methods;


	const onSubmitLogin = () => {
		var loginHeaders = { "Content-Type": "application/json" };
		setGetdata(true);
		axios
			.post(url_local.login,
				{
					"email": document.getElementById('mainEmail').value,
					"password": document.getElementById('mainpassword').value,
				},
				{ loginHeaders })
			.then(function (response) {
				if (response.data.Status === 1) {
// console.log(`response.data.Data`,response.data.Data)
					secureLocalStorage.setItem('PI_', response.data.Data.publicid);
					secureLocalStorage.setItem('AT_', response.data.Data['Access-Token']);
					secureLocalStorage.setItem('EM_', response.data.Data.email);
					secureLocalStorage.setItem('AL_', response.data.Data.accesslevel);
					secureLocalStorage.setItem('LS_', true);
					if (gContext) gContext.user(response.data, true);

					// setting last login time
					var d = new Date(response.data.Data.last_loggedon);
					let Offset = (new Date().getTimezoneOffset()) * -1;
					var hours = (Offset / 60);
					var rhours = Math.floor(hours);
					var minutes = (hours - rhours) * 60;
					//   var rminutes = Math.round(minutes);
					d.setHours(d.getHours() + rhours, d.getMinutes() + minutes, d.getSeconds());
					var dateString = d.toLocaleString().split('t')[0];

					secureLocalStorage.setItem('DT_', dateString);
					secureLocalStorage.setItem('RT_', response.data.Data['Refresh-Token']);
					var fAccounts = []; var CrAccounts = [];
					for (let i = 0; i < response.data.Data.supported_currencies.fiat.length; i++) {
						fAccounts.push(response.data.Data.supported_currencies.fiat[i])
					}
					for (let j = 0; j < response.data.Data.supported_currencies.crypto.length; j++) {
						CrAccounts.push(response.data.Data.supported_currencies.crypto[j])
					}
					secureLocalStorage.setItem('FA_', JSON.stringify(fAccounts));
					secureLocalStorage.setItem('CRA_', JSON.stringify(CrAccounts));


					document.getElementById('mainEmail').value = "";
					document.getElementById('mainpassword').value = "";
					setGetdata(false);
					history(routeCodes.OVERVIEW);//, localStorage.getItem('referTodashboard',false), secureLocalStorage.getItem('DT_',dateString));
				}
				else if (response.data.Status === 0) {
					setGetdata(false);
					secureLocalStorage.setItem('LS_', false);
					$(".modal-title").html("");
					$(".modal-body").html("");
					if (response.data.Code === 1100 || response.data.Code === 1006) {
						secureLocalStorage.setItem('EM_', document.getElementById('mainEmail').value);
						$(".modal-title").html("");
						$(".modal-body").html('<p class=class_p>' + response.data.Message + '<br />Please activate your account.</p>');
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=redirect>Activate now ?</button><button id=cancelredirect>Cancel</button>");
						$("#redirect").addClass("btn btn-block");
						$("#cancelredirect").addClass("btn btn-block");
						$("#redirect").on("click", function () {
							ActivateCode(response.data.Code);
							$("#modalDialog").toggle('hide');
						});
						$("#cancelredirect").on("click", function () {
							$("#modalDialog").toggle('hide');
						});
						$("#modalDialog").toggle('show');
					}
					else if (response.data.Code === 1101) {
						secureLocalStorage.setItem('EM_', document.getElementById('mainEmail').value);
						$(".modal-title").html("");
						$(".modal-body").html('<p class=class_p>' + response.data.Message + '<br />If you want to continue, Please do reset your password and verify your account again.</p>');
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=redirect>Verify now</button><button id=cancelredirect>Cancel</button>");
						$("#redirect").addClass("btn btn-block");
						$("#cancelredirect").addClass("btn btn-block");
						$("#redirect").on("click", function () {
							history(routeCodes.RESET, secureLocalStorage.getItem('EM_'));
							$("#modalDialog").toggle('hide');
						});
						$("#cancelredirect").on("click", function () {
							$("#modalDialog").toggle('hide');
						});
						$("#modalDialog").toggle('show');
					}
					else if (response.data.Code === 1104) {
						secureLocalStorage.setItem('EM_', document.getElementById('mainEmail').value);
						$(".modal-title").html("");
						$(".modal-body").html('<p class=class_p>' + response.data.Message + '<br />Please verify your Email now.</p>');
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=redirect>Verify now</button><button id=cancelredirect>Cancel</button>");
						$("#redirect").addClass("btn btn-block");
						$("#cancelredirect").addClass("btn btn-block");
						$("#redirect").on("click", function () {
							ActivateCode(response.data.Code);
							$("#modalDialog").toggle('hide');
						});
						$("#cancelredirect").on("click", function () {
							$("#modalDialog").toggle('hide');
						});
						$("#modalDialog").toggle('show');
					}
					else if (response.data.Code === 1105) {
						secureLocalStorage.setItem('EM_', document.getElementById('mainEmail').value);
						$(".modal-title").html("");
						$(".modal-body").html('<p class=class_p>' + response.data.Message + '<br />Please verify your Mobile number now.</p>');
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=redirect>Verify now</button><button id=cancelredirect>Cancel</button>");
						$("#redirect").addClass("btn btn-block");
						$("#cancelredirect").addClass("btn btn-block");
						$("#redirect").on("click", function () {
							ActivateCode(response.data.Code);
							secureLocalStorage.setItem('EM_')
							$("#modalDialog").toggle('hide');
						});
						$("#cancelredirect").on("click", function () {
							$("#modalDialog").toggle('hide');
						});
						$("#modalDialog").toggle('show');
					}
					else {
						secureLocalStorage.setItem('LS_', false);
						$(".modal-title").html("");
						$(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=cancelredirect>Got it</button>");
						$("#cancelredirect").addClass("btn btn-block");
						$("#cancelredirect").on("click", function () {
							$("#modalDialog").toggle('hide');
						});
						$("#modalDialog").toggle('show');
					}
				}
				else {
					setGetdata(false);
					secureLocalStorage.setItem('LS_', false);
					$(".modal-title").html("");
					$(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=cancelredirect>Got it</button>");
					$("#cancelredirect").addClass("btn btn-block");
					$("#cancelredirect").on("click", function () {
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
				}
			})
			.catch(function (err) {
				if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
				secureLocalStorage.clear();
				$(".modal-body").html("<p className='text-danger'>Network Error!</p>");
				$(".modal-title").html("")
				$(".modal-footer").html("<button id=redirect>Got it</button>");
				$("#redirect").addClass("btn btn-block");
				$("#redirect").on("click", function () {
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
				}
				else{
					console.warn(err,"Home");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
					$(".modal-title").html("")
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=closebtn1>ok</button>");
					$("#closebtn1").addClass('btn btn-block');
					$("#closebtn1").on("click", function () {
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
				}
				return;
			});
	}

	const ActivateCode = (code) => {
		setGetdata(true);
		var headers = { "Content-Type": "application/json" };

		axios
			.post(url_local.activateCode,
				{
					"email": secureLocalStorage.getItem('EM_')
				},
				{ headers })
			.then(function (response) {
// console.log(response.data)
				if (response.data.Status === 1) {
					setGetdata(false);
// console.log(code)
					if (code === 1100 || code === 1104 || code === 1006) {
						window.location.href = routeCodes.ACTIVE_EMAILOTP;
					}
					else if (code === 1105) {
						window.location.href = routeCodes.ACTIVE_MOBILEOTP;
					}
					else if (code === 1101) {
						window.location.href = routeCodes.RESET;
					}
					else {
						window.location.href = routeCodes.LOGIN;
					}
				}
				else if (response.data.Status === 0) {
					setGetdata(false);
					$(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
					$(".modal-title").html("")
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=redirect>Got it</button>");
					$("#redirect").addClass("btn btn-block");
					$("#redirect").on("click", function () {
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
				}
				else {
					setGetdata(false);
					$(".modal-body").html("<p className='text-danger'>Something went wrong. Try again.</p>");
					$(".modal-title").html("")
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=redirect>Got it</button>");
					$("#redirect").addClass("btn btn-block");
					$("#redirect").on("click", function () {
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
				}
				return;
			})
			.catch(function () {
				$(".modal-body").html("<p className='text-danger'>Something went wrong. Try again.</p>");
				$(".modal-title").html("")
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=redirect>Got it</button>");
				$("#redirect").addClass("btn btn-block");
				$("#redirect").on("click", function () {
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
				return;
			});
	}

	const handleEvent = (event) => {
		// console.log(event.type);
		if (event.type === "mousedown") {
			mousedown.current = true;
		}
		else if (event.type === "mouseup") {
			mousedown.current = false;
		}

		if (event.type === "mouseover") {
			mouseover.current = true;
		}
		else if (event.type === "mouseout") {
			mouseover.current = false;
			mousedown.current = false;
		}

		if (mousedown.current && mouseover.current) {
			setShowPassword(true);
		}
		else {
			setShowPassword(false);
		}
	}

	return (
		<>
		{
			loading ?
				<Spinner/>
			:
			<main>
				<div id="header" className='headerdark mb-4'>
					<div className="container">
						<WebHeaderHome />
					</div>
				</div>
				<div className='header-bg' id="home">
					<div className="container">
						<div className="row justify-content-between align-items-center">
							<div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
								<h2 className='h1_tag'>Trade with Meezan, <br /> Buy and sell cryptocurrency</h2>
								<p className='class_span'>Fast and secure way to purchase or exchange 150+ cryptocurrencies</p>
								<div className='intro-btn'>
									<Link to={routeCodes.HOME} className='btn btn-lg btn-about h-auto'>About Meezan</Link>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-8 col-12">
								<div className="mainPagecard">
									<h3 className='text-left getStart_tag'>Get Started</h3>
									<FormProvider methods={methods} onSubmit={handleSubmit(onSubmitLogin)}>
										<Stack spacing={3} className="mt-3">
											<RHFTextField type="text" name="email" id="mainEmail" placeholder="Email address"
												inputProps={{ className: "text-lowercase text-white" }} />
											<div id="mainpassworddiv" style={{ display: 'none' }} className="mt-3">
												<RHFTextField
													name="password" id="mainpassword"
													placeholder="Password"
													type={showPassword ? 'text' : 'password'}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton onMouseDown={handleEvent} onMouseUp={handleEvent} onMouseOver={handleEvent} onMouseOut={handleEvent} edge="end">
																	<Iconify style={{ color: '#fff' }} icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
																</IconButton>
															</InputAdornment>
														),
													}}
												/>
											</div>
										</Stack>
										{!sectionopen ?
											<div className="align-items-center" id="Registersection">
												<button id="Registerbtn" onClick={getuserRegister}
													className='intro-btn btn btn-lg btn-yodigi-register col-12 d-flex justify-content-center mt-4'>
													Register</button>
												<p className="text-left text-success mt-3 font-bold class_p" id="signinBtn" disabled>Already registered?
													<a className="text-white font-bold mx-1 class_p" onClick={loginOpen} href="/#">Sign in</a></p>
											</div>
											:
											<>
												<div className="text-center" id="loginSection">
													{!getdata ?
														<LoadingButton size="medium" type="submit" variant="contained" loading={isSubmitting} className="intro-btn btn btn-lg btn-yodigi-register d-block col-12 justify-content-center mt-4">
															Sign in
														</LoadingButton>
														:
														<div className="spinner-border mt-2" role="status">
															<span className="sr-only">Loading...</span>
														</div>
													}
												</div>
												<p className="text-left text-success mt-3 font-bold class_p" >Create an account?
													<a className="text-white font-bold class_p" onClick={regOpen} style={{ cursor: 'pointer' }} href="/#">&nbsp;Register Now</a></p>
											</>
										}
										<Link variant="subtitle2" underline="hover" to={routeCodes.RESET} className="auth-link text-white">Forgot password?</Link>
									</FormProvider>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="price-grid section-padding cardLight" id="market_rates">
					<div className="container ">

						<div className="row justify-content-center">
							<div className="col-xl-8">
								<div className="text-center mb-5">
									<h2 className='class_mainHeading'>Market Rates</h2>
								</div>
							</div>
							<Trade />
						</div>
					</div>
				</div>
				<div className="section-padding cardDark" id="accounts">
					<div className="container">
						<div className="row justify-content-center">
							<div className="text-center mb-5">
								<h2 className='class_mainHeading'>Get started in a few minutes</h2>
							</div>
							<div className='col-sm-12 col-md-6 col-xs-12 col-lg-3 col-xl-3 text-center '>
								<div>
									<hr className="mainPageDividersTop " />
									<img src={img1} className="mainPageimg mt-5" alt="img" />
								</div>
								<div className="card-body">
									<div style={{ height: '10em' }}>
										<h5 className="mt-5 text-left mt-5 mb-4">Register {'&'} Create<br />Account</h5>
										<p className='class_p text-left  '>Register to open your Crypto<br />accountinsimplesteps.</p>
									</div>
									<div className='mt-4 d-xs-none d-md-block'>
										<hr className="mainPageDividersBottom " />
									</div>
								</div>
							</div>
							<div className='col-sm-12 col-md-6 col-xs-12 col-lg-3 col-xl-3 text-center'>
								<div>
									<hr className="mainPageDividersTop" />
									<img src={img2} className="mainPageimg mt-5" alt="img2" />
								</div>
								<div className="card-body">
									<div style={{ height: '10em' }}>
										<h5 className="mt-5 text-left mt-5 mb-4">Fund your<br />Account</h5>
										<p className='class_p text-left  '>Add funds to your crypto account
											to start trading crypto.
											You can add funds with a
											variety pf payment methods.
											accountinsimplesteps.</p>
									</div>
									<div className='mt-4 d-xs-none d-md-block'><hr className="mainPageDividersBottom " /></div>
								</div>
							</div>
							<div className='col-sm-12 col-md-6 col-xs-12 col-lg-3 col-xl-3 text-center'>
								<div>
									<hr className="mainPageDividersTop" />
									<img src={img3} className="mainPageimg mt-5" alt="img" />
								</div>
								<div className="card-body">
									<div style={{ height: '10em' }}>
										<h5 className="mt-5 text-left mt-5 mb-4">Verify your<br />Account</h5>
										<p className='class_p text-left  '>
											Complete the identity verification process to secure your account and transactions.
										</p>
									</div>
									<div className='mt-4 d-xs-none d-md-block'>
										<hr className="mainPageDividersBottom " />
									</div>
								</div>
							</div>
							<div className='col-sm-12 col-md-6 col-xs-12 col-lg-3 col-xl-3 text-center'>
								<div>
									<hr className="mainPageDividersTop" />
									<img src={img4} className="mainPageimg mt-5" alt="img" />
								</div>
								<div className="card-body">
									<div style={{ height: '10em' }}>
										<h5 className="mt-5 text-left mt-5 mb-4">Start Crypto<br />Trading</h5>
										<p className='class_p text-left  '>
											You're good to go! Buy/sellcrypto,
											set up recurring buys for
											your investments,and discover
											what Meezan has to offer.
										</p>
									</div>
									<div className='mt-4 d-xs-none d-md-block'><hr className="mainPageDividersBottom " /></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="section-padding cardLight" id="portfolio">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-xl-12">
								<div className="text-center mb-5">
									<h2 className='class_mainHeading'>Create your cryptocurrency portfolio today</h2>
									<span className='class_span'>Meezan has a variety of features that make it the best place to start trading</span>
								</div>
							</div>
						</div>
						<div className="row align-items-center justify-content-between ">
							<div className="col-xl-6 col-lg-6 p-2">
								<div className="portfolio" >
									<div className="card-body">
										<div className="row">
											<div className="col-auto mb-2">
												<img src={manage} className="img1" alt="image1" />
											</div>
											<div className="col">
												<h5 className="class_heading">Manage your portfolio</h5>
												<p className="class_p">Buy and sell popular digital currencies, keep track of them in the one place.</p>
											</div>

										</div>
									</div>
								</div>
							</div>

							<div className="col-xl-6 col-lg-6 p-2">
								<div className="portfolio" >
									<div className="card-body">
										<div className="row">
											<div className="col-auto mb-2">
												<img src={buy} className="img1" alt="img1" />
											</div>
											<div className="col">
												<h5 className="class_heading">Recurring buys</h5>
												<p className="class_p">
													Invest in cryptocurrency slowly overtime by schedul-ing buys daily,weekly,or monthly;
												</p>
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row align-items-center justify-content-between">
							<div className="col-xl-6 col-lg-6 p-2">
								<div className="portfolio" >
									<div className="card-body">
										<div className="row">
											<div className="col-auto mb-2">
												<img src={vault} className="img1" alt="img1" />
											</div>
											<div className="col">
												<h5 className="class_heading">Vault protection</h5>
												<p className="class_p">
													For added security, store your funds in a vault with time delayed withdrawals.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-xl-6 col-lg-6 p-2">
								<div className="portfolio" >
									<div className="card-body">
										<div className="row">
											<div className="col-auto mb-2">
												<img src={mobile_apps} className="img1" alt="img" />
											</div>
											<br />
											<div className="col">
												<h5 className="class_heading">Mobile apps</h5>
												<p className="class_p">Stay on top of the markets with the YoDigiapp for Android/iOS.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="trade-app section-padding cardDark" >
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-xl-12">
								<div className="text-center mb-5">
									<h2 className='class_mainHeading'>Trade. Anywhere</h2>
									<span className='class_span'> All of our products are ready to go, easy to use and offer great value to any kind of
										business
									</span>
								</div>
							</div>
						</div>

						<div className="row justify-content-center">
							<div className="col-xl-4 col-lg-4 col-md-12 ">
								<div className="card trade-app-content h-100 gradBackground">
									<div className="card-body gradBackground">
										<span><i className="la la-mobile icon"></i></span>
										<h4 className="class_heading">Mobile</h4>
										<p className='class_p'>All the power of YodigiX's cryptocurrency exchange,
											in the palm of your hand. Download the
											YodigiX mobile crypto trading app today</p>
										<Link to={'#'}> Know More <i className="la la-arrow-right"></i> </Link>
									</div>
								</div>
							</div>

							<div className="col-xl-4 col-lg-4 col-md-12 containers">
								<div className="card trade-app-content h-100 gradBackground">
									<div className="card-body gradBackground">
										<span><i className="la la-desktop icon"></i></span>
										<h4 className="class_heading">Desktop</h4>
										<p className="class_p">Powerful crypto trading platform for those who mean business. The YodigiX crypto
											trading
											experience, tailor-made for your Windows or MacOS device.</p>
										<Link to={'#'}> Know More <i className="la la-arrow-right"></i> </Link>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-12">
								<div className="card trade-app-content h-100 gradBackground">
									<div className="card-body gradBackground">
										<span><i className="la la-connectdevelop icon"></i></span>
										<h4 className="class_heading">API</h4>
										<p className="class_p">The Meezan API is designed to provide an easy and efficient way to integrate your
											trading
											application into our platform.</p>
										<Link to={'#'}> Know More <i className="la la-arrow-right"></i> </Link>
									</div>
								</div>
							</div>
						</div>



					</div>
				</div>
				<div className="testimonial section-padding cardLight" id="testimonial">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-xl-12">
								<div className="text-center mb-5">
									<h2 className='class_mainHeading'>What our customer says</h2>
								</div>
							</div>
						</div>
						<div className="row justify-content-center">
							{/* <div className="col-md-12"> */}
							<div className="col-auto" >
								<Testimonial />
								{/* </div> */}
							</div>
						</div>
					</div>
				</div>
				<div className="secure-apps section-padding cardDark" id="secure_apps">
					<div className="container">
						<div className="row align-items-center justify-content-between">
							<div className="col-xl-6 col-lg-6 col-md-6 ">
								<div className="appss-content">
									<h2 className='class_mainHeading'>The secure app to store crypto yourself</h2>

									<div className='row justify-content-center'>
										<div className='col-md-4 col-12 mb-2 '>
											<div className='card-body securepoints'>
												<span><img src={logo1} className="logo_img mt-2" alt="logo" /></span>
												<p className='class_p mt-4'>All your digital assets in one place.</p>
											</div>
										</div>
										<div className='col-md-4 col-12 mb-2'>
											<div className='card-body securepoints'>
												<span><img src={logo2} className="logo_img mt-2" alt="logo" /></span>
												<p className='class_p mt-4'>Use Decentralized Apps.</p>
											</div>
										</div>
										<div className='col-md-4 col-12 mb-2'>
											<div className='card-body securepoints'>
												<span><img src={logo3} className="logo_img mt-2" alt=''/></span>
												<p className='class_p mt-4'>Ease of payment with QR code.</p>
											</div>
										</div>
									</div>
									<div className="row" role="group" aria-label="Basic example">
										<div className='col-md-3 col-12 mt-2'>
											<img src={androidimg} className='applogo' alt="applogo" />
										</div>
										<div className='col-md-3 col-12 mt-2'>
											<img src={appleimg} className='applogo' alt="applogo" />
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-4">
								<div className="appss-img" >
									<img className="img-fluid" src={appimg} alt="appimg" width="100%" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="trust section-padding cardLight" id="partners">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-xl-12">
								<div className="text-center mb-5">
									<h2 className='class_mainHeading'>Trusted by Our <strong>Partners &amp; Investors</strong></h2>
								</div>
							</div>
						</div>

						<div className="row align-items-center justify-content-between p-5">
							<div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-4 text-center">
								<img className="img4" src={image1} alt="img" />
							</div>

							<div className="col-xl-3 col-lg-3 col-sm-12 col-xs-12 mb-4 text-center">
								<img className="img4" src={image2} alt="img" />
							</div>

							<div className="col-xl-3 col-lg-3 col-sm-12 col-xs-12 mb-4 text-center">
								<img className="img4" src={image3} alt="img" />
							</div>

							<div className="col-xl-3 col-lg-3 col-sm-12 col-xs-12 mb-4 text-center">
								<img className="img4" src={image4} alt="img" />
							</div>
						</div>

					</div>

				</div>
				<div className="contact section-padding cardDark">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-12">
								<div className="text-center mb-5">
									<h2>Get in touch. Stay in touch.</h2>
								</div>
							</div>
						</div>
						<div className="row getTouch" >
							<div className='col-xs-12 col-lg-6 contactForm p-2'>
								<div className="mb-3 mt-4">
									<input type="text" name="fullname" id="fullname" className="contactFormInput col-12"
										placeholder="Full Name" />
								</div>

								<div className="mb-3 mt-4">
									<input type="text" name="email" id="email"
										className="contactFormInput col-12 "
										placeholder="Email" />
								</div>

								<div className=" mb-3 mt-4">
									<input type="text" name="subject" id="subject" className="contactFormInput col-12"
										placeholder="Subject" />
								</div>



								<div className="mb-3 ">
									<textarea type="text" name="message" id="message" col="10" rows="5"
										className="contactFormInput col-12 "
										placeholder="Message" />
								</div>

								<button type="submit" className="btn submitBtn text-center">Submit</button>
							</div>
						</div>
					</div>
				</div>
				<div className="section-padding cardLight">
					<Bottom />
					<Footer1 />
				</div>
			</main>
		}
		</>
	)

	//   }
}

// export default function Home() {

// 	return (
// 		<Main />
// 	)
// }