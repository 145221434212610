

import React, {  } from 'react';
import axios from 'axios';
import $ from 'jquery';
// import { Link } from 'react-router-dom';

// form
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from "yup";
import 'datatables.net-responsive';
import secureLocalStorage from 'react-secure-storage';
// import { Controller } from "react-hook-form";
// import PhoneInput, { isValidPhoneNumber, formatPhoneNumber, formatPhoneNumberIntl } from "react-phone-number-input";

import "react-phone-number-input/style.css";
import url_local from '../url_local';

// import routeCodes from '../../Paths';
// import { Nationalitylist, Residencelist } from '../shared/Nationalities';

import GetFiatAccounts from '../users/wallet/GetFiatAccounts';
import GetCryptoAccounts from '../users/wallet/GetCryptoAccounts';
import '../../App.scss';

// var arr = []; var optionList = [];
// let defaultValues = {};
// var array = [];

export default function AdminLists(props) {
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  // const [getdata, setGetdata] = useState(false);
  // const [changeForm, setchangeForm] = useState(false);
  // const [value, setValue] = useState()

  // const [selectedOptions, setSelectedOptions] = React.useState([]);
  // const initialState = { alt: "", src: "" };
  // const [{ alt, src, profiledata }, setPreview] = React.useState(initialState);
  // const [selected, setSelected] = useState([]);

  // const [value, setValue] = useState("")

  $.DataTable = require('datatables.net');
  React.useEffect(() => {
    $.fn.dataTableExt.sErrMode = 'none';
    $.extend($.fn.dataTable.defaults, {
      responsive: true
    });
    $('#tbl_transactionHistory').DataTable({
      columnDefs: [
        {
          targets: 0,
          className: 'dt-control',
          orderable: false
        },
        {
          target: 7,
          visible: false,
        },
        {
          target: 8,
          visible: false,
        },
        {
          target: 9,
          visible: false,
        },
        {
          target: 10,
          visible: false,
        },
        {
          target: 11,
          visible: false,
        },
        {
          target: 12,
          visible: false,
        },
        {
          target: 13,
          visible: false,
        }]
    });
  }, []);


  const getReport = () => {
    var datestart = new Date(document.getElementById("from_date").value);
    var month = '' + (datestart.getMonth() + 1);
    var day = '' + datestart.getDate();
    var year = datestart.getFullYear();

    if (month <= 9)
      month = '0' + month;
    if (day <= 9)
      day = '0' + day;
    var fromdate = [year, month, day].join('-');

    var dateend = new Date(document.getElementById("till_date").value);

    month = '' + (dateend.getMonth() + 1);
    day = '' + dateend.getDate();
    year = dateend.getFullYear();


    if (month <= 9)
      month = '0' + month;
    if (day <= 9) {
      day = '0' + day;
    }
    var tilldate = [year, month, day].join('-');

    if (document.getElementById("from_date").value === "") {
      $(".modal-body").html("<p className=class_p>Please select start date</p>");
      $(".modal-title").html("")
      $("#modalDialog").toggle('show');
    }
    else if (document.getElementById("till_date").value === "") {
      $(".modal-body").html("<p className=class_p>Please select end date</p>");
      $(".modal-title").html("")
      $("#modalDialog").toggle('show');
    }
    else {
      getallreports(fromdate, tilldate);
    }
  }



  function getallreports(fromdate, tilldate, accountno) {
    let table = $('#tbl_transactionHistory').DataTable({
      columnDefs: [
        {
          targets: 0,
          className: 'dt-control',
          orderable: false
        },
        {
          target: 7,
          visible: false,
        },
        {
          target: 8,
          visible: false,
        },
        {
          target: 9,
          visible: false,
        },
        {
          target: 10,
          visible: false,
        },
        {
          target: 11,
          visible: false,
        },
        {
          target: 12,
          visible: false,
        },
        {
          target: 13,
          visible: false,
        }]
    });

    /* Formatting function for row details - modify as you need */
    function format(d) {
      // `d` is the original data object for the row
      var credit,amount,debit,servicecharge;
      if(d[9] === "(Fiat Exchange - Sending)")
      {
        credit = '';
        amount = 'Amount : '+d[7];
        servicecharge = 'Service Charge : '+d[13];
        debit  = 'Debit : '+d[12];
      }
      else{
        credit = 'Amount : '+d[11];
        amount = '';
        servicecharge = '';
        debit  = '';
      }
      return (
        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
        '<tr>' +
        '<td style="text-align:left">Transaction Notes :&nbsp;' + d[8] + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="text-align:left">&nbsp;<p class="text-muted">' + d[9] + '</p></td>' +
        '</tr>' +
        '<tr>' +
        '<td style="text-align:left;">From:&nbsp;&nbsp;' + d[5] + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="text-align:left">To:&nbsp;&nbsp;' + d[10] + '<br/><br/>'
         +credit + '<br/><br/>'+debit+  '<br/><br/>'+servicecharge+'<br/><br/>'+amount+ '</td>' +
        '</td>' +
        '</tr>' +
        '</table>'
      );
    }
    // Event listener for opening and closing details
    $('#tbl_transactionHistory tbody').on('click', 'td.dt-control', function () {
      var tr = $(this).closest('tr');
      var row = table.row(tr);
      // console.log(row.data())
      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass('shown');
      } else {
        // Open this row
        row.child(format(row.data())).show();
        tr.addClass('shown');
      }
    });
    accountno = $("#accounts").find("option:selected").attr('data-accountno');
    if ($("#typeselect").find("option:selected").attr('value') === "") {
      accountno = "";
    }
    else {
      accountno = $("#accounts").find("option:selected").attr('data-accountno');
    }
    //var accounttype = document.getElementById("accounts").value;
    var headers = {
      "Access-Token": secureLocalStorage.getItem('AT_'),
      "Refresh-Token": secureLocalStorage.getItem('RT_')
    };
    var parameters = {
      "publicid": secureLocalStorage.getItem('PI_'),
      "accountno": accountno,
      "fromdate": fromdate,
      "tilldate": tilldate,
      "accounttype": $("#typeselect").find("option:selected").attr('value')
    }
    axios.post(url_local.reports,parameters,{ headers })
      .then(function (response) {
        if (response.data.Status === 1) {
          // window.clear("table1");
          table.clear();
          var dataRow;
          var creditamount, debitamount, servicecharge, trxmode, transferredamount, transactionnotes;
          for (let i = 0; i < response.data.Data.length; i++) {
            // var date = new Date(response.data.Data[i].date);
            // date = date.toString();
            var d = new Date(response.data.Data[i].date);
            var dateString = d.toLocaleString().split('t')[0];

            if (response.data.Data[i].state === "completed" || response.data.Data[i].state === "approved") {
              dataRow = '<span class="badge badge-success text-white">' + response.data.Data[i].state + '</span>';
            }
            else if (response.data.Data[i].state === "declined" || response.data.Data[i].state === "rejected") {
              dataRow = '<span class="badge badge-danger text-white">' + response.data.Data[i].state + '</span>';
            }
            else if (response.data.Data[i].state === "pending") {
              dataRow = '<span class="badge badge-warning text-white">' + response.data.Data[i].state + '</span>';
            }
            else {
              dataRow = '';
            }

            if (response.data.Data[i].trxmode === "receiving") {
              creditamount = response.data.Data[i].credit.toFixed(2);
              debitamount = "";
              transferredamount = response.data.Data[i].transferred.toFixed(2);
              servicecharge = "";
              trxmode = "(Fiat Exchange - Receiving)"
              transactionnotes = response.data.Data[i].transactionnotes;
            }
            else {
              creditamount = "";
              debitamount = Number(response.data.Data[i].debit.toFixed(2));
              servicecharge = Number(response.data.Data[i].servicecharge.toFixed(2));
              var total = debitamount + servicecharge;
              transferredamount = Number(total.toFixed(2));
              trxmode = "(Fiat Exchange - Sending)";
              transactionnotes = response.data.Data[i].transactionnotes;
            }

            table.row.add(
              [
                '',
                i + 1,
                dateString,
                response.data.Data[i].txreference,
                response.data.Data[i].transactiontype,
                response.data.Data[i].sender,
                dataRow,
                transferredamount,
                transactionnotes,
                trxmode,
                response.data.Data[i].receiver,
                creditamount,
                debitamount,
                servicecharge,
              ]
            );
            //
          }
          table.draw();
        }
        else if (response.data.Status === 0) {
          if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
            $(".modal-body").html("<p class=class_p>Your session expired.");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=cancel>ok</button>");
            $("body").addClass('no-select'); secureLocalStorage.clear();
            $("#cancel").addClass("btn btn-block");
            $("#cancel").on("click", function () {
              $("#modalDialog").toggle('hide');
              window.location.href = "/";
            });
            if($("#modalDialog" ).hasClass( "show" )){
              $(".main-panel").css("filter","blur(8px)");
              $("#modalDialog").toggle('show');
            }
            else{
              $(".main-panel").css("filter","blur(8px)");
              $("#modalDialog").toggle('show');
            }
          }
          else {
            // window.clear("table1");
            $(".modal-title").html("");
            $(".modal-body").html("<p class='class_p'>" + response.data.Message + "</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=cancelredirect>Got it</button>");
            $("#cancelredirect").addClass("btn btn-block");
            $("#cancelredirect").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
        }
        else {
          $(".modal-title").html("");
          $(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=cancelredirect1>Got it</button>");
          $("#cancelredirect1").addClass("btn btn-block");
          $("#cancelredirect1").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
      })
      .catch(function (err) {
        // setGetdata(true);
        if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
        // secureLocalStorage.clear();
        $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("<button id=redirect>Got it</button>");
        $("#redirect").addClass("btn btn-block");
        $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
        }
        else{
            console.warn(err,"TransactionHistory");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn1>ok</button>");
            $("#closebtn1").addClass('btn btn-block');
            $("#closebtn1").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
        }
        return;
      });
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    var dateReceived = new Date(date);
    return (
      [
        dateReceived.getFullYear(),
        padTo2Digits(dateReceived.getMonth() + 1),
        padTo2Digits(dateReceived.getDate()),
      ].join('-')
    );
  }

  const selectdropdown = (event) => {
    if (event.target.value === "fiat") {
      document.getElementById("fiataccountsLists").style.display = "block";
      document.getElementById("cryptoaccountsLists").style.display = "none";
    }
    else if (event.target.value === "crypto") {
      document.getElementById("fiataccountsLists").style.display = "none";
      document.getElementById("cryptoaccountsLists").style.display = "block";
    }
    else {
      document.getElementById("fiataccountsLists").style.display = "none";
      document.getElementById("cryptoaccountsLists").style.display = "none";
    }
  }

  return (
    <div className="card px-4">
      <div className="page-header w-100">
        <h3 className="page-title w-100 row">
          <div className='col-5'>
            <span className="page-title-icon bg-success me-2">
              <i className="mdi mdi-file-document"></i>
            </span>Transaction History
          </div>
          <div className='col'>
            <div className="float-end">
              <div className='text-lastlogin text-end fs-6'>
                <i className="mdi mdi-account px-2"></i>Last login time
                <label className='d-block'>{secureLocalStorage.getItem('DT_')}</label>
              </div>
            </div>
          </div>
        </h3>
      </div>
      <div className="card-body">
          <div className="row mt-2">
            <div className="mb-3 col-md-2 col-xl-2 col-xs-12">
              <label className="form-label">From Date</label>
              <input id="from_date" className="form-control form-control-sm " type="date" defaultValue={formatDate(
                new Date().setDate(new Date().getDate() - 60)
              )} max={formatDate(new Date())} />
            </div>
            <div className="mb-3 col-md-2 col-xl-2 col-xs-12">
              <label className="form-label">To Date</label>
              <input id="till_date" className="form-control form-control-sm " type="date" defaultValue={formatDate(Date.now())} max={formatDate(new Date())} />
            </div>
            <div className="mb-3 col-md-2 col-xs-12">
              <label className="form-label">Type</label>
              <select className='form-select form-select-sm ' id="typeselect" onChange={selectdropdown}>
                <option value="">All</option>
                <option value="fiat">Fiat</option>
                <option value="crypto">Crypto</option>
              </select>
            </div>
            <div id="fiataccountsLists" style={{ display: 'none' }} className='mb-3 col-md-2 col-xs-12 col-sm-12 '>
              <label className="form-label">Select Account</label>
              <GetFiatAccounts id="accounts" className="form-select form-select-sm " />
            </div>
            <div id="cryptoaccountsLists" style={{ display: 'none' }} className='mb-3 col-md-2 col-xs-12 col-sm-12 '>
              <label className="form-label">Select Account</label>
              <GetCryptoAccounts id="accounts" className="form-select form-select-sm " />
            </div>
            <div className='mb-3 mt-4 col-md-2 col-xs-12'>
              <button className="btn btn-sm btn-yodigi-buy mt-2" onClick={getReport}>Get report</button>
            </div>
          </div>
          <div className="d-flex table-responsive mb-3">
            <table className="table nowrap w-100" id="tbl_transactionHistory">
              <thead>
                <tr className='text-left'>
                  <th></th>
                  <th>ID</th>
                  <th>Transaction Date</th>
                  <th>Transaction Reference</th>
                  <th>Transaction Type</th>
                  <th>Received From</th>
                  {/* <th>Amount</th>
                  <th>Credit</th>
                  <th>Debit</th>
                  <th>Service Charge</th> */}
                  <th>Status</th>
                  {/* <th>Transaction Notes </th> */}
                  {/* <th>Transaction Mode </th>  */}
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
      </div>
    </div>
  )
}

