
import React from 'react';
import secureLocalStorage from 'react-secure-storage';

var storedfAccounts = [];
function GetfiatLists(props){
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  storedfAccounts = JSON.parse(secureLocalStorage.getItem('FA_'));
	let currencyLists = storedfAccounts.length > 0 && storedfAccounts.map((item, i) => {
    let text = item.accounttype;
// console.log(text)
    const type = String(text.split("account")).replace(',','').toUpperCase();
		return (
        <option key={i} value={type} className="p-1">{type}</option>
		)
	}, this);
    return(
      <select className={props.className} id={props.id}>
        {currencyLists}
      </select>
    )
}


export default GetfiatLists;