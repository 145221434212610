import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

//import components
import GetCryptoCurrencies from './GetCryptoCurrencies';
import url_local from '../../url_local';
import CryptoAccountTables from './CryptoAccountTables';
import secureLocalStorage from 'react-secure-storage';
import routeCodes from '../../../Paths';

function CreateAndListCrypto() {
    console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
    const history = useNavigate();
    const createCryptoAccount = () => {
        var headers = {
            "Content-Type": "application/json",
            "Access-Token": secureLocalStorage.getItem('AT_'),
            "Refresh-Token": secureLocalStorage.getItem('RT_')
        };
        axios
            .post(url_local.create_cryptoaccount,
                {
                    publicid: secureLocalStorage.getItem('PI_'),
                    symbol: document.getElementById("cryptolists").value
                },
                { headers })
            .then(function (response) {
// console.log(response.data)
                if (response.data.Status === 1) {
                    $(".modal-title").html("");
                    $(".modal-body").html('<p class=class_p>Account created successfully.</p>');
                    $(".modal-footer").html("");
                    $(".modal-footer").html("<button id=addcr class=btn-success>ok</button>");
                    $("#addcr").addClass("btn btn-block");
                    $("#addcr").on("click", function () {
                        window.location.reload();
                        $("#modalDialog").toggle('hide');
                    });
                    $("#modalDialog").toggle('show');
                }
                else if (response.data.Status === 0) {
                    if(response.data.Code === 4101)
                    {
                        $(".modal-title").html("");
                        $(".modal-body").html('<p class=class_p>' + response.data.Message + '.</p>');
                        $(".modal-footer").html("");
                        $(".modal-footer").html("<button id=redirectcr>Activate now?</button><button id=cancelredirectcr>Cancel</button>");
                        $("#redirectcr").addClass("btn btn-block");
                        $("#cancelredirectcr").addClass("btn btn-block");
                        $("#redirectcr").on("click", function () {
                            history(routeCodes.WALLET);
                            $("#modalDialog").toggle('hide');
                        });
                        $("#cancelredirectcr").on("click", function () {
                            $("#modalDialog").toggle('hide');
                        });
                        $("#modalDialog").toggle('show');
                    }
                    else if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
                        $(".modal-title").html('');
                        $(".modal-body").html('<p class=class_p>Your session expired.</p>');
                        $(".modal-footer").html("");
                        $(".modal-footer").html("<button id=okbtncr>ok</button>");
                        $("body").addClass('no-select'); secureLocalStorage.clear();
                        $("#okbtncr").addClass("btn btn-block");
                        $("#okbtncr").on("click", function () {
                            $("#modalDialog").toggle('hide');
                            window.location.href = "/";
                            });
                            if($("#modalDialog" ).hasClass( "show" )){
                            $(".main-panel").css("filter","blur(8px)");
                            $("#modalDialog").toggle('show');
                            }
                            else{
                                $(".main-panel").css("filter","blur(8px)");
                                $("#modalDialog").toggle('show');
                            }
                    }
                    else {
                        $(".modal-title").html('');
                        $(".modal-body").html('<p class=class_p>' + response.data.Response + '</p>');
                        $(".modal-footer").html("");
                        $(".modal-footer").html("<button id=laterbtncr class=btn-success>Got it</button>");
                        $("#laterbtncr").addClass("btn btn-block");
                        $("#laterbtncr").on("click", function () {
                            $("#modalDialog").toggle('hide');
                        });
                        $("#modalDialog").toggle('show');
                    }
                }
                else {
                    $(".modal-body").html("<p class=text-danger>Something went wrong. Try again!</p>");
                    $(".modal-title").html("")
                    $(".modal-footer").html("");
                    $(".modal-footer").html("<button id=delete>Got it</button>");
                    $("#delete").addClass("btn btn-block");
                    $("#delete").on('click', function () {
                        $("#modalDialog").toggle('hide');
                    });
                    $("#modalDialog").toggle('show');
                }
                return;
            })
            .catch(function (err) {
                // setGetdata(true);
                if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
                // secureLocalStorage.clear();
                $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
                $(".modal-title").html("")
                $(".modal-footer").html("<button id=redirect>Got it</button>");
                $("#redirect").addClass("btn btn-block");
                $("#redirect").on("click", function () {
                    $("#modalDialog").toggle('hide');
                });
                $("#modalDialog").toggle('show');
                }
                else{
                    console.warn(err,"CreateAndListCrypto");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
                    $(".modal-title").html("")
                    $(".modal-footer").html("");
                    $(".modal-footer").html("<button id=closebtn1>ok</button>");
                    $("#closebtn1").addClass('btn btn-block');
                    $("#closebtn1").on("click", function () {
                        $("#modalDialog").toggle('hide');
                    });
                    $("#modalDialog").toggle('show');
                }
                return;
            });
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    {/* internal container is div.card below */}
                    <div className="card px-4">
                        <div className="page-header w-100">
                            <h3 className="page-title w-100 row">
                                <div className='col-5'>
                                    <span className="page-title-icon bg-success me-2">
                                        <i className="mdi mdi-wallet"></i>
                                    </span> Wallet
                                </div>
                                <div className='col'>
                                    <div className="float-end">
                                        <div className='text-lastlogin text-end fs-6'>
                                            <i className="mdi mdi-account px-2"></i>Last login time
                                            <label className='d-block'>{secureLocalStorage.getItem('DT_')}</label>
                                        </div>
                                    </div>
                                </div>
                            </h3>
                        </div>
                        {/* here content is placed */}
                        <div className='card-body pt-2'>
                            <h3 className="">Fund your Account </h3>
                            <label className="form-label">
                                Meezan provides multiple options to fund your account.
                                You can start your crypto journey by activating your account.
                            </label>
                            {/* <h5>Select Crypto currency </h5> */}
                            <div className="row">
                                {/* <p className='px-2 class_p'>Add Fiat account</p> */}
                                <div className="d-flex">
                                    <GetCryptoCurrencies id="cryptolists" className="form-select form-select-sm form-select me-3 w-25" />
                                    <button className="btn btn-sm btn-yodigi-buy" onClick={createCryptoAccount}>
                                        Create Account</button>
                                </div>
                                <div>
                                    <p className='card-description mb-3 mt-3'>Crypto Balances</p>
                                    <CryptoAccountTables />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default CreateAndListCrypto;