import React, { useEffect } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import $ from 'jquery';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Tab, Nav } from 'react-bootstrap';
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import secureLocalStorage from 'react-secure-storage';

//import components
import WebHeaderLoggedInHome from '../web/layout/WebHeaderLoggedInHome';
import url_local from '../url_local';
import routeCodes from "../../Paths";
import SellOrder from "./SellOrder";
import BuyOrder from "./BuyOrder";
import BuyMarketOrder from "./BuyMarketOrder";
import SellMarketOrder from "./SellMarketOrder";
// import GetFiatAccounts from '../users/wallet/GetFiatAccounts';

//import css
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
// import zIndex from "@mui/material/styles/zIndex";

var cryptos = []; var cryptosymbols = [];
// var buyList = [], sellList = [], buyMarketList = [],
var mytradeongoinglists = [], mytradematuredlists = [];
function ExchangeDashboard(props) {
	console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
	// const [loading, setLoading] = React.useState(true);
	const selectedCrypto = React.useRef("BTC");
	const [buy, setBuy] = React.useState([]);
	const [sell, setSell] = React.useState([]);
	const [buyMarket, setMarket] = React.useState([]);
	const [myTradesOngoing, setmyTradesOngoing] = React.useState([]);
	const [myTradesMatured, setmyTradesMatured] = React.useState([]);
	const [cryptoData, setcryptoData] = React.useState([]);
	const [pairkey, setpairkey] = React.useState("");
	const [accData,setAccData] =  React.useState([]);
	const [loading, setLoading] = React.useState(true);

	// const [Resp, setResponse] = React.useState();
	const [placeholder, setPlaceholder] = React.useState("");
	const [placeholderValue, setPlaceholderValue] = React.useState("");
	const [currency, setCurrency] = React.useState("");
	const [account, setAccount] = React.useState("");
	const [theme, setTheme] = React.useState(Themes.DARK);


	useEffect(() => {
		let current = localStorage.getItem("theme");
		if (current === "light-theme"){
			setTheme(Themes.LIGHT);
      		delete document.documentElement.dataset.bsTheme;
		}
		else{
			setTheme(Themes.DARK);
      		document.documentElement.dataset.bsTheme = "dark";
		}

		getmyTrades();
		getmarketTrades();
		// selectedCrypto.current = $('#selectMarketRates').val().split("/USDT").replace(',', '').toUpperCase();
		// selectedCrypto.current = String(($('#selectMarketRates').attr('value')).split("/USDT")).replace(',', '').toUpperCase();
		getBuyOrderBook(selectedCrypto);
		// getBuyOrderBook(String(($('option:selected', this).attr('value')).split("/USDT")).replace(',', '').toUpperCase());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	function setCurrecyValue(option) {
		setPlaceholder(option.previousClose);
		setPlaceholderValue(String((option.symbol).split("/USDT")).replace(',', '').toUpperCase());
		document.getElementById("price").innerHTML = option.previousClose;
		document.getElementById('high').innerHTML = option.high;
		document.getElementById('low').innerHTML = option.low;
		document.getElementById('bitcoinCur').innerHTML = option.symbol;
		var perc = option.percentage;
		document.getElementById("percDiv1").style.display = "none";
		document.getElementById("percDiv2").style.display = "block";
		if (perc < 0) {
			document.getElementById('successdiv').style.display = "none";
			document.getElementById('faildiv').style.display = "block";
			document.getElementById("percdown").innerText = perc;
		}
		else {
			document.getElementById('faildiv').style.display = "none";
			document.getElementById('successdiv').style.display = "block";
			document.getElementById("percup").innerText = perc;
		}
		var symbol = "BINANCE:" + option.symbol.replace("/", "");
		setpairkey(symbol);
		var value = $('#bitcoinselect option:selected', this).attr('value');
		var accountno = $('#bitcoinselect option:selected', this).attr('data-accountno');
		localStorage.setItem("currency", value);
		localStorage.setItem("acid", accountno);
		setAccount(accountno);
		setCurrency(value);
		selectedCrypto.current = String((option.symbol).split("/USDT")).replace(',', '').toUpperCase();
		// getBuyOrderBook(String((option.symbol).split("/USDT")).replace(',', '').toUpperCase());
	}
	const getmarketTrades = () => {
		var headers = {
			"Content-Type": "application/json",
			"Access-Token": secureLocalStorage.getItem('AT_'),
			"Refresh-Token": secureLocalStorage.getItem('RT_'),
		};
		axios.post(url_local.trans,{"publicid": secureLocalStorage.getItem('PI_'),},{ headers })
		.then(function (response) {
			if (response.data.Status === 1) {
				// setLoading(false);
				cryptosymbols = JSON.parse(secureLocalStorage.getItem('CRA_'));
				for (let i = 0; i < response.data.Data.length; i++) {
					var text = (response.data.Data[i].symbol).split("/")[0];
					for (let j = 0; j < cryptosymbols.length; j++) {
						if (cryptosymbols[j].coin_symbol === text) {
							var data = {
								name: (response.data.Data[i].symbol).split("/")[0],
								previousClose: response.data.Data[i].previousClose,
								symbol: response.data.Data[i].symbol,
								close: response.data.Data[i].close,
								high: response.data.Data[i].high,
								low: response.data.Data[i].low,
								percentage: response.data.Data[i].percentage
							}
							cryptos.push(data)
						}
					}
				}
				setcryptoData(cryptos);
				setCurrecyValue(cryptos[0]);
			}
			else if (response.data.Status === 0) {
				// setLoading(true);
				$(".modal-title").html("");
				$(".modal-body").html(response.data.Message);
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=closebtn>ok</button>");
				$("#closebtn").addClass('btn btn-block');
				$("#closebtn").on("click", function () {
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
			}
			else {
				$(".modal-body").html("<p class=text-danger>Something went wrong. Try again.</p>");
				$(".modal-title").html("")
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=got_it>Got it</button>");
				$("#got_it").addClass("btn btn-block");
				$("#got_it").on("click", function () {
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
			}
			return;
		})
		.catch(function (err) {
			// setLoading(true);
			// setGetdata(true);
			if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
			// secureLocalStorage.clear();
			$(".modal-body").html("<p className='text-danger'>Network Error!</p>");
			$(".modal-title").html("")
			$(".modal-footer").html("<button id=redirect>Got it</button>");
			$("#redirect").addClass("btn btn-block");
			$("#redirect").on("click", function () {
				$("#modalDialog").toggle('hide');
			});
			$("#modalDialog").toggle('show');
			}
			else{
				console.warn(err,"ExchangeDashboard");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
				$(".modal-title").html("")
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=closebtn1>ok</button>");
				$("#closebtn1").addClass('btn btn-block');
				$("#closebtn1").on("click", function () {
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
			}
			return;
		});
	}
	function getBuyOrderBook(crypto) {
		// console.log("crypto selected",crypto)
		if (typeof (EventSource) !== "undefined") {
			let headers = new Headers();
			headers.append('Content-Type', 'application/json');
			headers.append('Accept', 'application/json');
			headers.append('Access-Control-Allow-Origin', '*');
			headers.append('Access-Control-Allow-Credentials', 'true');
			headers.append('GET', 'POST');
			var source = new EventSource(url_local.orderbooks);
			source.onopen = function (e) {
			}
			source.onmessage = function (message) {
				try{
					var object = message.data.replace(/'/g,'"');
					var data = JSON.parse(object);
					var buyList = []; var sellList = []; var buyMarketList = [];
					for (let i = 0; i < data.length; i++) {
						if(data[i].symbol === selectedCrypto.current){
							if (data[i].type === "Limit" && data[i].side === 1) {
								sellList.push(data[i]);
							}
							else if (data[i].type === "Limit" && data[i].side === 2) {
								buyList.push(data[i]);
							}
							else if (data[i].type === "Market" && data[i].side === 1) {
								buyMarketList.push(data[i]);
							}
							else if (data[i].type === "Market" && data[i].side === 2) {
								buyMarketList.push(data[i]);
							}
						}
						// setSellMarket(sellMarketList);
					}
					setBuy(buyList);
					setSell(sellList);
					setMarket(buyMarketList);
				}
				catch(e){

				}
			}
			source.onerror = function (e) {
				console.warn(e);
			}
		}
	}

	$('#selectMarketRates').on('change', function () {
		document.getElementById('percDiv1').style.display = 'none';
		var price = $('option:selected', this).attr('data-price');
		var high = $('option:selected', this).attr('data-high');
		var low = $('option:selected', this).attr('data-low');

		document.getElementById("bitcoinCur").innerHTML = $('option:selected', this).attr('value');
		document.getElementById("high").innerHTML = high;
		document.getElementById("low").innerHTML = low;
		document.getElementById("price").innerHTML = price;
		setPlaceholder(price);
		setPlaceholderValue($('option:selected', this).attr('value'));
		var perc = $('option:selected', this).attr('data-perc');
		document.getElementById('percDiv2').style.display = 'block';

		if (perc < 0) {
			document.getElementById('successdiv').style.display = "none";
			document.getElementById('faildiv').style.display = "block";
			document.getElementById("percdown").innerText = perc;
		}
		else {
			document.getElementById('faildiv').style.display = "none";
			document.getElementById('successdiv').style.display = "block";
			document.getElementById("percup").innerText = perc;
		}
		var pairkey = $('option:selected', this).attr('value').replace('/', '');
		setpairkey(pairkey);
		selectedCrypto.current = String(($('option:selected', this).attr('value')).split("/USDT")).replace(',', '').toUpperCase();
		// getBuyOrderBook(String(($('option:selected', this).attr('value')).split("/USDT")).replace(',', '').toUpperCase());
	});

	const getmyTrades = () => {
		const headers = {
			"Content-Type": "application/json",
			"Access-Token": secureLocalStorage.getItem('AT_'),
			"Refresh-Token": secureLocalStorage.getItem('RT_'),
		};
		const payload = { "publicid": secureLocalStorage.getItem('PI_') }
		axios.post(url_local.myTrades,payload,{ headers })
		.then(function (response) {
			if (response.data.Status === 1) {
				mytradeongoinglists = []; mytradematuredlists = [];
				for (let i = 0; i < response.data.Data.Ongoing.length; i++) {
					mytradeongoinglists.push(response.data.Data.Ongoing[i]);
				}

				for (let i = 0; i < response.data.Data.Matured.length; i++) {
					mytradematuredlists.push(response.data.Data.Matured[i]);
				}

				setmyTradesOngoing(mytradeongoinglists);
				setmyTradesMatured(mytradematuredlists);
			}
			else if (response.data.Status === 0) {
				localStorage.setItem('referTodashboard', false);
				$(".modal-title").html("");
				$(".modal-body").html("<p class='class_p'>" + response.data.Message + "</p>");
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=cancelredirect>Got it</button>");
				$("#cancelredirect").addClass("btn btn-block");
				$("#cancelredirect").on("click", function () {
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
			}
			else {
				$(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
				$(".modal-title").html("")
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=resp>Got it</button>");
				$("#resp").addClass("btn btn-block");
				$("#resp").on("click", function () {
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
			}
			return;
		})
		.catch(function (err) {
			// setGetdata(true);
			if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
			// secureLocalStorage.clear();
			$(".modal-body").html("<p className='text-danger'>Network Error!</p>");
			$(".modal-title").html("")
			$(".modal-footer").html("<button id=redirect>Got it</button>");
			$("#redirect").addClass("btn btn-block");
			$("#redirect").on("click", function () {
				$("#modalDialog").toggle('hide');
			});
			$("#modalDialog").toggle('show');
			}
			else{
				console.warn(err,"ExchangeDashboard");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
				$(".modal-title").html("")
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=closebtn1>ok</button>");
				$("#closebtn1").addClass('btn btn-block');
				$("#closebtn1").on("click", function () {
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
			}
			return;
		});

		axios.post(url_local.accountlist, payload, { headers })
		.then(function (response) {
			setLoading(false);
			if (response.data.Status === 1) {
				setAccData(response.data.Data.fiat.list)
			}
		})
		.catch(function () {
			setLoading(false);
		});
	}



	let markettrades = cryptoData.length > 0 && cryptoData.map((item, index) => {
		return (
			<option key={index} value={item.symbol} data-price={item.previousClose} data-perc={item.percentage}
				data-high={item.high} data-low={item.low}>{item.name}</option>
		)
	}, this);

	let mytrade_ongoing = myTradesOngoing.length > 0 && myTradesOngoing.map((item, index) => {
		const HH_MM_SS = { hour: '2-digit', minute: '2-digit', second: '2-digit' }
		let cFormat = new Date(item.timestamp).toLocaleTimeString('en-US', HH_MM_SS).replace(/AM|PM/, '');
		return (
			<tr key={index}>
				{item.side === 1 ?
					<td className="text-danger text-left">{item.price}</td> :
					<td className="text-success text-left">{item.price}</td>}
				<td className="text-left">{(item.quantity).toFixed(6)}</td>
				<td className="text-left">{cFormat}</td>
			</tr>
		)
	}, this);

	let mytrade_matured = myTradesMatured.length > 0 && myTradesMatured.map((item, index) => {
		// var d = new Date(item.timestamp);
		// var dateString=d.toLocaleString().split('t')[0];

		const HH_MM_SS = { hour: '2-digit', minute: '2-digit', second: '2-digit' }
		let cFormat = new Date(item.timestamp).toLocaleTimeString('en-US', HH_MM_SS).replace(/AM|PM/, '');
		return (
			<tr key={index}>
				{item.side === 1 ?
					<td className="text-danger text-left">{item.price_USD}</td> :
					<td className="text-success text-left">{item.price_USD}</td>}
				<td className="text-left">{(item.quantity).toFixed(6)}</td>
				<td className="text-left">{cFormat}</td>
			</tr>
		)
	}, this);


	// var storedfiat_datas = JSON.parse(secureLocalStorage.getItem("FL_"));

	function get_All_FiatAccounts() {
		return accData.length > 0 && accData.map((item, i) => {
			if (item.account === undefined || item.account === "" || item.account === null) {
				return (
					<option key={i} disabled>No accounts created</option>
				)
			}
			else {
				let text = item.type;
				const type = String(text.split("account")).replace(',', '').toUpperCase();
				return (
					<option key={i} value={type} id={item.account}>
						{type}
					</option>
				)
			}
		});
	}

	const handleChange = (e) => {
		// secureLocalStorage.setItem("CS_", "");
		// secureLocalStorage.setItem("AN_", "");
		var index = e.target.selectedIndex;
		var el = e.target.childNodes[index];
		var option = el.getAttribute('id');
		secureLocalStorage.setItem("CS_", e.target.value);
		secureLocalStorage.setItem("AN_", option);
		// document.getElementById("acno.").innerHTML = "Account No. :" + option;
		// document.getElementById("acno.").style.display = "block";
	}



	// if (loading) {
	// 	return (<Spinner />)
	// }
	// else{
	return (
		<>
			<div id="header" className='mb-2' >
				<div className="px-5" >
					<WebHeaderLoggedInHome />
				</div>
			</div>
			<div className="card" style={{ minHeight: '100vh' }}>
				<div className="card-body">
					<div className="row px-2">
						<div className="col-md-3 col-xs-12">
							<PerfectScrollbar>
								<Tab.Container defaultActiveKey="order_book">
									<Nav variant="tabs">
										<Nav.Link eventKey="order_book">
											<i className="mdi mdi-view-dashboard"></i>
										</Nav.Link>
										<Nav.Link eventKey="Buy">Buy</Nav.Link>
										<Nav.Link eventKey="Sell">Sell</Nav.Link>
									</Nav>
									<Tab.Content>
										<Tab.Pane eventKey="order_book">
											<div className="table-responsive mt-3" style={{ minHeight: '30vh' }}>
												<h5 className="card-description text-danger">Sell order</h5>
												<table className="table table-borderless">
													<thead className="mb-3">
														<tr className="text-left">
															<th className="text-left class_p" scope="col">Price</th>
															<th className="text-left class_p" scope="col">Size</th>
															<th className="text-left class_p" scope="col">Total</th>
														</tr>
													</thead>
													{sell.length > 0 ?
														<tbody>
															{sell.map((item, index) =>
															(
																<tr key={index}>
																	<td className="text-danger text-left">{item.usd_value}</td>
																	<td className="text-left">{item.volume}</td>
																	<td className="text-left">{(item.usd_value * item.volume).toFixed(6)}</td>
																</tr>
															)
															)}
														</tbody> :
														<tbody>
															<tr>
																<td colSpan={6}>
																	No records
																</td>
															</tr>
														</tbody>}
												</table>
											</div>
											<div className="order-book-divider mt-3 mb-3">
												<h6 className="text-danger">
													{" "}<i className="la la-arrow-down"></i>{" "} 6587.35</h6>
												<span>6520.220 / 4835.00</span>
											</div>
											<div className="table-responsive" style={{ minHeight: '30vh' }}>
												<h5 className="card-description text-success">Buy order</h5>
												<table className="table table-borderless h-100">
													<thead>
														<tr className="text-left mb-5">
															<th className="text-left class_p" scope="col">Price</th>
															<th className="text-left class_p" scope="col">Size</th>
															<th className="text-left class_p" scope="col">Total</th>
														</tr>
													</thead>
													{buy.length > 0 ?
														<tbody>
															{buy.map((item, index) =>
															(
																<tr key={index}>
																	<td className="text-success text-left">{item.usd_value}</td>
																	<td className="text-left">{item.volume}</td>
																	<td className="text-left">{(item.usd_value * item.volume).toFixed(6)}</td>
																</tr>
															)
															)}
														</tbody> :
														<tbody>
															<tr>
																<td colSpan={6}>
																	No records
																</td>
															</tr>
														</tbody>}
												</table>
											</div>
										</Tab.Pane>
										<Tab.Pane eventKey="Buy">
											<div className="table-responsive mt-3">
												<h5 className="card-description text-success">Buy order</h5>
												<table className="table table-borderless h-100">
													<thead>
														<tr className="text-left mb-5">
															<th className="text-left class_p" scope="col">Price</th>
															<th className="text-left class_p" scope="col">Size</th>
															<th className="text-left class_p" scope="col">Total</th>
														</tr>
													</thead>
													{buy.length > 0 ?
														<tbody>
															{buy.map((item, index) =>
															(
																<tr key={index}>
																	<td className="text-success text-left">{item.usd_value}</td>
																	<td className="text-left">{item.volume}</td>
																	<td className="text-left">{(item.usd_value * item.volume).toFixed(6)}</td>
																</tr>
															)
															)}
														</tbody> :
														<tbody>
															<tr>
																<td colSpan={6}>
																	No records
																</td>
															</tr>
														</tbody>}
												</table>
											</div>
										</Tab.Pane>
										<Tab.Pane eventKey="Sell">
											<div className="table-responsive mt-3">
												<h5 className="card-description text-danger">Sell order</h5>
												<table className="table table-borderless">
													<thead className="mb-3">
														<tr className="text-left">
															<th className="text-left class_p" scope="col">Price</th>
															<th className="text-left class_p" scope="col">Size</th>
															<th className="text-left class_p" scope="col">Total</th>
														</tr>
													</thead>
													{sell.length > 0 ?
														<tbody>
															{sell.map((item, index) =>
															(
																<tr key={index}>
																	<td className="text-danger text-left">{item.usd_value}</td>
																	<td className="text-left">{item.volume}</td>
																	<td className="text-left">{(item.usd_value * item.volume).toFixed(6)}</td>
																</tr>
															)
															)}
														</tbody> :
														<tbody>
															<tr>
																<td colSpan={6}>
																	No records
																</td>
															</tr>
														</tbody>}
												</table>
											</div>
										</Tab.Pane>
									</Tab.Content>
								</Tab.Container>
							</PerfectScrollbar>
						</div>
						<div className="col-md-6 col-xs-12 " style={{ borderLeft: "1px solid #292c37", borderRight: "1px solid #292c37" }}>
							{/* <BuyandSell /> */}
							<div className="row">
								<div className="col-xs-12 col-md-3">
									<p>Market Rates</p>
									<select id="selectMarketRates" className="form-select form-select-sm position-relative">
										{markettrades}
									</select>
								</div>
								<div className="col-xs-12 col-md-6  col-lg-2  text-center mt-2">
									<h5 id="bitcoinCur">--:--</h5>
									<h6 className="class_p text-success" id="price">--:--</h6>
								</div>
								<div className="col-xs-12 col-md-6  col-lg-2 text-center mt-2">
									<p className="class_p form-label">High<i className="mdi mdi-arrow-up"></i>(1D)</p>
									<h6 id="high">--:--</h6>
								</div>
								<div className="col-xs-12 col-md-6  col-lg-2  text-center mt-2">
									<p className="class_p form-label">Low<i className="mdi mdi-arrow-down"></i>(1D)</p>
									<h6 id="low">--:--</h6>
								</div>
								<div className="col-xs-12 col-sm-6  col-lg-2  text-center mt-2">
									<p className="class_p" id="percDiv1">--:--</p>
									<p className="class_heading" id="percDiv2" style={{ display: 'none' }}>
										<span className="text-danger" id="faildiv">
											<i className='mdi mdi-arrow-down'></i>&nbsp; <span id="percdown"></span>%</span>
										<span className="text-success" id="successdiv">
											<i className='mdi mdi-arrow-up'></i>&nbsp;<span id="percup"></span> %
										</span>
									</p>
								</div>
								<input type="hidden" id="securitysymbol" name="securitysymbol" />
							</div>
							<input type="hidden" id="accountno" name="accountno" value="" />
							<div className="row mt-2">
								<div className="col-12" id="chartDiv">
									{/* <!-- TradingView Widget BEGIN --> */}
									<div className="tradingview-widget-container card"
										style={{ height: "400px" }}>
										<TradingViewWidget
											symbol={pairkey}
											theme={theme}
											locale="en"
											autosize
										/>
									</div>
									{/* <!-- TradingView Widget END --> */}
								</div>
							</div>
							<div className="row mb-2">
								<Tab.Container defaultActiveKey="limit">
									<div className="card">
										<div className="row card-header">
											<div className="col-12 col-md-8">
												<Nav variant="tabs">
													<Nav.Link eventKey="limit" className="text-white">Limit</Nav.Link>
													<Nav.Link eventKey="market" className="text-white">Market</Nav.Link>
												</Nav>
											</div>
											<div className="col-12 col-md-4 mt-2">
												<label className="card-description">Choose currency</label>
												{/* <GetFiatAccounts id="bitcoinselect" className="mx-1 form-select form-select-sm  form-control w-50" /> */}
												{
                        							loading?<div className="spinner-border spinner-border-sm " role="status"></div>:
													<select defaultValue=""
														className="form-control"
														aria-label="Floating label select example"
														onChange={handleChange}>
														<option value="" disabled>
															-- Select one --
														</option>
														{get_All_FiatAccounts()}
													</select>
												}
											</div>
										</div>
										<Tab.Content className="mx-1">
											<Tab.Pane eventKey="limit">
												<div className="row">
													<div className="col-md-6 col-xs-12">
														<BuyOrder placeholder={placeholder} symbol={placeholderValue} value={currency} account={account} />
													</div>
													<div className="col-md-6 col-xs-12">
														<SellOrder placeholder={placeholder} symbol={placeholderValue} value={currency} account={account} />
													</div>
												</div>
											</Tab.Pane>
											<Tab.Pane eventKey="market">
												<div className="row">
													<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
														<BuyMarketOrder placeholder={placeholder} symbol={placeholderValue} value={currency} account={account} />
													</div>
													<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
														<SellMarketOrder placeholder={placeholder} symbol={placeholderValue} value={currency} account={account} />
													</div>
												</div>
											</Tab.Pane>
										</Tab.Content>
									</div>
								</Tab.Container>
								{/* <div id="errorDiv" clasname="text-center" style={{ display: 'none' }}>
									<p className='class_p text-danger'>{Resp}</p>
								</div>
								<div id="successDiv" className='text-success text-center' style={{ display: 'none' }}>
									<p className='class_p text-success'>{Resp}</p>
								</div> */}
								<div id="createcryptoaccount" clasname="text-center" style={{ display: 'none' }}>
									<p className='class_p text-center text-danger'>Currently no crypto account detected.
										<Link className='btn btn-sm btn-yodigi-buy mt-1' to={routeCodes.ACCOUNTS}>Create Account ?</Link></p>
								</div>
							</div>
						</div>
						<div className=" col-md-3 col-xs-12">
							<PerfectScrollbar>
								<Tab.Container defaultActiveKey="Market Trades">
									<div className="mb-3">
										<Nav variant="tabs">
											<Nav.Link eventKey="Market Trades">Market Trades</Nav.Link>
											<Nav.Link eventKey="My Trades">My Trades</Nav.Link>
										</Nav>
									</div>

									<Tab.Content>
										<Tab.Pane eventKey="Market Trades">
											<div className="row">
												<div className="card-body trade-history">
													<div className="table-responsive mt-3" style={{ minHeight: '35vh' }}>
														<table className="table table-borderless">
															<thead>
																<tr className="text-left class_p">
																	<th scope="col" className="text-left class_p">Price</th>
																	<th scope="col" className="text-left class_p">Size</th>
																	<th scope="col" className="text-left class_p">Total</th>
																</tr>
															</thead>
															{buyMarket.length > 0 ?
																<tbody>
																	{buyMarket.map((item, index) =>
																	(
																		<tr key={index}>
																			{item.side === 1 ?
																				<td className="text-danger text-left">{item.usd_value}</td> :
																				<td className="text-success text-left">{item.usd_value}</td>}
																			<td className="text-left">{item.volume}</td>
																			<td className="text-left">{(item.usd_value * item.volume)}</td>
																		</tr>
																	))}
																</tbody> :
																<tbody>
																	<tr>
																		<td colSpan={6}>No records</td>
																	</tr>
																</tbody>}
														</table>
													</div>
												</div>

											</div>
										</Tab.Pane>
										<Tab.Pane eventKey="My Trades">
											<div className="row">
												<div className="card-body trade-history">
													<div className="table-responsive" style={{ minHeight: '30vh' }}>
														<h5 className="card-description text-warning">Open orders</h5>
														<table className="table table-borderless">
															<thead className="mb-3">
																<tr className="text-left">
																	<th className="text-left class_p" scope="col">Price</th>
																	<th className="text-left class_p" scope="col">Size</th>
																	<th className="text-left class_p" scope="col">Time</th>
																</tr>
															</thead>
															{myTradesOngoing.length > 0 ?
																<tbody>
																	{mytrade_ongoing}
																</tbody> :
																<tbody>
																	<tr>
																		<td colSpan={6}>
																			No records
																		</td>
																	</tr>
																</tbody>}
														</table>
													</div>

													<div className="table-responsive mt-5" style={{ borderTop: "1px solid #292c37" }}>
														<h5 className="card-description text-danger mt-1">Closed orders</h5>
														<table className="table table-borderless">
															<thead>
																<tr className="text-left mb-5">
																	<th className="text-left class_p" scope="col">Price</th>
																	<th className="text-left class_p" scope="col">Size</th>
																	<th className="text-left class_p" scope="col">Time</th>
																</tr>
															</thead>
															{myTradesMatured.length > 0 ?
																<tbody>
																	{mytrade_matured}
																</tbody> :
																<tbody>
																	<tr>
																		<td colSpan={6}>
																			No records
																		</td>
																	</tr>
																</tbody>}
														</table>

													</div>
												</div>
											</div>
										</Tab.Pane>
									</Tab.Content>
								</Tab.Container>
							</PerfectScrollbar>
						</div>
					</div>
				</div>
			</div>
		</>
	);
	// }
}

export default ExchangeDashboard;
