import React, { useEffect } from "react";
import $ from 'jquery';
// import * as Yup from 'yup';
// import * as Yup from 'yup';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

// form
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';

// components
import Spinner from '../shared/Spinner';
import url_local from '../url_local';

import { Nationalitylist, Residencelist } from '../shared/Nationalities';
import { LocalCurrencylist } from '../shared/CurrencyLists';
import EditSecEmail from './kyc/EditSecEmail';
import EditSecMobile from './kyc/EditSecMobile';

// import '../../assets/styles/web/css/style.css';
import routeCodes from "../../Paths";
// import Spinner from '../shared/Spinner';
import secureLocalStorage from 'react-secure-storage';
// import { GlobalContext } from '../shared/useForm';

// var kycdata = [];
function padTo2Digits(num) {
	return num.toString().padStart(2, '0');
}

function formatDate(date) {
	var dateReceived = new Date(date);
	return (
		[
			dateReceived.getFullYear(),
			padTo2Digits(dateReceived.getMonth() + 1),
			padTo2Digits(dateReceived.getDate()),
		].join('-')
	);
}




function Settings() {
	console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
	// const history = useNavigate();
	// const gContext = React.useContext(GlobalContext);

	// const ref = useRef(null);
	const [loading, setLoading] = React.useState(false);
	// const [nationality, setNationality] = React.useState([]);
	// const [userdata, setuserdata] = React.useState([]);
	// const [show, setShow] = React.useState("false");
	// const handleShow = () => setShow(true);
	const [email, setEmail] = React.useState("");
	const [phone, setPhone] = React.useState("");
	const [maxDate, setMaxDate] = React.useState("2023-01-01");
	// const [show1, setShow1] = React.useState(false);

	// const handleClose1 = () => setShow1(false);
	// const handleShow1 = () => setShow1(true);
	const initialState = { alt: "", src: "" };
	// const initialStatefront = { altfront: "", srcfront: "" };
	// const initialStateback = { altback: "", srcback: "" };
	const media = React.useRef("media/")
	const [{ alt, src, profiledata }, setPreview] = React.useState(initialState);
	// const [{ altfront, srcfront, profiledatafront }, setPreviewfront] = React.useState(initialStatefront);
	// const [{ altback, srcback, profiledataback }, setPreviewback] = React.useState(initialStateback);


	// const SettingsSchema = Yup.object().shape({
	// 	firstname: Yup.string().required('* This field is required'),
	// 	lastname: Yup.string().required('* This field is required'),
	// 	dob: Yup.string().required('* This field is required'),
	// });

	// const defaultValues = {
	// 	firstname: '',
	// 	lastname: '',
	// 	dob: ''
	// };

	// const methods = useForm({
	// 	resolver: yupResolver(SettingsSchema),
	// 	defaultValues,
	// });

	// const {
	// 	handleSubmit,
	// 	formState: { isSubmitting },
	// } = methods;


	useEffect(() => {
		// const loadData = async () => {
		// 	await new Promise((r) => setTimeout(r, 1000));
		// 	setLoading((loading) => !loading);
		// };
		if (!url_local.home.startsWith("http://192.168",0)){
			media.current = "";
			console.log("clear media/",url_local.home);
		}

		var max18Years = new Date();
		var dd = max18Years.getDate();
		var mm = max18Years.getMonth() + 1; //January is 0!
		var yyyy = max18Years.getFullYear() - 18;
		if (dd < 10) { dd = '0' + dd;}
		if (mm < 10) { mm = '0' + mm; }
		max18Years = yyyy + '-' + mm + '-' + dd;
		setMaxDate(max18Years);

		var headers = {
			"Content-Type": "application/json",
			'Access-Token': secureLocalStorage.getItem('AT_'),
			"Refresh-Token": secureLocalStorage.getItem('RT_')
		};
		let payload = { publicid: secureLocalStorage.getItem('PI_') }
		axios.post(url_local.kyc, payload, headers)
			.then(function (response) {
				// setLoading(false);
// console.log(response.data,"kyc============")
				if (response.data.Status === 1) {
					// setuserdata(response.data.Data);
					document.getElementById('firstname').value = response.data.Data.firstname;
					document.getElementById('lastname').value = response.data.Data.lastname;
					document.getElementById('emailmain').innerHTML = response.data.Data.primemail;
					document.getElementById('phonemain').innerHTML = response.data.Data.primmobile;
					document.getElementById('idnumbermain').innerHTML = response.data.Data.idno;
					document.getElementById('primeEmail').innerHTML = response.data.Data.primemail;
					document.getElementById('primeMob').innerHTML = response.data.Data.primmobile;
					document.getElementById('secEmail').innerHTML = response.data.Data.secemail;
					document.getElementById('secMob').innerHTML = response.data.Data.secmobile;
					try {
						document.getElementById("profile_photo").setAttribute("src", url_local.home +media.current+ response.data.Data.profilepicture);
						document.getElementById("profile_photo").setAttribute("alt", media.current+response.data.Data.profilepicture);
						setPreview({
							src: url_local.home + media.current+response.data.Data.profilepicture,
							alt: media.current+ response.data.Data.profilepicture,
							// profiledata:'',
						});
						secureLocalStorage.setItem('PP_',url_local.home +media.current+ response.data.Data.profilepicture);
					} catch (e) {
// console.log('Error: response.data.Data.profilepicture');
					}

					$("#salutSelect").find('option[value=' + response.data.Data.salutation.toLowerCase() + ']')[0].defaultSelected = true;
					$("#genderSelect").find('option[value=' + response.data.Data.gender.toLowerCase() + ']')[0].defaultSelected = true;
					try {
						$("#currencySelect").find('option[value="' + response.data.Data.usercurrency.toUpperCase() + '"]')[0].defaultSelected = true;
					} catch (e) {
// console.log('Error: response.data.Data.usercurrency');
					}

					try {
						const nat = JSON.parse(response.data.Data.nationality.toString().replace(/'/g, '"'));
						if (nat instanceof Object){
							$("#nationalitySelect1").find('option[value="' + nat[0].trim() + '"]')[0].defaultSelected = true;
							$("#nationalitySelect2").find('option[value="' + nat[1].trim() + '"]')[0].defaultSelected = true;
							$("#nationalitySelect3").find('option[value="' + nat[2].trim() + '"]')[0].defaultSelected = true;
						}
						else{
							// setNationality([response.data.Data.nationality,'',''])
							$("#nationalitySelect1").find('option[value="' + response.data.Data.nationality.trim() + '"]')[0].defaultSelected = true;
						}
					} catch (e) {
// console.log('Error: response.data.Data.nationality');
					}
					try {
						$("#residenceSelected").find('option[value="' + response.data.Data.residence.trim() + '"]')[0].defaultSelected = true;
					} catch (e) {
// console.log('Error: response.data.Data.residence');
					}
					try {
						document.getElementById('dob').value = formatDate(new Date(response.data.Data.dob));
					} catch (e) {
// console.log('Error response.data.Data.dob');
					}
					try {
						document.getElementById('secEmail').innerHTML = response.data.Data.secemail;
					} catch (e) {
// console.log('Error response.data.Data.secemail');
					}
					// document.getElementById('editprimeEmail').innerHTML = response.data.Data.primemail;

					try {
						document.getElementById('secMob').innerHTML = response.data.Data.secmobile;
					} catch (e) {
// console.log('Error response.data.Data.secmobile');
					}

					// loadData();
					// document.getElementById('editprimeEmail').innerHTML = response.data.Data.primmobile;
					setEmail(document.getElementById('primeEmail').innerHTML);
					setPhone(document.getElementById('primeMob').innerHTML);

					// if (response.data.Data.secemail !== "" || response.data.Data.secemail !== null) {
					//  	document.getElementById('secEmail').innerHTML = response.data.Data.secemail;
					// }
					// else {
					//  	document.getElementById('secEmail').innerHTML = "";
					// }

					// if (response.data.Data.secmobile !== "" || response.data.Data.secmobile !== null) {
					//  	document.getElementById('secMob').innerHTML = response.data.Data.secmobile;
					// }
					// else {
					//  	document.getElementById('secMob').innerHTML = response.data.Data.secmobile;
					// }
// console.log("response.data.Data.secemail_status", response.data.Data.secemail_status)

					if (response.data.Data.secemail_status === false) {
						document.getElementById("toggleEmailsettingsbtn").disabled = true;
						document.getElementById("toggleEmailsettingsbtn").parentElement.style.display = 'none';
						document.getElementById("idnotverifiedSecEmail").style.display = 'block';
						document.getElementById("idverifiedSecEmail").style.display = 'none';
					}
					else {
						document.getElementById("toggleEmailsettingsbtn").disabled = false;
						document.getElementById("idnotverifiedSecEmail").style.display = 'none';
						document.getElementById("idverifiedSecEmail").style.display = 'block';
					}

					if (response.data.Data.secmobile_status === false) {
						document.getElementById("toggleMobilesettingsbtn").disabled = true;
						document.getElementById("toggleMobilesettingsbtn").parentElement.style.display = 'none';
						document.getElementById("idverifiedSecMob").style.display = 'none';
						document.getElementById("idnotverifiedSecMob").style.display = 'block';
					}
					else {
						document.getElementById("toggleMobilesettingsbtn").disabled = false;
						document.getElementById("idnotverifiedSecMob").style.display = 'none';
						document.getElementById("idverifiedSecMob").style.display = 'block';
					}

					document.getElementById('pAddressLine1').value = response.data.Data.permanaddress;
					document.getElementById('curAddressLine1').value = response.data.Data.currentaddress;
					document.getElementById('pZipcode').value = response.data.Data.permanent_postalcode;
					document.getElementById('curZipcode').value = response.data.Data.current_postalcode;
					document.getElementById('pCity').value = response.data.Data.permanent_city;
					document.getElementById('curCity').value = response.data.Data.current_city;

					if (response.data.Data.secmobile !== "" || response.data.Data.secmobile !== null) {
						document.getElementById('secMob').innerHTML = response.data.Data.secmobile;
					}
					else {
						document.getElementById('secMob').innerHTML = "";
					}


					if (response.data.Data.isidverified === false) {
						try {
							document.getElementById('kycbreadcrumb').style.display = 'block';
							document.getElementById("idverified").style.display = 'none';
							document.getElementById("idnotverified").style.display = 'block';
						}
						catch (e) {
// console.log('Error');
						}
					}
					else {
						try {
							document.getElementById('kycbreadcrumb').style.display = 'none';
							document.getElementById("idnotverified").style.display = 'none';
							document.getElementById("idverified").style.display = 'block';
						}
						catch (e) {
// console.log('Error');
						}
					}
				}
				else if (response.data.Status === 0) {
					if (response.data.Code === 3 || response.data.Code === 7) {
						$(".modal-title").html("");
						$(".modal-body").html("<p class=class_p>Your session expired.</p>");
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=closesessionclosebtn>ok</button>");
						$("body").addClass('no-select'); secureLocalStorage.clear();
						$("#closesessionclosebtn").addClass('btn btn-block');
						$("#closesessionclosebtn").on("click", function () {
							$("#modalDialog").toggle('hide');
							window.location.href = "/";
							});
							if($("#modalDialog" ).hasClass( "show" )){
								$(".main-panel").css("filter","blur(8px)");
							}
							else{
								$(".main-panel").css("filter","blur(8px)");
								$("#modalDialog").toggle('show');
							}
					}
					else {
						$(".modal-title").html("");
						$(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=closebtn>ok</button>");
						$("#closebtn").addClass('btn btn-block');
						$("#closebtn").on("click", function () {
							$("#modalDialog").toggle('hide');
						});
						$("#modalDialog").toggle('show');
					}
				}
				else {
					$(".modal-title").html("")
					$(".modal-body").html("<p class='class_p text-danger'>Something went wrong. Try again.</p>");
					$(".modal-title").html("")
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=ok>OK</button>");
					$("#ok").addClass("btn btn-block");
					$("#ok").on("click", function () {
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
				}
				return;
			})
			.catch(function () {
				setLoading(false);
				// $(".modal-title").html("")
				// $(".modal-body").html("<p class='class_p text-danger'>Something went wrong. Try again.</p>");
				// $(".modal-title").html("")
				// $(".modal-footer").html("");
				// $(".modal-footer").html("<button id=ok>OK</button>");
				// $("#ok").addClass("btn btn-block");
				// $("#ok").on("click", function () {
				// 	$("#modalDialog").toggle('hide');
				// });
				// $("#modalDialog").toggle('show');
				return;
			});
		// loadData();
	}, []);
	const fileHandler = event => {
		var { files } = event.target;
		if (files.length) {
			setPreview(
				files.length
					? {
						src: URL.createObjectURL(files[0]),
						alt: files[0].name,
						profiledata: files[0],
					}
					: initialState
			);
			document.getElementById("uploadbtn").disabled = false;
		}
		else {
			document.getElementById("uploadbtn").disabled = true;
		}
	};

	const handleimagupload = (e) => {
		let reader = new FileReader();
		reader.readAsDataURL(profiledata);
		reader.onloadend = function () {
			const headers = {
				'Content-Type': 'multipart/form-data',
				'Access-Token': secureLocalStorage.getItem('AT_'),
				"Refresh-Token": secureLocalStorage.getItem('RT_')
			};

			// var data = {
			//  	publicid: secureLocalStorage.getItem('PI_'),
			//  	file_type: alt,
			//  	user_image: reader.result
			// }
			const data = {
				"publicid": secureLocalStorage.getItem('PI_'),
				"purpose": "profile",
				"user_image": reader.result,
				"file_type": alt,
				"comments": "purpose = Options - [“profile” ,”identity”,”pop”]",
			}
			axios.post(url_local.uploadimage, data, { headers })
				.then(function (response) {
					if (response.data.Status === 1) {
						$(".modal-body").html("Photo updated Successfully!");
						$(".modal-title").html("")
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=closebtn>Continue</button>");
						$("#closebtn").addClass('btn btn-block');
						$("#closebtn").on("click", function () {
							$("#modalDialog").toggle('hide');
							window.location.reload();
						});
						$("#modalDialog").toggle('show');
					}
					else if (response.data.Data.Status === 0) {
						$(".modal-body").html(response.data.Data.Response);
						$(".modal-title").html("<h3>Update Failed!</h3>")
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=closebtn>Continue</button>");
						$("#closebtn").addClass('btn btn-block');
						$("#closebtn").on("click", function () {
							$("#modalDialog").toggle('hide');
						});
						$("#modalDialog").toggle('show');
					}
					else {
						$(".modal-body").html("<p className='text-danger'>Something went wrong. Try again!</p>");
						$(".modal-title").html("<h3>Update Failed!</h3>")
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=closebtnerr>Continue</button>");
						$("#closebtnerr").addClass('btn btn-block');
						$("#closebtnerr").on("click", function () {
							$("#modalDialog").toggle('hide');
						});
						$("#modalDialog").toggle('show');
					}
					return;
				})
				.catch(function () {
					return;
				});
		}
	}

	const handleKYCupdate = (event) => {
		event.preventDefault();
		// event.stopPropagation();
		const current_city = event.target.elements.curCity.value;
		const current_postalcode = event.target.elements.curZipcode.value;
		const permanaddress = event.target.elements.pAddressLine1.value;
		const currentaddress = event.target.elements.curAddressLine1.value;
		const dob = event.target.elements.dob.value;
		const gender = event.target.elements.genderSelect.value;
		const residence = event.target.elements.residenceSelected.value;
		const nationality1 = event.target.elements.nationalitySelect1.value;
		const nationality2 = event.target.elements.nationalitySelect2.value;
		const nationality3 = event.target.elements.nationalitySelect3.value;
		const permanent_city = event.target.elements.pCity.value;
		const permanent_postalcode = event.target.elements.pZipcode.value;
		const firstname = event.target.elements.firstname.value;
		const lastname = event.target.elements.lastname.value;
		const salutation = event.target.elements.salutSelect.value;
		// if (profiledata === undefined || profiledata === '') {

		// }
		// if (profiledata !== undefined && profiledata !== '') {
		// 	let reader = new FileReader();
		// 	reader.readAsDataURL(profiledata);
		// 	reader.onloadend = function () {
		// 		let headers = {
		// 			'Content-Type': 'multipart/form-data',
		// 			'Access-Token': secureLocalStorage.getItem('AT_'),
		// 			"Refresh-Token": secureLocalStorage.getItem('RT_')
		// 		};

		// 		let data = {
		// 			"publicid": secureLocalStorage.getItem('PI_'),
		// 			"purpose": "profile",
		// 			"user_image": reader.result,
		// 			"file_type": alt,
		// 			"comments": "purpose = Options - [“profile” ,”identity”,”pop”]",
		// 		}
		// 		axios.post(url_local.uploadimage, data, { headers })
		// 		.then(function (response) {
		// 			if (response.data.Status === 1) {
		// 				console.log();
		// 			}
		// 			else if (response.data.Data.Status === 0) {
		// 				$(".modal-body").html(response.data.Data.Response);
		// 				$(".modal-title").html("<h3>Update Failed!</h3>")
		// 				$(".modal-footer").html("");
		// 				$(".modal-footer").html("<button id=closebtn>Continue</button>");
		// 				$("#closebtn").addClass('btn btn-block');
		// 				$("#closebtn").on("click", function () {
		// 					$("#modalDialog").toggle('hide');
		// 					return;
		// 				});
		// 				$("#modalDialog").toggle('show');
		// 				return;
		// 			}
		// 			else {
		// 				$(".modal-body").html("<p className='text-danger'>Something went wrong. Try again!</p>");
		// 				$(".modal-title").html("<h3>Update Failed!</h3>")
		// 				$(".modal-footer").html("");
		// 				$(".modal-footer").html("<button id=closebtnerr>Continue</button>");
		// 				$("#closebtnerr").addClass('btn btn-block');
		// 				$("#closebtnerr").on("click", function () {
		// 					$("#modalDialog").toggle('hide');
		// 					return;
		// 				});
		// 				$("#modalDialog").toggle('show');
		// 				return;
		// 			}
		// 		})
		// 		.catch(function (err) {
		// 			console.warn(err,"Settings");$(".modal-body").html("<p className='text-danger'>Something went wrong. Try again!</p>");
		// 			$(".modal-title").html("<h3>Update Failed!</h3>")
		// 			$(".modal-footer").html("");
		// 			$(".modal-footer").html("<button id=closebtnerr>Continue</button>");
		// 			$("#closebtnerr").addClass('btn btn-block');
		// 			$("#closebtnerr").on("click", function () {
		// 				$("#modalDialog").toggle('hide');
		// 				return;
		// 			});
		// 			$("#modalDialog").toggle('show');
		// 			return;
		// 		});
		// 	}
		// }

		const headers = {
			'Content-Type': "application/json",
			'Access-Token': secureLocalStorage.getItem('AT_'),
			"Refresh-Token": secureLocalStorage.getItem('RT_')
		};
		var secEmail = "";
		if (document.getElementById('secEmail').innerHTML !== "") {
			secEmail = document.getElementById('secEmail').innerHTML;
		}
		var secMobile = "";
		if (document.getElementById('secMob').innerHTML !== "") {
			secMobile = document.getElementById('secMob').innerHTML;
		}

		const payload = {
			publicid: secureLocalStorage.getItem('PI_'),
			current_city: current_city,
			current_postalcode: current_postalcode,
			permanaddress: permanaddress,
			currentaddress: currentaddress,
			dob: dob,
			gender: gender,
			nationality: [nationality1,nationality2,nationality3],
			permanent_city: permanent_city,
			permanent_postalcode: permanent_postalcode,
			firstname: firstname,
			lastname: lastname,
			primemail: document.getElementById('primeEmail').innerHTML,
			primmobile: document.getElementById('primeMob').innerHTML,
			residence: residence,
			salutation: salutation,
			secemail: secEmail,
			// secemail_status: "",
			secmobile: secMobile,
			usercurrency: document.getElementById('currencySelect').value
		}
		console.log("payload",payload);
		axios.post(url_local.kyc,payload,{ headers })
		.then(function (response) {
			console.log("kyc response data =================>", response);
			if (response.data.Status === 1) {
				$(".modal-body").html("KYC updated successfully!");
				$(".modal-title").html("")
				$(".modal-footer").html("");
				$(".modal-footer").html("<a id=closebtn href=" + routeCodes.SETTINGS + ">Got it</a>");
				$("#closebtn").addClass('btn btn-block');
				$("#modalDialog").toggle('show');
			}
			else if (response.data.Status === 0) {
				if (response.data.Code === 3 || response.data.Code === 7) {
					$(".modal-title").html("");
					$(".modal-body").html("<p class=class_p>Your session expired.</p>");
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=closesessionclosebtn>ok</button>");
					$("body").addClass('no-select'); secureLocalStorage.clear();
					$("#closesessionclosebtn").addClass('btn btn-block');
					$("#closesessionclosebtn").on("click", function () {
						$("#modalDialog").toggle('hide');
						window.location.href = "/";
						});
						if($("#modalDialog" ).hasClass( "show" )){
						$(".main-panel").css("filter","blur(8px)");
						$("#modalDialog").toggle('show');
						}
						else{
							$(".main-panel").css("filter","blur(8px)");
							$("#modalDialog").toggle('show');
						}
				}
				else {
					$(".modal-title").html("");
					$(".modal-body").html(response.data.Message);
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=closebtn>ok</button>");
					$("#closebtn").addClass('btn btn-block');
					$("#closebtn").on("click", function () {
						$("#modalDialog").toggle('hide');
						// window.location.reload();
					});
					$("#modalDialog").toggle('show');
				}
			}
			else {
				$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again.</p>");
				$(".modal-title").html("")
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=got_it>Got it</button>");
				$("#got_it").addClass("btn btn-block");
				$("#got_it").on("click", function () {
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
			}
		})
		.catch(function (err) {
			console.warn(err,"Settings");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
			$(".modal-title").html("")
			$(".modal-footer").html("");
			$(".modal-footer").html("<button id=delete>Got it</button>");
			$("#delete").addClass("btn btn-block");
			$("#modalDialog").toggle('show');
			$("#delete").on('click', function () {
				$("#modalDialog").toggle('hide');
			});
			$("#modalDialog").toggle('show');
			return;
		});
	}

	// const validation = () => {
	// 	var current_city = document.getElementById('curCity').value;
	// 	var current_postalcode = document.getElementById('curZipcode').value;
	// 	var permanaddress = document.getElementById('pAddressLine1').value;
	// 	var currentaddress = document.getElementById('curAddressLine1').value;
	// 	var dob = document.getElementById('dob').value;
	// 	var residence = $("#residenceSelected").find('option:selected', this).val();

	// 	var nationality1 = $("#nationalitySelect1").find('option:selected', this).val();
	// 	// var nationality2 = $("#nationalitySelect2").find('option:selected', this).val();
	// 	// var nationality3 = $("#nationalitySelect3").find('option:selected', this).val();
	// 	var permanent_city = document.getElementById('pCity').value;
	// 	var permanent_postalcode = document.getElementById('pZipcode').value;
	// 	var firstname = document.getElementById('firstname').value;
	// 	var lastname = document.getElementById('lastname').value;
	// 	var salutation = $("#salutSelect").find('option:selected', this).val();


	// 	function underAgeValidate(birthday){
	// 		// it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
	// 		var optimizedBirthday = birthday.replace(/-/g, "/");

	// 		//set date based on birthday at 01:00:00 hours GMT+0100 (CET)
	// 		var myBirthday = new Date(optimizedBirthday);

	// 		// set current day on 01:00:00 hours GMT+0100 (CET)
	// 		var currentDate = new Date().toJSON().slice(0,10)+' 01:00:00';

	// 		// calculate age comparing current date and borthday
	// 		var myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));

	// 		if(myAge < 18) {
	// 			alert("age below 18")
	// 				 return false;
	// 			}else{
	// 			return true;
	// 		}

	// 	}

	// 	// console.log(underAgeValidate('1999-02-21'));
	// 	// secemail_status= "";
	// 	if (firstname === "" || lastname === "" || current_city === "" || current_postalcode === "" ||
	// 		permanaddress === "" || currentaddress === "" || dob === "" || residence === "" || nationality1 === "" ||
	// 		permanent_city === "" || permanent_postalcode === "" || salutation === "") {
	// 		$(".modal-title").html("");
	// 		$(".modal-body").html("<p class=class_p>Please fill all mandatory fields *</p>");
	// 		$(".modal-footer").html("");
	// 		$(".modal-footer").html("<button id=closebtnok>Continue</button>");
	// 		$("#closebtnok").addClass('btn btn-block');
	// 		$("#closebtnok").on("click", function () {
	// 			$("#modalDialog").toggle('hide');
	// 		});
	// 		$("#modalDialog").toggle('show');
	// 		// console.log(document.getElementById('dob').value)
	// 	}
	// 	else if(document.getElementById('dob').value ==="")
	// 	{
	// 		var dt = new Date();
	// 		console.log(dt.getFullYear() - 18 + "-" + (dt.getMonth() + 1) + "-" + dt.getDate());
	// 		underAgeValidate(dt.getFullYear() - 18 + "-" + (dt.getMonth() + 1) + "-" + dt.getDate())
	// 		// underAgeValidate("1999-02-21");
	// 	}
	// 	else {
	// 		if (profiledata === undefined || profiledata === '') {
	// 			update_user_info();
	// 		}
	// 		else {
	// 			handleimagupload();
	// 		}
	// 	}
	// }

	//update kyc info
	const update_user_info = () => {
		const headers = {
			'Content-Type': "application/json",
			'Access-Token': secureLocalStorage.getItem('AT_'),
			"Refresh-Token": secureLocalStorage.getItem('RT_')
		};
		var secEmail = "";
		if (document.getElementById('secEmail').innerHTML !== "") {
			secEmail = document.getElementById('secEmail').innerHTML;
		}
		var secMobile = "";
		if (document.getElementById('secMob').innerHTML !== "") {
			secMobile = document.getElementById('secMob').innerHTML;
		}

		const data = {
			publicid: secureLocalStorage.getItem('PI_'),
			// current_city: document.getElementById('curCity').value,
			// current_postalcode: document.getElementById('curZipcode').value,
			// permanaddress: document.getElementById('pAddressLine1').value,
			// currentaddress: document.getElementById('curAddressLine1').value,
			// dob: document.getElementById('dob').value,
			// gender: $("#genderSelect").find('option:selected', this).val(),
			// nationality: [$("#nationalitySelect1").find('option:selected', this).val(),$("#nationalitySelect2").find('option:selected', this).val(),$("#nationalitySelect3").find('option:selected', this).val()],
			// permanent_city: document.getElementById('pCity').value,
			// permanent_postalcode: document.getElementById('pZipcode').value,
			// firstname: document.getElementById('firstname').value,
			// lastname: document.getElementById('lastname').value,
			primemail: document.getElementById('primeEmail').innerHTML,
			primmobile: document.getElementById('primeMob').innerHTML,
			// residence: $("#residenceSelected").find('option:selected', this).val(),
			// salutation: document.getElementById('salutSelect').value,
			secemail: secEmail,
			// secemail_status: "",
			secmobile: secMobile,
			// usercurrency: document.getElementById('currencySelect').value
		}
// console.log("kyc updated data =================>", data)
		// console.warn(data);
		axios.post(url_local.kyc,
				data,
				{ headers })
			.then(function (response) {
// console.log("kyc response data =================>", response);
				// console.warn(response);
				if (response.data.Status === 1) {
					$(".modal-body").html("KYC updated successfully!");
					$(".modal-title").html("")
					$(".modal-footer").html("");
					$(".modal-footer").html("<a id=closebtn href=" + routeCodes.SETTINGS + ">Got it</a>");
					$("#closebtn").addClass('btn btn-block');
					$("#modalDialog").toggle('show');
				}
				else if (response.data.Status === 0) {
					if (response.data.Code === 3 || response.data.Code === 7) {
						$(".modal-title").html("");
						$(".modal-body").html("<p class=class_p>Your session expired.</p>");
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=closesessionclosebtn>ok</button>");
						$("body").addClass('no-select'); secureLocalStorage.clear();
						$("#closesessionclosebtn").addClass('btn btn-block');
						$("#closesessionclosebtn").on("click", function () {
							$("#modalDialog").toggle('hide');
								window.location.reload();
							});
							if($("#modalDialog" ).hasClass( "show" )){
							$(".main-panel").css("filter","blur(8px)");
							$("#modalDialog").toggle('show');
							}
							else{
								$(".main-panel").css("filter","blur(8px)");
								$("#modalDialog").toggle('show');
							}
					}
					else {
						$(".modal-title").html("");
						$(".modal-body").html(response.data.Message);
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=closebtn>ok</button>");
						$("#closebtn").addClass('btn btn-block');
						$("#closebtn").on("click", function () {
							$("#modalDialog").toggle('hide');
						});
						$("#modalDialog").toggle('show');
					}
				}
				else {
					$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again.</p>");
					$(".modal-title").html("")
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=got_it>Got it</button>");
					$("#got_it").addClass("btn btn-block");
					$("#got_it").on("click", function () {
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
				}
			})
			.catch(function (err) {
				console.warn(err,"Settings");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
				$(".modal-title").html("")
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=delete>Got it</button>");
				$("#delete").addClass("btn btn-block");
				$("#modalDialog").toggle('show');
				$("#delete").on('click', function () {
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
				return;
			});
	}

	const FillAddressInput = () => {
		let checkBox = document.getElementById('checkBox');
		let pAddressLine1 = document.getElementById("pAddressLine1");
		let pZipcode = document.getElementById("pZipcode");
		let pCity = document.getElementById("pCity");
		let curAddressLine1 = document.getElementById("curAddressLine1");
		let curZipcode = document.getElementById("curZipcode");
		let curCity = document.getElementById("curCity");
		// var curResidence = $("#residenceSelected").find('option:selected', this).val();
		// var nationality = [$("#nationalitySelect1").find('option:selected', this).val(),$("#nationalitySelect2").find('option:selected', this).val(),$("#nationalitySelect3").find('option:selected', this).val()];
		if (checkBox.checked === true) {

			let pAddressLine1Value = pAddressLine1.value;
			// 	 	let pAddressLine2Value = pAddressLine2.value;
			// 	 	let pLandmarkValue 	 	 = pLandmark.value;
			let pZipcodeValue = pZipcode.value;
			let pCityValue = pCity.value;
			// let pResidenceValue = $("#nationalitySelect").find('option:selected', this).val();
			// 	 	let pCountryValue 	 	 	= pCountry.value;
			curAddressLine1.value = pAddressLine1Value;
			// 	 	curAddressLine2.value = pAddressLine2Value;
			// 	 	curLandmark.value 	 	 = pLandmarkValue;
			curZipcode.value = pZipcodeValue;
			curCity.value = pCityValue;
			// curResidence = pResidenceValue;
			// 	 	curState.value 	 	 	 	= pStateValue;
			// 	 	curCountry.value 	 	 	= pCountryValue;
		}
		else {
			curZipcode.value = "";
			curCity.value = "";
			curAddressLine1.value = "";
			// 	 	curState.value 	 	 	 	= "";
			// 	 	curCountry.value 	 	 	= "";
		}
	}

	const deleteEmail = () => {
		$(".modal-title").html("<h4 class=class_p>Are you sure to delete this ?</h4>");
		$(".modal-body").html("<p class=class_p>You cannot undo this action. You can't able to add an alternative email again until it is verified again. ");
		$(".modal-footer").html("");
		$(".modal-footer").html("<button id=delete>Remove</button><button id=canceldelete>Cancel</button>");
		$("#delete").addClass("btn btn-sm btn-yodigi-danger");
		$("#canceldelete").addClass("btn btn-sm");
		$("#delete").on('click', function () {
			document.getElementById('secEmail').innerHTML = "";
			update_user_info();
			$("#modalDialog").toggle('hide');
		});
		$("#canceldelete").on('click', function () {
			$("#modalDialog").toggle('hide');
		});
		$("#modalDialog").toggle('show');
	}

	const deleteMobile = () => {
		$(".modal-title").html("<h4 class=class_p>Are you sure to delete this ?</h4>");
		$(".modal-body").html("<p class=class_p>You cannot undo this action. You can't able to add an alternative mobile number again until it is verified again. ");
		$(".modal-footer").html("");
		$(".modal-footer").html("<button id=deletemob>Remove</button><button id=canceldeletemob>Cancel</button>");
		$("#deletemob").addClass("btn btn-sm btn-yodigi-danger");
		$("#canceldeletemob").addClass("btn btn-sm");
		$("#deletemob").on('click', function () {
			document.getElementById('secMob').innerHTML = "";
			update_user_info();
			$("#modalDialog").toggle('hide');
		});
		$("#canceldeletemob").on('click', function () {
			$("#modalDialog").toggle('hide');
		});
		$("#modalDialog").toggle('show');
	}

	const toggleEmailsettings = () => {
		var primary = document.getElementById('primeEmail').innerHTML;
		var secondary = document.getElementById('secEmail').innerHTML;
		document.getElementById('primeEmail').innerHTML = secondary;
		document.getElementById('secEmail').innerHTML = primary;
	}

	const toggleMobilesettings = () => {
		var primary = document.getElementById('primeMob').innerHTML;
		var secondary = document.getElementById('secMob').innerHTML;
		document.getElementById('primeMob').innerHTML = secondary;
		document.getElementById('secMob').innerHTML = primary;
	}

	const openEditEmail = () => {
		document.querySelector('#openEditEmail-right-sidebar').classList.toggle('open');
	}
	const closeEditEmail = () => {
		document.querySelector('#openEditEmail-right-sidebar').classList.remove('open');
	}

	const openEditMobile = () => {
		document.querySelector('#openEditMobile-right-sidebar').classList.toggle('open');
	}
	const closeEditMobile = () => {
		document.querySelector('#openEditMobile-right-sidebar').classList.remove('open');
	}

	function fileHandlerbtn_click(e) {
		e.target.style.display = "none";
		document.getElementById("fileHandler").style.display = "flex";
		document.getElementById("uploadbtn").disabled = true;
	}

	function fileHandlerbtnclear_click(e) {
		document.getElementById("fileHandlerbtn").removeAttribute("style");
		document.getElementById("fileHandler").style.display = "none";
		let revert = document.getElementById("profile_photo").getAttribute('alt');
		// alert(url_local.home +revert.trim());
		document.getElementById("profile_photo").setAttribute("src", '');
		document.getElementById("profile_photo").setAttribute("src", url_local.home +media.current+ revert.trim());
		// document.getElementById("profile_photo").setAttribute("alt",revert);
	}
	function gender_change(e) {
		const g = e.target.value;
		const s = document.getElementById("salutSelect").value;
		if (g === "male")
			if(s === "ms" || s === "mrs" )
				$('#salutSelect').val('mr');
		if (g === "female")
			if(s === "mr")
				$('#salutSelect').val('ms');
	}

	if (loading) {
		return (<Spinner />)
	}
	else {
		return (
			// <div className="row">
			// 	 	 <div className="col-md-12">
			// 	 	 	 	 {/* internal container is div.card below */}
			// 	 	 	 	 <div className="card px-4">
			// 	 	 	 	 	 	 <div className="page-header w-100">
			// 	 	 	 	 	 	 	 	 <h3 className="page-title w-100 row">
			// 	 	 	 	 	 	 	 	 	 <div className='col-5'>
			// 	 	 	 	 	 	 	 	 	 	 <span className="page-title-icon bg-success me-2">
			// 	 	 	 	 	 	 	 	 	 	 	 	 <i className="mdi mdi-checkbox-marked-circle-outline"></i>
			// 	 	 	 	 	 	 	 	 	 	 </span> Personal Verification
			// 	 	 	 	 	 	 	 	 	 </div>
			// 	 	 	 	 	 	 	 	 	 <div className='col'>
			// 	 	 	 	 	 	 	 	 	 	 <div className="float-end">
			// 	 	 	 	 	 	 	 	 	 	 	 <div className='text-lastlogin text-end fs-6'>
			// 	 	 	 	 	 	 	 	 	 	 	 	 	 <i className="mdi mdi-account px-2"></i>Last login time
			// 	 	 	 	 	 	 	 	 	 	 	 	 	 <label className='d-block'>{secureLocalStorage.getItem('DT_')}</label>
			// 	 	 	 	 	 	 	 	 	 	 	 </div>
			// 	 	 	 	 	 	 	 	 	 	 </div>
			// 	 	 	 	 	 	 	 	 	 </div>
			// 	 	 	 	 	 	 	 	 </h3>
			// 	 	 	 	 	 	 </div>
			// 	 	 	 	 	 	 {/* here content is placed */}
			// 	 	 	 	 </div>
			// 	 	 </div>
			// </div>
			<div className="row">
				<div className="col-md-12">
					{/* internal container is div.card below */}
					<div className="card px-4">
						<div className="page-header w-100">
							<h3 className="page-title w-100 row">
								<div className='col-5'>
									<span className="page-title-icon bg-success me-2">
										<i className="mdi mdi-account"></i>
									</span> My Profile
								</div>
								<div className='col'>
									<div className="float-end">
										<div className='text-lastlogin text-end fs-6'>
											<i className="mdi mdi-account px-2"></i>Last login time
											<label className='d-block'>{secureLocalStorage.getItem('DT_')}</label>
										</div>
									</div>
								</div>
							</h3>
						</div>
						{/* here content is placed */}
						<p className="class_p text-danger mx-2 mb-1">Required( <sup>*</sup> )</p>
						<div className="card-body">
							<h5><i className="mdi mdi-account mr-1"></i>Personal information</h5>
							<form className="needs-validation" onSubmit={handleKYCupdate}>
							<div>
								<div className="card-body text-white">
									{/* <Personal_info /> */}
									<div className="row align-items-center">
										<div className="col-md-4 col-xs-12 mb-3">
											<div className="id_card">
												{/* <img src={image} alt="" className="img-fluid" /><br /><br /> */}
												<label htmlFor="service" className="card-description text-center w-100">
													Personal Avatar</label>

												<div className="card-body text-white">
													{/* <div className="addNew">
																						<img className="preview mx-1" src={src} alt={alt} />
																						<button onClick={handleimagupload} id="uploadbtn"
																						className="btn btn-sm btn-yodigi-buy mt-3 text-center" style={{display:'none'}}>Upload image</button>
																						<input accept="image/*" type="file" onChange={fileHandler} className="form-control form-control-sm" 	/>
																						<div className="text-light small mt-1 mx-1">Allowed JPG, GIF or PNG. Max size of 20mb*</div>
																						</div> */}
													<div className="mb-3 text-center">
														<div className="text-center">
															<img id="profile_photo" alt="" className="img-thumbnail mx-auto mb-3" style={{ maxHeight: "150px" }}
																// onError="if (this.src != https://placeimg.com/200/200/tech/grayscale') this.src = 'https://placeimg.com/200/200/tech/grayscale';"
																// onError={() => {this.src = 'https://placeimg.com/200/200/tech/grayscale';}}
																// onError="this.onError=null;this.src='https://placeimg.com/200/200/tech/grayscale"
																onError={(e) => {
																	// e.target.src = 'https://placeimg.com/200/200/tech/grayscale' // some replacement image
																	e.target.src = 'https://via.placeholder.com/150'
																	e.target.style = 'outline: 0px; margin: 0px; max-height:150px;' // inline styles in html format
																}}
																src={src}
															// alt={alt}
															/>
														</div>
														<div className="addNew text-center">
															<button type="button" className="btn btn-sm" id="fileHandlerbtn" onClick={fileHandlerbtn_click} >+ Change</button>
															<div className="input-group" id="fileHandler" style={{ display: 'none' }}>
																<input accept="image/*" type="file" onChange={fileHandler} className="form-control h-100" readOnly/>
																<button type="button" className="btn btn-outline-secondary image-preview-clear h-100"
																	id="fileHandlerbtnclear" onClick={fileHandlerbtnclear_click}>
																	<i className="fa fa-remove"></i> Clear
																</button>
																<button className="btn btn-yodigi-buy" id="uploadbtn" onClick={handleimagupload}>Upload</button>
															</div>
														</div>
														{/* <span className="float-right text-light small mt-1 mx-1">Maximum file size is 2mb</span> */}
													</div>

												</div>
											</div>
										</div>
										<div className="col-md-6 col-xs-12">
											<div className="form-group row">
												<label className="col-sm-3 col-xs-12 class_p">
													<i className="la la-envelope mr-1"></i>:
												</label>
												<div className="col-12 col-sm-9">
													<label id="emailmain">--</label>
												</div>
											</div>

											<div className="form-group row">
												<label className="col-sm-3 col-xs-12 class_p">
													<i className="la la-phone mr-1"></i>:
												</label>
												<div className="col-12 col-sm-9">
													<label id="phonemain">--</label>
												</div>
											</div>

											<div className="form-group row">
												<label className="col-sm-3 col-xs-12 class_p">
													ID :
												</label>
												<div className="col-12 col-sm-9">
													<label id="idnumbermain">--</label>
													<label className="mx-2"><a href={routeCodes.IDVERIFY}><i className="fa fa-pencil"></i></a></label>
												</div>
											</div>
											<div className="form-group row">
												<label className="col-sm-3 col-xs-12 class_p">
													Status :
												</label>
												<div className="col-12 col-sm-9">
													<label className="form-label">
														<span id="idverified" className="badge bg-success" style={{ display: 'none' }}><i className="ml-1 la la-check"></i>Verified</span>
														<span id="idnotverified" className="badge bg-danger"><i className="ml-1 mdi mdi-close"></i>Not verified</span>
													</label>
												</div>

											</div>


											{/* <Link to={"verify-step-2"} className="btn btn-yodigi-buy mt-3">New ID</Link> */}
											{/* <Link to={"/verify-step-2"} className="btn btn-yodigi-buy ">New ID</Link> */}

											{/* <img className="preview mx-1" src={src} alt={alt} />
																						<button onClick={handleimagupload} id="uploadbtn"
																						className="btn btn-sm btn-yodigi-buy mt-3 text-center" style={{display:'none'}}>Upload image</button> */}
										</div>

									</div>

								</div>


								<div className="row mx-2">
									<div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group row">
											<label className="col-4 col-form-label class_p">First Name<sup>*</sup></label>
											<div className="col-8">
												<input type="text" name="firstname" className="form-control form-control-sm" id="firstname" required maxLength="50" pattern="([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ']){2,50}"/>
											</div>
										</div>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group row">
											<label className="col-4 col-form-label class_p">Last Name<sup>*</sup></label>
											<div className="col-8">
												<input type="text" name="lastname" id="lastname" className="form-control form-control-sm" required maxLength="50" pattern="([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ']){2,50}"/>
											</div>
										</div>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group row">
											<label className="col-4 col-form-label class_p">Nationality<sup>*</sup></label>
											<div className="col-8">
												<div className="row">
													<div className="col-4 p-0"><Nationalitylist id="nationalitySelect1" className="form-control-sm form-select form-select-sm w-100" required/></div>
													<div className="col-4 p-0"><Nationalitylist id="nationalitySelect2" className="form-control-sm form-select form-select-sm w-100" /></div>
													<div className="col-4 p-0"><Nationalitylist id="nationalitySelect3" className="form-control-sm form-select form-select-sm w-100" /></div>
												</div>
												{/* <ul id="nationalityList">
													{
														nationality.map((nat, index) => (
															<li id={"nat_"+index} >{nat}</li>
														))
													}
												</ul> */}
											</div>
										</div>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group row">
											<label className="col-4 col-form-label class_p">Gender</label>
											<div className="col-8">
												<select className="form-control" id="genderSelect" onChange={gender_change}>
													<option value="male">Male</option>
													<option value="female">Female</option>
												</select>
											</div>
										</div>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group row">
											<label className="col-4 col-form-label class_p">Salutation</label>
											<div className="col-8">
												<select className="form-control-sm form-select form-select-sm w-100" id="salutSelect">
													<option value="ms">Ms.</option>
													<option value="mr">Mr.</option>
													<option value="mrs">Mrs.</option>
													<option value="dr">Dr.</option>
													<option value="engr">Engr.</option>
												</select>
											</div>
										</div>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group row">
											<label className="col-4 col-form-label class_p">Date of Birth<sup>*</sup></label>
											<div className="col-8">
												<input id="dob" name="dob" className="form-control form-control-sm" type="date" max={maxDate} required/>
											</div>
										</div>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group row">
											<label className="col-4 col-form-label class_p">Local Currency</label>
											<div className="col-8">
												<LocalCurrencylist id="currencySelect" className="form-control-sm form-select form-select-sm w-100" />
											</div>
										</div>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group row">
											<label className="col-4 col-form-label class_p">Residence<sup>*</sup></label>
											<div className="col-8">
												<Residencelist id="residenceSelected" className="form-control-sm form-select form-select-sm w-100" required />
											</div>
										</div>
									</div>
								</div>

								<p className="card-description mt-3 pt-3 mx-2" style={{ borderTop: "1px solid #242736" }}> Edit your address details </p>
								<div className="row mx-2">
									<div className="col-md-6 col-xs-12">
										<label className="form-label"><h5>Permanent Address</h5></label>
										<div className="form-group">
											Same as in your provided document.
										</div>

										<div className="col-12">
											<div className="form-group row">
												<label className="col-3 col-form-label class_p">Address Line<sup>*</sup></label>
												<div className="col-9">
													<textarea className="form-control form-control-sm" placeholder="" id="pAddressLine1" cols="30" rows="5" maxLength="128" pattern="([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.-']){3,128}" required></textarea>
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="form-group row">
												<label className="col-3 col-form-label class_p">Postal Code<sup>*</sup></label>
												<div className="col-9">
													<input type="text" className="form-control form-control-sm" placeholder="" id="pZipcode" maxLength="15" pattern="([a-zA-Z0-9]){2,15}" required/>
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="form-group row">
												<label className="col-3 col-form-label class_p">City<sup>*</sup></label>
												<div className="col-9">
													<input type="text" className="form-control form-control-sm" placeholder="" id="pCity" maxLength="50" pattern="([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.-']){3,50}" required/>
												</div>
											</div>
										</div>

									</div>
									<div className="col-md-6 col-xs-12">
										<label className="form-label"><h5>Present Address</h5></label>
										<div className="form-group">
											<input type="checkbox" id="checkBox"
												onChange={FillAddressInput} />&nbsp;
											Same as permanent address
										</div>

										<div className="form-group row">
											<label className="col-3 col-form-label class_p">Address Line<sup>*</sup></label>
											<div className="col-9">
												<textarea className="form-control form-control-sm" placeholder="" id="curAddressLine1" cols="30" rows="5" maxLength="128" pattern="([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.-']){3,128}" required></textarea>
											</div>
										</div>


										<div className="form-group row">
											<label className="col-3 col-form-label class_p">Postal Code<sup>*</sup></label>
											<div className="col-9">
												<input type="text" className="form-control form-control-sm" placeholder="" id="curZipcode" maxLength="15" pattern="([a-zA-Z0-9]){2,15}" required/>
											</div>
										</div>


										<div className="form-group row">
											<label className="col-3 col-form-label class_p">City<sup>*</sup></label>
											<div className="col-9">
												<input type="text" className="form-control form-control-sm" placeholder="" id="curCity" maxLength="50" pattern="([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.-']){3,50}" required/>
											</div>
										</div>




									</div>
								</div>

								<p className="card-description mt-3 pt-3 mx-2" style={{ borderTop: "1px solid #242736" }}>
									Preferences</p>

								<div className="col-12 table table-responsive">

									<div className="table-title">
										<h5 className="text-white">Email details</h5>
									</div>
									<div className="table-responsive mb-4">
										<table className="table table-borderless text-white">
											<thead style={{ width: '100%' }}>
												<tr>
													<th style={{ width: '50%' }}>Email</th>
													<th style={{ width: '30%' }}>Type</th>
													<th style={{ width: '10%' }}>Status</th>
													<th style={{ width: '30%' }}>Actions</th>
												</tr>
											</thead>
											<tbody style={{ minHeight: "20vh" }}>
												<tr>
													<td id="primeEmail">--</td>
													<td className="class_p">Primary</td>
													<td><span className="badge bg-success rounded-pill d-inline">
														<i className="ml-1 la la-check"></i>Verified</span></td>
													<td> --</td>
												</tr>
												<tr>
													<td className="text-left" colSpan={4}>
														<button className="btn btn-sm settingsBtn" id="toggleEmailsettingsbtn" onClick={toggleEmailsettings}>
															<i className="mdi mdi-swap-vertical"></i>
														</button>
													</td>
												</tr>
												<tr>
													<td id="secEmail"></td>
													<td className="class_p">Secondary </td>
													<td>
														<span id="idverifiedSecEmail" className="badge bg-success rounded-pill" style={{ display: 'none' }}><i className="ml-1 la la-check"></i>Verified</span>
														<span id="idnotverifiedSecEmail" className="badge bg-danger rounded-pill"><i className="ml-1 mdi mdi-close"></i>Not verified</span>
													</td>
													<td>
														<button className="edit mx-2 bg-transparent border-0" title="Edit" data-toggle="tooltip" onClick={openEditEmail}><i className="fa fa-pencil"></i></button>
														<button className="delete mx-2 bg-transparent border-0" title="Delete" data-toggle="tooltip" onClick={deleteEmail}><i className="fa fa-trash"></i></button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									{/* <table className="table table-responsive mb-4" id="table1">
															<thead style={{ width: '100%' }}>
																<tr>
																	<th style={{ width: '50%' }}>Email</th>
																	<th style={{ width: '30%' }}>Type</th>
																	<th style={{ width: '10%' }}>Status</th>
																	<th style={{ width: '30%' }}>Actions</th>
																</tr>
															</thead>
															<tbody style={{ minHeight: "20vh" }}>
																<tr>
																	<td id="primeEmail">--</td>
																	<td className="class_p">Primary</td>
																	<td>
																		<span className="badge bg-success rounded-pill d-inline">
																			<i className="ml-1 la la-check"></i>Verified</span>
																	</td>
																	<td>--</td>
																</tr>
																<tr>
																	<td className="text-left">
																		<button className="btn btn-sm settingsBtn" id="toggleEmailsettingsbtn" onClick={toggleEmailsettings}>
																			<i className="mdi mdi-swap-vertical"></i>
																		</button>
																	</td>
																</tr>
																<tr>
																	<td id="secEmail">--</td>
																	<td className="class_p">Secondary</td>
																	<td>
																		<span id="idverifiedSecEmail" className="badge bg-success rounded-pill" style={{ display: 'none' }}><i className="ml-1 la la-check"></i>Verified</span>
																		<span id="idnotverifiedSecEmail" className="badge bg-danger rounded-pill"><i className="ml-1 mdi mdi-close"></i>Not verified</span>
																	</td>
																	<td>
																		<a className="edit mx-2" title="Edit" data-toggle="tooltip" onClick={openEditEmail}><i className="fa fa-pencil"></i></a>
																		<a className="delete mx-1" title="Delete" data-toggle="tooltip" onClick={deleteEmail}><i className="fa fa-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table> */}

									<div className="table-title"><h5 className="text-white">Mobile details</h5></div>
									<div className="table-responsive">
										<table className="table table-borderless text-white">
											<thead style={{ width: '100%' }}>
												<tr>
													<th style={{ width: '50%' }}>Mobile No.</th>
													<th style={{ width: '30%' }}>Type</th>
													<th style={{ width: '10%' }}>Status</th>
													<th style={{ width: '30%' }}>Actions</th>
												</tr>
											</thead>
											<tbody style={{ minHeight: "20vh" }}>
												<tr>
													<td id="primeMob">--</td>
													<td className="class_p">Primary</td>
													<td><span className="badge bg-success rounded-pill d-inline">
														<i className="ml-1 la la-check"></i>Verified</span></td>
													<td>--</td>
												</tr>
												<tr>
													<td className="text-left" colSpan={4}>
														<button className="btn btn-sm settingsBtn" id="toggleMobilesettingsbtn" onClick={toggleMobilesettings}>
															<i className="mdi mdi-swap-vertical"></i>
														</button>
													</td>
												</tr>
												<tr>
													<td id="secMob"></td>
													<td className="class_p">Secondary </td>
													<td>
														<span id="idverifiedSecMob" className="badge bg-success rounded-pill" style={{ display: 'none' }}><i className="ml-1 la la-check"></i>Verified</span>
														<span id="idnotverifiedSecMob" className="badge bg-danger rounded-pill"><i className="ml-1 mdi mdi-close"></i>Not verified</span>
													</td>
													<td>
														<button className="edit mx-2 bg-transparent border-0" title="Edit" data-toggle="tooltip" onClick={openEditMobile}><i className="fa fa-pencil"></i></button>
														<button className="delete mx-2 bg-transparent border-0" title="Delete" data-toggle="tooltip" onClick={deleteMobile}><i className="fa fa-trash"></i></button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

								</div>
							</div>
							<div className="text-center mt-5 mb-5" >
								<button type="submit" className="btn btn-sm btn-yodigi-buy"
								// onClick={validation}
								>Update all</button>
							</div>
							</form>
							<div id="openEditEmail-right-sidebar" className="px-4 settings-panel right-sidebar">
								<i className="settings-close mdi mdi-close" onClick={closeEditEmail}></i>
								<div className="row p-4 ml-4">
									<div className="text-center">
										<i className="mdi mdi-alert mdi-48px text-warning"></i>
										<p className="class_p">Update Email Preferences</p>
									</div>
									<EditSecEmail email={email} />
								</div>
							</div>
							<div id="openEditMobile-right-sidebar" className="px-4 settings-panel right-sidebar">
								<i className="settings-close mdi mdi-close" onClick={closeEditMobile}></i>
								<div className="row p-4 ml-4">
									<div className="text-center">
										<i className="mdi mdi-alert mdi-48px text-warning"></i>
										<p className="class_p">Update Mobile number Preferences</p>
									</div>
									<EditSecMobile mobile={phone} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Settings;