import React, { useState } from 'react';
import $ from 'jquery';
// import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
// import { Dropdown } from 'react-bootstrap';
// import * as Yup from 'yup';
// import { FormProvider, RHFTextField } from '../components/hook-form';
// import { IconButton, InputAdornment } from '@mui/material';
// import { Tabs, Tab, Nav, Form } from 'react-bootstrap';

// form
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
import url_local from '../url_local';
import secureLocalStorage from 'react-secure-storage';

//css
import "../../assets/styles/web/css/style.css"

export default function Bank_Account_Details() {
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  // const [getdata, setGetdata] = useState(false);
  const [accountDetails, setAccountdetails] = useState(false);
  const [isKycApproved, setIsKycApproved] = useState(false);

  // const initialState = { alt: "", src: "" };
  // const [{ alt, src, profiledata }, setPreview] = React.useState(initialState);
  // const fileHandler = event => {
  //   var { files } = event.target;
  //   setPreview(
  //     files.length
  //       ? {
  //         src: URL.createObjectURL(files[0]),
  //         alt: files[0].name,
  //         profiledata: files[0]
  //       }
  //       : initialState
  //   );
  // };

  React.useEffect(() => {
    const KycApproved = secureLocalStorage.getItem('KYC_');
    if (!KycApproved){
      setIsKycApproved(KycApproved);
      // DO not even call the API since KYC not approved not allowed to show the Bank Details
      return;
    }
    var headers = {
      'Access-Token': secureLocalStorage.getItem('AT_'),
      "Refresh-Token": secureLocalStorage.getItem('RT_')
    };

    var payload = { publicid: secureLocalStorage.getItem('PI_'), purpose:'view' }
    axios.post(url_local.getbankAccountdetails,payload,{ headers })
      .then(function (response) {
        if (response.data.Status === 1) {
          setAccountdetails(response.data.Data);
        }
        else if (response.data.Status === 0) {
          // setGetdata(false);
          // if(response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600)
          // {
          //   $(".modal-title").html("");
          //   $(".modal-body").html("<p class=class_p>Your Session expired!</p>");
          //   $(".modal-footer").html("");
          //   $(".modal-footer").html("<button id=cancelredirecthome>ok</button>");
          //   $("#cancelredirecthome").addClass("btn btn-block");
          //   $("#cancelredirecthome").on("click", function () {
          //     window.location.href = "/";
          //     $("#modalDialog").toggle('hide');
          //   });
          //   $("#modalDialog").toggle('show');
          // }
          // else{
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=cancelredirect3>Got it</button>");
            $("#cancelredirect3").addClass("btn btn-block");
            $("#cancelredirect3").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          // }
        }
        else {
          // setGetdata(false);
          $(".modal-title").html("");
          $(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=cancelredirect4>Got it</button>");
          $("#cancelredirect4").addClass("btn btn-block");
          $("#cancelredirect4").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        return;
      })
      .catch(function () {
        $(".modal-title").html("");
				$(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=cancelredirect>Got it</button>");
				$("#cancelredirect").addClass("btn btn-block");
				$("#cancelredirect").on("click", function () {
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
        return;
      });
  }, []);

  // const PINSchema = Yup.object().shape({
  //   rxcryptoaccount: Yup.string()
  //     .required("This field is required"),
  //   cryptoValue: Yup.string()
  //     .required("This field is required"),
  // });

  // const defaultValues = {
  //   rxcryptoaccount: '',
  //   cryptoValue: ''
  // };

  // const methods = useForm({
  //   resolver: yupResolver(PINSchema),
  //   defaultValues,
  // });

  // const {
  //   handleSubmit,
  //   formState: { isSubmitting },
  // } = methods;

  var transactionData = accountDetails.length > 0 && accountDetails.map((item, index) => {
    return (
      <tr key={index}>
        <td>{item.bank}</td>
        <td>{item.account}</td>
        <td>{item.branch}</td>
        <td>{item.iban}</td>
        <td>{item.swift_code}</td>
        <td>{item.country}</td>
      </tr>
    )
  })

  return (
    <div className="rounded-legend legend-bottom-left pt-2">
      <div className="flex-grow-1 container-p-y">
        <div className="row no-gutters row-bordered row-border-light">
          <div className="tab-content">
            <div className="tab-pane fade active show" id="topup">
              <div className="pb-2">
                <div className="rounded-legend legend-bottom-left mt-2 p-3" id="accountTransfer">
                  <h5 className='text-muted'>Topup your Balance.</h5>
                  <p className='text-muted'>You can pre-fund your Meezan account.</p>
                  <p className='class_p mb-2'>1. Before you start topup, make sure you transferred the
                    total amount for payout to one of bank account(s) (listed below). Meezan will hold
                    these funds securely on your behalf.
                  </p>
                  <div className='table-responsive mb-3 mt-3'>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th>Bank Name</th>
                          <th>Account No.</th>
                          <th>Branch</th>
                          <th>IBAN No.</th>
                          <th>Swift Code</th>
                          <th>Country</th>
                        </tr>
                      </thead>
                      <tbody>
                        { isKycApproved?
                          transactionData
                          :<>
                          <tr>
                          <td colSpan={6} className='py-3 text-warning'>This requires KYC completion in order to proceed!</td>
                        </tr>
                          </>
                        }

                      </tbody>
                    </table>
                  </div>
                  <p className='class_p mb-3'>2. Topup by providing proof of transfer/deposit.</p>
                  <p className='class_p mb-3'>3. You can check once your funds are approved for topup or transfer purposes. </p>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

