import React, { useEffect } from "react";
import $ from 'jquery';
import * as Yup from 'yup';

import axios from 'axios';
import { Tab, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import PerfectScrollbar from "react-perfect-scrollbar";
import TradingViewWidget, { Themes } from "react-tradingview-widget";

import { FormProvider, RHFTextField } from '../components/hook-form';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//import components
import WebHeaderEX from '../web/layout/WebHeaderEX';
import url_local from '../url_local';
import routeCodes from "../../Paths";

//import css
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
import "../web/web.scss";


var lists = []
// var buyList = [], sellList = [], buyMarketList = [];

function Exchange() {
	console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
	const [cryptoData, setcryptoData] = React.useState([]);
	const [pairkey, setpairkey] = React.useState("");

	// const [isLoading, setLoading] = React.useState(false);
	// const [data, setdata] = React.useState([]);
	const [placeholder, setPlaceholder] = React.useState("");
	const [placeholderValue, setPlaceholderValue] = React.useState("");

	// setInterval(function () { getBuyOrderBook() }, 5000);
	const [buy, setBuy] = React.useState([]);
	const [sell, setSell] = React.useState([]);
	const [buyMarket, setMarket] = React.useState([]);
	// const [loading, setLoading] = React.useState(true);
	const [theme, setTheme] = React.useState(Themes.DARK);
	const selectedCrypto = React.useRef("ETH");
	useEffect(() => {
		let current = localStorage.getItem("theme");
		if (current === "light-theme"){
			setTheme(Themes.LIGHT);
      		delete document.documentElement.dataset.bsTheme;
		}
		else{
			setTheme(Themes.DARK);
      		document.documentElement.dataset.bsTheme = "dark";
		}
		getmarketTrades();
		getBuyOrderBook(selectedCrypto.current);
		//eslint-disable-next-line
	}, []);

	const TradeSchema = Yup.object().shape({
		buytradeprice: Yup.number()
			.typeError('you must specify a number')
			.min(0, 'Min value 0.')
			.required("This field is required"),
		buytradeqnty: Yup.number()
			.typeError('you must specify a number')
			.min(0, 'Min value 0.')
			.required("This field is required"),
	});

	const defaultValues = {
		buytradeprice: '',
		buytradeqnty: '',
	};

	const methods = useForm({
		resolver: yupResolver(TradeSchema),
		defaultValues,
	});

	// const {
	// 	formState: { isSubmitting },
	// } = methods;


	const getmarketTrades = () => {
		var headers = { "Content-Type": "application/json" };

		axios
			.post(url_local.trans,
				{ headers })
			.then(function (response) {
				// console.log("trans", response.data)
				if (response.data.Status === 1) {
					for (let i = 0; i < response.data.Data.length; i++) {
						var data = {
							id: i,
							name: (response.data.Data[i].symbol).split("/")[0],
							previousClose: response.data.Data[i].previousClose,
							symbol: response.data.Data[i].symbol,
							close: response.data.Data[i].close,
							high: response.data.Data[i].high,
							low: response.data.Data[i].low,
							percentage: response.data.Data[i].percentage
						}
						lists.push(data);
					}
					setcryptoData(lists);
					setCurrecyValue(lists[0]);

					//   loadData();
				}
				else if (response.data.Status === 0) {
					$(".modal-title").html("");
					$(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=cancelredirect>Got it</button>");
					$("#cancelredirect").addClass("btn btn-block");
					$("#modalDialog").toggle('show');
				}
				else {
					$(".modal-title").html("");
					$(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=cancelredirect>Got it</button>");
					$("#cancelredirect").addClass("btn btn-block");
					$("#modalDialog").toggle('show');
				}
				return;
			})
			.catch(function (err) {
				// setGetdata(true);
				if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
				// secureLocalStorage.clear();
				$(".modal-body").html("<p className='text-danger'>Network Error!</p>");
				$(".modal-title").html("")
				$(".modal-footer").html("<button id=redirect>Got it</button>");
				$("#redirect").addClass("btn btn-block");
				$("#redirect").on("click", function () {
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
				}
				else{
					console.warn(err,"Exchange");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
					$(".modal-title").html("")
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=closebtn1>ok</button>");
					$("#closebtn1").addClass('btn btn-block');
					$("#closebtn1").on("click", function () {
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
				}
				return;
			});
	}
	function setCurrecyValue(option) {
		setPlaceholder(option.previousClose);
		setPlaceholderValue(String((option.symbol).split("/USDT")).replace(',', '').toUpperCase());
		document.getElementById("price").innerHTML = option.previousClose;
		document.getElementById('high').innerHTML = option.high;
		document.getElementById('low').innerHTML = option.low;
		document.getElementById('bitcoinCur').innerHTML = option.symbol;
		var perc = option.percentage;
		document.getElementById("percDiv1").style.display = "none";
		document.getElementById("percDiv2").style.display = "block";
		if (perc < 0) {
			document.getElementById('successdiv').style.display = "none";
			document.getElementById('faildiv').style.display = "block";
			document.getElementById("percdown").innerText = perc;
		}
		else {
			document.getElementById('faildiv').style.display = "none";
			document.getElementById('successdiv').style.display = "block";
			document.getElementById("percup").innerText = perc;
		}
		var symbol = "BINANCE:" + option.symbol.replace("/", "");
		setpairkey(symbol);
		// loadData();
		// console.log(option.symbol)
		// getBuyOrderBook(String((option.symbol).split("/USDT")).replace(',', '').toUpperCase());
	}

	$('#selectMarketRates').on('change', function () {
		document.getElementById('percDiv1').style.display = 'none';
		var price = $('option:selected', this).attr('data-price');
		var high = $('option:selected', this).attr('data-high');
		var low = $('option:selected', this).attr('data-low');

		document.getElementById("bitcoinCur").innerHTML = $('option:selected', this).attr('value');
		document.getElementById("high").innerHTML = high;
		document.getElementById("low").innerHTML = low;
		document.getElementById("price").innerHTML = price;
		setPlaceholder(price);
		setPlaceholderValue($('option:selected', this).attr('value'));
		var perc = $('option:selected', this).attr('data-perc');
		document.getElementById('percDiv2').style.display = 'block';

		if (perc < 0) {
			document.getElementById('successdiv').style.display = "none";
			document.getElementById('faildiv').style.display = "block";
			document.getElementById("percdown").innerText = perc;
		}
		else {
			document.getElementById('faildiv').style.display = "none";
			document.getElementById('successdiv').style.display = "block";
			document.getElementById("percup").innerText = perc;
		}
		const select_value = $('option:selected', this).attr('value');
		const pairkey = select_value.replace('/', '');
		setpairkey(pairkey);
		// getBuyOrderBook(String(pairkey.toUpperCase()));
		selectedCrypto.current = String(select_value.split("/USDT")).replace(',', '').toUpperCase();
	});

	function getBuyOrderBook(crypto) {
		if (typeof (EventSource) !== "undefined") {
			let headers = new Headers();
			headers.append('Content-Type', 'application/json');
			headers.append('Accept', 'application/json');
			headers.append('Access-Control-Allow-Origin', '*');
			headers.append('Access-Control-Allow-Credentials', 'true');
			headers.append('GET', 'POST');
			var source = new EventSource(url_local.orderbooks);
			source.onopen = function (e) {
			}
			source.onmessage = function (message) {
				try{
					var object = message.data.replace(/'/g,'"');
					var data = JSON.parse(object);
					var buyList = []; var sellList = []; var buyMarketList = [];
					for (let i = 0; i < data.length; i++) {
						if(data[i].symbol === selectedCrypto.current){
							if (data[i].type === "Limit" && data[i].side === 1) {
								sellList.push(data[i]);
							}
							else if (data[i].type === "Limit" && data[i].side === 2) {
								buyList.push(data[i]);
							}
							else if (data[i].type === "Market" && data[i].side === 1) {
								buyMarketList.push(data[i]);
							}
							else if (data[i].type === "Market" && data[i].side === 2) {
								buyMarketList.push(data[i]);
							}
						}
						// setSellMarket(sellMarketList);
					}
					setBuy(buyList);
					setSell(sellList);
					setMarket(buyMarketList);
				}
				catch(e){

				}
				// console.log("buyList",buyList)
				// console.log("sellList",sellList)
				// console.log("buyMarketList",buyMarketList)
			}
			source.onerror = function (e) {
			}
		}
	}

	let markettrades = cryptoData.length > 0 && cryptoData.map((item, index) => {
		return (
			<option key={index} value={item.symbol} data-price={item.previousClose} data-perc={item.percentage}
				data-high={item.high} data-low={item.low}>{item.name}</option>
		)
	}, this);

	// if (loading) {
	// 	return (<Spinner />)
	//   }
	//   else{
	return (
		<main className=''>
			<div id="header" className='headerdark mb-5'>
				<div className="px-5" style={{ borderBottom: "1px solid rgb(43, 55, 66)" }}>
					<WebHeaderEX />
				</div>
			</div>
			<div className="row mt-5">
				<div className="card">
					<div className="card-body">
						<div className="row mt-3 px-5" >
							{/* <div className="col-md-1 d-md-block d-none"></div> */}
							<div className="col-md-3 col-xs-12">
								<PerfectScrollbar className="mt-3">
									<Tab.Container defaultActiveKey="order_book">
										<Nav variant="tabs">
											<Nav.Link eventKey="order_book"><i className="mdi mdi-view-dashboard"></i></Nav.Link>
											<Nav.Link eventKey="Buy">Buy</Nav.Link>
											<Nav.Link eventKey="Sell">Sell</Nav.Link>
										</Nav>
										<Tab.Content>
											<Tab.Pane eventKey="order_book">
												<div className="table-responsive mt-5" style={{ minHeight: '30vh' }}>
													<h5 className="card-description text-danger">SELL ORDER</h5>
													<table className="table table-borderless">
														<thead className="mb-3">
															<tr className="text-left">
																<th className="text-left class_p" scope="col">Price</th>
																<th className="text-left class_p" scope="col">Size</th>
																<th className="text-left class_p" scope="col">Total</th>
															</tr>
														</thead>
														{sell.length > 0 ?
															<tbody>
																{sell.map((item, index) =>
																(<tr key={index}>
																		<td className="text-danger text-left">{item.usd_value}</td>
																		<td className="text-left">{item.volume}</td>
																		<td className="text-left">{(item.usd_value * item.volume).toFixed(6)}</td>
																	</tr>)
																)}
															</tbody> :
															<tbody>
																<tr>
																	<td colSpan={6}>
																		No records
																	</td>
																</tr>
															</tbody>}
													</table>
												</div>
												<div className="order-book-divider mt-3 mb-3">
													<h6 className="text-danger">
														{" "}<i className="la la-arrow-down"></i>{" "} 6587.35</h6>
													<span>6520.220 / 4835.00</span>
												</div>
												<div className="table-responsive" style={{ minHeight: '30vh' }}>
													<h5 className="card-description text-success">BUY ORDER</h5>
													<table className="table table-borderless h-100">
														<thead>
															<tr className="text-left mb-5">
																<th className="text-left class_p" scope="col">Price</th>
																<th className="text-left class_p" scope="col">Size</th>
																<th className="text-left class_p" scope="col">Total</th>
															</tr>
														</thead>
														{buy.length > 0 ?
															<tbody>
																{buy.map((item, index) =>
																(
																	<tr key={index}>
																		<td className="text-success text-left">{item.usd_value}</td>
																		<td className="text-left">{item.volume}</td>
																		<td className="text-left">{(item.usd_value * item.volume).toFixed(6)}</td>
																	</tr>
																)
																)}
															</tbody> :
															<tbody>
																<tr>
																	<td colSpan={6}>
																		No records
																	</td>
																</tr>
															</tbody>}
													</table>
												</div>
											</Tab.Pane>
											<Tab.Pane eventKey="Buy">
												<div className="table-responsive mt-5" style={{ minHeight: '30vh' }}>
													<h5 className="card-description text-success">BUY ORDER</h5>
													<table className="table table-borderless h-100">
														<thead>
															<tr className="text-left mb-5">
																<th className="text-left class_p" scope="col">Price</th>
																<th className="text-left class_p" scope="col">Size</th>
																<th className="text-left class_p" scope="col">Total</th>
															</tr>
														</thead>
														{buy.length > 0 ?
															<tbody>
																{buy.map((item, index) =>
																(
																	<tr key={index}>
																		<td className="text-success text-left">{item.usd_value}</td>
																		<td className="text-left">{item.volume}</td>
																		<td className="text-left">{(item.usd_value * item.volume).toFixed(6)}</td>
																	</tr>
																)
																)}
															</tbody> :
															<tbody>
																<tr>
																	<td colSpan={6}>
																		No records
																	</td>
																</tr>
															</tbody>}
													</table>
												</div>
											</Tab.Pane>
											<Tab.Pane eventKey="Sell">
												<div className="table-responsive mt-5" style={{ minHeight: '30vh' }}>
													<h5 className="card-description text-danger">SELL ORDER</h5>
													<table className="table table-borderless">
														<thead className="mb-3">
															<tr className="text-left">
																<th className="text-left class_p" scope="col">Price</th>
																<th className="text-left class_p" scope="col">Size</th>
																<th className="text-left class_p" scope="col">Total</th>
															</tr>
														</thead>
														{sell.length > 0 ?
															<tbody>
																{sell.map((item, index) =>
																(
																	<tr key={index}>
																		<td className="text-danger text-left">{item.usd_value}</td>
																		<td className="text-left">{item.volume}</td>
																		<td className="text-left">{(item.usd_value * item.volume).toFixed(6)}</td>
																	</tr>
																)
																)}
															</tbody> :
															<tbody>
																<tr>
																	<td colSpan={6}>
																		No records
																	</td>
																</tr>
															</tbody>}
													</table>
												</div>
											</Tab.Pane>
										</Tab.Content>
									</Tab.Container>
								</PerfectScrollbar>
							</div>
							<div className="col-md-6 col-xs-12" style={{ borderLeft: "1px solid #292c37", borderRight: "1px solid #292c37" }}>
								{/* <h6 className="class_p">Market Rates</h6> */}
								<div className="row mt-3">
									<p>Market Rates</p>
									<div className="col-xs-12 col-sm-6 col-lg-2">
										<select id="selectMarketRates" className="form-select form-select-sm">
											{markettrades}
										</select>
									</div>
									{/* <div className="vl col-auto"></div>  */}
									<div className="col-xs-12 col-sm-6  col-lg-2 text-center mt-1">
										<h5 id="bitcoinCur">--:--</h5>
										<h6 className="class_p text-success" id="price">--:--</h6>
									</div>
									<div className="col-xs-12 col-sm-6 col-lg-2 text-center mt-1">
										<p className="class_p form-label">High<i className="mdi mdi-arrow-up"></i>(1D)</p>
										<h6 id="high">--:--</h6>
									</div>
									<div className="col-xs-12 col-sm-6 col-lg-2 text-center mt-1">
										<p className="class_p form-label">Low<i className="mdi mdi-arrow-down"></i>(1D)</p>
										<h6 id="low">--:--</h6>
									</div>
									<div className="col-xs-12 col-sm-6 col-lg-2 text-center mt-1">
										<p className="class_p" id="percDiv1">--:--</p>
										<p className="class_heading" id="percDiv2" style={{ display: 'none' }}>
											<span className="text-danger" id="faildiv" >
												<i className='mdi mdi-arrow-down'></i>&nbsp; <span id="percdown"></span>%</span>
											<span className="text-success" id="successdiv" >
												<i className='mdi mdi-arrow-up'></i>&nbsp;<span id="percup"></span> %
											</span>
										</p>
									</div>
									<input type="hidden" id="securitysymbol" name="securitysymbol" />
									<input type="hidden" id="accountno" name="accountno" value="" />
								</div>
								<div className="row">
									{/* <!-- TradingView Widget BEGIN --> */}
									<div className="tradingview-widget-container card"
										style={{ height: "400px" }}>
										<TradingViewWidget
											symbol={pairkey}
											theme={theme}
											locale="en"
											autosize
										/>
									</div>
									{/* <!-- TradingView Widget END --> */}
								</div>
								{/* <div className="col-12"> */}
								<Tab.Container defaultActiveKey="limit">
									{/* <div className="card-header"> */}
									<Nav variant="tabs" className="mt-3 mb-2 text-white">
										<Nav.Link eventKey="limit">
											Limit
										</Nav.Link>
										<Nav.Link eventKey="market">
											Market
										</Nav.Link>
									</Nav>
									{/* </div> */}
									<Tab.Content className="mt-2">
										<Tab.Pane eventKey="limit">
											<div className="row">
												<div className="col-md-6 col-xs-12">
													{/* <BuyOrder placeholder={placeholder} /> */}
													<div className="d-flex flex-sm-row flex-column">
														<div className="mr-auto p-2"><h4 className=''>Buy</h4></div>
														<div className="p-2"><p className='class_p'>{placeholderValue}</p></div>
													</div>
													<FormProvider methods={methods}>
														<RHFTextField id="buytradeprice" name="buytradeprice" label="Amount"
															inputProps={{ className: "mb-3" }}
															placeholder={placeholder + " $"}
														/>
														<RHFTextField id="buytradeqnty" name="buytradeqnty" label="Volume" placeholder="Enter Size" />
														{/* <div className="d-flex justify-content-around mt-2">
																												<p className='class_p'>Time In Force Policy</p>
																												<p className='class_p'>Good Till Cancelled</p>
																											</div> */}
														<div className="col-md-12 text-center mt-3">
															<Link to={routeCodes.LOGIN} className="btn btn-sm btn-block btn-yodigi-buy">Login/ Register</Link>
														</div>
													</FormProvider>
												</div>
												<div className="col-md-6 col-xs-12">
													{/* <SellOrder placeholder={placeholder} /> */}
													<div className="d-flex flex-sm-row flex-column">
														<div className="mr-auto p-2">	<h4 className=''>Sell</h4></div>
														<div className="p-2"><p className='class_p'>{placeholderValue}</p></div>
													</div>
													<FormProvider methods={methods}>
														<RHFTextField id="selltradeprice" name="selltradeprice" label="Amount" inputProps={{ className: "mb-3" }}
															placeholder={placeholder + " $"}
														/>
														<RHFTextField id="selltradeqnty" name="selltradeqnty" label="Volume" placeholder="Enter Size" />
														{/* <div className="d-flex justify-content-between mt-2">
																<p className='class_p'>Time In Force Policy</p>
																<p className='class_p'>Good Till Cancelled</p>
															</div> */}
														<div className="col-md-12 text-center mt-3">
															{/* {ChangeBtnstate ?  */}
															<Link to={routeCodes.LOGIN} className="btn btn-sm btn-block btn-yodigi-danger">Login/ Register</Link>
															{/* <LoadingButton size="small" type="submit" className="btn btn-sm btn-block btn-yodigi-danger"
																loading={isSubmitting}>Sell now</LoadingButton> */}
															{/* // }      */}
														</div>
													</FormProvider>
												</div>

											</div>

										</Tab.Pane>
										<Tab.Pane eventKey="market">
											<div className="row">
												<div className="col-md-6 col-xs-12">
													<div className="d-flex flex-sm-row flex-column">
														<div className="mr-auto p-2"><h4 className=''>Buy</h4></div>
														<div className="p-2"><p className='class_p'>{placeholderValue}</p></div>
													</div>
													<FormProvider methods={methods}>
														<RHFTextField id="buytradeprice" name="buytradeprice" label="Amount" inputProps={{ readOnly: true,className: "mb-3" }}
															placeholder={placeholder + " $"} />
														<RHFTextField id="buytradeqntyMarket" name="buytradeqntyMarket" label="Volume" placeholder="Enter Size" />
														{/* <div className="d-flex justify-content-around mt-2">
																											<p className='class_p'>Time In Force Policy</p>
																											<p className='class_p'>Good Till Cancelled</p>
																										</div> */}
														<div className="col-md-12 text-center mt-3">
															<Link to={routeCodes.LOGIN} className="btn btn-sm btn-block btn-yodigi-buy">Login/ Register</Link>
														</div>
													</FormProvider>
												</div>
												<div className="col-md-6 col-xs-12">
													<div className="d-flex flex-sm-row flex-column">
														<div className="mr-auto p-2"><h4 className=''>Sell</h4></div>
														<div className="p-2"><p className='class_p'>{placeholderValue}</p></div>
													</div>
													<FormProvider methods={methods}>
														<RHFTextField id="selltradepricemarket" name="selltradepricemarket" label="Amount"
															inputProps={{ readOnly: true,className: "mb-3" }}
															placeholder={placeholder + " $"} />
														<RHFTextField id="selltradeqntymarket" name="selltradeqntymarket" label="Volume" placeholder="Enter Size" />
														{/* <div className="d-flex justify-content-around mt-2">
																										<p className='class_p'>Time In Force Policy</p>
																										<p className='class_p'>Good Till Cancelled</p>
																									</div> */}
														<div className="col-md-12 text-center mt-3">
															<Link to={routeCodes.LOGIN} className="btn btn-sm btn-block btn-yodigi-danger">Login/ Register</Link>
														</div>
													</FormProvider>
												</div>

											</div>
										</Tab.Pane>
									</Tab.Content>
								</Tab.Container>
								{/* </div> */}
							</div>

							<div className="col-md-3 col-xs-12 "
							>
								<div className="row mt-3">
									<div className="col-12">
										<div className="card">
											{/* <div className="card-header">
												<h4 className="card-title">
													Trade History
												</h4>
											</div> */}
											<PerfectScrollbar>
												<Tab.Container defaultActiveKey="Market Trades">
													<div className="card">
														<div className="mb-3">
															<Nav variant="tabs">
																<Nav.Link eventKey="Market Trades">
																	Market Trades
																</Nav.Link>
																<Nav.Link eventKey="My Trades">
																	My Trades
																</Nav.Link>
															</Nav>
														</div>

														<Tab.Content>
															<Tab.Pane eventKey="Market Trades">
																<div className="row">
																	<div className="card-body trade-history">
																		<div className="table-responsive" style={{ minHeight: '100%' }}>
																			<table className="table table-borderless">
																				<thead>
																					<tr className="text-left mb-5">
																						<th className="text-left class_p" scope="col">Price</th>
																						<th className="text-left class_p" scope="col">Size</th>
																						<th className="text-left class_p" scope="col">Total</th>
																					</tr>
																				</thead>
																				{buyMarket.length > 0 ?
																					<tbody>
																						{buyMarket.map((item, index) =>
																						(
																							<tr key={index}>
																								{item.side === 1 ?
																									<td className="text-danger text-left">{item.usd_value}</td> :
																									<td className="text-success text-left">{item.usd_value}</td>}
																								<td className="text-left">{item.volume}</td>
																								<td className="text-left">{(item.usd_value * item.volume).toFixed(6)}</td>
																							</tr>
																						))}
																					</tbody> :
																					<tbody>
																						<tr>
																							<td colSpan={6}>No records</td>
																						</tr>
																					</tbody>}
																			</table>
																		</div>
																	</div>
																</div>
															</Tab.Pane>
															<Tab.Pane eventKey="My Trades">
																<div className="card-body trade-history">
																	<div className="table-responsive" style={{ minHeight: '100%' }}>
																		<table className="table table-borderless">
																			<thead>
																				<tr className="text-left mb-5">
																					<th className="text-left class_p" scope="col">Price</th>
																					<th className="text-left class_p" scope="col">Size</th>
																					<th className="text-left class_p" scope="col">Total</th>
																				</tr>
																			</thead>
																			<tbody className="pt-5">
																				<tr className="text-center">
																					<td colSpan="6" className="mt-3">
																						<h4 className="text-center">
																							<div className="mt-5"><p className="class_p">
																								<a href={routeCodes.SIGNUP} className="btn btn-sm btn-yodigi-buy mx-1">Signup</a> Already Account?
																								<a href={routeCodes.LOGIN} style={{ color: "#3ee278" }} className="mx-1">Login</a>
																							</p>
																							</div>
																						</h4>
																					</td>
																				</tr>
																			</tbody>
																		</table>
																	</div>
																</div>
															</Tab.Pane>
														</Tab.Content>
													</div>
												</Tab.Container>
											</PerfectScrollbar>
										</div>
									</div>
								</div>
							</div>
							{/* <div className="col-md-1 d-md-block d-none"></div> */}
						</div>
					</div>
				</div>
			</div>

		</main>
	);
	// }
}

export default Exchange;
