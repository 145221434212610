import React, { useState } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import * as Yup from 'yup';
import { FormProvider, RHFTextField } from '../components/hook-form';
import { IconButton, InputAdornment } from '@mui/material';
import url_local from '../url_local';

import routeCodes from '../../Paths';
import { LoadingButton } from '@mui/lab';

//components
import Iconify from '../components/Iconify';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import secureLocalStorage from 'react-secure-storage';

export default function NewFiatTransfer() {
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  const history = useNavigate();
  const [previewData, setpreviewData] = useState([]);
  const [showPassword, setShowPassword] = React.useState(false);
  const [getdata, setGetdata] = useState(false);

  const transferSchema = Yup.object().shape({
    rxaccount: Yup.string()
      .required("Please enter a valid account number"),
    Transferamount: Yup.number()
      .typeError('Please enter a amount')
      .min(1, 'Amount should not be zero')
      .required("Please enter an amount"),
  });

  const defaultValues = {
    rxaccount: '',
    Transferamount: '',
  };

  const methods = useForm({
    resolver: yupResolver(transferSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  const previewdetails = () => {
    var headers = {
      "Content-Type": "application/json",
      "Access-Token": secureLocalStorage.getItem('AT_'),
      "Refresh-Token": secureLocalStorage.getItem('RT_')
    };
    var amt = parseFloat(document.getElementById("Transferamount").value);
    axios
      .post(url_local.fiattransfer,
        {
          "publicid": secureLocalStorage.getItem('PI_'),
          "amount": amt,
          "currency": secureLocalStorage.getItem("CS_"),
          "txaccount": secureLocalStorage.getItem("AN_"),
          "rxaccount": document.getElementById("rxaccount").value,
          // // "re-accountno":document.getElementById("re-acno.").value,
          "bankname": "",
          "bankcode": "",
          "transactionref": "",
          "transferdate": "",
          "txnotes": document.getElementById("remarks1").value,
          "txtypeid": 202,
          "isconfirmed": false,
          "pin": 0
          // currency:""
        },
        { headers })
      .then(function (response) {
        if (response.data.Status === 1) {
          setpreviewData(response.data.Data);
          // $("#myModal").toggle('show');
          document.querySelector('#view-right-sidebar').classList.toggle('open');
        }
        else if (response.data.Status === 0) {
          setGetdata(false);
          if (response.data.Code === 3102) {
            $(".modal-title").html("");
            $(".modal-body").html('<p class=class_p>' + response.data.Message + '.</p>');
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=redirect>Activate now?</button><button id=cancelredirect>Cancel</button>");
            $("#redirect").addClass("btn btn-block");
            $("#cancelredirect").addClass("btn btn-block");
            $("#redirect").on("click", function () {
              history(routeCodes.SECURITY);
              $("#modalDialog").toggle('hide');
            });
            $("#cancelredirect").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
          else if (response.data.Status === 0) {
            if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
              $(".modal-title").html("");
              $(".modal-body").html("<p class=class_p>Your session expired</p>");
              $(".modal-footer").html("");
              $(".modal-footer").html("<button id=signout_btn>ok</button>");
              $("#signout_btn").addClass('btn btn-block');
              $("#signout_btn").on('click', function () {
                  var headers = {
                    "Content-Type": "application/json",
                    'Access-Token': secureLocalStorage.getItem('AT_'),
                    'Refresh-Token': secureLocalStorage.getItem('RT_')
                  }
                  axios
                    .post(url_local.logout, { publicid: secureLocalStorage.getItem('PI_') },
                      { headers })
                    .then(function (response) {
                      if (response.data.Status === 1) {
                        history(routeCodes.HOME);
                        secureLocalStorage.clear()
                      }
                      else if (response.data.Status === 0) {
                        $(".modal-title").html("")
                        $(".modal-body").html("<p class=class_p" > +response.data.Message + "</p>");
                        $(".modal-title").html("")
                        $(".modal-footer").html("");
                        $(".modal-footer").html("<button id=ok>OK</button>");
                        $("#ok").addClass("btn btn-block");
                        $("#ok").on("click", function () {
                          $("#modalDialog").toggle('hide');
                        });
                        $("#modalDialog").toggle('show');
                      }
                      else {
                        $(".modal-title").html("")
                        $(".modal-body").html("<p class='class_p text-danger'>Something went wrong. Try again.</p>");
                        $(".modal-title").html("")
                        $(".modal-footer").html("");
                        $(".modal-footer").html("<button id=ok1>ok</button>");
                        $("#ok1").addClass("btn btn-block");
                        $("#ok1").on("click", function () {
                          $("#modalDialog").toggle('hide');
                        });
                        $("#modalDialog").toggle('show');
                      }
                    })
                    .catch(function (error) {
                      return;
                    });
              });
              $("#modalDialog").toggle('show');
            }
           else{
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=cancelredirect1>Got it</button>");
            $("#cancelredirect1").addClass("btn btn-block");
            $("#cancelredirect1").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
           }
          }
          else {
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=cancelredirect>Got it</button>");
            $("#cancelredirect").addClass("btn btn-block");
            $("#cancelredirect").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
        }
        else {
          setGetdata(false);
          $(".modal-title").html("");
          $(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=cancelredirect2>Got it</button>");
          $("#cancelredirect2").addClass("btn btn-block");
          $("#cancelredirect2").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        return;
      })
      .catch(function (err) {
        setGetdata(true);
        if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
        // secureLocalStorage.clear();
        $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("<button id=redirect>Got it</button>");
        $("#redirect").addClass("btn btn-block");
        $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
        }
        else{
            console.warn(err,"NewFiatTransfer");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn1>ok</button>");
            $("#closebtn1").addClass('btn btn-block');
            $("#closebtn1").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
        }
        return;
      });
  }

  const submitTransactiondetails = (event) => {
    var headers = {
      "Content-Type": "application/json",
      "Access-Token": secureLocalStorage.getItem('AT_'),
      "Refresh-Token": secureLocalStorage.getItem('RT_')
    };
    var amt = parseFloat(document.getElementById("Transferamount").value);
    var data = {
      "publicid": secureLocalStorage.getItem('PI_'),
      "amount": amt,
      "currency": secureLocalStorage.getItem("CS_"),
      "txaccount": secureLocalStorage.getItem("AN_"),
      "rxaccount": document.getElementById("rxaccount").value,
      "bankname": "",
      "bankcode": "",
      "transactionref": "",
      "transferdate": "",
      "txnotes": document.getElementById("remarks1").value,
      "txtypeid": 202,
      "isconfirmed": true,
      "pin": document.getElementById("pin").value
    }

    axios
      .post(url_local.fiattransfer,
        data,
        { headers })
      .then(function (response) {
        if (response.data.Status === 1) {
          document.querySelector('#view-right-sidebar').classList.remove('open');
          document.getElementById("errMsg").style.display = "none";
          $(".modal-title").html("");
          $(".modal-body").html('<p class=class_p>' + response.data.Message + '.</p>');
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=cancelredirect>Got it</button>");
          $("#cancelredirect").addClass("btn btn-block");
          $("#cancelredirect").on("click", function () {
            history(routeCodes.CREATEFIATACCOUNT);
            secureLocalStorage.setItem("CS_", "");
            secureLocalStorage.setItem("AN_", "");
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        else if (response.data.Status === 0) {
          setGetdata(false);
          document.querySelector('#view-right-sidebar').classList.remove('open');
          if (response.data.Code === 3102) {
            document.getElementById("errMsg").style.display = "block";
            document.getElementById("errMsg").innerHTML = response.data.Message;
          }
          else if (response.data.Status === 0) {
            if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
              $(".modal-title").html("");
              $(".modal-body").html("<p class=class_p>Your session expired</p>");
              $(".modal-footer").html("");
              $(".modal-footer").html("<button id=signoutbtn>ok</button>");
              $("body").addClass('no-select'); secureLocalStorage.clear();
              $("#signoutbtn").addClass('btn btn-block');
              $("#modalDialog").toggle('show');
              $("#signoutbtn").on('click', function () {
                $("#modalDialog").toggle('hide');
                window.location.href = "/";
              });
            }
           else{
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=cancelredirect1>Got it</button>");
            $("#cancelredirect1").addClass("btn btn-block");
            $("#cancelredirect1").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
           }
          }
          else {
            document.getElementById("errMsg").style.display = "block";
            document.getElementById("errMsg").innerHTML = response.data.Message;
          }
        }
        else {
          setGetdata(false);
          document.getElementById("errMsg").style.display = "block";
          document.getElementById("errMsg").innerHTML = "Something went wrong. Try again.";
        }
        return;
      })
      .catch(function (err) {
        setGetdata(true);
        if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
        // secureLocalStorage.clear();
        $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("<button id=redirect>Got it</button>");
        $("#redirect").addClass("btn btn-block");
        $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
        }
        else{
            console.warn(err,"NewFiatTransfer");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn1>ok</button>");
            $("#closebtn1").addClass('btn btn-block');
            $("#closebtn1").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
        }
        return;
      });
  }
  const viewcloseRightSidebar = () => {
    document.querySelector('#view-right-sidebar').classList.remove('open');
    document.getElementById('pinsection').style.display = "none";
    document.getElementById('btnsection').style.display = "block";
  }

  const getPinsection = () => {
    document.getElementById("errMsg").style.display = "none";
    document.getElementById('pinsection').style.display = "block";
    document.getElementById('btnsection').style.display = "none";
    document.getElementById("pin").value = "";
  }

  const ContinueTransfer = () => {
    document.getElementById("receipient").innerHTML = "";
    document.getElementById("from_account").innerHTML = "";
    document.getElementById("to_account").innerHTML = "";

    var headers = {
      "Content-Type": "application/json",
      "Access-Token": secureLocalStorage.getItem('AT_'),
      "Refresh-Token": secureLocalStorage.getItem('RT_')
    };

    var data = {
      "publicid": secureLocalStorage.getItem('PI_'),
      "accountno": document.getElementById("rxaccount").value,
    }
    // console.log("transfer data", data)
    axios
      .post(url_local.getaccountowner,
        data,
        { headers })
      .then(function (response) {
// console.log("response.data.Status")
// console.log(response.data)
        if (response.data.Status === 1) {
          // secureLocalStorage.setItem("receipient",response.data.Data.account_owner);
          document.getElementById("receipient").innerHTML = response.data.Data.account_owner;
          document.getElementById("from_account").innerHTML = secureLocalStorage.getItem("AN_") + " (" + secureLocalStorage.getItem("CS_") + ")";
          document.getElementById("to_account").innerHTML = response.data.Data.accountno + " ( " + response.data.Data.currency + ")";
          document.getElementById("selectidsection").style.display = "none";
          document.getElementById("submitdetails").style.display = "block";
        }
        else if (response.data.Status === 0) {
          if (response.data.Code === 3 || response.data.Code === 7) {
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>Your session expired.</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closesessionclosebtn>ok</button>");
            $("body").addClass('no-select'); secureLocalStorage.clear();
            $("#closesessionclosebtn").addClass('btn btn-block');
            $("#closesessionclosebtn").on("click", function () {
              $("#modalDialog").toggle('hide');
              window.location.href = "/";
            });
            if($("#modalDialog" ).hasClass( "show" )){
              $(".main-panel").css("filter","blur(8px)");
              $("#modalDialog").toggle('show');
             }
             else{
                 $(".main-panel").css("filter","blur(8px)");
                 $("#modalDialog").toggle('show');
             }
          }
          else {
            document.getElementById("selectidsection").style.display = "block";
            document.getElementById("submitdetails").style.display = "none";
            $(".modal-body").html("<p class=class_p>" + response.data.Response + "</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn>ok</button>");
            $("#closebtn").addClass('btn btn-block');
            $("#closebtn").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
        }
        else {
          console.warn("NewFiatTransfer");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
          $(".modal-title").html("")
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=closebtn>ok</button>");
          $("#closebtn").addClass('btn btn-block');
          $("#closebtn").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        return;
      })
      .catch(function (err) {
        setGetdata(true);
        if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
          // secureLocalStorage.clear();
          $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
          $(".modal-title").html("")
          $(".modal-footer").html("<button id=redirect>Got it</button>");
          $("#redirect").addClass("btn btn-block");
          $("#redirect").on("click", function () {
              $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        else{
            console.warn(err,"NewFiatTransfer");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn1>ok</button>");
            $("#closebtn1").addClass('btn btn-block');
            $("#closebtn1").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
        }
        return;
      });

  }

  const BackToTransfer = () => {
    document.getElementById("selectidsection").style.display = "block";
    document.getElementById("submitdetails").style.display = "none";
  }


  var array = [];
  array.push(previewData);

  var transactionData = array.map((item, index) => {
    return (
      <div key={index} id={index} className=" pt-4">
        <h4 className='mb-3'>Confirm payment</h4>
        <div className='row mt-5'>
          <div className='col-md-4 col-xs-12'>
            <p className='text-center'>{item.Debit_Currency}</p>
            <p className='class_p'>{secureLocalStorage.getItem("AN_")}</p>
            <div className='mt-2 text-left'>
              <p>Amount debited : {item.Debited_Amount}</p>
              <p>Service Charge : {item.Service_Charge}</p>
            </div>
          </div>
          <div className='col-md-3 col-xs-12 text-center'>
            <i className='mdi mdi-arrow-right'></i>
          </div>
          <div className='col-md-4 col-xs-12'>
            <p className='text-center'>{item.Credit_Currency}</p>
            <p className='class_p'>{item.Credit_Account}</p>
            <div className='mt-2 text-left'>
              <p>Credited Amount : {item.Credit_Amount}</p>
            </div>
          </div>
        </div>
        <h4 style={{ borderTop: "1px solid #242736" }} className="mb-3 pt-3">Total : {item.Debited_Amount}</h4>
      </div>
    )
  })

  return (
    <div className="row">
      {/* internal container is div.card below */}
      <div className="card px-4">
        <div className="page-header w-100">
          <h3 className="page-title w-100 row">
            <div className='col-5'>
              <span className="page-title-icon bg-success me-2">
                <i className="mdi mdi-cash"></i>
              </span> Transfer Fiat
            </div>
            <div className='col'>
              <div className="float-end">
                <div className='text-lastlogin text-end fs-6'>
                  <i className="mdi mdi-account px-2"></i>Last login time
                  <label className='d-block'>{secureLocalStorage.getItem('DT_')}</label>
                </div>
              </div>
            </div>
          </h3>
        </div>
        {/* here content is placed */}
        <div className='p-3'>
          <p className='card-description pt-3'>Start a new transfer with Meezan</p>
            <div id="selectidsection">
              <div className="row">
                <div className='col-md-6 col-xs-12'>
                  <label className="form-label">Account Type</label>
                  <p className="class_p card-description font-bold ">
                    <span id="accountTypeselect">{secureLocalStorage.getItem("CS_")}</span>
                    <a className='mx-2 btn btn-sm settingsBtn' href={routeCodes.CREATEFIATACCOUNT}>
                      Change
                    </a>
                  </p>
                  <p className='class_p mb-3'>{secureLocalStorage.getItem("AN_")}</p>
                </div>
              </div>
              <p>Enter Transaction details</p>
              <div className="mb-3 mt-2">
                <FormProvider methods={methods} onSubmit={handleSubmit(ContinueTransfer)}>
                  <div className='row'>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label class_p">Beneficiary Account *</label>
                      <div className="col-sm-6">
                        {/* <RHFTextField name="rxaccount" id='rxaccount' placeholder="" /> */}
                        <input type='text' name="rxaccount" id='rxaccount' className='form-control' placeholder="" maxLength={35} minLength={30} pattern="([0-9-]){30,35}" title='Must be 30-35 numbers long with hyphens!'/>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label class_p">Enter Amount *</label>
                      <div className="col-sm-6">
                        <input type='number' name="Transferamount" id='Transferamount' className='form-control' placeholder="" min={1} max={9999} title='Amount should be from 1-9999'/>
                        {/* <RHFTextField name="Transferamount" id='Transferamount' placeholder="" /> */}
                      </div>
                    </div>
                  </div>
                  {!getdata ?
                    <div className='text-center'>
                      <LoadingButton size="small" type="submit" variant="contained" loading={isSubmitting} className="btn-sm btn-yodigi-buy text-center">
                        Next
                      </LoadingButton>
                      <a className='btn btn-block mx-2' href={routeCodes.CREATEFIATACCOUNT}>Cancel</a>
                    </div>
                    :
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  }
                </FormProvider>
              </div>
            </div>
            <div className="auth-form " id="submitdetails" style={{ display: 'none' }}>
              <div className="row">
                <label className="card-description text-label font-bold mr-1">Receipient Name :
                  <span id="receipient" className='mx-2 card-description text-label font-bold'></span></label>

                <label className="card-description text-label font-bold mr-1"> Beneficiary account :
                  <span id="to_account" className='mx-2 card-description text-label font-bold'></span></label>

                <label className="card-description text-label font-bold mr-1"> Sender Account :
                  <span id="from_account" className='mx-2 card-description text-label font-bold'></span></label>
                <div className="col-12 mb-3">
                  <label className="card-description text-label font-bold">Remarks(optional)</label>
                  <textarea col="10" rows="4" type="text" id="remarks1" className="form-control form-control-sm" required maxLength={200} ></textarea>
                </div>
              </div>
              <div className="text-center mt-3">
                <button className="btn-sm btn-yodigi-buy ps-5 pe-5" id="203" onClick={previewdetails}>Submit</button>
                <button className="btn btn-sm " onClick={BackToTransfer}>Back</button>
              </div>
            </div>
        </div>
        <div id="view-right-sidebar"
          className="px-4 settings-panel right-sidebar">
          <i className="settings-close mdi mdi-arrow-left justify-content-right"
            onClick={viewcloseRightSidebar}></i>
          <div className="" id="uncontrolled-tab-example ">
            <div className="row">
              <div className="col-lg-12">
                <div className="px-3">
                  <span></span>
                  <ul >
                    {transactionData}
                  </ul>
                  <div id="btnsection" className='p-2'>
                    <button className='btn-sm btn-default' onClick={viewcloseRightSidebar}>Cancel</button>
                    <button className='btn-sm btn-yodigi-buy' onClick={getPinsection}>Confirm payment</button>
                  </div>
                  <div id="pinsection" style={{ display: 'none' }} className="mt-3">
                    <p className='class_p'>Enter your Security PIN</p>
                    <FormProvider methods={methods} onSubmit={handleSubmit(submitTransactiondetails)}>
                      <div className="col-12">
                        <RHFTextField name="pin"
                          id="pin" placeholder=""
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (<InputAdornment position="end">
                              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                <Iconify style={{ color: '#fff' }} icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                              </IconButton>
                            </InputAdornment>)
                          }} />
                      </div>
                    </FormProvider>
                    <div id="btnsection" className='p-2 text-center'>
                      <button className='btn-sm btn-default mx-2' onClick={viewcloseRightSidebar}>Cancel</button>
                      <button type="submit" className="btn-sm btn-yodigi-buy mx-2" id="202"
                        onClick={submitTransactiondetails}>Send</button>
                    </div>
                    <p className='text-danger mt-1' id="errMsg" style={{ display: 'none' }}></p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

