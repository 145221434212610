import React from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Tab} from 'react-bootstrap';
import secureLocalStorage from 'react-secure-storage';

//components
import BankAccountDetails from "./Bank_Account_Details";
import url_local from '../url_local';
import routeCodes from '../../Paths';

export default function NewTransaction() {
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  const history = useNavigate();
  const initialState = { alt: "", src: "" };
  const [{ alt, src, profiledata }, setPreview] = React.useState(initialState);
  const fileHandler = event => {
    var { files } = event.target;
    setPreview(
      files.length
        ? {
          src: URL.createObjectURL(files[0]),
          alt: files[0].name,
          profiledata: files[0]
        }
        : initialState
    );
  };

  React.useEffect(() => {
    var headers = {
        "Content-Type": "application/json",
        'Access-Token': secureLocalStorage.getItem('AT_'),
        "Refresh-Token": secureLocalStorage.getItem('RT_')
    };
    axios
        .post(url_local.checkuserstatus,
            {
                publicid: secureLocalStorage.getItem('PI_')
            },
            { headers })
        .then(function (response) {
            if (response.data.Status === 1) {
              secureLocalStorage.setItem('FN_', response.data.Data.user_name);
            }
            else if (response.data.Status === 0) {
                if (response.data.Code === 3 || response.data.code === 7 || response.data.Data.Status === 600) {
                    $(".modal-title").html("");
                    $(".modal-body").html("<p class=class_p>Your session expired.</p>");
                    $(".modal-footer").html("");
                    $(".modal-footer").html("<button id=closesessionclosebtn>ok</button>");
                    $("body").addClass('no-select'); secureLocalStorage.clear();
                    $("#closesessionclosebtn").addClass('btn btn-block');
                    $("#closesessionclosebtn").on("click", function () {
                      $("#modalDialog").toggle('hide');
                      window.location.href = "/";
                    });
                    if($("#modalDialog" ).hasClass( "show" )){
                      $(".main-panel").css("filter","blur(8px)");
                      $("#modalDialog").toggle('show');
                     }
                     else{
                         $(".main-panel").css("filter","blur(8px)");
                         $("#modalDialog").toggle('show');
                     }
                }
                else {
                    $(".modal-title").html("");
                    $(".modal-body").html(response.data.Message);
                    $(".modal-footer").html("");
                    $(".modal-footer").html("<button id=closebtn>ok</button>");
                    $("#closebtn").addClass('btn btn-block');
                    $("#closebtn").on("click", function () {
                        $("#modalDialog").toggle('hide');
                    });
                    $("#modalDialog").toggle('show');
                }
            }
            else {
                $(".modal-body").html("<p class='text-danger'>Something went wrong. Try again.</p>");
                $(".modal-title").html("")
                $(".modal-footer").html("");
                $(".modal-footer").html("<button id=got_it>Got it</button>");
                $("#got_it").addClass("btn btn-block");
                $("#got_it").on("click", function () {
                    $("#modalDialog").toggle('hide');
                });
                $("#modalDialog").toggle('show');
            }
            return;
        })
        .catch(function () {
            return;
        });
    // loadData();
}, [history]);


  const validation = () => {
    var bankName = document.getElementById('bankName').value;
    var bankCode = document.getElementById('bankCode').value;
    var Topupamount = document.getElementById('Topupamount').value;
    var txdate = document.getElementById('txdate').value;
    var TransferRef = document.getElementById('TransferRef').value;

    // secemail_status= "";
    if (bankName === "" || bankCode === "" ||
      Topupamount === "" || txdate === "" || TransferRef === "") {
// console.log("dsafdsfds")
      $(".modal-title").html("");
      $(".modal-body").html("<p class=class_p>Please fill all mandatory fields *</p>");
      $(".modal-footer").html("");
      $(".modal-footer").html("<button id=closebtnok>Continue</button>");
      $("#closebtnok").addClass('btn btn-block');
      $("#closebtnok").on("click", function () {
        $("#modalDialog").toggle('hide');
      });
      $("#modalDialog").toggle('show');
    }
    else if (profiledata === undefined) {
      $(".modal-title").html("");
      $(".modal-body").html("<p class=class_p>* Please upload a valid and clear document</p>");
      $(".modal-footer").html("");
      $(".modal-footer").html("<button id=closebtn>Continue</button>");
      $("#closebtn").addClass('btn btn-block');
      $("#closebtn").on("click", function () {
        $("#modalDialog").toggle('hide');
      });
      $("#modalDialog").toggle('show');
    }
    else if (profiledata !== undefined) {
      //uploadreceipt();
      topup("201");
    }
    else {
      topup("201");
    }
  }


  const topup = (id) => {
    var reader = new FileReader();
    reader.readAsDataURL(profiledata);
    reader.onloadend = function () {
      var headers = {
        "Content-Type": "application/json",
        "Access-Token": secureLocalStorage.getItem('AT_'),
        "Refresh-Token": secureLocalStorage.getItem('RT_')
      };
      var amt = parseFloat(document.getElementById("Topupamount").value);
      var data = {
        "publicid": secureLocalStorage.getItem('PI_'),
        "amount": amt,
        "currency": secureLocalStorage.getItem("CS_"),
        "txaccount": secureLocalStorage.getItem("AN_"),
        "user_image": reader.result,
        "file_type": alt,
        "bankname": document.getElementById("bankName").value,
        "bankcode": document.getElementById("bankCode").value,
        "transactionref": document.getElementById("TransferRef").value,
        "transferdate": document.getElementById("txdate").value,
        "txnotes": document.getElementById("remarks").value,
        "txtypeid": id,
        "isconfirmed": true,
      }
// console.log(data,"data-----------------------------------")
      axios.post(url_local.creditrequest, data, { headers })
        .then(function (response) {
// console.log("topup", response.data);
          if (response.data.Status === 1) {
            $(".modal-title").html("");
            $(".modal-footer").html("");
            $(".modal-body").html("<p class=class_p>" + response.data.Message + ".</p>");
            $(".modal-footer").html("<button id=cancelredirect2>Got it</button>");
            $("#cancelredirect2").addClass("btn btn-block");
            $("#cancelredirect2").on("click", function () {
              history(routeCodes.CREATEFIATACCOUNT);
              secureLocalStorage.setItem("CS_", "");
              secureLocalStorage.setItem("AN_", "");
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
          else if (response.data.Status === 0) {
            if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
              $(".modal-title").html("");
              $(".modal-body").html("<p class=class_p>Your session expired</p>");
              $(".modal-footer").html("");
              $(".modal-footer").html("<button id=signoutbtn>ok</button>");
              $("body").addClass('no-select'); secureLocalStorage.clear();
              $("#signoutbtn").addClass('btn btn-block');
              $("#modalDialog").toggle('show');
              $("#signoutbtn").on('click', function () {
                $("#modalDialog").toggle('hide');
                window.location.href = "/";
              });
            }
           else{
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=cancelredirect1>Got it</button>");
            $("#cancelredirect1").addClass("btn btn-block");
            $("#cancelredirect1").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
           }
          }
          else {
            $(".modal-title").html("");
            $(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=cancelredirect>Got it</button>");
            $("#cancelredirect").addClass("btn btn-block");
            $("#cancelredirect").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
          return;
        })
        .catch(function (err) {
          // setGetdata(true);
          if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
          // secureLocalStorage.clear();
          $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
          $(".modal-title").html("")
          $(".modal-footer").html("<button id=redirect>Got it</button>");
          $("#redirect").addClass("btn btn-block");
          $("#redirect").on("click", function () {
              $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
          }
          else{
              console.warn(err,"NewTopup");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
              $(".modal-title").html("")
              $(".modal-footer").html("");
              $(".modal-footer").html("<button id=closebtn1>ok</button>");
              $("#closebtn1").addClass('btn btn-block');
              $("#closebtn1").on("click", function () {
                  $("#modalDialog").toggle('hide');
              });
              $("#modalDialog").toggle('show');
          }
          return;
        });
    }
  }

  function fileHandlerbtn_click(e) {
    // alert(2);
    e.target.style.display = "none";
    document.getElementById("fileHandler").style.display = "flex";
  }

  function fileHandlerbtnclear_click(e) {
    document.getElementById("fileHandlerbtn").style.display = "block";
    document.getElementById("fileHandler").style.display = "none";
    setPreview({ src: '' });
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    var dateReceived = new Date(date);
    return (
      [
        dateReceived.getFullYear(),
        padTo2Digits(dateReceived.getMonth() + 1),
        padTo2Digits(dateReceived.getDate()),
      ].join('-')
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {/* internal container is div.card below */}
          <div className="card px-4">
            <div className="page-header w-100">
              <h3 className="page-title w-100 row">
                <div className='col-5'>
                  <span className="page-title-icon bg-success me-2">
                    <i className="mdi mdi-cash"></i>
                  </span> Topup Fiat
                </div>
                <div className='col'>
                  <div className="float-end">
                    <div className='text-lastlogin text-end fs-6'>
                      <i className="mdi mdi-account px-2"></i>Last login time
                      <label className='d-block'>{secureLocalStorage.getItem('DT_')}</label>
                    </div>
                  </div>
                </div>
              </h3>
            </div>
            {/* here content is placed */}
            <div className='p-0' id="paymentsection">
              <Tab.Pane eventKey="account_details">
                <BankAccountDetails />
                <div className='row rounded-legend legend-bottom-left pt-2 mx-1'>
                  <div className='col-md-6 col-xs-12'>
                    <label className="form-label">Account Type</label>
                    <p className="class_p card-description font-bold ">
                      <span id="accountTypeselect">{secureLocalStorage.getItem("CS_")}</span>
                      <a className='mx-2 btn btn-sm settingsBtn' href={routeCodes.CREATEFIATACCOUNT}>
                        Change
                      </a>
                    </p>
                    <p>{secureLocalStorage.getItem("AN_")}</p>
                  </div>
                  <div className="col-md-6 col-xs-12 ">
                    <label className="card-description text-label font-bold">Accountant Name</label>
                    <p id="name">{secureLocalStorage.getItem('FN_')}</p>
                  </div>
                </div>
                <div className="card-body rounded-legend legend-bottom-left pt-2">
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label class_p">Bank Name *</label>
                        <div className="col-sm-9">
                          <input type="text" name="bankName" className="form-control form-control-sm" id="bankName" required maxLength="50" pattern="([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+){3,}"/>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label class_p">Bank Code *</label>
                        <div className="col-sm-9">
                          <input type="text" name="bankCode" id="bankCode" className="form-control form-control-sm" required maxLength="50" pattern="([a-zA-Z0-9 .-]){1,50}" title='Must proper and not be longer than 50 characters'/>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label class_p">Transfer Date *</label>
                        <div className="col-sm-9">
                          <input type="date" id="txdate" placeholder="" max={formatDate(new Date())} className="form-control form-control-sm mb-2" name="txdate" required />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label class_p">Transferred Amount *</label>
                        <div className="col-sm-9">
                          <input type="number" id="Topupamount" placeholder="" min="1" max="99999" className="form-control form-control-sm" name="Topupamount" required />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label class_p">Reference No. *</label>
                        <div className="col-sm-9">
                          <input type="text" id="TransferRef" placeholder="" min="0" className="form-control form-control-sm mb-2" name="TransferRef" required maxLength="50" pattern="([a-zA-Z0-9 ,'.-]){3,50}" title='Must proper and not be longer than 50 characters'/>
                        </div>
                      </div>

                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="mb-3">
                        <label className="col-form-label class_p">Upload Receipt *</label>
                        <div>
                          <img id="profile_photo" alt="receipt"
                            className="img-thumbnail mx-auto mb-3"
                            style={{ maxHeight: "150px" }}
                            onError={(e) => {
                              e.target.src = 'https://via.placeholder.com/150'
                              e.target.style = 'outline: 0px; margin: 0px; max-height:150px;' // inline styles in html format
                            }}
                            src={src}
                          />
                        </div>
                        <div className="addNew">
                          <button type="button" className="btn btn-sm" id="fileHandlerbtn" onClick={fileHandlerbtn_click} >+ Add</button>
                          <div className="input-group" id="fileHandler" style={{ display: 'none' }}>
                            <input accept="image/*" type="file" onChange={fileHandler} className="form-control h-100" readOnly />
                            <button type="button" className="btn btn-outline-secondary image-preview-clear h-100"
                              id="fileHandlerbtnclear" onClick={fileHandlerbtnclear_click}>
                              <i className="fa fa-remove"></i> Clear
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group row">
                        <label className="col-form-label class_p">Remarks(optional)</label>
                        <div className="col-12">
                          <textarea col="10" rows="4" type="text" id="remarks" className="form-control form-control-sm" maxLength={200} pattern="([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{0,200})" title='Must proper and not be longer than 200 characters'></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-sm btn-yodigi-buy mb-3"
                     onClick={validation} id="201">Continue</button>
                     <a className='btn btn-block mx-2 mb-3'
                      href={routeCodes.CREATEFIATACCOUNT}>Cancel</a>
                  </div>
                </div>
              </Tab.Pane>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

