import React, { useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
// import image from '../../assets/images/web/logo.svg';
import miniLogo from '../../assets/images/web/icon.svg';

import routeCodes from '../../Paths';
import '../../assets/styles/web/css/style.css';
import url_local from '../url_local';
// import { GlobalContext } from '../shared/useForm';
import secureLocalStorage from 'react-secure-storage';
import logo from '../../assets/images/web/logo.svg';
import logo_black from '../../assets/images/web/logo_black.svg';

function Navbar() {
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  const initialState = { src: "" };

  const [{ src }, setPreview] = React.useState(initialState);
  const [img_logo, setImg_logo] = React.useState(logo);
  const [theme_toggle, setTheme_toggle] = React.useState('mdi mdi-weather-sunny fs-3 px-2');
  const media = React.useRef("media/")
  useEffect(() => {
    let current = localStorage.getItem("theme");
		if (current === "light-theme"){
      setImg_logo(logo_black);
      setTheme_toggle('mdi mdi-weather-night fs-3 px-2')
      delete document.documentElement.dataset.bsTheme;
		}
		else{
			setImg_logo(logo);
      setTheme_toggle('mdi mdi-weather-sunny fs-3 px-2')
      document.documentElement.dataset.bsTheme = "dark";
		}
    if (!url_local.home.startsWith("http://192.168",0)){
			media.current = "";
			console.log("clear media/",url_local.home);
		}

    const profilePicture = secureLocalStorage.getItem('PP_');
    if (profilePicture === null){
      var headers = {
        "Content-Type": "application/json",
        'Access-Token': secureLocalStorage.getItem('AT_'),
        "Refresh-Token": secureLocalStorage.getItem('RT_')
      };
      let payload = { publicid: secureLocalStorage.getItem('PI_') }
      axios.post(url_local.kyc, payload, headers)
        .then(function (response) {
          // setLoading(false);
          if (response.data.Status === 1) {
            try {
              document.getElementById("profile_photo").setAttribute("src", url_local.home + media.current+response.data.Data.profilepicture);
              document.getElementById("profile_photo").setAttribute("alt", media.current+response.data.Data.profilepicture);
              setPreview({
                src: url_local.home + media.current+response.data.Data.profilepicture,
                // profiledata:'',
              });
              secureLocalStorage.setItem('PP_',url_local.home +media.current+ response.data.Data.profilepicture);
            } catch (e) {
              // console.log('Error: response.data.Data.profilepicture');
            }
          }
          else if (response.data.Status === 0) {
            if (response.data.Code === 3 || response.data.Code === 7) {
              $(".modal-title").html("");
              $(".modal-body").html("<p class=class_p>Your session expired.</p>");
              $(".modal-footer").html("");
              $(".modal-footer").html("<button id=closesessionclosebtn>ok</button>");
              $("#closesessionclosebtn").addClass('btn btn-block');
              $("body").addClass('no-select'); secureLocalStorage.clear();
              $("#closesessionclosebtn").on("click", function () {
                $("#modalDialog").toggle('hide');
                window.location.href = "/";
              });
              if($("#modalDialog" ).hasClass( "show" )){
                $(".main-panel").css("filter","blur(8px)");
                $("#modalDialog").toggle('show');
              }
              else{
                $(".main-panel").css("filter","blur(8px)");
                $("#modalDialog").toggle('show');
              }
            }
            else {
              $(".modal-title").html("");
              $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
              $(".modal-footer").html("");
              $(".modal-footer").html("<button id=closebtn>ok</button>");
              $("#closebtn").addClass('btn btn-block');
              $("#closebtn").on("click", function () {
                $("#modalDialog").toggle('hide');
              });
              $("#modalDialog").toggle('show');
            }
          }
          else {
            $(".modal-title").html("")
            $(".modal-body").html("<p class='class_p text-danger'>Something went wrong. Try again.</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=ok>OK</button>");
            $("#ok").addClass("btn btn-block");
            $("#ok").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
          return;
        })
        .catch(function (err) {
          console.error("err",err);
          if (err.response) {
              // The client was given an error response (5xx, 4xx)
              if (err.response.status === 500) {
                $(".modal-title").html("")
                $(".modal-body").html("<p class='class_p'><!-- Navbar.js -->Sorry, we have encountered server issue. Try again later!</p>");
                $(".modal-title").html("")
                $(".modal-footer").html("");
                $(".modal-footer").html("<button id=ok>OK</button>");
                $("#ok").addClass("btn btn-block");
                $("#ok").on("click", function () {
                  $("#modalDialog").toggle('hide');
                });
                $("#modalDialog").toggle('show');
              }
          } else if (err.request) {
              // The client never received a response, and the request was never left
              $(".modal-title").html("")
              $(".modal-body").html("<p class='class_p'><!-- Navbar.js -->Sorry, we could not get response. Try again later!</p>");
              $(".modal-title").html("")
              $(".modal-footer").html("");
              $(".modal-footer").html("<button id=ok>OK</button>");
              $("#ok").addClass("btn btn-block");
              $("#ok").on("click", function () {
                $("#modalDialog").toggle('hide');
              });
              $("#modalDialog").toggle('show');
          } else {
            // setGetdata(true);
            if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
            // secureLocalStorage.clear();
            $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("<button id=redirect>Got it</button>");
            $("#redirect").addClass("btn btn-block");
            $("#redirect").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
            }
            else{
                console.warn(err,"Navbar");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
                $(".modal-title").html("")
                $(".modal-footer").html("");
                $(".modal-footer").html("<button id=closebtn1>ok</button>");
                $("#closebtn1").addClass('btn btn-block');
                $("#closebtn1").on("click", function () {
                    $("#modalDialog").toggle('hide');
                });
                $("#modalDialog").toggle('show');
            }
          }
          return;
        });
    }
    else{
      setPreview({
        src: profilePicture,
        // profiledata:'',
      });
    }
    // loadData();
  }, []);


  // const gContext = React.useContext(GlobalContext);
  const LogoutAccount = () => {
    $(".modal-title").html("Confirm Logout");
    $(".modal-body").html("<p class=class_p>Are you sure you want to log-off?</p>");
    $(".modal-footer").html("");
    $(".modal-footer").html("<button id=logoutbtn>Logout</button><button id=cancel_logout>Cancel</button>");
    $("body").addClass('no-select');
    $("#logoutbtn").addClass('btn btn-sm btn-yodigi-danger');
    $("#cancel_logout").addClass('btn btn-sm btn-default');
    $("#logoutbtn").on('click', function () {
      secureLocalStorage.clear();
      $("#modalDialog").toggle('hide');
      window.location.href = "/";
      return;
      // if (gContext) { gContext.signout(); return; }
      // let public_ID = secureLocalStorage.getItem('PI_');
      // if (public_ID == null) {
      //   localStorage.setItem('referTodashboard', false);
      //   gContext.signout(); return;
      // }
      // // console.log(`public_ID`, public_ID)
      // let headers = {
      //   "Content-Type": "application/json",
      //   'Access-Token': secureLocalStorage.getItem('AT_'),
      //   'Refresh-Token': secureLocalStorage.getItem('RT_')
      // }

      // axios.post(url_local.logout, { publicid: public_ID },
      //   { headers })
      //   .then(function (response) {
      //     // console.log(response.data);
      //     if (response.data.Status === 1) {
      //       if (gContext) {
      //         gContext.signout();
      //         return;
      //       }
      //     }
      //     else if (response.data.Status === 0) {
      //       $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
      //       $(".modal-title").html("")
      //       $(".modal-footer").html("");
      //       $(".modal-footer").html("<button id=redirect>Got it</button>");
      //       $("#redirect").addClass("btn btn-block");
      //       $("#redirect").on("click", function () {
      //         $("#modalDialog").toggle('hide');
      //       });
      //       $("#modalDialog").toggle('show');
      //     }
      //     else {
      //       $(".modal-body").html("<p className='text-danger'>Something went wrong. Try again.</p>");
      //       $(".modal-title").html("")
      //       $(".modal-footer").html("");
      //       $(".modal-footer").html("<button id=redirect>Got it</button>");
      //       $("#redirect").addClass("btn btn-block");
      //       $("#redirect").on("click", function () {
      //         $("#modalDialog").toggle('hide');
      //       });
      //       $("#modalDialog").toggle('show');
      //     }
      //   })
      //   .catch(function (error) {
      //     $(".modal-body").html("<p className=text-danger>Something went wrong. Try again.</p>");
      //     $(".modal-title").html("")
      //     $(".modal-footer").html("");
      //     $(".modal-footer").html("<button id=redirect1>Got it</button>");
      //     $("#redirect1").addClass("btn btn-block");
      //     $("#redirect1").on("click", function () {
      //       $("#modalDialog").toggle('hide');
      //     });
      //     $("#modalDialog").toggle('show');
      //     return;
      //   });
    });
    $('#cancel_logout').on('click', function () {
      $("#modalDialog").toggle('hide');
    });
    $("#modalDialog").toggle('show');


  }

  const toggleTheme = () => {
    let current = localStorage.getItem("theme")
    const body = document.body
    if (current === "light-theme"){
      localStorage.setItem('theme', "dark-theme");
		  body.classList.add('dark-mode');
      setImg_logo(logo);
      setTheme_toggle('mdi mdi-weather-sunny fs-3 px-2')
      $("#bottom_logo").attr("src", logo);
    }
    else if (current === "dark-theme"){
      localStorage.setItem('theme', "light-theme");
		  body.classList.remove('dark-mode');
      setImg_logo(logo_black);
      setTheme_toggle('mdi mdi-weather-night fs-3 px-2')
      $("#bottom_logo").attr("src", logo_black);
    }
    else if (current === null){
      localStorage.setItem('theme', "dark-theme");
      body.classList.add('dark-mode');
      setImg_logo(logo);
      setTheme_toggle('mdi mdi-weather-sunny fs-3 px-2');
      $("#bottom_logo").attr("src", logo);
    }
    else{
      localStorage.setItem('theme', "dark-theme");
      body.classList.add('dark-mode');
      setImg_logo(logo);
      setTheme_toggle('mdi mdi-weather-sunny fs-3 px-2');
      $("#bottom_logo").attr("src", logo);
    }
  }
  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row mb-5"
      style={{ borderBottom: "1px solid #292c37" }}>
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo" to={routeCodes.OVERVIEW}><img src={img_logo} alt="logo" /></Link>
        <Link className="navbar-brand brand-logo-mini" to={routeCodes.OVERVIEW}>
          <img src={miniLogo} alt="logo" /></Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <button className="navbar-toggler navbar-toggler align-self-center" type="button"
          onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
          <span className="mdi mdi-menu"></span>
        </button>
        <button className="nav-link  align-self-center" type="button" onClick={toggleTheme}>
          <span className={theme_toggle}></span>
        </button>

        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile">
            <Link className="nav-link mb-2" to={routeCodes.EXCHANGEDASHBOARD}>Exchange</Link>
          </li>
          <li className="nav-item nav-profile">
            <Dropdown alignRight>
              <Dropdown.Toggle className="navbarlinks">
                <p className="class_p mb-1">Wallet</p>
              </Dropdown.Toggle>
              <Dropdown.Menu className="navbar-dropdown">
                <Dropdown.Item href={routeCodes.WALLET}>
                  <i className="mdi mdi-view-dashboard mx-1 class_p"></i><p className="class_p m-1 ">Overview</p>
                </Dropdown.Item>
                {/* <Dropdown.Item href={routeCodes.ACCOUNTS}>
                    <i className="mdi mdi-wallet mx-1 class_p"></i><p className="class_p m-1">Funding Wallet</p>
                  </Dropdown.Item> */}
                <Dropdown.Item href={routeCodes.CREATEFIATACCOUNT}>
                  <i className="mdi mdi-cash mx-1 class_p"></i><p className="class_p m-1">Fiat</p>
                </Dropdown.Item>
                <Dropdown.Item href={routeCodes.CREATECRYPTOACCOUNT}>
                  <i className="mdi mdi-coin mx-1 class_p"></i><p className="class_p m-1">Crypto</p>
                </Dropdown.Item>

                <Dropdown.Item href={routeCodes.TRANSACTIONS}>
                  <i className="mdi mdi-history mx-1 class_p"></i><p className="class_p m-1">Transaction History</p>
                </Dropdown.Item>
                <Dropdown.Item href={routeCodes.REPORTS}>
                  <i className="mdi mdi-file-document-box mx-1 class_p"></i><p className="class_p m-1">Account Statement</p>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="nav-item nav-profile">
            <Dropdown alignRight>
              <Dropdown.Toggle className="navbarlinks">
                <div className="nav-profile-img">
                  <img id="profile_photo" alt='' className="img-thumbnail-navbar mx-auto"
                    onError={(e) => {
                      e.target.src = 'https://via.placeholder.com/150'
                      e.target.style = 'outline: 0px; margin: 0px; max-height:50px;' // inline styles in html format
                    }}
                    src={src}
                  />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="navbar-dropdown">
                <Dropdown.Item href={routeCodes.DASHBOARD}>
                  <i className="mdi mdi-view-dashboard mx-1 class_p"></i><p className="class_p m-1">Market Rates</p>
                </Dropdown.Item>
                <Dropdown.Item href={routeCodes.SETTINGS}>
                  <i className="mdi mdi-settings mx-1 class_p"></i><p className="class_p m-1">My Profile</p>
                </Dropdown.Item>
                <Dropdown.Item onClick={LogoutAccount}>
                  <i className="mdi mdi-logout mx-1 class_p"></i><p className="class_p m-1">Signout</p>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>

        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
