import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';

//import css
import '../../web/web.scss';
import '../../../assets/styles/web/css/style.css';

import routeCodes from '../../../Paths';
import image from '../../../assets/images/web/logo.svg';
// import miniLogo from '../../../assets/images/web/icon.svg';

export default function WebHeaderEX()
    {
    React.useEffect(()=>
    {
        // let current = localStorage.getItem("theme");
        // const body = document.body
        // if (current == "light-theme"){
        //     localStorage.setItem('theme', "dark-theme");
        //     body.classList.add('dark-mode');
        // }
        // else if (current == null){
        //     localStorage.setItem('theme', "dark-theme");
        //     body.classList.add('dark-mode');
        // }
        // else{
        //     localStorage.setItem('theme', "light-theme");
        //     body.classList.remove('dark-mode')
        // }
        document.getElementById("mySidebar").style.width = "0";
        document.getElementById("mySidebar").style.marginRight = "0";
    });
    const handleClose=()=>
        {
        document.getElementById('menulist').classList.remove('show');
        };
    const openNav=()=> {
        document.getElementById("mySidebar").style.width = "320px";
        document.getElementById("mySidebar").style.marginRight = "320px";
        document.getElementById("togglerbtn").style.display = "none";
        }
    const closeNav=()=> {
        document.getElementById("mySidebar").style.width = "0";
        document.getElementById("mySidebar").style.marginRight = "0";
        document.getElementById("togglerbtn").style.display = "block";
        }

    return(
    <>
    <Navbar id="nav" expand="lg" className="navdark">
        <Link className="navbar-brand" to={'/'}>
            <img className='image' src={image} alt="Meezan" />
        </Link>

        <button className="d-lg-none d-xs-block btn class_p" id="togglerbtn"
            onClick={openNav}><span className='mdi mdi-menu'></span></button>
        <Navbar.Collapse id="menulist" className='d-lg-block d-xs-none'>
            <Nav className="ms-left ml-auto" >
                <Nav.Item><Nav.Link className="nav-Nav.link mainPagelinks px-3" href="/">Home</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link className="nav-Nav.link mainPagelinks px-3" href={"#"} onClick={handleClose}>News</Nav.Link></Nav.Item>
                {/* <Nav.Item><Nav.Link className="nav-Nav.link mainPagelinks px-3" href={"#secure_apps"} onClick={handleClose}>Wallet</Nav.Link></Nav.Item> */}
                <Nav.Item><Nav.Link className="nav-Nav.link mainPagelinks px-3" href={routeCodes.LOGIN}>Start trading</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link className="btn-sm btn-yodigi-buy fw-bold" href={routeCodes.SIGNUP}>Sign Up</Nav.Link></Nav.Item>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
    <div id="mySidebar" className="sidebarhome d-lg-none">
    {/* <Link className="navbar-brand brand-logo-mini" to={routeCodes.HOMELOGGED}>
            <img  src={miniLogo}  alt="logo"/></Link> */}
        <span className="closebtn"  onClick={closeNav} style={{cursor:"pointer"}}>&times;</span>
        <div className="ms-left" onClick={closeNav}>
            <a className="nav-Nav.link  p-3 mx-2" href="/"><i className="mdi mdi-home mx-1 "></i>Home</a>
            <a className="nav-Nav.link  p-3 mx-2" href="/"><i className="mdi mdi-finance mx-1 "></i>Market Rates</a>
            <a className="nav-Nav.link  p-3 mx-2" href="/" onClick={handleClose}><i className="mdi mdi-newspaper mx-1 "></i>News</a>
            {/* <a className="nav-Nav.link  p-3 mx-2" href={"#secure_apps"} onClick={handleClose}><i className="mdi mdi-wallet mx-1 "></i>Wallet</a> */}
            <a className="nav-Nav.link  p-3 mx-2" href={routeCodes.LOGIN}><i className="mdi mdi-cash mx-1 "></i>Start Trading</a>
            <a className="nav-Nav.link  p-3 mx-2" href={routeCodes.SIGNUP}><i className="mdi mdi-account-key mx-1 "></i>Sign Up</a>
        </div>
    </div>
    </>
)
}