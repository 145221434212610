import React, { useEffect } from "react";
// import { Tab, Nav } from 'react-bootstrap';
import axios from 'axios';
import $ from 'jquery';


//import components
import url_local from '../url_local';
import GetFiatAccounts from '../users/wallet/GetFiatAccounts';
// import GetCryptoAccounts from '../users/wallet/GetCryptoAccounts';
// import CryptoAccountStatement from '../dashboard/CryptoAccountStatement';
import secureLocalStorage from 'react-secure-storage';
// import { tabClasses } from "@mui/material";

export default function AccountStatement() {
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  $.DataTable = require('datatables.net');
  $.DataTable = require('datatables.net-buttons');
  $.DataTable = require('datatables.net-buttons/js/buttons.html5.min');
  $.DataTable = require('datatables.net-buttons/js/buttons.print.min');

  useEffect(() => {
    $.fn.dataTableExt.sErrMode = 'none';
    $.extend($.fn.dataTable.defaults, {
      responsive: true
    });
    const node = document.getElementsByClassName("dataTables_empty");
    for (var i = 0; i < node.length; i++) {
      node[i].innerHTML = "No Records Found...";
    }
  }, [])

  const getReportFiat = (event) => {
    if (document.getElementById("fiat_from_date").value === "") {
      $(".modal-body").html("<p className=class_p>Please select start date</p>");
      $(".modal-title").html("")
      $("#modalDialog").toggle('show');
    }
    else if (document.getElementById("fiat_till_date").value === "") {
      $(".modal-body").html("<p className=class_p>Please select end date</p>");
      $(".modal-title").html("")
      $("#modalDialog").toggle('show');
    }
    else {
      getallreports(event.target.id);
    }
  }

  // const getReportCrypto = (event) => {
  //   if (document.getElementById("crypto_from_date").value === "") {
  //     $(".modal-body").html("<p className=class_p>Please select start date</p>");
  //     $(".modal-title").html("")
  //     $("#modalDialog").toggle('show');
  //   }
  //   else if (document.getElementById("crypto_till_date").value === "") {
  //     $(".modal-body").html("<p className=class_p>Please select end date</p>");
  //     $(".modal-title").html("")
  //     $("#modalDialog").toggle('show');
  //   }
  //   else {
  //     getallreports(event.target.id);
  //   }
  // }

  function getallreports(type) {
    secureLocalStorage.setItem("AAC_","");
    secureLocalStorage.setItem("FD_", "");
    secureLocalStorage.setItem("TD_", "");
    // window.clear("table2");
    // var accounttype =  document.getElementById("accounts").value;
    var datestart = new Date();
    var month = '1';
    var day = '1' + datestart.getDate();
    var year = 2023;
    var from_date = [year, month, day].join('-');
    var till_date = [year, month, day].join('-');
    var dateend = new Date();
    var parameters = {}
    var headers = {
      "Access-Token": secureLocalStorage.getItem('AT_'),
      "Refresh-Token": secureLocalStorage.getItem('RT_')
    };
    if (type === 'fiat') {
      datestart = new Date(document.getElementById("fiat_from_date").value);
      month = '' + (datestart.getMonth() + 1);
      day = '' + datestart.getDate();
      year = datestart.getFullYear();

      if (month <= 9)
        month = '0' + month;
      if (day <= 9)
        day = '0' + day;
      from_date = [year, month, day].join('-');

      dateend = new Date(document.getElementById("fiat_till_date").value);

      month = '' + (dateend.getMonth() + 1);
      day = '' + dateend.getDate();
      year = dateend.getFullYear();


      if (month <= 9)
        month = '0' + month;
      if (day <= 9) {
        day = '0' + day;
      }
      till_date = [year, month, day].join('-');
      parameters = {
        "publicid": secureLocalStorage.getItem('PI_'),
        "accountno": $("#fiataccounts").find("option:selected").attr('data-accountno'),
        "fromdate": from_date,
        "tilldate": till_date,
        "accounttype": $("#fiataccounts").find("option:selected").attr('value')
      }
    }
    else {
      datestart = new Date(document.getElementById("crypto_from_date").value);
      month = '' + (datestart.getMonth() + 1);
      day = '' + datestart.getDate();
      year = datestart.getFullYear();

      if (month <= 9)
        month = '0' + month;
      if (day <= 9)
        day = '0' + day;
      from_date = [year, month, day].join('-');

      dateend = new Date(document.getElementById("crypto_till_date").value);

      month = '' + (dateend.getMonth() + 1);
      day = '' + dateend.getDate();
      year = dateend.getFullYear();


      if (month <= 9)
        month = '0' + month;
      if (day <= 9) {
        day = '0' + day;
      }
      till_date = [year, month, day].join('-');
      parameters = {
        "publicid": secureLocalStorage.getItem('PI_'),
        "accountno": $("#cryptoaccounts").find("option:selected").attr('data-accountno'),
        "fromdate": from_date,
        "tilldate": till_date,
        "accounttype": type
      }
    }
    axios.post(url_local.accountstatements,parameters,{ headers })
      .then(function (response) {
        if (response.data.Status === 1) {
          document.getElementById("accountstatement").style.display = 'block';
          var creditamount, balance, debitamount, servicecharge,transactionnotes;
          // var fromdate = new Date(response.data.Data.from);
          // fromdate = fromdate.toString();
          var d = new Date(response.data.Data.from);
          var fromdateString = new Intl.DateTimeFormat('en-US').format(d); // d.toLocaleString().split('t')[0];

          // var todate = new Date(response.data.Data.till);
          // todate = todate.toString();
          d = new Date(response.data.Data.till);
          var todatestring = new Intl.DateTimeFormat('en-US').format(d); // d.toLocaleString().split('t')[0];

          document.getElementById("AAC_").innerHTML = response.data.Data.account;
          document.getElementById("CLB_").innerHTML = response.data.Data.closing_balance;
          document.getElementById("OPB_").innerHTML = response.data.Data.opening_balance;
          document.getElementById("FD_").innerHTML = fromdateString;
          document.getElementById("TD_").innerHTML = todatestring;
          let table = $('#tbl_accountStatement').DataTable(
            {
              columnDefs: [
                { "width": "30%", target: 2 },
                {
                  target:3,className: 'dt-body-right dt-head-right'
                },
                {
                  target:4,className: 'dt-body-right dt-head-right'
                },
                {
                  target:5,className: 'dt-body-right dt-head-right'
                },
              ],
              dom: 'Blfrtip',
              buttons: [{
                  text: 'Download',
                  extend: 'pdfHtml5',
                  message: '',
                  title:'Account Statement',
                  messageTop: "Account No. : "+response.data.Data.account+"\r\nPeriod covered : "+from_date+" to "+ till_date+"\r\nOpening Balance : "+response.data.Data.opening_balance+"\r\nClosing Balance : "+response.data.Data.closing_balance,
                  messageBottom:[``,{text:`Total Remaining Credits: `+response.data.Data.closing_balance, alignment:'right' }],
                  orientation: 'landscape',
                  styles:{
                    tableHeader:{
                      alignment:'center',
                      margin:10,
                    }
                  },
                  customize: function (doc) {
                    doc.pageSize = 'A4';
                    doc.pageOrientation = 'landscape';
                    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                    doc.pageMargins = [40,60];
                    doc.defaultStyle.fontSize = 10;
                    doc.styles.tableHeader.fontSize = 11;
                    doc.styles.title.fontSize = 12;
                    // Remove spaces around page title

                    // Create a footer
                    doc['footer']=(function(page, pages) {
                        return {
                            columns: [
                                'Downloaded from ' + window.location.href,
                                {
                                    // This is the right column
                                    alignment: 'right',
                                    text: ['page ', { text: page.toString() },  ' of ', { text: pages.toString() }]
                                }
                            ],
                            // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                            margin: [40,20]
                        }
                    });
                    doc['header']=(function(page, pages) {
                      return {
                          columns: [
                              'Meezan - Online Statement',
                              {
                                  // This is the right column
                                  alignment: 'right',
                                  text: new Date().toLocaleString() + ""
                              }
                          ],
                          // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                          margin: [40, 40,40,20]
                      }
                    });
                    // doc.content[0].layout['width'] = "100%";
                    const tbl = doc.content[2];
                    tbl.table.widths = ["auto","auto","*","auto","auto","auto"];
                    // tbl.alignment = 'right';

                    var rowCount = tbl.table.body.length;
                    for (var i = 0; i < rowCount; i++) {
                      tbl.table.body[i][0].alignment = 'center';
                      tbl.table.body[i][2].alignment = 'left';
                    };
                    doc.styles.tableHeader.alignment = 'center';
                    doc.styles.tableHeader.margin = [10, 0];
                    doc.styles.tableBodyOdd.alignment = 'right';
                    doc.styles.tableBodyEven.alignment = 'right';
                    doc.styles.tableBodyOdd.margin = [15, 0];
                    doc.styles.tableBodyEven.margin = [15, 0];
                    doc.content[3].margin = [15,10];
                    console.dir(doc.content);
                  }
                }
              ]
            }
          );
          table.clear();
          for (let i = 0; i < response.data.Data.Transactions.length; i++) {
            var date = new Date(response.data.Data.Transactions[i].date);
            date = date.toString();
            d = new Date(response.data.Data.Transactions[i].date);
            var dateString = d.toLocaleString().split('t')[0];

            if (response.data.Data.Transactions[i].type === "Cr") {
              creditamount = response.data.Data.Transactions[i].amount.toFixed(2);
              debitamount = "";
              balance = response.data.Data.Transactions[i].balance.toFixed(2);
              servicecharge = "";
              transactionnotes = response.data.Data.Transactions[i].transactionnotes;
            }
            else if(response.data.Data.Transactions[i].type === "Dr"){
              if(response.data.Data.Transactions[i] !== undefined){
                creditamount = "";
                console.log(response.data.Data.Transactions[i]);
                debitamount = response.data.Data.Transactions[i].totaldebit.toFixed(2) + "\r\n<br /><br />" + servicecharge;
                balance = response.data.Data.Transactions[i].balance.toFixed(2);
                servicecharge =  response.data.Data.Transactions[i].servicecharge.toFixed(2);
                transactionnotes = response.data.Data.Transactions[i].transactionnotes + "\r\n<br /><br />Service Charge";
              }
            }
            else{
              transactionnotes = response.data.Data.Transactions[i].transactiontype +"\r\n<br /><br />"+ response.data.Data.Transactions[i].transactionnotes;
              // continue;
            }


            table.row.add(
              [
                i + 1,
                dateString,
                transactionnotes,
                creditamount,
                debitamount,
                balance
              ]
            );
          }
          table.draw();
          table.on( 'draw', function () {
            const node = document.getElementsByClassName("dataTables_empty");
            for (var i = 0; i < node.length; i++) {
              node[i].innerHTML = "No Records Found...";
            }
          });
        }
        else if (response.data.Status === 0) {
          document.getElementById("accountstatement").style.display = 'none';
          if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
            $(".modal-body").html("<p class=class_p>Your session expired.");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=redirect>ok</button>");
            $("body").addClass('no-select'); secureLocalStorage.clear();
            $("#redirect").addClass("btn btn-block");
            $("#redirect").on("click", function () {
              window.location.href = "/";
              $("#modalDialog").toggle('hide');
            });
            if($("#modalDialog" ).hasClass( "show" )){
              $(".main-panel").css("filter","blur(8px)");
              $("#modalDialog").toggle('show');
            }
            else{
              $(".main-panel").css("filter","blur(8px)");
              $("#modalDialog").toggle('show');
            }
          }
          else {
            // window.clear("table2");
            $(".modal-title").html("");
            $(".modal-body").html("<p class='class_p'>" + response.data.Message + "</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=cancelredirect>Got it</button>");
            $("#cancelredirect").addClass("btn btn-block");
            $("#cancelredirect").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
        }
        else {
          document.getElementById("accountstatement").style.display = 'none';
          $(".modal-title").html("");
          $(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=cancelredirect1>Got it</button>");
          $("#cancelredirect1").addClass("btn btn-block");
          $("#cancelredirect1").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
      })
      .catch(function (err) {
        // setGetdata(true);
        if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
        // secureLocalStorage.clear();
        $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("<button id=redirect>Got it</button>");
        $("#redirect").addClass("btn btn-block");
        $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
        }
        else{
            console.warn(err,"AccountStatement,js");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn1>ok</button>");
            $("#closebtn1").addClass('btn btn-block');
            $("#closebtn1").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
        }
        return;
      });


  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    var dateReceived = new Date(date);
    return (
      [
        dateReceived.getFullYear(),
        padTo2Digits(dateReceived.getMonth() + 1),
        padTo2Digits(dateReceived.getDate()),
      ].join('-')
    );
  }

  return (
    <div className="row">
      <div className="col-12">
        {/* internal container is div.card below */}
        <div className="card px-4">
          <div className="page-header w-100">
            <h3 className="page-title w-100 row">
              <div className='col-5'>
                <span className="page-title-icon bg-success me-2">
                  <i className="mdi mdi-file-document"></i>
                </span>Account Statement
              </div>
              <div className='col'>
                <div className="float-end">
                  <div className='text-lastlogin text-end fs-6'>
                    <i className="mdi mdi-account px-2"></i>Last login time
                    <label className='d-block'>{secureLocalStorage.getItem('DT_')}</label>
                  </div>
                </div>
              </div>
            </h3>
          </div>
          {/* here content is placed */}
          <div className="card-body">
            <div className="row mt-2">
              <div className="mb-3 col-md-3 col-xl-2 col-xs-12">
                <label className="form-label">From Date</label>
                <input id="fiat_from_date" name="from_date" className="form-control form-control-sm " type="date" defaultValue={formatDate(
                  new Date().setDate(new Date().getDate() - 30)
                )} max={formatDate(new Date())} />
              </div>
              <div className="mb-3 col-md-3 col-xl-2 col-xs-12">
                <label className="form-label">To Date</label>
                <input id="fiat_till_date" name="till_date" className="form-control form-control-sm " type="date" defaultValue={formatDate(Date.now())} max={formatDate(new Date())} />
              </div>
              <div className='mb-3 col-md-2 col-xs-12 col-sm-12'>
                <label className="form-label">Choose an Account</label>
                <GetFiatAccounts id="fiataccounts" className="form-select form-select-sm " />
              </div>
              <div className='mb-3 mt-4 col-md-2 col-xs-12'>
                <button className="btn btn-sm btn-yodigi-buy mt-2" id="fiat" onClick={getReportFiat}>Get Statement</button>
              </div>
            </div>

            <div id="accountstatement" style={{display:'none'}}>
              <div className="mt-3 d-flex flex-sm-row flex-column">
              <p className="text-center">STATEMENT OF ACCOUNT</p>

                <div className="p-2 ms-auto">
                  <p>Account No. :<span id="AAC_" className="mx-1"></span></p>
                  <p>Period covered :<span id="FD_" className="mx-1"></span>  -  <span id="TD_" className="mx-1"></span></p>
                  <p>Opening Balance : <span id="OPB_"></span></p>
                  <p>Closing Balance : <span id="CLB_"></span></p>
                </div>
              </div>
              <p className="mt-2 mb-2">Transactions</p>
              <div className="d-flex table-responsive mt-3 pt-3" style={{ borderTop: '1px solid rgb(41, 44, 55)' }}>
                <table className="table nowrap w-100" id="tbl_accountStatement" style={{width:"100%"}}>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Transaction Date</th>
                      <th>Description</th>
                      <th>Credit</th>
                      <th>Debit</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}