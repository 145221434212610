import React from 'react';
import $ from 'jquery';
import * as Yup from 'yup';
import axios from 'axios';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import { useFormContext, Controller } from 'react-hook-form';

// @mui
import { Stack, IconButton, InputAdornment  } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import Iconify from '../components/Iconify';
// import { Nationalitylist } from '../shared/Nationalities';
import { FormProvider, RHFTextField, PhoneCodes, RHFSelect} from '../components/hook-form';
import url_local from '../url_local';
import routeCodes from '../../Paths';
// import CountryDialCodes from '../components/hook-form';
import  image from '../../assets/images/web/logo.svg';
import secureLocalStorage from 'react-secure-storage';


// ----------------------------------------------------------------------

export default function RegisterForm() {
 const [getdata,setGetdata] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const [checked, setChecked] = useState(false);

  const history = useNavigate();
  const mousedown = React.useRef(false);
  const mouseover = React.useRef(false);
//   React.useEffect(()=>
//   {

// 	if(secureLocalStorage.getItem('EM_') !== "" || secureLocalStorage.getItem('EM_') !== null )
// 	{
// 		console.log(secureLocalStorage.getItem('EM_'),"email reg")
// 		document.getElementById('email').value = secureLocalStorage.getItem('EM_');
// 	}
// 	else{
// 		// document.getElementById('email').value = "";
// 	}
//   },[]);

const handleChangeCheckbox = (event) =>
{
	if(event.target.checked) {
		// setChecked(event.target.checked);
		document.getElementById("termserror").style.display = "none";
	}
	else{
		// setChecked(event.target.checked);
		document.getElementById("termserror").style.display = "block";
	}
}
const handleEvent = (event) => {
	// console.log(event.type);
	if (event.type === "mousedown") {
		mousedown.current = true;
	}
	else if (event.type === "mouseup") {
		mousedown.current = false;
	}

	if (event.type === "mouseover") {
		mouseover.current = true;
	}
	else if (event.type === "mouseout") {
		mouseover.current = false;
		mousedown.current = false;
	}

	if (mousedown.current && mouseover.current) {
		setShowPassword(true);
	}
	else {
		setShowPassword(false);
	}
}

const handleEvent2 = (event) => {
	// console.log(event.type);
	if (event.type === "mousedown") {
		mousedown.current = true;
	}
	else if (event.type === "mouseup") {
		mousedown.current = false;
	}

	if (event.type === "mouseover") {
		mouseover.current = true;
	}
	else if (event.type === "mouseout") {
		mouseover.current = false;
		mousedown.current = false;
	}

	if (mousedown.current && mouseover.current) {
		setShowConfirmPassword(true);
	}
	else {
		setShowConfirmPassword(false);
	}
}

const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required')
	.matches(/^[aA-zZ \s]+$/, "Only alphabets are allowed"),
    lastName: Yup.string().required('Last name required')
	.matches(/^[aA-zZ \s]+$/, "Only alphabets are allowed"),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
	password:  Yup.string()
	.required("Password is required")
	.min(8, "Min 8 characters, 1 Uppercase, 1 Special Case Character,1 Number required.")
	.matches(/^(?=.*[A-Z])/, 'Must Contain One Uppercase')
	.matches(/^\S*$/,'Whitespace is not allowed')
	.matches(/^(?=.*[!@#$%^&*])/,"Must Contain One Special Case Character")
	.matches(/^(?=.*[0-9])\D*\d/,"Must Contain One Number")
	.max(15),
	confirmpassword:  Yup.string()
	.required("Password is required")
	.min(8, "Min 8 characters, 1 Uppercase, 1 Special Case Character,1 Number required.")
	.matches(/^(?=.*[A-Z])/, 'Must Contain One Uppercase')
	.matches(/^\S*$/,'Whitespace is not allowed')
	.matches(/^(?=.*[!@#$%^&*])/,"Must Contain One Special Case Character")
	.matches(/^(?=.*[0-9])\D*\d/,"Must Contain One Number")
	.max(15),
	mobile: Yup.string().required('Mobile number is required')
	.min(10, "Phone number must be valid").max(19, "Phone number must be valid"),
	nationality:Yup.string().required("Nationality is required"),
	// agreeTerms:Yup.boolean().isFalse("You have to accept Terms & conditions.")
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
	confirmpassword:'',
	nationality:'',
	mobile:'',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;



const onRegister  = (event) => {
	// console.log("im here");
 var password = document.getElementById("password").value;
 var confirmpassword = document.getElementById("confirmpassword").value;

// Get the checkbox
var checkBox = document.getElementById("agreeTerms");



	if(checkBox.checked===true )
	{
	document.getElementById("termserror").style.display = "none";
	if(password !== confirmpassword)
	{
		setGetdata(false);
		$(".modal-title").html("Registeration Failed!");
		$(".modal-body").html("<p class=class_p>Password doesn't matches.</p>");
		$(".modal-footer").html("");
		$(".modal-footer").html("<button id=cancelredirect>Got it</button>");
		$("#cancelredirect").addClass("btn btn-block");
		$("#cancelredirect").on("click", function () {
			$("#modalDialog").toggle('hide');
		});
		$("#modalDialog").toggle('show');
		secureLocalStorage.getItem('EM_',"");
	}
	else{
		setGetdata(true);
		$(".modal-title").html("");
		$(".modal-body").html("");
		var data ={
			'firstname': document.getElementById('firstname').value,
			'lastname':document.getElementById('lastname').value,
			'nationality': document.getElementById("nationality").textContent,
			'email': document.getElementById('email').value,
			'password': document.getElementById('password').value,
			'mobileno':document.getElementById('mobile').value,
		  }
		var headers = { "Content-Type": "application/json"};
		// console.log("payload",data);
		axios.post(url_local.register,data,{headers},)
		.then(function(response) {
			// console.log("response.data",response.data);
		  if(response.data.Status === 1)
		  {
			secureLocalStorage.setItem('EM_',response.data.Data.Email);
			secureLocalStorage.setItem('PI_', response.data.Data.publicid);
			secureLocalStorage.setItem('AT_',response.data.Data["Access-Token"]);
			secureLocalStorage.setItem('RT_',response.data.Data["Refresh-Token"]);
			setGetdata(false);
			$(".modal-title").html("User registered!")
			$(".modal-body").html("<p class=class_p>Please verify your email and mobile number to activate your account</p>");
			$(".modal-footer").html("");
			$(".modal-footer").html("<button id=redirect>Continue</button>");
			$("#redirect").addClass("btn btn-block");
			$("#redirect").on('click',function()
				{
					history(routeCodes.ACTIVE_EMAILOTP,secureLocalStorage.getItem('EM_'));
					$("#modalDialog").toggle('hide');
				});
			$("#modalDialog").toggle('show');
			}
			else if(response.data.Status === 0){
				setGetdata(false);
				$(".modal-title").html("");
				$(".modal-body").html("<p class=class_p>"+response.data.Message+"</p>");
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=redirect>Got it</button>");
				$("#redirect").addClass("btn btn-block");
				$("#redirect").on("click",function()
					{
						$("#modalDialog").toggle('hide');
					});
				$("#modalDialog").toggle('show');
			  }
			else{
				setGetdata(false);
				$(".modal-title").html();
				$(".modal-body").html("<p class='class_p text-danger'>Something went wrong. Try again.</p>");
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=redirect>Got it</button>");
				$("#redirect").addClass("btn btn-block");
				$("#redirect").on("click",function()
					{
						$("#modalDialog").toggle('hide');
					});
				$("#modalDialog").toggle('show');
			  }
		return;
		})
		.catch(function(err) {
			$(".modal-title").html("");
			$(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
			$(".modal-footer").html("");
			$(".modal-footer").html("<button id=cancelredirect>Got it</button>");
			$("#cancelredirect").addClass("btn btn-block");
			$("#cancelredirect").on("click", function () {
				$("#modalDialog").toggle('hide');
			});
			$("#modalDialog").toggle('show');
		});
	}
	}
	else{
		document.getElementById("termserror").style.display = "block";
	}
}
const handleChange = (e) => {
    e.preventDefault();
  };

  return (
	<div className="d-flex align-items-center auth px-0 mt-5 mb-5">
		<div className="row w-100 mx-0">
			<div className="brand-logo text-center mb-3" >
				<Link className="navbar-brand" to={routeCodes.HOME}>
					<img className='image' src={image} alt="Meezan" />
				</Link>
			</div>
			<div className="col-md-6 col-xs-12 mx-auto card">
				<div className="card-body text-left py-3 ">
				<FormProvider methods={methods} onSubmit={handleSubmit(onRegister)}>
					<div className="d-flex flex-column justify-content-center mb-3">
						<h4 className='text-center text-white'>Welcome to Meezan</h4>
						<h6 className="text-center font-weight-light class_p">
							Let's begin the journey!</h6>
					</div>

				<Stack spacing={3} className="mt-3">
					<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
					<RHFTextField name="firstName" placeholder="First name" id="firstname" inputProps={{minLength:2, maxLength: 50}} />
					{/* <RHFTextField name="firstName" placeholder="First name" id="firstname" inputProps={{minLength:2, maxLength: 50, pattern: "/^[aA-zZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ']+$/"}} /> */}
					<RHFTextField name="lastName" placeholder="Last name" id="lastname" inputProps={{maxLength: 50}} />
					{/* <RHFTextField name="lastName" placeholder="Last name" id="lastname" inputProps={{maxLength: 50, pattern: "/^[aA-zZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ']+$/"}} /> */}
				</Stack>
				<RHFTextField name="email" placeholder="Email address" id="email" inputProps={{ className: "text-lowercase",maxLength: 100 }}/>
				<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
				<RHFTextField
				  onCut={handleChange}
				  onCopy={handleChange}
				  onPaste={handleChange}
					name="password"
					id="password"
					placeholder="Password"
					type={showPassword ? 'text' : 'password'}
					InputProps={{
						endAdornment: (
						// <InputAdornment position="end" >
						// 	<IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
						// 	<Iconify style={{ color: '#fff' }} icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
						// 	</IconButton>
						// </InputAdornment>
						<InputAdornment position="end">
							<IconButton onMouseDown={handleEvent} onMouseUp={handleEvent} onMouseOver={handleEvent} onMouseOut={handleEvent} edge="end" tabIndex={-1}>
								<Iconify style={{ color: '#fff' }} icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
							</IconButton>
						</InputAdornment>
						),
					}}
					/>

				<RHFTextField
					name="confirmpassword"
					id="confirmpassword"
					placeholder="Re-enter Password"
					type={showConfirmPassword ? 'text' : 'password'}
					InputProps={{
						endAdornment: (
						// <InputAdornment position="end" >
						// 	<IconButton edge="end" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
						// 	<Iconify style={{ color: '#fff' }} icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
						// 	</IconButton>
						// </InputAdornment>
						<InputAdornment position="end">
							<IconButton onMouseDown={handleEvent2} onMouseUp={handleEvent2} onMouseOver={handleEvent2} onMouseOut={handleEvent2} edge="end" tabIndex={-1}>
								<Iconify style={{ color: '#fff' }} icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
							</IconButton>
						</InputAdornment>
						),
					}}
					/>
				</Stack>

				<Stack spacing={3} className="mt-3">
							{/* <Nationalitylist name="nationality" id="nationality" className=" form-select-lg form-control" /> */}
							<RHFSelect name="nationality" id="nationality"
							 placeholder="Nationality" />
							<PhoneCodes name="mobile" id="mobile" type="tel" placeholder="" />


					</Stack>
					<div className="row" sx={{ my: 2 }}>


					{/* <Controller
						name="terms"
						control={control}
						render={({ fieldState: { error } }) => (
							<Checkbox
							error={!!error}
							helperText={error?.message}
							size="small" />
						)}
						/> */}

					{/* <RHFCheckbox name="terms" id="agreeTerms" /> */}

					<div className="form-check col-12">
						<input type="checkbox" className="form-check-input text-center" name="agreeTerms" id="agreeTerms" onClick={handleChangeCheckbox}  required/>
						<label className="form-check-label m-0" htmlFor="exampleCheck1"> By registering, I agree to Meezan <a className="px-1" href="/#">Terms & conditions</a> and <a className="px-1" href="/#">Privacy policy</a>.</label>
						<p className='class_p text-danger' id="termserror" style={{display:'none'}}>* You have to accept Terms & conditions.</p>
					</div>
					<div className="text-center mt-3">
						{!getdata ?
						<LoadingButton  size="medium" className="btn-sm btn-yodigi-buy" type="submit" variant="contained" loading={isSubmitting}>
							Register
						</LoadingButton>
								:
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					}

					</div>
					<div className="mt-3 font-weight-light col-12 text-center">
					<p className='class_p'>Already have an account? <a
						href={routeCodes.LOGIN}> Sign in.</a></p>
				</div>
				</div>

				</Stack>

    		</FormProvider>

{/*
<FormProvider {...methods} onSubmit={handleSubmit((onRegister))}>
        <label>Test</label>
        <input type="text" {...register("test", { required: true })} />
        <input type="email" {...register("test1", { required: true })} />
        <label>Nested Input</label>
		<input
          {...register("firstName", { required: true })}
          placeholder="First name"
        />
         <input type="email"
          {...register("email", { required: "field is required" })}
          placeholder="email"
        />
        <select  {...register("category", { required: true })}>
          <option value="">Select...</option>
          <option value="A">Category A</option>
          <option value="B">Category B</option>
        </select>

        <input type="submit" />
    </FormProvider> */}


{/* <form onSubmit={handleSubmit(onRegister)}>

<div className="brand-logo text-center" >
					<Link className="navbar-brand" to={routeCodes.HOME}>
						<img className='image' src={image} alt="Meezan" />
					</Link>
                </div>
                <div className="justify-content-center m-2">
                      <h4 className='text-center' style={{color:"#fff"}}>Create a new account</h4>
				</div>

				<Stack spacing={3} className="mt-3">
					<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
					<input className="form-control form-control-sm"
          {...register("firstname", {  required: "This is required.", })}
          placeholder="First name"
        />
		 {errors.lastName && errors.lastName.types && (
        <p>{errors.lastName.types.required}</p>
      )}
		<input className="form-control form-control-sm"
		{...register("lastname", { required: true })}
		placeholder="First name"
	  />

				</Stack>
				<select  className="form-select form-select-sm" {...register("category", { required: true })}>
          <option value="">Select...</option>
          <option value="A">Category A</option>
          <option value="B">Category B</option>
        </select>
				</Stack>


        <input type="submit" className="btn btn-yodigi-buy" />
      </form> */}

			{/* <form class="row g-3 needs-validation" novalidate>
  <div class="col-md-4 position-relative">
    <label for="validationTooltip01" class="form-label">First name</label>
    <input type="text" class="form-control" id="validationTooltip01" value="Mark"
	 required />
    <div class="valid-tooltip">
      Looks good!
    </div>
  </div>
  <div class="col-md-4 position-relative">
    <label for="validationTooltip02" class="form-label">Last name</label>
    <input type="text" class="form-control" id="validationTooltip02" value="Otto"
	required />
    <div class="valid-tooltip">
      Looks good!
    </div>
  </div>
  <div class="col-md-4 position-relative">
    <label for="validationTooltipUsername" class="form-label">Username</label>
    <div class="input-group has-validation">
      <span class="input-group-text" id="validationTooltipUsernamePrepend">@</span>
      <input type="text" class="form-control" id="validationTooltipUsername"
	   aria-describedby="validationTooltipUsernamePrepend" required />
      <div class="invalid-tooltip">
        Please choose a unique and valid username.
      </div>
    </div>
  </div>
  <div class="col-md-6 position-relative">
    <label for="validationTooltip03" class="form-label">City</label>
    <input type="text" class="form-control" id="validationTooltip03" required />
    <div class="invalid-tooltip">
      Please provide a valid city.
    </div>
  </div>
  <div class="col-md-3 position-relative">
    <label for="validationTooltip04" class="form-label">State</label>
    <select class="form-select" id="validationTooltip04" required>
      <option selected disabled value="">Choose...</option>
      <option>...</option>
    </select>
    <div class="invalid-tooltip">
      Please select a valid state.
    </div>
  </div>
  <div class="col-md-3 position-relative">
    <label for="validationTooltip05" class="form-label">Zip</label>
    <input type="text" class="form-control" id="validationTooltip05" required />
    <div class="invalid-tooltip">
      Please provide a valid zip.
    </div>
  </div>
  <div class="col-12">
    <button class="btn btn-primary" type="submit" onClick={onRegister}>Submit form</button>
  </div>
</form> */}
		</div>

		</div></div></div>
  );
}
