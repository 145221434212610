import React  from 'react';
import { Link } from 'react-router-dom';
// import '../../css/style.css';
import logo from '../../../assets/images/web/logo.svg';
import logo_black from '../../../assets/images/web/logo_black.svg';

import '../../web/web.scss';
// import '../css/index.css';
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

function Bottom() {
    console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
    const refer1 = React.useRef('#home');
    const executeScrollBack = () => scrollToRef(refer1);
    const [img_logo, setImg_logo] = React.useState(logo);
    React.useEffect(() => {
		let current = localStorage.getItem("theme");
		if (current === "light-theme"){
            setImg_logo(logo_black);
            delete document.documentElement.dataset.bsTheme;
		}
		else{
			setImg_logo(logo);
            document.documentElement.dataset.bsTheme = "dark";
		}
	}, []);

    return (
            <div className="bottom cardLight text-center mb-5">
                <div className="px-5">
                    <div className="row justify-content-center">



                        <div className="col-xl-3 col-lg-3 col-sm-3 col-md-3">

                             <div className='d-flex flex-column'>
                                    <div className='mb-3'>
                                        <Link className="" to={'/'} onClick={executeScrollBack}>
                                             <img id="bottom_logo" className='img3' src={ img_logo } alt="Meezan" />
                                        </Link>
                                    </div>
                                    <div className='text-left'>
                                        <p style={{fontSize:'15px'}}>
                                                ATTENTION !! Crypto Asset Trading is a high-risk activity.
                                                Crypto asset prices are very volatile, where price can change significantly from time to time.
                                                All crypto asset trading decision are independent decision by the user.
                                        </p>
                                    </div>
                             </div>


                            {/* </div> */}
                        </div>
                        {/* <div className="col-xl-1 col-lg-1 col-sm-1 col-md-1"></div> */}
                        <div className="col-xl-1 col-lg-1 col-sm-1 col-md-1"></div>
                        <div className="col-xl-2 col-lg-2 col-sm-2 col-md-2">
                            <div className="bottom-widget">
                                <h4 className="widget-title text-left">Company</h4>
                                <ul className='footer_links text-left'>
                                    <li><Link to={"#"}>
                                        <i className="mdi mdi-menu-right" ></i>About</Link></li>
                                    <li><Link to={"#"}>
                                    <i className="mdi mdi-menu-right" ></i>Career</Link></li>
                                    <li><Link to={"#"}>
                                    <i className="mdi mdi-menu-right" ></i>Affiliate</Link></li>
                                    <li><Link to={"#"}>
                                    <i className="mdi mdi-menu-right" ></i>Our Partner</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-sm-2 col-md-2">
                            <div className="bottom-widget">
                                <h4 className="widget-title text-left">
                                <i className="mdi mdi-menu-right" ></i>Support</h4>
                                <ul  className='footer_links text-left'>
                                    <li><Link to={"#"}>
                                    <i className="mdi mdi-menu-right" ></i>Ticket</Link></li>
                                    <li><Link to={"#"}>
                                    <i className="mdi mdi-menu-right" ></i>FAQ</Link></li>
                                    <li><Link to={"#"}>
                                    <i className="mdi mdi-menu-right" ></i>Blog</Link></li>
                                    <li><Link to={"#"}>
                                    <i className="mdi mdi-menu-right" ></i>Helpdesk</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-sm-3 col-md-3">
                            <div className="bottom-widget">
                                <h4 className="widget-title text-left">Exchange Pair</h4>
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-sm-6 col-md-6">
                                        <ul  className='footer_links text-left'>
                                            <li><Link to={"#"}>
                                            <i className="mdi mdi-menu-right" ></i>ETH to BTC</Link></li>
                                            <li><Link to={"#"}>
                                            <i className="mdi mdi-menu-right" ></i>BTC to ETH</Link></li>
                                            <li><Link to={"#"}>
                                            <i className="mdi mdi-menu-right" ></i>LTC to ETH</Link></li>
                                            <li><Link to={"#"}>
                                            <i className="mdi mdi-menu-right" ></i>USDT to BTC</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-6 col-md-6">
                                        <ul>
                                            <li><Link to={"#"}>
                                            <i className="mdi mdi-menu-right" ></i>BTC to USDT</Link></li>
                                            <li><Link to={"#"}>
                                            <i className="mdi mdi-menu-right" ></i>LTC to BTC</Link></li>
                                            <li><Link to={"#"}>
                                            <i className="mdi mdi-menu-right" ></i>XMR to BTC</Link></li>
                                            <li><Link to={"#"}>
                                            <i className="mdi mdi-menu-right" ></i>ETC to DASH</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Bottom;