import React from 'react';
import * as Yup from 'yup';
import $ from 'jquery';
import axios from 'axios';
import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import {Link } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
// import { Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../components/hook-form';
// import  image from '../../../assets/images/web/logo.svg';
// import routeCodes from '../../../Paths';
import url_local from '../../url_local';

// ----------------------------------------------------------------------

export default function EditSecEmail(props) {
	console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
	// const history = useNavigate();

	const [getdata,setGetdata] = useState(false);
	const [verify,setVerify] = useState(false);
	const [newEmail,setNewEmail] = useState("");

	const LoginSchema = Yup.object().shape({
		editSecEmail: Yup.string().email('Email must be a valid email address').required('Email is required'),
		// password: Yup.string().required('Password is required').matches(/^\S*$/, 'Whitespace is not allowed'),
	});

	const defaultValues = {
		editSecEmail: '',
		// password: '',
	};

	const methods = useForm({
		resolver: yupResolver(LoginSchema),
		defaultValues,
	});

	const {
		handleSubmit,
		formState: { isSubmitting },
	} = methods;

//update kyc info
const update_user_info = () =>{
		setVerify(false);
		var headers = {  'Content-Type': 'multipart/form-data',
				'Access-Token': secureLocalStorage.getItem('AT_'),
				"Refresh-Token": secureLocalStorage.getItem('RT_')
		};
		const secondaryEmail = document.getElementById('editSecEmail').value;
		var data =   {
				publicid:secureLocalStorage.getItem('PI_'),
				secemail: secondaryEmail,
		}
		// console.log("kyc updated email data=================>",data)
		axios.post(url_local.kyc,data,{headers})
		.then(function(response) {
		// console.log("kyc response data=================>",response.data)
			if(response.data.Status === 1)
			{
				localStorage.setItem("addemail",secondaryEmail);
				$(".modal-body").html("KYC updated successfully");
				$(".modal-title").html("")
				$(".modal-footer").html("");
				$(".modal-footer").html("<a id=closebtn>Got it</a>");
				$("#closebtn").addClass('btn btn-block');
				$("#closebtn").on("click",function()
				{
				ActivateCode(secondaryEmail);
				$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
			}
			else if(response.data.Data.Status === 0){
				$(".modal-body").html("<p class=class_p>"+response.data.Data.Message+"</p>");
				$(".modal-title").html("")
				$(".modal-footer").html("");
				$(".modal-footer").html("<a id=closebtn1>Got it</a>");
				$("#closebtn1").addClass('btn btn-block');
				$("#closebtn1").on("click",function()
				{
				$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
			}
			else{
				$(".modal-body").html("<p class=text-danger>Something went wrong. Try again!</p>");
				$(".modal-title").html("")
				$(".modal-footer").html("");
				$(".modal-footer").html("<a id=closebtn1>Got it</a>");
				$("#closebtn1").addClass('btn btn-block');
				$("#closebtn1").on("click",function()
				{
				$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
			}
		return;
		})
		.catch(function() {
			return;
		});
}

const Verify = () => {
	// setAskPin(false);
	// document.getElementById("receipient").innerHTML = "";
	// document.getElementById("from_account").innerHTML = "";
	// document.getElementById("to_account").innerHTML = "";

	const code = document.getElementById("otp").value;
	// setNewEmail(secondaryEmail);
	var headers = {  'Content-Type': 'multipart/form-data',
		'Access-Token': secureLocalStorage.getItem('AT_'),
		"Refresh-Token": secureLocalStorage.getItem('RT_')
	};
	const payload = {
		"email": newEmail,
		"email-code": code,
		}

	setGetdata(true);
	axios.post(url_local.activateotp,payload,{ headers })
		.then(function (response) {
			setGetdata(false);
			setVerify(false);
			// console.log(response.data)
			if (response.data.Status === 1) {
					$(".modal-title").html("");
					$(".modal-body").html("<p class=class_p>Email Verification Successful</p>");
					$(".modal-footer").html("<button id=cancelled>Got it</button>");
					$("#cancelled").addClass("btn btn-block");
					$("#cancelled").on("click", function () {
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
			}
			if (response.data.Status === 1) {
					$(".modal-title").html("");
					$(".modal-body").html("<p class=class_p>Verification Completed</p>");
					$(".modal-footer").html("<button id=cancelled>Got it</button>");
					$("#cancelled").addClass("btn btn-block");
					$("#cancelled").on("click", function () {
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
			}
			else if (response.data.Status === 0) {
				if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
					$(".modal-title").html("");
					$(".modal-body").html("<p class=class_p>Your session expired</p>");
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=signoutbtncrypto>ok</button>");
					$("body").addClass('no-select'); secureLocalStorage.clear();
					$("#signoutbtncrypto").addClass('btn btn-block');
					$("#modalDialog").toggle('show');
					$("#signoutbtncrypto").on('click', function () {
						$("#modalDialog").toggle('hide');
						window.location.href = "/";
					});
				}
			 	else{
					$(".modal-title").html("");
					$(".modal-body").html("<p class=class_p>" + response.data.Response + "</p>");
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=cancelredirect_crypto>Got it</button>");
					$("#cancelredirect_crypto").addClass("btn btn-block");
					$("#cancelredirect_crypto").on("click", function () {
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
			 	}
			}
			else {
				setGetdata(false);
				$(".modal-title").html("");
				$(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=cancelredirect>Got it</button>");
				$("#cancelredirect").addClass("btn btn-block");
				$("#cancelredirect").on("click", function () {
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
			}
			return;
		})
		.catch(function (err) {
			setGetdata(true);
			if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
			// secureLocalStorage.clear();
			$(".modal-body").html("<p className='text-danger'>Network Error!</p>");
			$(".modal-title").html("")
			$(".modal-footer").html("<button id=redirect>Got it</button>");
			$("#redirect").addClass("btn btn-block");
			$("#redirect").on("click", function () {
				$("#modalDialog").toggle('hide');
			});
			$("#modalDialog").toggle('show');
			}
			else{
				console.warn(err,"EditSecEmail");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
				$(".modal-title").html("")
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=closebtn1>ok</button>");
				$("#closebtn1").addClass('btn btn-block');
				$("#closebtn1").on("click", function () {
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
			}
			return;
		});
}

const ActivateCode = (secondaryEmail) =>
{
	// console.log(localStorage.getItem("addemail"))
	setGetdata(true);
	var headers = { "Content-Type": "application/json"};
	var payload = {
			"publicid":secureLocalStorage.getItem('PI_'),
			"email" :  secondaryEmail,
	};


	axios.post(url_local.activateCode,payload,{headers})
		.then(function(response) {
			setGetdata(false);
			if(response.data.Status === 1)
			{
				setNewEmail(secondaryEmail);
				setVerify(true);
				$(".modal-body").html("<p class=class_p>Activation Code has been sent to your email</p>");
				$(".modal-title").html("")
				$(".modal-footer").html("<button id=redirect>Got it</button>");
				$("#redirect").addClass("btn btn-block");
				$("#redirect").on("click",function(){
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
				// // console.log(code)
				// if(code === 1100 || code === 1104  || code === 1006 )
				// {
				//   history(routeCodes.ACTIVE_SECEMAILOTP,localStorage.getItem("addemail"));
				// }
				// else if(code === 1105){
				//   history(routeCodes.ACTIVE_SECMOBILEOTP,localStorage.getItem("addemail"));
				// }
				// else{
				//   history(routeCodes.SETTINGS);
				// }
			}
			else if(response.data.Status === 0){
				$(".modal-body").html("<p class=class_p>"+response.data.Message+"</p>");
				$(".modal-title").html("")
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=redirect>Got it</button>");
				$("#redirect").addClass("btn btn-block");
				$("#redirect").on("click",function()
				{
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
			}
			else{
				$(".modal-body").html("<p className='text-danger'>Something went wrong. Try again.</p>");
				$(".modal-title").html("")
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=redirect>Got it</button>");
				$("#redirect").addClass("btn btn-block");
				$("#redirect").on("click",function()
				{
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
			}
			return;
		})
		.catch(function() {
			return;
		});
}
	return (
				<div className=''>
						<div className="mb-3 mt-3">
						<label className="col-form-label mx-1">Primary Email</label>
						<p className='class_p  mx-1' id="editPrimeEmail">{props.email}</p>
						</div>
						<div className="mb-3 mt-3">
						<label className="col-form-label mx-1">Secondary Email{verify?"-1-":"-0-"}</label>
						<FormProvider methods={methods} onSubmit={handleSubmit(update_user_info)}>
								<RHFTextField type="email" name="editSecEmail" id='editSecEmail'   placeholder="Enter email address" inputProps={{ className: "text-lowercase text-white w-100 mx-1" }}
								disabled={verify?true:false}
								/>
								<div className="text-center mt-3">
								{!getdata ?
										verify === false ?
										<LoadingButton  size="small" type="submit" variant="contained" loading={isSubmitting} className="btn-sm btn-yodigi-buy">
														Update
										</LoadingButton>
										:<></>
										:
										<div className="spinner-border" role="status">
												<span className="sr-only">Loading...</span>
										</div>
								}
								</div>
						</FormProvider>
						{
							verify?<>
								<div className="mb-3 mt-3">
									<FormProvider methods={methods} onSubmit={handleSubmit(Verify)}>
										<div className="col-12">
											<label className="col-form-label mx-1">Verification Code</label>
											<input type="text" class="form-control" id="otp" name="otp" placeholder="OTP" required></input>
										</div>
										<div id="btnsection" className='p-2 text-center'>
										{!getdata ?
											<button type="submit" className="btn-sm btn-yodigi-buy mx-2" id="202">Verify</button>
											:
											<div className="spinner-border" role="status">
												<span className="sr-only">Loading...</span>
											</div>
										}
										</div>
									</FormProvider>
								</div>
							</>:<></>
						}
						</div>
				</div>
		);
}
