import React, { useState } from 'react';
import $ from 'jquery';
import axios from 'axios';
import * as Yup from 'yup';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider } from '../components/hook-form';
import { RHFTextField } from '../components/hook-form';
import { IconButton, InputAdornment } from '@mui/material';
import url_local from '../url_local';
import routeCodes from '../../Paths';

//mui
import { LoadingButton } from '@mui/lab';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';

import Iconify from '../components/Iconify';

export default function NewTradeCrypto() {
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  const history = useNavigate();
  const [getdata, setGetdata] = useState(false);
  const [confirmData, setConfirmData] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [previewData, setpreviewData] = useState([]);
  const [askPin, setAskPin] = React.useState(false);
  const headers = {
    "Content-Type": "application/json",
    "Access-Token": secureLocalStorage.getItem('AT_'),
    "Refresh-Token": secureLocalStorage.getItem('RT_')
  };
  const TransferSchema = Yup.object().shape({
    address: Yup.string()
      .required("Please enter an address"),
    cryptoValue: Yup.number()
      .typeError('Please enter a value')
      .min(0, 'Min value 0.')
      .required("Please enter a value"),
  });

  const defaultValues = {
    address: '',
    cryptoValue: '',
  };

  const methods = useForm({
    resolver: yupResolver(TransferSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const transferCryptoAccount = () => {
    setAskPin(false);
    var headers = {
      "Content-Type": "application/json",
      "Access-Token": secureLocalStorage.getItem('AT_'),
      "Refresh-Token": secureLocalStorage.getItem('RT_')
    };
    setGetdata(true);
    axios.post(url_local.cryptotransaction,
        {
          "publicid": secureLocalStorage.getItem('PI_'),
          "from_address": secureLocalStorage.getItem("AD_"),
          "to_address": document.getElementById("address").value,
          "value": document.getElementById("cryptoValue").value,
          "network": "",
          "signing_key": 0,
          "symbol": secureLocalStorage.getItem("CV_")
        },
        { headers })
      .then(function (response) {
        setGetdata(false);
        console.log(response);
        if (response.data.Status === 1) {

          document.getElementById("address").value = "";
          document.getElementById("cryptoValue").value = "";
          if(response.data.Code === 4100){
            setConfirmData(true);
            setpreviewData(response.data.Data);
            // document.querySelector('#view-right-sidebar').classList.toggle('open');
          }
          else{
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=cancelled>Got it</button>");
            $("#cancelled").addClass("btn btn-block");
            $("#cancelled").on("click", function () {
              history(routeCodes.CREATECRYPTOACCOUNT);
              secureLocalStorage.setItem("AD_", "");
              secureLocalStorage.setItem("CV_", "");
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
          // $(".modal-title").html("");
          // $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
          // $(".modal-footer").html("");
          // $(".modal-footer").html("<button id=cancelled>Got it</button>");
          // $("#cancelled").addClass("btn btn-block");
          // $("#cancelled").on("click", function () {
          //   history(routeCodes.CREATECRYPTOACCOUNT);
          //   secureLocalStorage.setItem("AD_", "");
          //   secureLocalStorage.setItem("CV_", "");
          //   $("#modalDialog").toggle('hide');
          // });
          // $("#modalDialog").toggle('show');

        }
        else if (response.data.Status === 0) {
          setGetdata(false);
          $(".modal-title").html("");
          $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=cancelredirect>Got it</button>");
          $("#cancelredirect").addClass("btn btn-block");
          $("#cancelredirect").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        else {
          setGetdata(false);
          $(".modal-title").html("");
          $(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=cancelredirect>Got it</button>");
          $("#cancelredirect").addClass("btn btn-block");
          $("#cancelredirect").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        return;
      })
      .catch(function (err) {
        setGetdata(true);
        if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
        // secureLocalStorage.clear();
        $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("<button id=redirect>Got it</button>");
        $("#redirect").addClass("btn btn-block");
        $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
        }
        else{
            console.warn(err,"NewTradeCrypto");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn1>ok</button>");
            $("#closebtn1").addClass('btn btn-block');
            $("#closebtn1").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
        }
        return;
      });
  }

  const showPinConfirmation = () => {
    setAskPin(true);
  }

  const ContinueTransfer = () => {
    setAskPin(false);
    document.getElementById("receipient").innerHTML = "";
    document.getElementById("from_account").innerHTML = "";
    document.getElementById("to_account").innerHTML = "";

    var amt =  parseFloat(document.getElementById("cryptoValue").value);
    const payload = {
      "symbol": secureLocalStorage.getItem("CV_"),
      "network": "",
      "from_address": secureLocalStorage.getItem("AD_"),
      "to_address": document.getElementById("address").value,
      "value": amt,
      "isconfirmed": confirmData,
      "pin":"",
      "publicid": secureLocalStorage.getItem('PI_'),
    }
    setGetdata(true);
    setConfirmData(false);
    axios.post(url_local.cryptotransaction,payload,{ headers })
      .then(function (response) {
        setGetdata(false);
        console.log(response);
        // console.log(response.data)
        if (response.data.Status === 1) {

          // document.getElementById("address").value = "";
          // document.getElementById("cryptoValue").value = "";

          if(response.data.Code === 4100){
            setConfirmData(true);
            setpreviewData(response.data.Data);
            // document.querySelector('#view-right-sidebar').classList.toggle('open');
          }
          else{
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=cancelled>Got it</button>");
            $("#cancelled").addClass("btn btn-block");
            $("#cancelled").on("click", function () {
              history(routeCodes.CREATECRYPTOACCOUNT);
              secureLocalStorage.setItem("AD_", "");
              secureLocalStorage.setItem("CV_", "");
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
        }
        else if (response.data.Status === 0) {
          setGetdata(false);
          if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>Your session expired</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=signoutbtncrypto>ok</button>");
            $("body").addClass('no-select'); secureLocalStorage.clear();
            $("#signoutbtncrypto").addClass('btn btn-block');
            $("#modalDialog").toggle('show');
            $("#signoutbtncrypto").on('click', function () {
              $("#modalDialog").toggle('hide');
              window.location.href = "/";
            });
          }
         else{
          $(".modal-title").html("");
          $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=cancelredirect_crypto>Got it</button>");
          $("#cancelredirect_crypto").addClass("btn btn-block");
          $("#cancelredirect_crypto").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
         }
        }
        else {
          setGetdata(false);
          $(".modal-title").html("");
          $(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=cancelredirect>Got it</button>");
          $("#cancelredirect").addClass("btn btn-block");
          $("#cancelredirect").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        return;
      })
      .catch(function (err) {
        setGetdata(true);
        if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
        // secureLocalStorage.clear();
        $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("<button id=redirect>Got it</button>");
        $("#redirect").addClass("btn btn-block");
        $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
        }
        else{
            console.warn(err,"NewTradeCrypto");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn1>ok</button>");
            $("#closebtn1").addClass('btn btn-block');
            $("#closebtn1").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
        }
        return;
      });
  }

  const viewcloseRightSidebar = () => {
    setAskPin(false);
    setConfirmData(false);
    document.getElementById('view-right-sidebar').classList.remove('open');
    document.getElementById('pinsection').style.display = "none";
    document.getElementById('btnsection').style.display = "block";
  }

  var array = [];
  array.push(previewData);

    var transactionData = array.map((item, index) => {
        return (
            <div key={index} id={index} className=" pt-4">
                <h4 className='mb-3'>Confirm payment</h4>
                <div className='row mt-5'>
                    <div className='col-md-4 col-xs-12'>
                        <p className='text-center'>{item.Debit_Currency}</p>
                        <p className='class_p'>{secureLocalStorage.getItem("AN_")}</p>
                        <div className='mt-2 text-left'>
                            <p>Amount debited : {item.Debited_Amount}</p>
                            <p>Service Charge : {item.Service_Charge}</p>
                        </div>
                    </div>
                    <div className='col-md-3 col-xs-12 text-center'>
                        <i className='mdi mdi-arrow-right'></i>
                    </div>
                    <div className='col-md-4 col-xs-12'>
                        <p className='text-center'>{item.Credit_Currency}</p>
                        <p className='class_p'>{item.Credit_Account}</p>
                        <div className='mt-2 text-left'>
                            <p>Credited Amount : {item.Credit_Amount}</p>
                        </div>
                    </div>
                </div>
                <h4 style={{ borderTop: "1px solid #242736" }} className="mb-3 pt-3">Total : {item.Debited_Amount}</h4>
            </div>
        )
    });

  return (
    <div className="row">
      <div className="card px-4">
        <div className="page-header w-100">
          <h3 className="page-title w-100 row">
            <div className='col-5'>
              <span className="page-title-icon bg-success me-2">
                <i className="mdi mdi-cash"></i>
              </span> Send Crypto
            </div>
            <div className='col'>
              <div className="float-end">
                <div className='text-lastlogin text-end fs-6'>
                  <i className="mdi mdi-account px-2"></i>Last login time
                  <label className='d-block'>{secureLocalStorage.getItem('DT_')}</label>
                </div>
              </div>
            </div>
          </h3>
        </div>
        <div className='p-3'>
          <p className='card-description pt-3'>Start a new trade with Meezan</p>
          <div className="row">
            <div className="col-12 mb-3">
              <label className="form-label">Account Type</label>
              <p id="CryptoAddress" className='class_p mb-1'>
                Address :  {secureLocalStorage.getItem("AD_")}
              </p>
              <p id="CryptoSymbol" className='class_p mb-1'>
                Crypto selected : {secureLocalStorage.getItem("CV_")}
              </p>
              <a className='btn btn-sm settingsBtn' href={routeCodes.CREATECRYPTOACCOUNT}>
                Change
              </a>
            </div>
            <p>Enter trading details</p>
            <div className="mb-3 mt-2" id="receiveCryptoAccount" >
              <FormProvider methods={methods} onSubmit={handleSubmit(transferCryptoAccount)}>
                <div className='row'>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label class_p">Provide address *</label>
                    <div className="col-sm-6">
                    <input type='text' name="address" id='address' className='form-control' placeholder="" maxLength={50} minLength={25} pattern="([a-zA-Z0-9-]){10,50}" title='Must be 10-50 characters long and only contains letters and numbers!'/>
                    {/* <RHFTextField name="address" id='address' placeholder=""  inputProps={{maxLength: 128, pattern: "/^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u"}}/> */}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label class_p">Enter Value *</label>
                    <div className="col-sm-6">
                      <input type='number' name="cryptoValue" id='cryptoValue' placeholder="0" max={9999} min={0.001} step={0.001}/>
                      {/* <RHFTextField name="cryptoValue" id='cryptoValue' placeholder="" inputProps={{ type: "number"}}/> */}
                    </div>
                  </div>
                </div>
                <div className='text-center'>
                  {!getdata ?
                    <div>
                      <LoadingButton size="small" type="submit" variant="contained" loading={isSubmitting} className="btn-sm btn-yodigi-buy text-center">
                        Transfer
                      </LoadingButton>
                      <a className='btn btn-block mx-2'
                        href={routeCodes.CREATECRYPTOACCOUNT}>Cancel</a>
                    </div>
                    :
                    <div className="spinner-border text-center" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  }
                </div>
              </FormProvider>
            </div>
          </div>
          <div id="view-right-sidebar"  className={confirmData ? "px-4 settings-panel right-sidebar open" :"px-4 settings-panel right-sidebar"}>
            <i className="settings-close mdi mdi-arrow-left justify-content-left" onClick={viewcloseRightSidebar}></i>
            <div className="" id="uncontrolled-tab-example ">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="px-3">
                            <ul >
                                {transactionData}
                            </ul>
                            <div id="btnsection" className='p-2' style={{ display: 'block' }}>
                                <button className='btn-sm btn-default' onClick={viewcloseRightSidebar}>Cancel</button>
                                <button className='btn-sm btn-yodigi-buy' onClick={ showPinConfirmation  }>Confirm payment</button>
                            </div>
                            <div id="pinsection" style={ askPin ? {}: {display:'none'}} className="mt-3">
                                <p className='class_p'>Enter your Security PIN</p>
                                <FormProvider methods={methods} onSubmit={handleSubmit(ContinueTransfer)}>
                                    <div className="col-12">
                                        <RHFTextField name="pin"
                                            id="confirm_pin" placeholder=""
                                            type={showPassword ? 'text' : 'password'}
                                            InputProps={{
                                                endAdornment: (<InputAdornment position="end">
                                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                        <Iconify style={{ color: '#fff' }} icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                    </IconButton>
                                                </InputAdornment>)
                                            }} />
                                    </div>
                                    <div id="btnsection" className='p-2 text-center'>
                                      <button className='btn-sm btn-default mx-2' onClick={viewcloseRightSidebar}>Cancel</button>
                                      <button type="submit" className="btn-sm btn-yodigi-buy mx-2" id="202">Send</button>
                                    </div>
                                </FormProvider>

                                <p className='text-danger mt-1' id="errMsg" style={{ display: 'none' }}></p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


