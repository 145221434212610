import React from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import url_local from '../../url_local';
import * as Yup from 'yup';
import secureLocalStorage from 'react-secure-storage';

import { FormProvider, RHFTextField } from '../../components/hook-form';
import { IconButton, InputAdornment } from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//mui
import { LoadingButton } from '@mui/lab';

//components
import Iconify from '../../components/Iconify';

//css
import '../../../assets/styles/web/css/style.css';
import routeCodes from '../../../Paths';

export default function Userpin() {
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  const [showPassword, setShowPassword] = React.useState(false);
  const [getdata, setGetdata] = React.useState(false);
  const mousedown = React.useRef(false);
	const mouseover = React.useRef(false);
  const history = useNavigate();

  React.useEffect(() => {
    document.getElementById('pinPrev').value = " ";
  }, []);

  const PINSchema = Yup.object().shape({
    pinPrev: Yup.string()
      .required("* This field is required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .max(6, "PIN must be an unique 6-digit number.")
      .min(6, "PIN must be an unique 6-digit number.").matches(/^\S*$/, 'Whitespace is not allowed'),
  });

  const PINSchema1 = Yup.object().shape({
    otp: Yup.string()
      .required("* This field is required")
  });

  const defaultValues = {
    pinPrev: '',
  };


  const defaultValues1 = {
    otp: '',
  };

  const methods = useForm({
    resolver: yupResolver(PINSchema, PINSchema1),
    defaultValues, defaultValues1
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  const generatePIN = (event) => {
    setGetdata(true);
    // calling generate pin
    var headers = {
      "Content-Type": "application/json",
      "Refresh-Token": secureLocalStorage.getItem('RT_'),
      "Access-Token": secureLocalStorage.getItem('AT_')
    };
    axios
      .post(url_local.managepin,
        {
          "publicid": secureLocalStorage.getItem('PI_'),
          "purpose": "PIN-UPDATE",
          "pin": document.getElementById('pinPrev').value
        },
        { headers })
      .then(function (response) {
        if (response.data.Status === 1) {
          setGetdata(false);
          secureLocalStorage.setItem("PP_", document.getElementById('pinPrev').value);
          window.location.href=routeCodes.PINOTP;
        }
        else if (response.data.Status === 0) {
          setGetdata(false);
          if (response.data.Code === 4001) {
            $(".modal-title").html("");
            $(".modal-body").html('<p class=class_p>' + response.data.Data.Message + '</p>');
            $(".modal-footer").html("");
            $(".modal-footer").html("<div class=row><a id=redirect href=" + routeCodes.WALLET + ">Create Wallet ?</a><button id=laterbtn>Not now</button></div>");
            $("#redirect").addClass("btn btn-block");
            $("#laterbtn").addClass("btn btn-block");
            $("#laterbtn").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
          else if (response.data.Code === 4101) {
            $(".modal-title").html("");
            $(".modal-body").html('<p class=class_p>' + response.data.Message + '.</p>');
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=redirect>Activate now?</button><button id=cancelredirect>Cancel</button>");
            $("#redirect").addClass("btn btn-block");
            $("#cancelredirect").addClass("btn btn-block");
            $("#redirect").on("click", function () {
              history(routeCodes.WALLET);
              $("#modalDialog").toggle('hide');
            });
            $("#cancelredirect").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
          else if (response.data.Code === 3 || response.data.Code === 7) {
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>Your session expired.</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closesessionclosebtn>ok</button>");
            $("body").addClass('no-select'); secureLocalStorage.clear();
            $("#closesessionclosebtn").addClass('btn btn-block');
            $("#closesessionclosebtn").on("click", function () {
              $("#modalDialog").toggle('hide');
              window.location.href = "/";
            });
            if($("#modalDialog" ).hasClass( "show" )){
              $(".main-panel").css("filter","blur(8px)");
              $("#modalDialog").toggle('show');
             }
             else{
                 $(".main-panel").css("filter","blur(8px)");
                 $("#modalDialog").toggle('show');
             }
          }
          else {
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>"+response.data.Data.Message+"</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn>ok</button>");
            $("#closebtn").addClass('btn btn-block');
            $("#closebtn").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }

        }
        else {
          setGetdata(false);
          console.error('err');$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
          $(".modal-title").html("")
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=delete>Got it</button>");
          $("#delete").addClass("btn btn-block");
          $("#modalDialog").toggle('show');
          $("#delete").on('click', function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        return;
      })
      .catch(function (err) {
        console.warn(err,"userpin");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("");
        $(".modal-footer").html("<button id=delete>Got it</button>");
        $("#delete").addClass("btn btn-block");
        $("#modalDialog").toggle('show');
        $("#delete").on('click', function () {
          $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
        return;
      });
  }

  const handleEvent3 = (event) => {
		// console.log(event.type);
		if (event.type === "mousedown") {
			mousedown.current = true;
		}
		else if (event.type === "mouseup") {
			mousedown.current = false;
		}

		if (event.type === "mouseover") {
			mouseover.current = true;
		}
		else if (event.type === "mouseout") {
			mouseover.current = false;
			mousedown.current = false;
		}

		if (mousedown.current && mouseover.current){
			setShowPassword(true);
		}
		else{
			setShowPassword(false);
		}
	}
  return (
    <>
      <div id="pinGenSection" style={{ display: 'block' }}>
        <label className='card-description'> For security reasons, create a 6-digit unique number.
          We advice you avoid using years, pair of numbers or numerical palindrome for your technical services.
        </label>
        <FormProvider methods={methods} onSubmit={handleSubmit(generatePIN)}>
          <div className="row">
            <div className='col-md-6 col-xs-12'>
              <div className="form-group row">
                <label className="col-md-3 col-xs-12 col-form-label class_p">Enter Security PIN *</label>
                <div className="col-md-9 col-xs-12">
                  <RHFTextField name="pinPrev"
                    id="pinPrev" placeholder=""
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (<InputAdornment position="end">
                        <IconButton onMouseDown={handleEvent3} onMouseUp={handleEvent3} onMouseOver={handleEvent3} onMouseOut={handleEvent3} edge="end">
                          <Iconify style={{ color: '#fff' }} icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>)
                    }} />
                  {!getdata ?
                    <LoadingButton size="small" type="submit" loading={isSubmitting}
                      className="btn-sm btn-yodigi-buy mt-2">
                      Save changes
                    </LoadingButton>
                    :
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </FormProvider>
      </div>
    </>
  )
}
