import React, { useRef } from 'react';
// import { Navigate } from 'react-router-dom';

import '../../App.scss';
import '../web/web.scss';

import "../../assets/styles/web/css/style.css";

import TradeDashboard from './TradeDashboard';
import { GlobalContext } from '../shared/useForm';
import secureLocalStorage from 'react-secure-storage';
// import routeCodes from '../../Paths';

export default function Overview(props) {
  console.info(`-->`,`Dashboard`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  // let current = localStorage.getItem("theme");
  // const body = document.body
  // if (current === "light-theme"){
  //   body.classList.remove('dark-mode')
  // }
  // else if (current === "dark-theme"){
  //   body.classList.add('dark-mode');
  // }
  // else if (current === null){
  //   localStorage.setItem('theme', "dark-theme");
  //   body.classList.add('dark-mode');
  // }
  // else{
  //     localStorage.setItem('theme', "dark-theme");
  //     body.classList.add('dark-mode');
  // }
  const userData = useRef();
  const gContext = React.useContext(GlobalContext);
  userData.current = gContext.user();

  // if (secureLocalStorage.getItem('LS_')) {
    return (
      <div className="row">
        <div className="col-md-12">
          {/* internal container is div.card below */}
          <div className="card px-4">
            <div className="page-header w-100">
              <h3 className="page-title w-100 row">
                <div className='col-5'>
                  <span className="page-title-icon bg-success me-2">
                    <i className="mdi mdi-chart-line"></i>
                  </span>Market Rates
                </div>
                <div className='col'>
                  <div className="float-end">
                    <div className='text-lastlogin text-end fs-6'>
                      <i className="mdi mdi-account px-2"></i>Last login time
                      <label className='d-block'>{secureLocalStorage.getItem('DT_')}</label>
                    </div>
                  </div>
                </div>
              </h3>
            </div>

            <h3 className="">Welcome to Meezan digital exchange</h3>
            <label className='text-label'>Your crypto Journey starts now. Create meezan accounts for your crypto transfers.</label>
            <div className='w-100 mb-3 mt-3'>
              <p id='userData'>Market Rates</p>
            </div>
            <TradeDashboard />
          </div>
        </div>
      </div>
    )
  // }
  // else {
  //   return <Navigate to={{ pathname: routeCodes.LOGIN }} />
  // }

}

