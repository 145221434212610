import React, { useState } from 'react';
import $ from 'jquery';
// import QRCode from 'qrcode'
import axios from 'axios';
import * as Yup from 'yup';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider } from '../components/hook-form';
// import { Trans } from 'react-i18next';
import url_local from '../url_local';
import routeCodes from '../../Paths';
// import QR_Code from '../dashboard/QR_code';

//mui
// import { LoadingButton } from '@mui/lab';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';

export default function NewRequestCrypto() {
    console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
    const history = useNavigate();
    const [qr, setQr] = useState('');

    React.useEffect(()=>
    {
        var data = {
            publicid:secureLocalStorage.getItem('PI_'),
            acc:secureLocalStorage.getItem("AD_")
        }

		let headers = {
			"Content-Type": "application/json",
			'Access-Token': secureLocalStorage.getItem('AT_'),
			'Refresh-Token': secureLocalStorage.getItem('RT_')
		}

		axios.post(url_local.accountQR,data,
			{ headers })
			.then(function (response) {
// console.log(response.data,"accountQR")
				if (response.data.Status === 1) {
                    // GenerateQRCode(response.data.Data.accountqr);
                    setQr("data:image/jpg;base64,"+response.data.Data.accountqr)
				}
				else if (response.data.Status === 0) {
					$(".modal-title").html("")
					$(".modal-body").html("<p class=class_p" > +response.data.Message + "</p>");
					$(".modal-title").html("")
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=ok>OK</button>");
					$("#ok").addClass("btn btn-block");
					$("#ok").on("click", function () {
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
				}
				else {
					$(".modal-title").html("")
					$(".modal-body").html("<p class=class_p text-danger>Something went wrong. Try again.</p>");
					$(".modal-title").html("")
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=ok>OK</button>");
					$("#ok").addClass("btn btn-block");
					$("#ok").on("click", function () {
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
				}
			})
			.catch(function () {
				return;
			});
    })


    // const GenerateQRCode = (accountqr) => {
    //     QRCode.toDataURL(accountqr, {
    //         // margin: '10px',
    //         color: {
    //             // dark: '#335383FF',
    //             // light: '#EEEEEEFF'
    //         }
    //     }, (err, accountqr) => {
    //         if (err) return console.error(err)
    //         setQr(accountqr)
    //     })
    // }


    // const [getdata, setGetdata] = useState(false);
    const TransferSchema = Yup.object().shape({
        address: Yup.string()
            .required("Please enter an address"),
        cryptoValue: Yup.number()
            .typeError('Please enter a value')
            .min(0, 'Min value 0.')
            .required("Please enter a value"),
    });

    const defaultValues = {
        address: '',
        cryptoValue: '',
    };

    const methods = useForm({
        resolver: yupResolver(TransferSchema),
        defaultValues,
    });

    const {
        handleSubmit,
    } = methods;

    const transferCryptoAccount = () => {
        var headers = {
            "Content-Type": "application/json",
            "Access-Token": secureLocalStorage.getItem('AT_'),
            "Refresh-Token": secureLocalStorage.getItem('RT_')
        };
        // setGetdata(true);
        axios
            .post(url_local.cryptotransaction,
                {
                    "publicid": secureLocalStorage.getItem('PI_'),
                    "from_address": secureLocalStorage.getItem("AD_"),
                    "to_address": document.getElementById("address").value,
                    "value": document.getElementById("cryptoValue").value,
                    "network": "",
                    "signing_key": 0,
                    "symbol": secureLocalStorage.getItem("CV_")
                },
                { headers })
            .then(function (response) {
// console.log(response.data)
                if (response.data.Status === 1) {
                    // setGetdata(false);
                    document.getElementById("address").value = "";
                    document.getElementById("cryptoValue").value = "";
                    $(".modal-title").html("");
                    $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
                    $(".modal-footer").html("");
                    $(".modal-footer").html("<button id=cancelled>Got it</button>");
                    $("#cancelled").addClass("btn btn-block");
                    $("#cancelled").on("click", function () {
                        history(routeCodes.CREATECRYPTOACCOUNT);
                        secureLocalStorage.setItem("AD_", "");
                        secureLocalStorage.setItem("CV_", "");
                        $("#modalDialog").toggle('hide');
                    });
                    $("#modalDialog").toggle('show');

                }
                else if (response.data.Status === 0) {
                    // setGetdata(false);
                    $(".modal-title").html("");
                    $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
                    $(".modal-footer").html("");
                    $(".modal-footer").html("<button id=cancelredirect>Got it</button>");
                    $("#cancelredirect").addClass("btn btn-block");
                    $("#cancelredirect").on("click", function () {
                        $("#modalDialog").toggle('hide');
                    });
                    $("#modalDialog").toggle('show');
                }
                else {
                    // setGetdata(false);
                    $(".modal-title").html("");
                    $(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
                    $(".modal-footer").html("");
                    $(".modal-footer").html("<button id=cancelredirect>Got it</button>");
                    $("#cancelredirect").addClass("btn btn-block");
                    $("#cancelredirect").on("click", function () {
                        $("#modalDialog").toggle('hide');
                    });
                    $("#modalDialog").toggle('show');
                }
                return;
            })
            .catch(function (err) {
                // setGetdata(true);
                if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
                // secureLocalStorage.clear();
                $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
                $(".modal-title").html("")
                $(".modal-footer").html("<button id=redirect>Got it</button>");
                $("#redirect").addClass("btn btn-block");
                $("#redirect").on("click", function () {
                    $("#modalDialog").toggle('hide');
                });
                $("#modalDialog").toggle('show');
                }
                else{
                    console.warn(err,"NewRequestCrypto");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
                    $(".modal-title").html("")
                    $(".modal-footer").html("");
                    $(".modal-footer").html("<button id=closebtn1>ok</button>");
                    $("#closebtn1").addClass('btn btn-block');
                    $("#closebtn1").on("click", function () {
                        $("#modalDialog").toggle('hide');
                    });
                    $("#modalDialog").toggle('show');
                }
                return;
            });
    }

    return (
        <div className="row">
            <div className="card px-4">
                <div className="page-header w-100">
                    <h3 className="page-title w-100 row">
                        <div className='col-5'>
                            <span className="page-title-icon bg-success me-2">
                                <i className="mdi mdi-cash"></i>
                            </span> Request Crypto
                        </div>
                        <div className='col'>
                            <div className="float-end">
                                <div className='text-lastlogin text-end fs-6'>
                                    <i className="mdi mdi-account px-2"></i>Last login time
                                    <label className='d-block'>{secureLocalStorage.getItem('DT_')}</label>
                                </div>
                            </div>
                        </div>
                    </h3>
                </div>
                <div className='p-3'>
                    <p className='card-description pt-3'>Start a new trade with Meezan</p>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <label className="form-label">Account Type</label>
                            <p id="CryptoAddress" className='class_p mb-1'>
                                Address :  {secureLocalStorage.getItem("AD_")}
                            </p>
                            <p id="CryptoSymbol" className='class_p mb-1'>
                                Crypto selected : {secureLocalStorage.getItem("CV_")}
                            </p>
                            <a className='btn btn-sm settingsBtn' href={routeCodes.CREATECRYPTOACCOUNT}>
                                Change
                            </a>
                        </div>
                        <div className="mb-3 mt-3" id="receiveCryptoAccount" >
                            <FormProvider methods={methods} onSubmit={handleSubmit(transferCryptoAccount)}>
                                    <p className="class_p">Scan the code below</p>
                                    {qr && <>
                                        <img src={qr} className="imgQR" alt='' />
                                        {/* <Button variant='contained' color='success' href={qr} download="qrcode.png">Download</Button> */}
                                    </>}
                                {/* <div className='mt-2'>
                                    {!getdata ?
                                        <div>
                                            <LoadingButton size="small" type="submit" variant="contained" loading={isSubmitting} className="btn-sm btn-yodigi-buy text-center">
                                                Request
                                            </LoadingButton>
                                            <a className='btn btn-block mx-2'
                                                href={routeCodes.CREATECRYPTOACCOUNT}>Cancel</a>
                                        </div>
                                        :
                                        <div className="spinner-border text-center" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    }
                                </div> */}
                            </FormProvider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


