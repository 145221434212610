 const Location = [	{"country":"Afghanistan","nationality":"Afghan"},
	{"country":"Albania","nationality":"Albanian"},
	{"country":"Algeria","nationality":"Algerian"},
	{"country":"Andorra","nationality":"Andorran"},
	{"country":"Angola","nationality":"Angolan"},
	{"country":"Antigua and Barbuda","nationality":"Antiguan or Barbudan"},
	{"country":"Argentina","nationality":"Argentine"},
	{"country":"Armenia","nationality":"Armenian"},
	{"country":"Australia","nationality":"Australian"},
	{"country":"Austria","nationality":"Austrian"},
	{"country":"Azerbaijan","nationality":"Azerbaijani, Azeri"},
	{"country":"The Bahamas","nationality":"Bahamian"},
	{"country":"Bahrain","nationality":"Bahraini"},
	{"country":"Bangladesh","nationality":"Bengali"},
	{"country":"Barbados","nationality":"Barbadian"},
	{"country":"Belarus","nationality":"Belarusian"},
	{"country":"Belgium","nationality":"Belgian"},
	{"country":"Belize","nationality":"Belizean"},
	{"country":"Benin","nationality":"Beninese, Beninois"},
	{"country":"Bhutan","nationality":"Bhutanese"},
	{"country":"Bolivia","nationality":"Bolivian"},
	{"country":"Bosnia and Herzegovina","nationality":"Bosnian or Herzegovinian"},
	{"country":"Botswana","nationality":"Motswana, Botswanan"},
	{"country":"Brazil","nationality":"Brazilian"},
	{"country":"Brunei","nationality":"Bruneian"},
	{"country":"Bulgaria","nationality":"Bulgarian"},
	{"country":"Burkina Faso","nationality":"Burkinabé"},
	{"country":"Burma","nationality":"Burmese"},
	{"country":"Burundi","nationality":"Burundian"},
	{"country":"Cabo Verde","nationality":"Cabo Verdean"},
	{"country":"Cambodia","nationality":"Cambodian"},
	{"country":"Cameroon","nationality":"Cameroonian"},
	{"country":"Canada","nationality":"Canadian"},
	{"country":"Central African Republic","nationality":"Central African"},
	{"country":"Chad","nationality":"Chadian"},
	{"country":"Chile","nationality":"Chilean"},
	{"country":"China, People's Republic of","nationality":"Chinese"},
	{"country":"Colombia","nationality":"Colombian"},
	{"country":"Comoros","nationality":"Comoran, Comorian"},
	{"country":"Congo, Democratic Republic of the","nationality":"Congolese"},
	{"country":"Congo, Republic of the","nationality":"Congolese"},
	{"country":"Costa Rica","nationality":"Costa Rican"},
	{"country":"Côte d'Ivoire","nationality":"Ivorian"},
	{"country":"Croatia","nationality":"Croatian"},
	{"country":"Cuba","nationality":"Cuban"},
	{"country":"Cyprus","nationality":"Cypriot"},
	{"country":"Czech Republic","nationality":"Czech"},
	{"country":"Denmark","nationality":"Danish"},
	{"country":"Djibouti","nationality":"Djiboutian"},
	{"country":"Dominica","nationality":"Dominican"},
	{"country":"Dominican Republic","nationality":"Dominican"},
	{"country":"East Timor","nationality":"Timorese"},
	{"country":"Ecuador","nationality":"Ecuadorian"},
	{"country":"Egypt","nationality":"Egyptian"},
	{"country":"El Salvador","nationality":"Salvadoran"},
	{"country":"Equatorial Guinea","nationality":"Equatorial Guinean, Equatoguinean"},
	{"country":"Eritrea","nationality":"Eritrean"},
	{"country":"Estonia","nationality":"Estonian"},
	{"country":"Ethiopia","nationality":"Ethiopian"},
	{"country":"Fiji","nationality":"Fijian"},
	{"country":"Finland","nationality":"Finnish"},
	{"country":"France","nationality":"French"},
	{"country":"Gabon","nationality":"Gabonese"},
	{"country":"Gambia, The","nationality":"Gambian"},
	{"country":"Georgia","nationality":"Georgian"},
	{"country":"Germany","nationality":"German"},
	{"country":"Ghana","nationality":"Ghanaian"},
	{"country":"Gibraltar","nationality":"Gibraltar"},
	{"country":"Greece","nationality":"Greek, Hellenic"},
	{"country":"Grenada","nationality":"Grenadian"},
	{"country":"Guatemala","nationality":"Guatemalan"},
	{"country":"Guinea","nationality":"Guinean"},
	{"country":"Guinea-Bissau","nationality":"Bissau-Guinean"},
	{"country":"Guyana","nationality":"Guyanese"},
	{"country":"Haiti","nationality":"Haitian"},
	{"country":"Honduras","nationality":"Honduran"},
	{"country":"Hungary","nationality":"Hungarian, Magyar"},
	{"country":"Iceland","nationality":"Icelandic"},
	{"country":"India","nationality":"Indian"},
	{"country":"Indonesia","nationality":"Indonesian"},
	{"country":"Iran","nationality":"Iranian, Persian"},
	{"country":"Iraq","nationality":"Iraqi"},
	{"country":"Ireland","nationality":"Irish"},
	{"country":"Israel","nationality":"Israeli"},
	{"country":"Italy","nationality":"Italian"},
	{"country":"Ivory Coast","nationality":"Ivorian"},
	{"country":"Jamaica","nationality":"Jamaican"},
	{"country":"Japan","nationality":"Japanese"},
	{"country":"Jordan","nationality":"Jordanian"},
	{"country":"Kazakhstan","nationality":"Kazakhstani, Kazakh"},
	{"country":"Kenya","nationality":"Kenyan"},
	{"country":"Kiribati","nationality":"I-Kiribati"},
	{"country":"North Korea","nationality":"North Korean"},
	{"country":"South Korea","nationality":"South Korean"},
	{"country":"Kuwait","nationality":"Kuwaiti"},
	{"country":"Kyrgyzstan","nationality":"Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz"},
	{"country":"Laos","nationality":"Lao, Laotian"},
	{"country":"Latvia","nationality":"Latvian, Lettish"},
	{"country":"Lebanon","nationality":"Lebanese"},
	{"country":"Lesotho","nationality":"Basotho"},
	{"country":"Liberia","nationality":"Liberian"},
	{"country":"Libya","nationality":"Libyan"},
	{"country":"Liechtenstein","nationality":"Liechtensteiner"},
	{"country":"Lithuania","nationality":"Lithuanian"},
	{"country":"Luxembourg","nationality":"Luxembourg, Luxembourgish"},
	{"country":"Macedonia, Republic of","nationality":"Macedonian"},
	{"country":"Madagascar","nationality":"Malagasy"},
	{"country":"Malawi","nationality":"Malawian"},
	{"country":"Malaysia","nationality":"Malaysian"},
	{"country":"Maldives","nationality":"Maldivian"},
	{"country":"Mali","nationality":"Malian, Malinese"},
	{"country":"Malta","nationality":"Maltese"},
	{"country":"Marshall Islands","nationality":"Marshallese"},
	{"country":"Martinique","nationality":"Martiniquais, Martinican"},
	{"country":"Mauritania","nationality":"Mauritanian"},
	{"country":"Mauritius","nationality":"Mauritian"},
	{"country":"Mexico","nationality":"Mexican"},
	{"country":"Micronesia, Federated States of","nationality":"Micronesian"},
	{"country":"Moldova","nationality":"Moldovan"},
	{"country":"Monaco","nationality":"Monégasque, Monacan"},
	{"country":"Mongolia","nationality":"Mongolian"},
	{"country":"Montenegro","nationality":"Montenegrin"},
	{"country":"Morocco","nationality":"Moroccan"},
	{"country":"Mozambique","nationality":"Mozambican"},
	{"country":"Namibia","nationality":"Namibian"},
	{"country":"Nauru","nationality":"Nauruan"},
	{"country":"Nepal","nationality":"Nepali, Nepalese"},
	{"country":"Netherlands","nationality":"Dutch, Netherlandic"},
	{"country":"New Zealand","nationality":"New Zealand, NZ, Zelanian"},
	{"country":"Nicaragua","nationality":"Nicaraguan"},
	{"country":"Niger","nationality":"Nigerien"},
	{"country":"Nigeria","nationality":"Nigerian"},
	{"country":"Northern Mariana Islands","nationality":"Northern Marianan"},
	{"country":"Norway","nationality":"Norwegian"},
	{"country":"Oman","nationality":"Omani"},
	{"country":"Pakistan","nationality":"Pakistani"},
	{"country":"Palau","nationality":"Palauan"},
	{"country":"Palestine","nationality":"Palestinian"},
	{"country":"Panama","nationality":"Panamanian"},
	{"country":"Papua New Guinea","nationality":"Papua New Guinean, Papuan"},
	{"country":"Paraguay","nationality":"Paraguayan"},
	{"country":"Peru","nationality":"Peruvian"},
	{"country":"Philippines","nationality":"Filipino"},
	{"country":"Poland","nationality":"Polish"},
	{"country":"Portugal","nationality":"Portuguese"},
	{"country":"Puerto Rico","nationality":"Puerto Rican"},
	{"country":"Qatar","nationality":"Qatari"},
	{"country":"Romania","nationality":"Romanian"},
	{"country":"Russia","nationality":"Russian"},
	{"country":"Rwanda","nationality":"Rwandan"},
	{"country":"Saint Kitts and Nevis","nationality":"Kittitian or Nevisian"},
	{"country":"Saint Lucia","nationality":"Saint Lucian"},
	{"country":"Saint Vincent and the Grenadines","nationality":"Saint Vincentian, Vincentian"},
	{"country":"Samoa","nationality":"Samoan"},
	{"country":"San Marino","nationality":"Sammarinese"},
	{"country":"São Tomé and Príncipe","nationality":"São Toméan"},
	{"country":"Saudi Arabia","nationality":"Saudi, Saudi Arabian"},
	{"country":"Senegal","nationality":"Senegalese"},
	{"country":"Serbia","nationality":"Serbian"},
	{"country":"Seychelles","nationality":"Seychellois"},
	{"country":"Sierra Leone","nationality":"Sierra Leonean"},
	{"country":"Singapore","nationality":"Singapore, Singaporean"},
	{"country":"Slovakia","nationality":"Slovak"},
	{"country":"Slovenia","nationality":"Slovenian, Slovene"},
	{"country":"Solomon Islands","nationality":"Solomon Island"},
	{"country":"Somalia","nationality":"Somali"},
	{"country":"South Africa","nationality":"South African"},
	{"country":"South Sudan","nationality":"South Sudanese"},
	{"country":"Spain","nationality":"Spanish"},
	{"country":"Sri Lanka","nationality":"Sri Lankan"},
	{"country":"Sudan","nationality":"Sudanese"},
	{"country":"Suriname","nationality":"Surinamese"},
	{"country":"Swaziland","nationality":"Swazi"},
	{"country":"Sweden","nationality":"Swedish"},
	{"country":"Switzerland","nationality":"Swiss"},
	{"country":"Syria","nationality":"Syrian"},
	{"country":"Tajikistan","nationality":"Tajikistani"},
	{"country":"Tanzania","nationality":"Tanzanian"},
	{"country":"Thailand","nationality":"Thai"},
	{"country":"Timor-Leste","nationality":"Timorese"},
	{"country":"Togo","nationality":"Togolese"},
	{"country":"Tokelau","nationality":"Tokelauan"},
	{"country":"Tonga","nationality":"Tongan"},
	{"country":"Trinidad and Tobago","nationality":"Trinidadian or Tobagonian"},
	{"country":"Tunisia","nationality":"Tunisian"},
	{"country":"Turkey","nationality":"Turkish"},
	{"country":"Turkmenistan","nationality":"Turkmen"},
	{"country":"Tuvalu","nationality":"Tuvaluan"},
	{"country":"Uganda","nationality":"Ugandan"},
	{"country":"Ukraine","nationality":"Ukrainian"},
	{"country":"United Arab Emirates","nationality":"Emirati"},
	{"country":"United Kingdom of Great Britain and Northern Ireland","nationality":"UK, British"},
	{"country":"United States of America","nationality":"United States, U.S., American"},
	{"country":"Uruguay","nationality":"Uruguayan"},
	{"country":"Uzbekistan","nationality":"Uzbekistani, Uzbek"},
	{"country":"Vanuatu","nationality":"Ni-Vanuatu, Vanuatuan"},
	{"country":"Vatican City State","nationality":"Vatican"},
	{"country":"Venezuela","nationality":"Venezuelan"},
	{"country":"Vietnam","nationality":"Vietnamese"},
	{"country":"Yemen","nationality":"Yemeni"},
	{"country":"Zambia","nationality":"Zambian"},
	{"country":"Zimbabwe","nationality":"Zimbabwean"}];

	function compareStrings(a, b) {
		// Assuming you want case-insensitive comparison
		a = a.toLowerCase();
		b = b.toLowerCase();

		return (a < b) ? -1 : (a > b) ? 1 : 0;
	  }

	  Location.sort(function(a, b) {
		return compareStrings(a.nationality, b.nationality);//, compareStrings(a.country, b.country);
	  })

function Nationalitylist(props)
{
	let  Nationalitylist   = Location.length > 0 && Location.map((item,index)=>{
		return(
			<option key={index} id={item.nationality} value={item.nationality}>{item.nationality}</option>
		);
	})

	return(
		<>
			{
			props.required ?
				<select className={props.className} id={props.id} defaultValue={''}  required>
					<option disabled value={""}>{props.required} Select Nationality</option>
						{ Nationalitylist}
				</select>
			:

				<select className={props.className} id={props.id} defaultValue={''}  >
					<option disabled value={""}>{props.required} Select Nationality</option>
					{ Nationalitylist}
				</select>
			}
		</>
	)
}

function Residencelist(props)
{
	let Residencelist  = Location.length > 0 && Location.map((item,index)=>{
		return(
				<option key={index} id={item.country} value={item.country}>{item.country}</option>
		);
	})

	return(
		<select className={props.className} id={props.id} defaultValue={''} required>
			<option disabled value={''}>Select</option>
			{Residencelist}
		</select>
	)
}

function IdIssuedCountrylist(props)
{
	let CountryList  = Location.length > 0 && Location.map((item,index)=>{
		return(
				<option key={index} id={item.country} value={item.country}>{item.country}</option>
		);
	})

	return(
		<select className={props.className} id={props.id} defaultValue={''}>
			<option disabled value={''}>Select Country</option>
			{CountryList}
		</select>
	)
}


export {Nationalitylist, Residencelist,IdIssuedCountrylist};