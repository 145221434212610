
import React from 'react';
import $ from 'jquery';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import url_local from '../../url_local';

function GetCryptoAccounts(props){
    console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
    const [accountData,setAccountData] =  React.useState([])
    const [loading, setLoading] = React.useState(true);
     React.useEffect(() => {
      let headers = {
      	'Access-Token': secureLocalStorage.getItem('AT_'),
      	"Refresh-Token": secureLocalStorage.getItem('RT_')
      };
      let payload = { publicid: secureLocalStorage.getItem('PI_')}
      axios.post(url_local.accountlist, payload, { headers })
      .then(function (response) {
        setLoading(false);
      	if (response.data.Status === 1) {
          setAccountData(response.data.Data.crypto.list)
      	}
      })
      .catch(function () {
      });
  },[]);
    const handleonChange = () => {
// console.log($('option:selected', this))
      var value = $('option:selected', this).attr('value');
      var accountno =  $('option:selected', this).attr('data-accountno');
      secureLocalStorage.setItem("CS_",value);
      secureLocalStorage.setItem("AN_",accountno);
  };
  let cryptoLists = accountData.length > 0 && accountData.map((item, i) => {
    if(item.account === undefined || item.account === "" ||  item.account === null){
      return (
        <option key={i} disabled>No accounts created</option>
        )
    }
    else{
        return (
            <option key={i} value={item.type} data-accountno={item.account}>
                {item.type}
            </option>
        )
    }
  }, this);

    return(
        <>
        {
            loading?<div className="spinner-border spinner-border-sm " role="status"></div>:
            <select className={props.className} id={props.id} defaultValue={''} onChange={handleonChange}>
            <option disabled value={''}>Select one</option>
                {cryptoLists}
            </select>
        }
        </>
    )
}


export default GetCryptoAccounts;