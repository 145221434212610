import React from "react";
import { Link } from "react-router-dom";
// import { Row, Col, Card } from 'react-bootstrap';
import '../../../assets/styles/web/css/style.css';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)


function Footer1() {
    console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
    const refer1 = React.useRef('#home');
    const executeScrollBack = () => scrollToRef(refer1);

    return (
        <>
            <div className="footer cardLight">
                <div className="container px-5">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-md-12">
                            <div className="copyright">
                                <p>
                                    © Copyright {new Date().getFullYear()}{" "}
                                    <Link to={"/"} onClick={executeScrollBack}>Meezan</Link>  All Rights
                                    Reserved
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mr-auto">
                            <div className="footer-social">
                                <ul>
                                    <li>
                                        <Link to={"#"}>
                                            <i className="fa fa-facebook"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"#"}>
                                            <i className="fa fa-twitter"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"#"}>
                                            <i className="fa fa-linkedin"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"#"}>
                                            <i className="fa fa-youtube"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer1;
