// function iconImage(currency) {
// 	switch (currency) {
// 		case 'ETH/USDT'  : return require('../assets/icons/ETH.png');
// 		case 'BTC/USDT'  : return require('../assets/icons/BTC.png');
// 		case 'BNB/USDT'  : return require('../assets/icons/BNB.png');
// 		case 'SOL/USDT'  : return require('../assets/icons/SOL.png');
// 		case 'BUSD/USDT' : return require('../assets/icons/BUSD.png');
// 		case 'DOGE/USDT' : return require('../assets/icons/DOGE.png');
// 		case 'SHIB/USDT' : return require('../assets/icons/SHIB.png');
// 		default:
// 			return require('../assets/icons/faq-active.png');
// 	}
// }

// var React = require('react');

 var currencyIcon = 
    [{"name":'ETH/USDT' ,"image": require('./icons/ETH.png')},
    {"name":'BTC/USDT' ,"image": require('./icons/BTC.png')},
    {"name":'BNB/USDT' ,"image": require('./icons/BNB.png')},
    {"name":'SOL/USDT' ,"image": require('./icons/SOL.png')},
    {"name":'BUSD/USDT' ,"image": require('./icons/BUSD.png')},
    {"name":'DOGE/USDT' ,"image": require('./icons/DOGE.png')},
    {"name":'SHIB/USDT' ,"image": require('./icons/SHIB.png')},

    {"name":'BCH/USDT' ,"image": require('./icons/BCH.png')},
    {"name":'DOT/USDT' ,"image": require('./icons/DOT.png')},
    {"name":'XRP/USDT' ,"image": require('./icons/XRP.png')},
    {"name":'XLM/USDT' ,"image": require('./icons/XLM.png')},
    {"name":'ADA/USDT' ,"image": require('./icons/ADA.png')},
    {"name":'LTC/USDT' ,"image": require('./icons/LTC.png')},
    {"name":'APE/USDT' ,"image": require('./icons/APE.png')},
    {"name":'DAI/USDT' ,"image": require('./icons/DAI.png')},
    {"name":'UNI/USDT' ,"image": require('./icons/UNI.png')}
 ]


export default currencyIcon;

