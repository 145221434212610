import React, { useEffect } from "react";
import $ from 'jquery';
// import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

// form
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';

// components
// import Spinner from '../../shared/Spinner';
import url_local from '../../url_local';

import { IdIssuedCountrylist } from '../../shared/Nationalities';

// import '../../assets/styles/web/css/style.css';
import routeCodes from "../../../Paths";
// import userimage from '../../../assets/images/avatar/user.png';

// var kycdata = [];
function padTo2Digits(num) {
	return num.toString().padStart(2, '0');
}

function formatDate(date) {
	var dateReceived = new Date(date);
	return (
		[
			dateReceived.getFullYear(),
			padTo2Digits(dateReceived.getMonth() + 1),
			padTo2Digits(dateReceived.getDate()),
		].join('-')
	);
}


function IdCardSubmit() {
	console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
	const history = useNavigate();
	// const ref = useRef(null);
	// const [loading, setLoading] = React.useState(false);
	// const [userdata, setuserdata] = React.useState([]);
	// const [show, setShow] = React.useState("false");
	// const handleShow = () => setShow(true);
	const [accesslevel,SetAccesslevel] = React.useState(0);
	// const [show1, setShow1] = React.useState(false);

	// const handleClose1 = () => setShow1(false);
	// const handleShow1 = () => setShow1(true);
	// const initialState = { alt: "", src: "",profiledata:null };
	const initialStatefront = { srcfront: "", profiledatafront: undefined };
	const initialStateback = { srcback: "", profiledataback: undefined };
	// const [{ alt, src, profiledata }, setPreview] = React.useState(initialState);
	const [{ altfront, srcfront, profiledatafront }, setPreviewfront] = React.useState(initialStatefront);
	const [{ altback, srcback, profiledataback }, setPreviewback] = React.useState(initialStateback);
	const media = React.useRef("media/")


	useEffect(() => {
		SetAccesslevel(secureLocalStorage.getItem('AL_'));
		if (!url_local.home.startsWith("http://192.168",0)){
			media.current = "";
			console.log("clear media/",url_local.home);
		}
		var headers = {
			"Content-Type": "application/json",
			'Access-Token': secureLocalStorage.getItem('AT_'),
			"Refresh-Token": secureLocalStorage.getItem('RT_')
		};
		let payload = { publicid: secureLocalStorage.getItem('PI_') }
		try{
    	axios.post(url_local.kyc, payload, headers)
			.then(function (response) {
				if (response.data.Status === 1) {
					// setuserdata(response.data.Data);
					document.getElementById('IdIssuedon').value = formatDate(response.data.Data.idissuedon);
					document.getElementById('IdExpiry').value = formatDate(response.data.Data.idexpireson);
					document.getElementById('idnumber').value = response.data.Data.idno;
					document.getElementById('idAuthority').value = response.data.Data.idissuedby;

					if (response.data.Data.isidverified === false) {
						document.getElementById('kycbreadcrumb').style.display = 'block';
					}
					else {
						document.getElementById('kycbreadcrumb').style.display = 'none';
					}

					try {
						$("#idType").find('option[value=' + response.data.Data.iddoctype.toLowerCase() + ']')[0].defaultSelected = true;
					} catch (e) {
						// console.log('Error response.data.Data.iddoctype');
					}
					try {
						$("#idissued_countrySelect").find('option[value=' + response.data.Data.idissued_country + ']')[0].defaultSelected = true;
					} catch (e) {
						// console.log('Error response.data.Data.idissued_country');
					}
					document.getElementById('idnumber').value = response.data.Data.idno;
					document.getElementById('idAuthority').value = response.data.Data.idissuedby;
					document.getElementById("id_front").setAttribute("src", url_local.home +media.current+ response.data.Data.iddoc_front);
					document.getElementById("id_front").setAttribute("alt", media.current+response.data.Data.iddoc_front);

					document.getElementById("id_back").setAttribute("src", url_local.home +media.current+ response.data.Data.iddoc_back);
					document.getElementById("id_back").setAttribute("alt", media.current+response.data.Data.iddoc_back);
				}
				else if (response.data.Status === 0) {
					if (response.data.Code === 3 || response.data.code === 7) {
						$(".modal-title").html("");
						$(".modal-body").html("<p class=class_p>Your session expired.</p>");
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=closesessionclosebtn>ok</button>");
						$("body").addClass('no-select'); secureLocalStorage.clear();
						$("#closesessionclosebtn").addClass('btn btn-block');
						$("#closesessionclosebtn").on("click", function () {
							$("#modalDialog").toggle('hide');
							window.location.href = "/";
							});
							if($("#modalDialog" ).hasClass( "show" )){
							$(".main-panel").css("filter","blur(8px)");
							}
							else{
								$(".main-panel").css("filter","blur(8px)");
								$("#modalDialog").toggle('show');
							}
					}
					else {
						$(".modal-title").html("");
						$(".modal-body").html(response.data.Message);
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=closebtn>ok</button>");
						$("#closebtn").addClass('btn btn-block');
						$("#closebtn").on("click", function () {
							$("#modalDialog").toggle('hide');
						});
						$("#modalDialog").toggle('show');
					}
				}
				else {
					$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again.</p>");
					$(".modal-title").html("")
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=got_it>Got it</button>");
					$("#got_it").addClass("btn btn-block");
					$("#got_it").on("click", function () {
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
				}
				return;
			})
			.catch(function () {
				$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again.</p>");
				$(".modal-title").html("")
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=got_it>Got it</button>");
				$("#got_it").addClass("btn btn-block");
				$("#got_it").on("click", function () {
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
				return;
			});
		} catch (error) {
			console.error(error.response.data);
			  if (error.response.status === 500) {
				$(".modal-title").html("")
				$(".modal-body").html("<p class=class_p><!-- IdCardSubmit.js -->Sorry! We have encountered server issue. Try again later!</p>");
				$(".modal-title").html("")
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=ok>OK</button>");
				$("#ok").addClass("btn btn-block");
				$("#ok").on("click", function () {
				  $("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
			  }
		  }
		// loadData();
	}, [history]);

	const fileHandlerfront = event => {
		var { files } = event.target;

		if (files.length) {
			setPreviewfront(
				files.length
					? {
						// srcfront: URL.createObjectURL(files[0]),
						// profiledatafront: URL.createObjectURL(files[0]),
						srcfront: URL.createObjectURL(files[0]),
						altfront: files[0].name,
						profiledatafront: files[0],
					}
					: initialStatefront
			);
			document.getElementById("uploadbtnfront").disabled = false;
		}
		else {
			document.getElementById("uploadbtnfront").disabled = true;
			$(".modal-title").html("");
			$(".modal-body").html("<p className='text-danger'>Please upload a clear and valid document!</p>");
			$(".modal-footer").html("");
			$(".modal-footer").html("<button id=closebtn>Continue</button>");
			$("#closebtn").addClass('btn btn-block');
			$("#closebtn").on("click", function () {
				$("#modalDialog").toggle('hide');
			});
			$("#modalDialog").toggle('show');
		}

	};

	const fileHandlerback = event => {
		var { files } = event.target;
		if (files.length) {
			setPreviewback(
				files.length
					? {
						// srcback: URL.createObjectURL(files[0]),
						// profiledataback: URL.createObjectURL(files[0]),
						srcback: URL.createObjectURL(files[0]),
						altback: files[0].name,
						profiledataback: files[0],
					}
					: initialStateback
			);
			document.getElementById("uploadbtnback").disabled = false;
		}
		else {
			document.getElementById("uploadbtnback").disabled = true;
			$(".modal-title").html("");
			$(".modal-body").html("<p className='text-danger'>Please upload a clear and valid document!</p>");
			$(".modal-footer").html("");
			$(".modal-footer").html("<button id=closebtn>Continue</button>");
			$("#closebtn").addClass('btn btn-block');
			$("#closebtn").on("click", function () {
				$("#modalDialog").toggle('hide');
			});
			$("#modalDialog").toggle('show');
		}

	};

	const handleimaguploadidprooffront = (e) => {
		var reader = new FileReader();
		if (profiledatafront !== "" || profiledatafront !== undefined) {
			reader.readAsDataURL(profiledatafront);
			reader.onloadend = function () {
				var headers = {
					'Content-Type': 'multipart/form-data',
					'Access-Token': secureLocalStorage.getItem('AT_'),
					"Refresh-Token": secureLocalStorage.getItem('RT_')
				};

				let data = {
					"publicid": secureLocalStorage.getItem('PI_'),
					"purpose": "identity",
					"id_front": reader.result,
					"front_file_name": altfront,
					"comments": "purpose = Options - [“profile” ,”identity”,”pop”]",
				}
				axios.post(url_local.uploadimage, data, { headers })
					.then(function (response) {
						// console.log(response.data.Status, "front image upload")
						if (response.data.Status === 1) {
							update_user_info();
						}
						else if (response.data.Data.Status === 0) {
							$(".modal-title").html("");
							$(".modal-body").html("<p className='text-danger'>" + response.data.Data.Response + "</p>");
							$(".modal-footer").html("");
							$(".modal-footer").html("<button id=closebtn>Got it</button>");
							$("#closebtn").addClass('btn btn-block');
							$("#closebtn").on("click", function () {
								$("#modalDialog").toggle('hide');
							});
							$("#modalDialog").toggle('show');
						}
						else {
							$(".modal-body").html("<p className='text-danger'>Something went wrong. Try again!</p>");
							$(".modal-title").html("")
							$(".modal-footer").html("");
							$(".modal-footer").html("<button id=closebtn>Got it</button>");
							$("#closebtn").addClass('btn btn-block');
							$("#closebtn").on("click", function () {
								$("#modalDialog").toggle('hide');
							});
							$("#modalDialog").toggle('show');
						}
						return;
					})
					.catch(function () {
						return;
					});
			}
		}
		else {
			$(".modal-title").html("");
			$(".modal-body").html("<p className='text-danger'>Please upload a clear and valid document!</p>");
			$(".modal-footer").html("");
			$(".modal-footer").html("<button id=closebtn>Continue</button>");
			$("#closebtn").addClass('btn btn-block');
			$("#closebtn").on("click", function () {
				$("#modalDialog").toggle('hide');
			});
			$("#modalDialog").toggle('show');
		}
	}

	const handleimaguploadidproofback = (e) => {
		var reader = new FileReader();
		if (profiledataback !== "" || profiledataback !== "undefined") {
			reader.readAsDataURL(profiledataback);
			reader.onloadend = function () {
				var headers = {
					'Content-Type': 'multipart/form-data',
					'Access-Token': secureLocalStorage.getItem('AT_'),
					"Refresh-Token": secureLocalStorage.getItem('RT_')
				};

				// var data = {
				// 	publicid: secureLocalStorage.getItem('PI_'),
				// 	file_type: altback,
				// 	user_image: reader.result,
				// 	user_image_type: 2
				// }
				let data = {
					"publicid": secureLocalStorage.getItem('PI_'),
					"purpose": "identity",
					"id_back": reader.result,
					"back_file_name": altback,
					"comments": "purpose = Options - [“profile” ,”identity”,”pop”]",
				}
// console.log("image data", data)
				axios
					.post(url_local.uploadimage,
						data,
						{ headers })
					.then(function (response) {
						if (response.data.Status === 1) {
							update_user_info();
						}
						else if (response.data.Data.Status === 0) {
							$(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
							$(".modal-title").html("")
							$(".modal-footer").html("");
							$(".modal-footer").html("<button id=redirect>Got it</button>");
							$("#redirect").addClass("btn btn-block");
							$("#redirect").on("click", function () {
								$("#modalDialog").toggle('hide');
							});
							$("#modalDialog").toggle('show');
						}
						else {
							$(".modal-body").html("<p className='text-danger'>Something went wrong. Try again.</p>");
							$(".modal-title").html("")
							$(".modal-footer").html("");
							$(".modal-footer").html("<button id=redirect>Got it</button>");
							$("#redirect").addClass("btn btn-block");
							$("#redirect").on("click", function () {
								$("#modalDialog").toggle('hide');
							});
							$("#modalDialog").toggle('show');
						}
						return;
					})
					.catch(function () {
						$(".modal-body").html("<p className='text-danger'>Something went wrong. Try again.</p>");
						$(".modal-title").html("")
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=redirect>Got it</button>");
						$("#redirect").addClass("btn btn-block");
						$("#redirect").on("click", function () {
							$("#modalDialog").toggle('hide');
						});
						$("#modalDialog").toggle('show');
						return;
					});
			}
		}
	}

	const validation = () => {
		var idnumber = document.getElementById('idnumber').value;
		var iddoctype = $("#idType").find('option:selected', this).val();
		var idissuedby = document.getElementById('idAuthority').value;
		var startDate = document.getElementById("IdIssuedon").value;
		var endDate = document.getElementById("IdExpiry").value;
		var idissued_countrySelect = $("#idissued_countrySelect").find('option:selected', this).val();
		// var id_front = document.getElementById("id_front").getAttribute("alt");
		console.log("0");
		if (idnumber === "" || iddoctype === "" || idissuedby === "" || startDate === "" || endDate === "" || idissued_countrySelect === "") {
			$(".modal-title").html("");
			$(".modal-body").html("<p class=class_p>Please fill all mandatory fields *</p>");
			$(".modal-footer").html("");
			$(".modal-footer").html("<button id=closebtn>Continue</button>");
			$("#closebtn").addClass('btn btn-block');
			$("#closebtn").on("click", function () {
				$("#modalDialog").toggle('hide');
			});
			$("#modalDialog").toggle('show');
		}
		else if (profiledatafront !== undefined) {
			console.log("1");
			handleimaguploadidprooffront();
		}
		else if (profiledataback !== undefined) {
			console.log("2");
			handleimaguploadidproofback();
		}
		else{
			update_user_info();
		}
		// else {
		// 	id_front = document.getElementById("id_front").getAttribute("alt")
		// 	if (profiledatafront === undefined || profiledatafront === "" || id_front === "") {
		// 		$(".modal-title").html("");
		// 		$(".modal-body").html("<p class=class_p>* Please upload a valid and clear document</p>");
		// 		$(".modal-footer").html("");
		// 		$(".modal-footer").html("<button id=closebtn>Continue</button>");
		// 		$("#closebtn").addClass('btn btn-block');
		// 		$("#closebtn").on("click", function () {
		// 			$("#modalDialog").toggle('hide');
		// 		});
		// 		$("#modalDialog").toggle('show');
		// 	}
		// 	else {
		// 		handleimaguploadidprooffront();
		// 	}
		// }
	}

	//update kyc info
	const update_user_info = () => {
		var startDate = document.getElementById("IdIssuedon").value;
		var endDate = document.getElementById("IdExpiry").value;
		// token = secureLocalStorage.getItem('AT_');

// console.log(startDate, endDate)
		var headers = {
			'Content-Type': 'multipart/form-data',
			'Access-Token': secureLocalStorage.getItem('AT_'),
			"Refresh-Token": secureLocalStorage.getItem('RT_')
		};

		var data = {
			publicid: secureLocalStorage.getItem('PI_'),
			iddoctype: $("#idType").find('option:selected', this).val(),
			idexpireson: endDate,
			idissuedby: document.getElementById('idAuthority').value,
			idissuedon: startDate,
			idissued_country: $("#idissued_countrySelect").find('option:selected', this).val(),
			idno: document.getElementById('idnumber').value,
		}
// console.log("data given", data)
		axios.post(url_local.kyc, data, headers)
			.then(function (response) {
				if (response.data.Status === 1) {
					$(".modal-body").html("KYC updated successfully");
					$(".modal-title").html("")
					$(".modal-footer").html("");
					$(".modal-footer").html("<a id=closebtn href=" + routeCodes.IDVERIFY + ">Got it</a>");
					$("#closebtn").addClass('btn btn-block');
					$("#modalDialog").toggle('show');
				}
				else if (response.data.Status === 0) {
					if (response.data.Code === 3 || response.data.code === 7) {
						$(".modal-title").html("");
						$(".modal-body").html("<p class=class_p>Your session expired.</p>");
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=closesessionclosebtn>ok</button>");
						$("body").addClass('no-select'); secureLocalStorage.clear();
						$("#closesessionclosebtn").addClass('btn btn-block');
						$("#closesessionclosebtn").on("click", function () {
							$("#modalDialog").toggle('hide');
							window.location.href = "/";
							});
							if($("#modalDialog" ).hasClass( "show" )){
							$(".main-panel").css("filter","blur(8px)");
							$("#modalDialog").toggle('show');
							}
							else{
								$(".main-panel").css("filter","blur(8px)");
								$("#modalDialog").toggle('show');
							}
					}
					else {
						$(".modal-title").html("");
						$(".modal-body").html(response.data.Message);
						$(".modal-footer").html("");
						$(".modal-footer").html("<button id=closebtn>ok</button>");
						$("#closebtn").addClass('btn btn-block');
						$("#closebtn").on("click", function () {
							$("#modalDialog").toggle('hide');
						});
						$("#modalDialog").toggle('show');
					}
				}
				else {
					$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again.</p>");
					$(".modal-title").html("")
					$(".modal-footer").html("");
					$(".modal-footer").html("<button id=got_it>Got it</button>");
					$("#got_it").addClass("btn btn-block");
					$("#got_it").on("click", function () {
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
				}
				return;
			})
			.catch(function () {
				return;
			});
	}

	function fileHandlerbtn_clickback(e) {
		e.target.style.display = "none";
		document.getElementById("fileHandlerback").style.display = "block";
		document.getElementById("uploadbtnback").disabled = true;
	}

	function fileHandlerbtnclear_clickback(e) {
		document.getElementById("fileHandlerbackbtn").style.display = "block";
		document.getElementById("fileHandlerback").style.display = "none";
		let revert = document.getElementById("id_back").getAttribute('alt');
		// alert(url_local.home +revert.trim());
		document.getElementById("id_back").setAttribute("src", '');
		document.getElementById("id_back").setAttribute("src", url_local.home + revert.trim());
		// document.getElementById("id_back").setAttribute("alt",revert);
		// document.getElementById("fileHandlerback").firstChild.value= "";
		// setPreviewback(initialStateback);
	}

	function fileHandlerbtn_clickfront(e) {
		e.target.style.display = "none";
		document.getElementById("fileHandlerfront").style.display = "block";
		document.getElementById("uploadbtnfront").disabled = true;
	}
	function fileHandlerbtnclear_clickfront(e) {
		document.getElementById("fileHandlerfrontbtn").style.display = "block";
		document.getElementById("fileHandlerfront").style.display = "none";
		let revert = document.getElementById("id_front").getAttribute('alt');
		// alert(url_local.home +revert.trim());
		document.getElementById("id_front").setAttribute("src", '');
		document.getElementById("id_front").setAttribute("src", url_local.home + revert.trim());
		// document.getElementById("id_front").setAttribute("alt",revert);
		// document.getElementById("fileHandlerfront").firstChild.value= "";
		// setPreviewfront(initialStatefront);
	}


	// if (loading) {
	// 	return (<Spinner />)
	// }
	// else
	return (
		<div className="card">
			<div className="page-header px-4 row">
				<h3 className="page-title col-xs-12 col-md-9">
					<span className="page-title-icon bg-success text-white mr-2">
						<i className="mdi mdi-account"></i>
					</span>ID verification</h3>
				<div className="col-xs-12 col-md-3 float-right">
					{
					accesslevel <= 1 ?
						<nav aria-label="breadcrumb" id="kycbreadcrumb" style={{ display: 'none' }}>
							<ul className="breadcrumb">
								<li className="breadcrumb-item active" aria-current="page">
									<div className="alert alert-warning mt-2" role="alert" id='formAlert'>
										Please complete KYC for full access.</div>
								</li>
							</ul>
						</nav>
						:
						accesslevel <= 2 ?
						<nav aria-label="breadcrumb" id="kycbreadcrumb" style={{ display: 'none' }}>
							<ul className="breadcrumb">
								<li className="breadcrumb-item active" aria-current="page">
									<div className="alert alert-warning mt-2" role="alert" id='formAlert'>
										KYC Pending for Approval</div>
								</li>
							</ul>
						</nav>
						:<>
						</>
					}
				</div>
			</div>

			<div className="card-body mt-0">
				<label className="card-description">Only the following documents listed below will be accepted, all other documents will be rejected.</label>
				{/* <FormProvider methods={methods} onSubmit={handleSubmit(update_user_info)}> */}
				<p className="class_p text-danger">Required( * )</p>
				<div className="row mt-2" >
					<div className="col-md-6 col-xs-12">
						<div className="form-group row">
							<label className="col-sm-3 col-form-label class_p">ID type *</label>
							<div className="col-sm-9 col-xs-12">
								<select className="form-control-sm form-select form-select-sm" id="idType">
									<option value="national_id">National ID</option>
									<option value="passport">Passport</option>
									<option value="license">Driving License</option>
								</select>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-xs-12">
						<div className="form-group row">
							<label className="col-sm-3 col-form-label class_p">ID number *</label>
							<div className="col-sm-9 col-xs-12">
								<input type="text" className="form-control form-control-sm" id="idnumber"
									placeholder="0024 5687 2254 3698" name="idnumber" />
								{/* <RHFTextField type="text" name="idnumber" placeholder="0024 5687 2254 3698" id="idnumber"	/> */}
							</div>
						</div>
					</div>

					<div className="col-md-6 col-xs-12">
						<div className="form-group row">
							<label className="col-sm-3 col-form-label class_p">Issued by *</label>
							<div className="col-sm-9 col-xs-12">
								<input type="text" className="form-control form-control-sm" id="idAuthority"
									placeholder="Type the Authority name as given in ID" name="issuedby" />
								{/* <RHFTextField type="text" name="issuedby"
																	placeholder="Type the Authority name as given in ID" id="issuedby"	/> */}
							</div>
						</div>
					</div>

					<div className="col-md-6 col-xs-12">
						<div className="form-group row">
							<label className="col-sm-3 col-form-label class_p">Issued Country *</label>
							<div className="col-sm-9 col-xs-12">
								<IdIssuedCountrylist className="form-control form-control-sm" id="idissued_countrySelect"
									name="idissued_country" />
							</div>
						</div>
					</div>

					<div className="col-md-6 col-xs-12">
						<div className="form-group row">
							<label className="col-sm-3 col-form-label class_p">Issued date *</label>
							<div className="col-sm-9 col-xs-12">
								<input id="IdIssuedon" className="form-control form-control-sm" type="date" max={formatDate(new Date())} />

							</div>
						</div>
					</div>

					<div className="col-md-6 col-xs-12">
						<div className="form-group row">
							<label className="col-sm-3 col-form-label class_p">Expiry date *</label>
							<div className="col-sm-9 col-xs-12">
								<input id="IdExpiry" className="form-control" type="date" min={formatDate(new Date())} />

							</div>
						</div>
					</div>

					<div className="text-center mt-3 mb-3" >
						<button className="btn btn-sm btn-yodigi-buy" id="update_all"
							onClick={validation}>Update all</button>
					</div>
					<div className="text-center mt-3">
						<h4 className="card-description">Upload your ID card</h4>
						<span className="class_p">
							(Uploading your ID helps to ensure the safety and security of your funds)
						</span>
					</div>

					{/* <div className="text-center mt-3 mb-4" >
													<button className="btn btn-sm btn-yodigi-buy" onClick={ContinueToLoadPhoto}>Continue</button>
											</div> */}
				</div>



				<div className="row">
					<div className="col-xs-12 col-md-6">
						<div className="mb-3">
							<label className="form-label">
								Upload Front side of ID *
							</label>
							{/* <div class="file-upload-wrapper" data-text="front.jpg">
																	<input name="file-upload-field" type="file" class="file-upload-field" /></div> */}
							<div className="addNew">
								<div>
									<img id="id_front" alt="" className="frontidpic img-fluid img-thumbnail d-block"
										style={{ maxHeight: "300px" }}
										onError={(e) => {
											// e.target.src = 'https://placeimg.com/200/200/tech/grayscale' // some replacement image

											e.target.src = 'https://via.placeholder.com/150'
											e.target.style = 'outline: 0px; margin: 0px; max-height:150px;' // inline styles in html format
										}}
										src={srcfront} />
								</div>
								<button type="button" className="btn btn-sm mt-1" id="fileHandlerfrontbtn" onClick={fileHandlerbtn_clickfront}>+ Upload New</button>
							</div>


							{/* <div className="input-group" id="fileHandler" style={{ display: 'none' }}>
									<input accept="image/*" type="file" onChange={fileHandler} className="form-control h-100" />
										<button type="button" className="btn btn-outline-secondary image-preview-clear h-100" id="fileHandlerbtnclear">
											<i className="fa fa-remove"></i> Clear
										</button>
								</div> */}

							<div className="input-group" id="fileHandlerfront" style={{ display: 'none' }}>
								<input accept="image/*" type="file" onChange={fileHandlerfront} className="form-control d-inline w-50 h-100" readOnly />
								<button type="button" className="btn btn-outline-secondary image-preview-clear h-100"
									id="fileHandlerfrontbtnclear" onClick={fileHandlerbtnclear_clickfront}>
									<span className="fa fa-remove fs-5"></span> Clear
								</button>
								<button className="btn btn-yodigi-buy" id="uploadbtnfront"
									onClick={handleimaguploadidprooffront}>
									Upload</button>
							</div>
						</div>
					</div>
					<div className="col-xs-12 col-md-6">
						<div className="mb-3"><label className="form-label">Upload Back side of ID (optional)</label>
							<div>
								<img id="id_back" alt="" className="frontidpic img-fluid img-thumbnail d-block"
									style={{ maxHeight: "300px" }}
									onError={(e) => {
										// e.target.src = 'https://placeimg.com/200/200/tech/grayscale' // some replacement image

										e.target.src = 'https://via.placeholder.com/150'
										e.target.style = 'outline: 0px; margin: 0px; max-height:150px;' // inline styles in html format
									}}
									src={srcback} />
							</div>
							<div className="addNew">
								<button type="button" className="btn btn-sm" id="fileHandlerbackbtn" onClick={fileHandlerbtn_clickback}>+ Upload New</button>

								<div className="input-group" id="fileHandlerback" style={{ display: 'none' }}>
									<input accept="image/*" type="file" onChange={fileHandlerback} className="form-control d-inline w-50 h-100" readOnly/>
									<button type="button" className="btn btn-outline-secondary image-preview-clear h-100" id="fileHandlerbackbtnclear" onClick={fileHandlerbtnclear_clickback}>
										<span className="fa fa-remove fs-5"></span> Clear
									</button>
									<button className="btn btn-yodigi-buy" id="uploadbtnback"
									onClick={handleimaguploadidproofback}>
									Upload</button>
								</div>
							</div>
							{/* <span className="float-right text-light small mt-1 mx-1">Maximum file size is 2mb</span> */}
						</div>
					</div>
				</div>
				<p className="text-muted mt-3">Ensure that the document meet all the conditions below:</p>
				<ul className="mt-1 mb-5">
					<li className="card-description">* Readable, clear and well-lit</li>
					<li className="card-description">* Should Not be reflective, not blurry</li>
					<li className="card-description">* No black and white images, no edited images should be submitted.</li>
					<li className="card-description">* Document is placed against a solid-colored background.</li>
				</ul>

			</div>
		</div>
	)
}

export default IdCardSubmit;