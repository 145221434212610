import React from "react";
import $ from 'jquery';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FormProvider, RHFTextField } from '../components/hook-form';
import { IconButton, InputAdornment } from '@mui/material';
import secureLocalStorage from 'react-secure-storage';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//mui
import { LoadingButton } from '@mui/lab';

//components
import Iconify from '../components/Iconify';
import UserPin from './wallet/userpin';
import url_local from '../url_local';
// import routeCodes from "../../Paths";
import { GlobalContext } from '../shared/useForm';

function Security() {
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  // const history = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [getdata, setGetdata] = React.useState(false);
  const gContext = React.useContext(GlobalContext);
  const mousedown = React.useRef(false);
	const mouseover = React.useRef(false);

  const LoginSchema = Yup.object().shape({
    currentpswd: Yup.string().required('* This field is required').matches(/^\S*$/, 'Whitespace is not allowed'),
    newpassword: Yup.string()
      .required("* This field is required")
      .min(8, "Min 8 characters, 1 Uppercase, 1 Special Case Character,1 Number required.")
      .matches(/^(?=.*[A-Z])/, 'Must Contain One Uppercase')
      .matches(/^\S*$/, 'Whitespace is not allowed')
      .matches(/^(?=.*[!@#$%^&*])/, "Must Contain One Special Case Character")
      .matches(/^(?=.*[0-9])\D*\d/, "Must Contain One Number")
      .max(15),
    confirmpassword: Yup.string()
      .required("* This field is required")
      .min(8, "Min 8 characters, 1 Uppercase, 1 Special Case Character,1 Number required.")
      .matches(/^(?=.*[A-Z])/, 'Must Contain One Uppercase')
      .matches(/^\S*$/, 'Whitespace is not allowed')
      .matches(/^(?=.*[!@#$%^&*])/, "Must Contain One Special Case Character")
      .matches(/^(?=.*[0-9])\D*\d/, "Must Contain One Number")
      .max(15),
  });

  const defaultValues = {
    currentpswd: '',
    newpassword: '',
    confirmpassword: ''
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const LogoutAccount = () => {
    $(".modal-title").html("Password updated");
    $(".modal-body").html("<p class=class_p>You have to sign in with new password</p>");
    $(".modal-footer").html("");
    $(".modal-footer").html("<button id=signoutbtn>Logout</button>");
    $("#signoutbtn").addClass('btn btn-block');
    $("#signoutbtn").on('click', function () {
      var headers = {
        "Content-Type": "application/json",
        'Access-Token': secureLocalStorage.getItem('AT_'),
        'Refresh-Token': secureLocalStorage.getItem('RT_')
      }
      axios
        .post(url_local.logout, { publicid: secureLocalStorage.getItem('PI_') },
          { headers })
        .then(function (response) {
          if (response.data.Status === 1) {
            if (gContext) {
              gContext.signout();
              return;
            }
          }
          else if (response.data.Status === 0) {
            $(".modal-body").html("<p class=class_p" > +response.data.Message + "</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=delete>Got it</button>");
            $("#delete").addClass("btn btn-block");
            $("#delete").on('click', function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
          else {
            $(".modal-title").html("");
            $(".modal-body").html("<p class='class_p text-danger'>Something went wrong. Try again.</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=delete>Got it</button>");
            $("#delete").addClass("btn btn-block");
            $("#delete").on('click', function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
        })
        .catch(function (error) {
          localStorage.setItem('referTodashboard', true);
          return;
        });
    });
    $("#modalDialog").toggle('show');
  }

  const changePassword = () => {
    setGetdata(true);
    var password1 = document.getElementById('pswd').value;
    var password2 = document.getElementById('pswdconfirm').value;
    if ((password1 === password2)) {
      var headers = {
        "content-Type": "application/json",
        "Access-Token": secureLocalStorage.getItem('AT_'),
        "Refresh-Token": secureLocalStorage.getItem('RT_'),
      }
      var data ={
        "publicid": secureLocalStorage.getItem('PI_'),
        "old_password": document.getElementById('currentpswd').value,
        "new_password": document.getElementById('pswdconfirm').value,
      }

// console.log("data",data)
      axios
        .post(url_local.passwordupdate,
          data,
          { headers },{ timeout: 5000 })
        .then(function (response) {
// console.log(response.data)
          if (response.data.Status === 1) {
            setGetdata(false);
            document.getElementById('currentpswd').value = "";
            document.getElementById('pswd').value = "";
            document.getElementById('pswdconfirm').value = "";
            LogoutAccount();
          }
          else if (response.data.Status === 0) {
            setGetdata(false);
            if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
              $(".modal-title").html("");
              $(".modal-body").html("<p class=class_p>Your session expired.</p>");
              $(".modal-footer").html("");
              $(".modal-footer").html("<button id=closesessionclosebtn>ok</button>");
              $("body").addClass('no-select'); secureLocalStorage.clear();
              $("#closesessionclosebtn").addClass('btn btn-block');
              $("#closesessionclosebtn").on("click", function () {
                $("#modalDialog").toggle('hide');
                window.location.href = "/";
              });
              if($("#modalDialog" ).hasClass( "show" )){
                $(".main-panel").css("filter","blur(8px)");
              }
              else{
                  $(".main-panel").css("filter","blur(8px)");
                  $("#modalDialog").toggle('show');
              }
            }
            else {
              $(".modal-title").html("");
              $(".modal-body").html("<p class=class_p>" + response.data.Response + "</p>");
              $(".modal-footer").html("");
              $(".modal-footer").html("<button id=closebtn>ok</button>");
              $("#closebtn").addClass('btn btn-block');
              $("#closebtn").on("click", function () {
                $("#modalDialog").toggle('hide');
              });
              $("#modalDialog").toggle('show');
            }
          }
          else {
            setGetdata(false);
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p text-danger>Something went wrong. Try again.</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=delete>Got it</button>");
            $("#delete").addClass("btn btn-block");
            $("#modalDialog").toggle('show');
            $("#delete").on('click', function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
          return;
        })
        .catch(function (err) {
          setGetdata(true);
          if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
          // secureLocalStorage.clear();
          $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
          $(".modal-title").html("")
          $(".modal-footer").html("<button id=redirect>Got it</button>");
          $("#redirect").addClass("btn btn-block");
          $("#redirect").on("click", function () {
              $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
          }
          else{
              console.warn(err,"Security");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
              $(".modal-title").html("")
              $(".modal-footer").html("");
              $(".modal-footer").html("<button id=closebtn1>ok</button>");
              $("#closebtn1").addClass('btn btn-block');
              $("#closebtn1").on("click", function () {
                  $("#modalDialog").toggle('hide');
              });
              $("#modalDialog").toggle('show');
          }
          return;
        });
    }
    else {
      setGetdata(false);
      $(".modal-body").html("<p class='class_p'>Password doesn't match!</p>");
      $(".modal-title").html("")
      $(".modal-footer").html("");
      $(".modal-footer").html("<button id=ok>Got it</button>");
      $("#ok").addClass("btn btn-block");
      $("#ok").on('click', function () {
        $("#modalDialog").toggle('hide');
      });
      $("#modalDialog").toggle('show');
    }
  }

  const disableAccount = () => {
    $(".modal-title").html("");
    $(".modal-body").html("<h4 class=class_p>Are you sure to disable your account ?</h4>");
    $(".modal-footer").html("");
    $(".modal-footer").html("<button id=deletecontinue>Continue</button><button id=canceldelete>Cancel</button>");
    $("#deletecontinue").addClass("btn btn-sm btn-yodigi-danger");
    $("#canceldelete").addClass("btn btn-sm");
    $("#deletecontinue").on('click', function () {
      var headers = {
        "Content-Type": "application/json",
        'Access-Token': secureLocalStorage.getItem('AT_'),
        'Refresh-Token': secureLocalStorage.getItem('RT_')
      }
      axios
        .post(url_local.disableWallet, { publicid: secureLocalStorage.getItem('PI_') },
          { headers })
        .then(function (response) {
// console.log(response.data, "disable wallet-----------------")
          if (response.data.Status === 1) {
            if (gContext) {
              gContext.signout();
              return;
            }
          }
          else if (response.data.Status === 0) {
            if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
              // console.warn("inside", response.data.Data.Status)
              $("#modalDialog").toggle('hide');
              $(".modal-title").html("");
              $(".modal-body").html("<p class=class_p>Your session expired.</p>");
              $(".modal-footer").html("");
              $(".modal-footer").html("<button id=closesessionclosebtn>ok</button>");
              $("body").addClass('no-select'); secureLocalStorage.clear();
              $("#closesessionclosebtn").addClass('btn btn-block');
              $("#closesessionclosebtn").on("click", function () {
                $("#modalDialog").toggle('hide');
                window.location.href = "/";
              });
              if($("#modalDialog" ).hasClass( "show" )){
                $(".main-panel").css("filter","blur(8px)");
                $("#modalDialog").toggle('show');
              }
              else{
                  $(".main-panel").css("filter","blur(8px)");
                  $("#modalDialog").toggle('show');
              }
            }
            else {
              $(".modal-title").html("");
              $(".modal-body").html(response.data.Message);
              $(".modal-footer").html("");
              $(".modal-footer").html("<button id=closebtn>ok</button>");
              $("#closebtn").addClass('btn btn-block');
              $("#closebtn").on("click", function () {
                $("#modalDialog").toggle('hide');
              });
              $("#modalDialog").toggle('show');
            }
          }
          else {
            $(".modal-title").html("");
            $(".modal-body").html("<p class='class_p text-danger'>Something went wrong. Try again.</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=delete>Got it</button>");
            $("#delete").addClass("btn btn-block");
            $("#modalDialog").toggle('show');
            $("#delete").on('click', function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
        })
        .catch(function (error) {
          return;
        });
    });
    $("#canceldelete").on('click', function () {
      $("#modalDialog").toggle('hide');
    });
    $("#modalDialog").toggle('show');
  }

  const handleChange = (e) => {
    e.preventDefault();
  };

  const handleEvent = (event) => {
		// console.log(event.type);
		if (event.type === "mousedown") {
			mousedown.current = true;
		}
		else if (event.type === "mouseup") {
			mousedown.current = false;
		}

		if (event.type === "mouseover") {
			mouseover.current = true;
		}
		else if (event.type === "mouseout") {
			mouseover.current = false;
			mousedown.current = false;
		}

		if (mousedown.current && mouseover.current){
			setShowPassword(true);
		}
		else{
			setShowPassword(false);
		}
	}

  const handleEvent1 = (event) => {
		// console.log(event.type);
		if (event.type === "mousedown") {
			mousedown.current = true;
		}
		else if (event.type === "mouseup") {
			mousedown.current = false;
		}

		if (event.type === "mouseover") {
			mouseover.current = true;
		}
		else if (event.type === "mouseout") {
			mouseover.current = false;
			mousedown.current = false;
		}

		if (mousedown.current && mouseover.current){
			setShowPassword1(true);
		}
		else{
			setShowPassword1(false);
		}
	}
  const handleEvent2 = (event) => {
		// console.log(event.type);
		if (event.type === "mousedown") {
			mousedown.current = true;
		}
		else if (event.type === "mouseup") {
			mousedown.current = false;
		}

		if (event.type === "mouseover") {
			mouseover.current = true;
		}
		else if (event.type === "mouseout") {
			mouseover.current = false;
			mousedown.current = false;
		}

		if (mousedown.current && mouseover.current){
			setShowPassword2(true);
		}
		else{
			setShowPassword2(false);
		}
	}

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {/* internal container is div.card below */}
          <div className="card px-4">
            <div className="page-header w-100">
              <h3 className="page-title w-100 row">
                <div className='col-5'>
                  <span className="page-title-icon bg-success me-2">
                    <i className="mdi mdi-security"></i>
                  </span> Security settings
                </div>
                <div className='col'>
                  <div className="float-end">
                    <div className='text-lastlogin text-end fs-6'>
                      <i className="mdi mdi-account px-2"></i>Last login time
                      <label className='d-block'>{secureLocalStorage.getItem('DT_')}</label>
                    </div>
                  </div>
                </div>
              </h3>
            </div>
            {/* here content is placed */}
            <h5>Protect your account and transactions.</h5>
            <div className="accordion" id="accordionExample">
              <div className="mb-3 accordiondiv" id="headingOne">
                {/* <button className="btn btn-sm btn-block text-white text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> */}
                <h5><i className="mdi mdi-account-key"></i>&nbsp;Change Login password</h5>
                {/* </button> */}

              </div>
              {/* <div id="collapseOne" className="collapse show " aria-labelledby="headingOne" data-parent="#accordionExample"> */}
              <div className="card-body px-4 pb-2">
                <FormProvider methods={methods} onSubmit={handleSubmit(changePassword)}>
                  <label className="m-2 card-description">You can reset your password for login purpose any time.
                    {/* But all your Withdrawals and any other payment services will be disabled for
                                  24 hours after you make this change to protect your account.  */}
                  </label>
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group row">
                        <label className="col-md-3 col-xs-12 col-form-label class_p">Current Password *</label>
                        <div className="col-md-9 col-xs-12">
                          <RHFTextField
                            name="currentpswd"
                            id="currentpswd"
                            placeholder=""
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onMouseDown={handleEvent} onMouseUp={handleEvent} onMouseOver={handleEvent} onMouseOut={handleEvent} edge="end">
                                    <Iconify style={{ color: '#fff' }} icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-3 col-xs-12 col-form-label class_p">New Password *</label>
                        <div className="col-md-9 col-xs-12">
                          <RHFTextField
                            onCut={handleChange}
                            onCopy={handleChange}
                            onPaste={handleChange}
                            name="newpassword"
                            id="pswd"
                            placeholder=""
                            type={showPassword1 ? 'text' : 'password'}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onMouseDown={handleEvent1} onMouseUp={handleEvent1} onMouseOver={handleEvent1} onMouseOut={handleEvent1} edge="end">
                                    <Iconify style={{ color: '#fff' }} icon={showPassword1 ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-3 col-xs-12 col-form-label class_p">Confirm Password *</label>
                        <div className="col-md-9 col-xs-12">
                          <RHFTextField
                            onCut={handleChange}
                            onCopy={handleChange}
                            onPaste={handleChange}
                            name="confirmpassword"
                            id="pswdconfirm"
                            placeholder=""
                            type={showPassword2 ? 'text' : 'password'}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onMouseDown={handleEvent2} onMouseUp={handleEvent2} onMouseOver={handleEvent2} onMouseOut={handleEvent2} edge="end">
                                    <Iconify style={{ color: '#fff' }} icon={showPassword2 ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {!getdata ?
                            <LoadingButton size="small" type="submit" loading={isSubmitting} className="btn-sm btn-yodigi-buy mt-2">
                              Save changes
                            </LoadingButton>
                            :
                            <div className="spinner-border mt-2" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          }
                        </div>
                      </div>
                    </div></div>
                </FormProvider>

              </div>
              {/* </div> */}

              <div className="mb-3 accordiondiv" id="headingphoto">
                <h5 className="mb-0">
                  {/* <button className="btn btn-sm btn-block text-white text-left" type="button" data-toggle="collapse" data-target="#collapsephoto" aria-expanded="true" aria-controls="collapsephoto"> */}
                  <i className="mdi mdi-account-card-details"></i>&nbsp;Security PIN settings
                  {/* </button> */}
                </h5>
              </div>
              {/* <div id="collapsephoto" className="collapse " aria-labelledby="headingphoto" data-parent="#accordionExample"> */}
              <div className="card-body">
                <UserPin />
              </div>
              {/* </div> */}
              <div className="mb-3 accordiondiv" id="headingdisable">
                {/* <button className="btn btn-sm btn-block text-white text-left" type="button" data-toggle="collapse" data-target="#collapsedisable" aria-expanded="true" aria-controls="collapsedisable"> */}
                <h5><i className="mdi mdi-account-off mr-1"></i>Disable Account</h5>
                {/* </button> */}
              </div>
              {/* <div id="collapsedisable" className="collapse " aria-labelledby="headingdisable" data-parent="#accordionExample"> */}
              <div className="card-body">
                <label className="m-2 card-description">You can disable your account temporarily. Once done, you can activate again
                  after verifying your registered email and mobile number.</label>
                <div>
                  <button className="mt-2 mb-5 btn btn-sm btn-yodigi-danger" onClick={disableAccount}>Disable account</button>
                </div>

              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Security;