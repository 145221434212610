import React from 'react';
import $ from 'jquery';

import { useEffect, useState } from "react";
import axios from 'axios';
// import BtcChart from '../charts/btc';
import { Tab, Nav } from 'react-bootstrap';
import currencyIcon from '../CryptoIcons/iconsImage';
// import GetFiatAccounts from '../users/wallet/GetFiatAccounts';
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import { useNavigate } from 'react-router-dom';

//import components
import '../web/web.scss';
import url_local from '../url_local';
import SellOrder from '../Exchange/SellOrder';
import BuyOrder from '../Exchange/BuyOrder';
import BuyMarketOrder from '../Exchange/BuyMarketOrder';
import SellMarketOrder from '../Exchange/SellMarketOrder';
import secureLocalStorage from 'react-secure-storage';
// import routeCodes from '../../Paths';
import Flags from '../components/Flags';
import Currencies from '../components/Currencies';

var tradelists = [];
function TradeDashboard() {
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  const [getdata, setGetdata] = useState(false);
  const [accData,setAccData] =  React.useState([]);
  // const [cryptoData,setCryptoData] =  React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [accountData, setAccountdata] = useState([]);
  const [fiatData, setFiatData] = useState([]);
  const [tradeData, setTradedata] = useState([]);
  const [currency, setCurrency] = React.useState("");
  const [account, setAccount] = React.useState("");
  const [pairkey, setpairkey] = React.useState("");
  // const [loading, setLoading] = useState(true);
  const history = useNavigate();
  const [theme, setTheme] = React.useState(Themes.DARK);
  useEffect(() => {
    let current = localStorage.getItem("theme");
		if (current === "light-theme"){
			setTheme(Themes.LIGHT);
      delete document.documentElement.dataset.bsTheme;
		}
		else{
			setTheme(Themes.DARK);
      document.documentElement.dataset.bsTheme = "dark";
		}
    //   setTimeout(() => {
    const headers = {
      "Content-Type": "application/json",
      'Access-Token': secureLocalStorage.getItem('AT_'),
      "Refresh-Token": secureLocalStorage.getItem('RT_')
    };
    axios.post(url_local.trans,{ headers })
      .then(function (response) {
        setGetdata(true);
        if (response.data.Status === 1) {
          let lists = []
          for (let i = 0; i < response.data.Data.length; i++) {
            var data = {
              id: i,
              symbol: response.data.Data[i].symbol,
              previousClose: response.data.Data[i].previousClose,
              high: response.data.Data[i].high,
              low: response.data.Data[i].low,
              percentage: response.data.Data[i].percentage,
            }
            lists.push(data);
          }
          setAccountdata(lists);
          setCurrencyValue();
          // var value = $('#bitcoinselect option:selected', this).attr('value');
          // var accountno = $('#bitcoinselect option:selected', this).attr('data-accountno');
          // console.log("value",accountno,value)
          secureLocalStorage.setItem("CS_","");
          secureLocalStorage.setItem("AN_","");
          // loadData();
        }
        else if (response.data.Status === 0) {
          if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>Your session expired</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=signoutbtnow>ok</button>");
            $("body").addClass('no-select'); secureLocalStorage.clear();
            $("#signoutbtnow").addClass('btn btn-block');
            $("#signoutbtnow").on('click', function () {
              $("#modalDialog").toggle('hide');
							window.location.href = "/";
              return;
              // var headers = {
              //   "Content-Type": "application/json",
              //   'Access-Token': secureLocalStorage.getItem('AT_'),
              //   'Refresh-Token': secureLocalStorage.getItem('RT_')
              // }
              // axios
              //   .post(url_local.logout, { publicid: secureLocalStorage.getItem('PI_') },
              //     { headers })
              //   .then(function (response) {
              //     // console.log("overview", response.data)
              //     if (response.data.Status === 1) {
              //       history(routeCodes.HOME);
              //       secureLocalStorage.clear()
              //     }
              //     else if (response.data.Status === 0) {
              //       $(".modal-title").html("")
              //       $(".modal-body").html("<p class=class_p" > +response.data.Message + "</p>");
              //       $(".modal-title").html("")
              //       $(".modal-footer").html("");
              //       $(".modal-footer").html("<button id=ok>OK</button>");
              //       $("#ok").addClass("btn btn-block");
              //       $("#ok").on("click", function () {
              //         $("#modalDialog").toggle('hide');
              //       });
              //       $("#modalDialog").toggle('show');
              //     }
              //     else {
              //       $(".modal-title").html("")
              //       $(".modal-body").html("<p class='class_p text-danger'>Something went wrong. Try again.</p>");
              //       $(".modal-title").html("")
              //       $(".modal-footer").html("");
              //       $(".modal-footer").html("<button id=ok1>ok</button>");
              //       $("#ok1").addClass("btn btn-block");
              //       $("#ok1").on("click", function () {
              //         $("#modalDialog").toggle('hide');
              //       });
              //       $("#modalDialog").toggle('show');
              //     }
              //   })
              //   .catch(function (error) {
              //     return;
              //   });
            });
            $(".main-panel").css("filter","blur(8px)");
            $("#modalDialog").toggle('show');
          }
          else{
            $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=redirect>Got it</button>");
            $("#redirect").addClass("btn btn-block");
            $("#redirect").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
        }
        else {
          $(".modal-body").html("<p className='text-danger'>Something went wrong. Try again.</p>");
          $(".modal-title").html("")
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=redirect>Got it</button>");
          $("#redirect").addClass("btn btn-block");
          $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        return;
      })
      .catch(function (err) {
        setGetdata(true);
        if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
        // secureLocalStorage.clear();
        $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("<button id=redirect>Got it</button>");
        $("#redirect").addClass("btn btn-block");
        $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
        }
        else{

            console.warn(err,"TradeDashboard");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn1>ok</button>");
            $("#closebtn1").addClass('btn btn-block');
            $("#closebtn1").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
        }
        return;
      });
      axios.post(url_local.fxrates,{ headers })
      .then(function (response) {
        if (response.data.Status === 1) {
          // console.log(`response`,response.data.Data);
          let fxlists = []
          for (let i = 0; i < response.data.Data.length; i++) {
            const current_item = response.data.Data[i]
            let luo_date = new Date(current_item.lastupdatedon);

            const has_flag =  Currencies.find(e => e.currencycode ===  current_item.fiatpair.replace('USD-',''));
            let found_flag = Currencies[0].flag
            if (has_flag !== undefined)
              found_flag = has_flag.flag
            var data = {
              id: i,
              fiatpair: current_item.fiatpair,
              flag: found_flag,
              convertionrate: current_item.convertionrate,
              markuppercentage: current_item.markuppercentage,
              lastupdatedon: luo_date.toUTCString(),
              maxminutesforupdate: current_item.maxminutesforupdate,
            }
            fxlists.push(data);
          }
          // console.log(`fxlists`,fxlists)
          setFiatData(fxlists);
        }
        else {
          $(".modal-body").html("<p className='text-danger'>Something went wrong. Try again.</p>");
          $(".modal-title").html("")
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=redirect>Got it</button>");
          $("#redirect").addClass("btn btn-block");
          $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        return;
      })
      .catch(function (err) {
        setGetdata(true);
        if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
        // secureLocalStorage.clear();
        $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("<button id=redirect>Got it</button>");
        $("#redirect").addClass("btn btn-block");
        $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
        }
        else{
            console.warn(err,"TradeDashboard");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn1>ok</button>");
            $("#closebtn1").addClass('btn btn-block');
            $("#closebtn1").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
        }
        return;
      });

      const payload = {publicid: secureLocalStorage.getItem('PI_')}
      axios.post(url_local.accountlist, payload, { headers })
      .then(function (response) {
        setLoading(false);
        if (response.data.Status === 1) {
          setAccData(response.data.Data.fiat.list)
        }
        else if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
            secureLocalStorage.setItem('LS_', false);
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>Your session expired</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=signoutbtnow>ok</button>");
            $("body").addClass('no-select'); secureLocalStorage.clear();
            $("#signoutbtnow").addClass('btn btn-block');
            $("#signoutbtnow").on('click', function () {
              $("#modalDialog").toggle('hide');
              window.location.reload();
              return;
            });
            $(".main-panel").css("filter","blur(8px)");
            $("#modalDialog").toggle('show');
        }
      })
      .catch(function () {
        setLoading(false);
      });

  }, [history]);

  function getImage(img) {
    let image = currencyIcon.length > 0 && currencyIcon.map((cur, i) => {
      if (cur.name === img) {
        return (
          <span key={i}>
            <img className='logo_img mr-1' src={cur.image} alt='' />{cur.name}
          </span>
        )
      }
      else return (
        <span key={i}></span>
      )
    });
    return image;
  }

  function handleOnClick(item) {
    // var cryptosymbols = JSON.parse(secureLocalStorage.getItem('CRA_'));
    document.getElementById('trade-right-sidebar').classList.remove('open');
    var pairkey = item.symbol.replace('/', '');
    setpairkey(pairkey);
    tradelists = [];
    // var text = (item.symbol).split("/")[0];
    // console.log(text)
    var tradedata = {
      id: item.id,
      symbol: item.symbol,
      previousClose: item.previousClose,
      percentage: getPerc(item.percentage),
    }

    tradelists.push(tradedata)
    setTradedata(tradelists);
    // console.log(tradedata)
    document.getElementById('trade-right-sidebar').classList.toggle('open');
    var value = $('#bitcoinselect option:selected', this).attr('value');
    var accountno = $('#bitcoinselect option:selected', this).attr('data-accountno');
    // console.log("value",accountno,value)
    setAccount(accountno);
    setCurrency(value);
  }


  function setCurrencyValue() {
    var value = $('#bitcoinselect option:selected', this).attr('value');
    var accountno = $('#bitcoinselect option:selected', this).attr('data-accountno');
    secureLocalStorage.setItem("CS_",value);
    secureLocalStorage.setItem("AN_",accountno);
    setAccount(accountno);
    setCurrency(value);
  }

  const handleClose = () => {
    document.getElementById('trade-right-sidebar').classList.remove('open');
  }


  $('#bitcoinselect').on('change', function () {
    var value = $('#bitcoinselect').find('option:selected', this).attr('value');
    var accountno = $('#bitcoinselect').find('option:selected', this).attr('data-accountno');
    secureLocalStorage.setItem("CS_",value);
    secureLocalStorage.setItem("AN_",accountno);
    setAccount(accountno);
    setCurrency(value);
  });

  // const format = num => String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');

  let accountLists = accountData.length > 0 && accountData.map((item, index) => {
    return (
      <tr key={index} className="text-white" id={item.symbol} value={item.previousClose} style={{ cursor: 'pointer' }} onClick={() => handleOnClick(item)}>
        <td><span className='img'>{getImage(item.symbol)}</span></td>
        <td className='text-end'>{parseFloat(item.previousClose).toFixed(3)}</td>
        <td className='text-end'>{parseFloat(item.high).toFixed(3)}</td>
        <td className='text-end'>{parseFloat(item.low).toFixed(3)}</td>
        <td className='perc pe-4 text-center'> {getPerc(item.percentage)}</td>
      </tr>
    )
  }, this);

  let fiatList = fiatData.length > 0 && fiatData.map((item, index) => {
    return (
      <tr key={index} className="text-white" style={{ cursor: 'pointer', height:52 }}>
        <td>
        {
        item.fiatpair === "USD-EUR" ?
        <>
          <img className='flag_img mx-1' src={Flags.EU} alt='' /> Euro
        </>
        :
        item.fiatpair === "USD-USD" ?
        <>
          <img className='flag_img mx-1' src={Flags.US} alt=''/> United States Dollar
        </>
        :
        <>
        <img className='flag_img mx-1' src={item.flag} alt=''/> {item.fiatpair.replace('USD-','')}
        {/* <img className='flag_img mx-1' src={Currencies.find(e => e.currencycode === item.fiatpair.replace('USD-','')).flag}  alt=''/> {Currencies.find(e => e.currencycode === item.fiatpair.replace('USD-','')).currency} */}
        </>

        }

        </td>
        <td className='text-end'>{parseFloat(item.convertionrate).toFixed(3)}</td>
        <td className='text-center'>{item.lastupdatedon}</td>
        {/* <td className='perc pe-4 text-center'> {item.markuppercentage}</td> */}
      </tr>
    )
  }, this);

  function getPerc(perc) {
    if (perc < 0) {
      return (
        <>
          <span className="text-danger"><i className='mdi mdi-arrow-down'></i>{perc} %</span>
        </>
      )
    }
    else {

      return (
        <><span className="text-success"><i className='mdi mdi-arrow-up'></i>{perc} %</span></>
      )
    }

  }


  // var storedfiat_datas = JSON.parse(secureLocalStorage.getItem("FL_"));

  function getAllFiatAccounts() {

    return accData.length > 0 && accData.map((item, i) => {
      if (item.account === undefined || item.account === "" || item.account === null) {
        return (
          <option key={i} disabled>No accounts created</option>
        )
      }
      else {
        let text = item.type;
        const type = String(text.split("account")).replace(',', '').toUpperCase();
        return (
          <option key={i} value={type} id={item.account}>
            {type}
          </option>
        )
      }
    });

    // return storedfiat_datas.length > 0 && storedfiat_datas.map((item, i) => {
    //   if (item.account === undefined || item.account === "" || item.account === null) {
    //     return (
    //       <option key={i} disabled>No accounts created</option>
    //     )
    //   }
    //   else {
    //     let text = item.type;
    //     const type = String(text.split("account")).replace(',', '').toUpperCase();
    //     return (
    //       <option key={i} value={type} id={item.account}>
    //         {type}
    //       </option>
    //     )
    //   }
    // });
  }

  const handleChange = (e) => {
    secureLocalStorage.setItem("CS_", "");
    secureLocalStorage.setItem("AN_", "");
    var index = e.target.selectedIndex;
    var el = e.target.childNodes[index]
    var option = el.getAttribute('id');
    secureLocalStorage.setItem("CS_", e.target.value);
    secureLocalStorage.setItem("AN_", option);
    // document.getElementById("acno.").innerHTML = "Account No. :" + option;
    // document.getElementById("acno.").style.display = "block";
  }




  // if (loading) {
  //   return (<Spinner />)
  // }
  // else{
  return (
    <>
      {getdata ?
        <div className='row'>
          <div className="table-responsive col-12 col-md-6">
            <table className="table table-borderless table-hover text-white" id="table1">
              <thead className='mb-3'>
                <tr>
                  <th className='text-left'>Name</th>
                  <th className='text-right'>Last Price</th>
                  <th className='text-right'>High</th>
                  <th className='text-right'>Low</th>
                  <th className='text-right'>24H change</th>
                </tr>
              </thead>
              <tbody>{accountLists}</tbody>
            </table>
          </div>
          <div className="table-responsive col-12 col-md-6">
            <table className="table table-borderless table-hover text-white" id="table2">
                <thead className='mb-3'>
                  <tr>
                    <th className='text-left'>Currency</th>
                    <th className='text-right'>Rate</th>
                    <th className='text-right'>Last Updated</th>
                  </tr>
                </thead>
                <tbody>{fiatList}</tbody>
              </table>
          </div>
        </div>
        :
        <div className='table-responsive'>
          <table className="table table-borderless table-hover text-white" id="table1">
            <tbody className='form-label text-center mt-5'>
              <tr className='mt-4'>
                <td colSpan={6}>
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      }
      <div id="trade-right-sidebar" className="px-4 settings-panel right-sidebar">
        <i className="settings-close mdi mdi-close" onClick={handleClose}></i>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3">
          <Tab.Container defaultActiveKey="limit">
            <div className="row p-4 ml-4">
              {tradeData.map((item, index) =>
                <div key={index}>
                  <div className="p-0">
                    <span className='img'>
                      {getImage(item.symbol)}
                      <p>{item.percentage}</p>
                      {/* <p>{item.supported}</p> */}
                    </span>
                    Last Price (24h) : {item.previousClose}
                    <input type="hidden" id="securitysymbol" name="securitysymbol" value="" />

                    <input type="hidden" id="accountno" name="accountno" value="" />
                    <input type="hidden" id="currency_value" name="currency_value" value="" />
                  </div>
                  <div className='p-0 mb-3' id="chartsection">
                    <div
                      className="tradingview-widget-container card"
                      style={{ height: 'calc(100vh - 450px)' }}>
                      <TradingViewWidget
                        symbol={pairkey}
                        theme={theme}
                        locale="en"
                        autosize
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-md-6">
                      <Nav variant="tabs">
                        <Nav.Link eventKey="limit">Limit</Nav.Link>
                        <Nav.Link eventKey="market">Market</Nav.Link>
                      </Nav>
                    </div>
                    <div className="col-12 mt-1">
                      <label className="card-description">Choose currency</label>
                      {/* <GetFiatAccounts id="bitcoinselect" className="mx-1 form-select form-select-sm form-control w-50"  /> */}
                      {
                        loading?<div className="spinner-border spinner-border-sm " role="status"></div>:
                        <select defaultValue=""
                          className="form-control"
                          aria-label="Floating label select example"
                          onChange={handleChange}>
                          <option value="" disabled>
                            -- Select one --
                          </option>
                          {getAllFiatAccounts()}
                        </select>
                      }
                    </div>
                  </div>

                  <Tab.Content>
                    <Tab.Pane eventKey="limit">
                      <div className='row'>
                        <div className='col-6 m-0'><BuyOrder placeholder={item.previousClose} value={currency} account={account} symbol={item.symbol} /></div>
                        <div className='col-6 m-0'><SellOrder placeholder={item.previousClose} value={currency} account={account} symbol={item.symbol} /></div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="market">
                      <div className='row'>
                        <div className='col-6 m-0'><BuyMarketOrder placeholder={item.previousClose} value={currency} account={account} symbol={item.symbol} /></div>
                        <div className='col-6 m-0'><SellMarketOrder placeholder={item.previousClose} value={currency} account={account} symbol={item.symbol} /></div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              )}
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  )
  // }
}

export default TradeDashboard;