import React from 'react';
import { Link } from 'react-router-dom';

//import components
import Trade from './layout/Trade';
import Testimonial from './element/testimonial';
import Bottom from './element/bottom';
import Footer1 from './layout/footer1';
import WebHeaderLoggedInHome from '../web/layout/WebHeaderLoggedInHome';
import Spinner from '../shared/Spinner';

//import images
import image1 from '../../assets/images/web/Main/kinetic_logo.png';
import image2 from '../../assets/images/web/Main/Ditracon_logo.png';
import image3 from '../../assets/images/web/Main/yods.png';
import image4 from '../../assets/images/web/Main/cell7_logo.png';

import manage from '../../assets/images/web/Main/Manage_portfolio.jpg';
import buy from '../../assets/images/web/Main/recurringbuy.jpg';
import vault from '../../assets/images/web/Main/Vaultprotection.jpg';
import mobile_apps from '../../assets/images/web/Main/Mobile_apps.jpg';

import androidimg from '../../assets/images/web/Main/playstorelogo.png';
import appleimg from '../../assets/images/web/Main/app_store_icon.png';
import appimg from '../../assets/images/web/Main/Mobile_app.png';

import img1 from '../../assets/images/web/Main/create_user_icon.png';
import img2 from '../../assets/images/web/Main/fund_ac_icon.png';
import img3 from '../../assets/images/web/Main/verifyacicon.png';
import img4 from '../../assets/images/web/Main/tradingicon.png';

import logo1 from '../../assets/images/web/Main/wallet.png';
import logo2 from '../../assets/images/web/Main/decentralized.png';
import logo3 from '../../assets/images/web/Main/qr.png';

// import Tables from '../users/tables'
// import css
import './web.scss';
import '../../assets/styles/web/css/style.css';

// import secureLocalStorage from 'react-secure-storage';
// import routeCodes from '../../Paths';

function Main() {
	console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
	// const location = useLocation();
	const [loading, setLoading] = React.useState(true);
	React.useEffect(() => {
		setLoading(false);
		// let current = localStorage.getItem("theme");
		// const body = document.body
		// if (current === "light-theme"){
		// 	body.classList.remove('dark-mode')
		// }
		// else if (current === "dark-theme"){
		// 	body.classList.add('dark-mode');
		// }
		// else if (current === null){
		// 	localStorage.setItem('theme', "dark-theme");
		// 	body.classList.add('dark-mode');
		// }
		// else{
		// 	localStorage.setItem('theme', "dark-theme");
		// 	body.classList.add('dark-mode');
		// }
		// const loadData = async () => {
		// 	await new Promise((r) => setTimeout(r, 1000));
		// 	setLoading(loading => !loading);
		// };
		// loadData();
	}, []);

	if (loading) {
		return (<Spinner />)
	}
	else {
		return (
			<main>
				<div id="header" className='mb-5' >
					<div className="px-5" >
						<WebHeaderLoggedInHome />
					</div>
				</div>
				<div className='header-bg' id="home">
					<div className="container">
						<div className="row align-items-center justify-content-between">
							<div className="col-xl-8 col-lg-8 col-md-8  col-sm-12 col-xs-12">
								<h1 className='h1_tag'>Trade with Meezan, <br /> Buy and sell cryptocurrency</h1>
								<p className='class_span'>Fast and secure way to purchase or exchange 150+ cryptocurrencies</p>
								{/* <div className="row text-center mt-3">
									<div className="col-2 mx-1">
										<img src={ androidimg } alt="" className='applogo' />
									</div>
									<div className="col-2 mx-1">
										<img src={ appleimg } alt="" className='applogo' />
									</div>
								</div> */}
								<div className="row text-center mt-3" role="group" aria-label="Basic example">
									<div className='col-md-3 col-12 mt-2'>
										<img src={androidimg} alt="" className='applogo' />
									</div>
									<div className='col-md-3 col-12 mt-2'>
										<img src={appleimg} alt="" className='applogo' />
									</div>
								</div>
							</div>

							<div className="col-xl-4 col-lg-4 col-md-4">
								<div className="appss-img" >
									<img className="img-fluid" src={appimg} alt="" width="100%" />
								</div>
							</div>
						</div>

					</div>
				</div>

				<div className="price-grid section-padding cardLight" id="market_rates">
					{/* <Tradesections />  */}
					<div className="container ">
						<div className="row justify-content-center">
							<div className="col-xl-8">
								<div className="text-center mb-5">
									<h2 className='class_mainHeading'>Market Rates</h2>
								</div>
							</div>
						</div>
						<div className="row justify-content-center">
							{/* <div className='col-12'> */}
							<Trade />
							{/* </div> */}
						</div>
					</div>


				</div>

				<div className="section-padding cardDark" id="accounts">
					<div className="container">
						<div className="row justify-content-center">
							{/* <div className=" text-center"> */}
							<div className="text-center mb-5">
								<h2 className='class_mainHeading'>Get started in a few minutes</h2>
							</div>
							{/* </div> */}
							<div className='col-sm-12 col-md-6 col-xs-12 col-lg-3 col-xl-3 text-center '>
								<div className="">
									<div>
										<hr className="mainPageDividersTop " />
										<img src={img1} className="mainPageimg mt-5" alt="" />
									</div>
									<div className="card-body">
										<div style={{ height: '10em' }}>
											<h5 className="mt-5 text-left mt-5 mb-4">
												Register {'&'} Create<br />
												Account
											</h5>
											<p className='class_p text-left  '>Register to open your Crypto<br />
												accountinsimplesteps.</p>
										</div>
										<div className='mt-4 d-xs-none d-md-block'>
											<hr className="mainPageDividersBottom " />
										</div>
									</div>
								</div>
							</div>


							<div className='col-sm-12 col-md-6 col-xs-12 col-lg-3 col-xl-3 text-center'>
								<div className="">
									<div>
										<hr className="mainPageDividersTop" />
										<img src={img2} className="mainPageimg mt-5" alt="" />
									</div>
									<div className="card-body">
										<div style={{ height: '10em' }}>
											<h5 className="mt-5 text-left mt-5 mb-4">
												Fund your<br />
												Account
											</h5>
											<p className='class_p text-left  '>Add funds to your crypto account
												to start trading crypto.
												You can add funds with a
												variety pf payment methods.
												accountinsimplesteps.</p>
										</div>
										<div className='mt-4 d-xs-none d-md-block'><hr className="mainPageDividersBottom " /></div>
									</div>
								</div>
							</div>


							<div className='col-sm-12 col-md-6 col-xs-12 col-lg-3 col-xl-3 text-center'>
								<div className="">
									<div>
										<hr className="mainPageDividersTop" />
										<img src={img3} className="mainPageimg mt-5" alt="" />
									</div>
									<div className="card-body">
										<div style={{ height: '10em' }}>
											<h5 className="mt-5 text-left mt-5 mb-4">
												Verify your<br />
												Account
											</h5>
											<p className='class_p text-left  '>
												Complete the identity verification
												process to secure your
												account and transactions.
											</p>
										</div>
										<div className='mt-4 d-xs-none d-md-block'><hr className="mainPageDividersBottom " /></div>
									</div>
								</div>
							</div>


							<div className='col-sm-12 col-md-6 col-xs-12 col-lg-3 col-xl-3 text-center'>

								<div className="">
									<div>
										<hr className="mainPageDividersTop" />
										<img src={img4} className="mainPageimg mt-5" alt="" />
									</div>
									<div className="card-body">
										<div style={{ height: '10em' }}>
											<h5 className="mt-5 text-left mt-5 mb-4">
												Start Crypto<br />
												Trading
											</h5>
											<p className='class_p text-left  '>
												You're good to go! Buy/sellcrypto,
												set up recurring buys for
												your investments,and discover
												what Meezan has to offer.
											</p>
										</div>
										<div className='mt-4 d-xs-none d-md-block'><hr className="mainPageDividersBottom " /></div>
									</div>
								</div>

							</div>


						</div>
					</div>

				</div>

				<div className=" section-padding cardLight" id="portfolio">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-xl-12">
								<div className="text-center mb-5">
									<h2 className='class_mainHeading'>Create your cryptocurrency portfolio today</h2>
									<span className='class_span'>Meezan has a variety of features that make it the best place to start trading</span>
								</div>
							</div>
						</div>
						<div className="row align-items-center justify-content-between ">
							<div className="col-xl-6 col-lg-6 p-2">
								<div className="portfolio" >
									<div className="card-body">
										<div className="row">
											<div className="col-auto mb-2">
												<img src={manage} className="img1" alt="" />
											</div>
											<div className="col">
												<h5 className="class_heading">Manage your portfolio</h5>
												<p className="class_p">Buy and sell popular digital currencies, keep track of them in the one place.</p>
											</div>

										</div>
									</div>
								</div>
							</div>

							<div className="col-xl-6 col-lg-6 p-2">
								<div className="portfolio" >
									<div className="card-body">
										<div className="row">
											<div className="col-auto mb-2">
												<img src={buy} className="img1" alt="" />
											</div>
											<div className="col">
												<h5 className="class_heading">Recurring buys</h5>
												<p className="class_p">
													Invest in cryptocurrency slowly overtime by schedul-ing buys daily,weekly,or monthly;
												</p>
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row align-items-center justify-content-between">
							<div className="col-xl-6 col-lg-6 p-2">
								<div className="portfolio" >
									<div className="card-body">
										<div className="row">
											<div className="col-auto mb-2">
												<img src={vault} className="img1" alt="" />
											</div>
											<div className="col">
												<h5 className="class_heading">Vault protection</h5>
												<p className="class_p">
													For added security, store your funds in a vault with time delayed withdrawals.
												</p>
											</div>

										</div>
									</div>
								</div>
							</div>

							<div className="col-xl-6 col-lg-6 p-2">
								<div className="portfolio" >
									<div className="card-body">
										<div className="row">
											<div className="col-auto mb-2">
												<img src={mobile_apps} className="img1" alt="" />
											</div>
											<br />
											<div className="col">
												<h5 className="class_heading">Mobile apps</h5>
												<p className="class_p">
													Stay on top of the markets with the YoDigiapp for Android/iOS.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>


					</div>
				</div>

				<div className="trade-app section-padding cardDark" >
					<div className="container ">
						<div className="row justify-content-center">
							<div className="col-xl-12">
								<div className="text-center mb-5">
									<h2 className='class_mainHeading'>Trade. Anywhere</h2>
									<span className='class_span'> All of our products are ready to go, easy to use and offer great value to any kind of
										business
									</span>
								</div>
							</div>

						</div>



						<div className="row justify-content-center">
							<div className="col-xl-4 col-lg-4 col-md-12 ">
								<div className="card trade-app-content h-100 gradBackground">
									<div className="card-body gradBackground">
										<span><i className="la la-mobile icon"></i></span>
										<h4 className="class_heading">Mobile</h4>
										<p className='class_p'>All the power of YodigiX's cryptocurrency exchange, in the palm of your hand. Download
											the
											YodigiX mobile crypto trading app today</p>

										<Link to={'#'}> Know More <i className="la la-arrow-right"></i> </Link>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-12 containers">
								<div className="card trade-app-content h-100 gradBackground">
									<div className="card-body gradBackground">
										<span><i className="la la-desktop icon"></i></span>
										<h4 className="class_heading">Desktop</h4>
										<p className="class_p">Powerful crypto trading platform for those who mean business. The YodigiX crypto
											trading
											experience, tailor-made for your Windows or MacOS device.</p>

										<Link to={'#'}> Know More <i className="la la-arrow-right"></i> </Link>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-12">
								<div className="card trade-app-content h-100 gradBackground">
									<div className="card-body gradBackground">
										<span><i className="la la-connectdevelop icon"></i></span>
										<h4 className="class_heading">API</h4>
										<p className="class_p">The Meezan API is designed to provide an easy and efficient way to integrate your
											trading
											application into our platform.</p>

										<Link to={'#'}> Know More <i className="la la-arrow-right"></i> </Link>
									</div>
								</div>
							</div>
						</div>



					</div>
				</div>



				<div className="testimonial section-padding cardLight" id="testimonial">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-xl-12">
								<div className="text-center mb-5">
									<h2 className='class_mainHeading'>What our customer says</h2>
								</div>
							</div>
						</div>
						<div className="row justify-content-center">
							{/* <div className="col-md-12"> */}
							<div className="col-auto" >
								<Testimonial />
								{/* </div> */}
							</div>
						</div>
					</div>
				</div>

				<div className="secure-apps section-padding cardDark" id="secure_apps">
					<div className="container">
						<div className="row align-items-center justify-content-between">
							<div className="col-xl-6 col-lg-6 col-md-6 ">
								<div className="appss-content">
									<h2 className='class_mainHeading'>The secure app to store crypto yourself</h2>

									<div className='row justify-content-center'>
										<div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2 '>
											<div className='card-body securepoints p-4'>
												<span><img src={logo1} className="logo_img mt-2" alt="" /></span>
												<p className='class_p mt-4'>All your digital assets in one place.</p>
											</div>
										</div>
										<div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2'>
											<div className='card-body securepoints p-4'>
												<span><img src={logo2} className="logo_img mt-2" alt="" /></span>
												<p className='class_p mt-4'>Use Decentralized Apps.</p>

											</div>
										</div>
										<div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2'>
											<div className='card-body securepoints p-4'>
												<span><img src={logo3} className="logo_img mt-2" alt="" /></span>
												<p className='class_p mt-4'>Ease of payment with QR code.</p>
											</div>
										</div>
									</div>

								</div>
								<div className="row text-center mt-5">
									<div className="col-md-2 col-sm-6 col-xs-4 col-lg-4 col-xl-4 mr-5">
										<img src={androidimg} alt="" className='applogo' />
									</div>
									<div className="col-md-2 col-sm-6 col-xs-4 col-lg-4 col-xl-4 ml-5">
										<img src={appleimg} alt="" className='applogo' />
									</div>
								</div>

							</div>
							<div className="col-xl-4 col-lg-4 col-md-4">
								<div className="appss-img" >
									<img className="img-fluid" src={appimg} alt="" width="100%" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="trust section-padding cardLight" id="partners">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-xl-12">
								<div className="text-center mb-5">
									<h2 className='class_mainHeading'>Trusted by Our <strong>Partners &amp; Investors</strong></h2>
								</div>
							</div>
						</div>

						<div className="row align-items-center justify-content-between p-5">
							<div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-4 text-center">
								<img className="img4" src={image1} alt="" />
							</div>

							<div className="col-xl-3 col-lg-3 col-sm-12 col-xs-12 mb-4 text-center">
								<img className="img4" src={image2} alt="" />
							</div>

							<div className="col-xl-3 col-lg-3 col-sm-12 col-xs-12 mb-4 text-center">
								<img className="img4" src={image3} alt="" />
							</div>

							<div className="col-xl-3 col-lg-3 col-sm-12 col-xs-12 mb-4 text-center">
								<img className="img4" src={image4} alt="" />
							</div>
						</div>

					</div>

				</div>


				<div className="contact section-padding cardDark">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-12">
								<div className="text-center mb-5">
									<h2>Get in touch. Stay in touch.</h2>
								</div>
							</div>
						</div>
						<div className="row getTouch" >
							<div className='col-md-6 col-xl-6 col-xs-6 col-sm-6 col-lg-6 contactForm p-2'>
								<div className="mb-3 mt-4">
									<input type="text" name="fullname" id="fullname" className="contactFormInput col-12"
										placeholder="Full Name" />
								</div>

								<div className="mb-3 mt-4">
									<input type="text" name="email" id="email"
										className="contactFormInput col-12 "
										placeholder="Email" />
								</div>

								<div className=" mb-3 mt-4">
									<input type="text" name="subject" id="subject" className="contactFormInput col-12"
										placeholder="Subject" />
								</div>



								<div className="mb-3 ">
									<textarea type="text" name="message" id="message" col="10" rows="5"
										className="contactFormInput col-12 "
										placeholder="Message" />
								</div>

								<button type="submit" className="btn submitBtn text-center">Submit</button>
							</div>
						</div>
					</div>
				</div>


				<div className="section-padding cardLight">
					<Bottom />
					<Footer1 />
				</div>


				{/* <a href="#home" className="scroll-top d-flex align-items-center justify-content-center active"><i className="mdi mdi-arrow-up-short"></i></a> */}
			</main>
		)
	}
}

export default function HomeLoggedIn() {
	return (
		<Main />
	)
}