import React, { useState } from 'react';
// import { useLocation } from 'react-router-dom';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as Yup from 'yup';
import { FormProvider, RHFTextField } from '../components/hook-form';
import { IconButton, InputAdornment } from '@mui/material';
import { Tab, Nav } from 'react-bootstrap';

//mui
import { LoadingButton } from '@mui/lab';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// import { Trans } from 'react-i18next';
import url_local from '../url_local';
import routeCodes from '../../Paths';


//components
import Iconify from '../components/Iconify';

// import GetFiatAccounts from '../users/wallet/GetFiatAccounts';
import CryptoTransaction from "./CryptoTransaction";
import BankAccountDetails from "./Bank_Account_Details";
import secureLocalStorage from 'react-secure-storage';
import FiatTransfer from './FiatTransfer';

export default function NewTransaction() {
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  const history = useNavigate();
  const [previewData, setpreviewData] = useState([]);
  const [showPassword, setShowPassword] = React.useState(false);
  // const [isKycApproved, setIsKycApproved] = useState(false);
  const [bankData, setBankData] = useState([]);
  const [accountData,setAccountData] =  React.useState([])
  const [cryptoData,setCryptoData] =  React.useState([])
  const [loading, setLoading] = React.useState(true);
  // const [selected, setSelected] = React.useState("");
  const initialState = { alt: "", src: "" };
  const [{ alt, src, profiledata }, setPreview] = React.useState(initialState);
  const [currentTab, setCurrentTab] = useState("account_details");
  const fileHandler = event => {
    var { files } = event.target;
    setPreview(
      files.length
        ? {
          src: URL.createObjectURL(files[0]),
          alt: files[0].name,
          profiledata: files[0]
        }
        : initialState
    );
  };

  // const location = useLocation();
  const headers = {
    "Content-Type": "application/json",
    'Access-Token': secureLocalStorage.getItem('AT_'),
    "Refresh-Token": secureLocalStorage.getItem('RT_')
  };

  React.useEffect(() => {
    const headers = {
        "Content-Type": "application/json",
        'Access-Token': secureLocalStorage.getItem('AT_'),
        "Refresh-Token": secureLocalStorage.getItem('RT_')
    };
    const payload = {publicid: secureLocalStorage.getItem('PI_')}
    // secureLocalStorage.getItem("CV_")
    if(secureLocalStorage.getItem("AD_","") !== "" && secureLocalStorage.getItem("AD_","") !== null)
    {
      console.log("AD_",secureLocalStorage.getItem("AD_"))
      // console.log("CV_",secureLocalStorage.getItem("CV_"))
      setCurrentTab("Crypto");
    }
    else if(secureLocalStorage.getItem("AN_","") !== "" && secureLocalStorage.getItem("AN_","") !== null)
    {
      console.log("AN_",secureLocalStorage.getItem("AN_"))
      // console.log("CV_",secureLocalStorage.getItem("CV_"))
      setCurrentTab("Fiat");
    }
    else{
      setCurrentTab("account_details");
    }

    axios.post(url_local.checkuserstatus,payload,{ headers })
    .then(function (response) {
        if (response.data.Status === 1) {
          secureLocalStorage.setItem('FN_', response.data.Data.user_name);
        }
        else if (response.data.Status === 0) {
            if (response.data.Code === 3 || response.data.code === 7 || response.data.Data.Status === 600) {
                $(".modal-title").html("");
                $(".modal-body").html("<p class=class_p>Your session expired.</p>1");
                $(".modal-footer").html("");
                $(".modal-footer").html("<button id=closesessionclosebtn2>ok</button>");
                $("body").addClass('no-select'); secureLocalStorage.clear();
                $("#closesessionclosebtn2").addClass('btn btn-block');
                $("#closesessionclosebtn2").on("click", function () {
                  $("#modalDialog").toggle('hide');
                  window.location.href = "/";
                });
                if($("#modalDialog" ).hasClass( "show" )){
                  $(".main-panel").css("filter","blur(8px)");
                  $("#modalDialog").toggle('show');
                  }
                else{
                    $(".main-panel").css("filter","blur(8px)");
                    $("#modalDialog").toggle('show');
                }
            }
            else {
                $(".modal-title").html("");
                $(".modal-body").html(response.data.Message);
                $(".modal-footer").html("");
                $(".modal-footer").html("<button id=closebtn2>ok</button>");
                $("#closebtn2").addClass('btn btn-block');
                $("#closebtn2").on("click", function () {
                    $("#modalDialog").toggle('hide');
                });
                $("#modalDialog").toggle('show');
            }
        }
        else {
            $(".modal-body").html("<p class='text-danger'>Something went wrong. Try again.</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=got_it2>Got it</button>");
            $("#got_it2").addClass("btn btn-block");
            $("#got_it2").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
        }
        return;
    })
    .catch(function () {
        return;
    });
    // console.warn(`headers`,headers)
    axios.post(url_local.accountlist, payload, { headers })
    .then(function (response) {
      setLoading(false);
      // console.warn(response);
      if (response.data.Status === 1) {
        setAccountData(response.data.Data.fiat.list)
        setCryptoData(response.data.Data.crypto.list)
      }
    })
    .catch(function () {
    });

    axios.post(url_local.userbank, {
      "publicid": secureLocalStorage.getItem('PI_'),
      "purpose": "list",
    }, { headers })
      .then(function (response) {
        if (response.data.Status === 1) {
          setBankData(response.data.Data);
        }
        return;
      })
      .catch(function () {
        return
    });
    // loadData();
}, []);

  const PINSchema = Yup.object().shape({
    pinPrev: Yup.string()
      .required("* This field is required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .max(6, "PIN must be an unique 6-digit number.")
      .min(6, "PIN must be an unique 6-digit number.").matches(/^\S*$/, 'Whitespace is not allowed'),
  });

  const PINSchema1 = Yup.object().shape({
    otp: Yup.string()
      .required("* This field is required")
  });

  const defaultValues = {
    pinPrev: '',
  };


  const defaultValues1 = {
    otp: '',
  };

  const methods = useForm({
    resolver: yupResolver(PINSchema, PINSchema1),
    defaultValues, defaultValues1
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  // const uploadreceipt = (event) => {
  //   var reader = new FileReader();
  //   console.log(profiledata, event.target.id)
  //   reader.readAsDataURL(profiledata);
  //   reader.onloadend = function () {
  //     var headers = {
  //       'Content-Type': 'multipart/form-data',
  //       'Access-Token': secureLocalStorage.getItem('AT_'),
  //       "Refresh-Token": secureLocalStorage.getItem('RT_')
  //     };

  //     var data = {
  //       publicid: secureLocalStorage.getItem('PI_'),
  //       file_type: alt,
  //       user_image: reader.result
  //     }

  //     console.log(data)
  //     axios
  //       .post(url_local.uploadimage,
  //         data,
  //         { headers })
  //       .then(function (response) {
  //         console.log("upload image", response.data);
  //         if (response.data.Status === 1) {
  //           topup(event.target.id);
  //           console.log("preview deails")
  //         }
  //         else if (response.data.Data.Status === 0) {
  //           $(".modal-body").html(response.data.Data.Response);
  //           $(".modal-title").html("<h3>Update Failed!</h3>")
  //           $("#modalDialog").toggle('show');
  //         }
  //         else {
  //           $(".modal-body").html("<p className='text-danger'>Something went wrong. Try again!</p>");
  //           $(".modal-title").html("")
  //           $("#modalDialog").toggle('show');
  //         }
  //         return;
  //       })
  //       .catch(function () {
  //         return;
  //       });
  //   }

  // }

  const validation = () => {
    var bankName = document.getElementById('bankName').value;
    var bankCode = document.getElementById('bankCode').value;
    var Topupamount = document.getElementById('Topupamount').value;
    var txdate = document.getElementById('txdate').value;
    var TransferRef = document.getElementById('TransferRef').value;

    // secemail_status= "";
    if (bankName === "" || bankCode === "" ||
      Topupamount === "" || txdate === "" || TransferRef === "") {
// console.log("dsafdsfds")
      $(".modal-title").html("");
      $(".modal-body").html("<p class=class_p>Please fill all mandatory fields *</p>");
      $(".modal-footer").html("");
      $(".modal-footer").html("<button id=closebtnok>Continue</button>");
      $("#closebtnok").addClass('btn btn-block');
      $("#closebtnok").on("click", function () {
        $("#modalDialog").toggle('hide');
      });
      $("#modalDialog").toggle('show');
    }
    else if (profiledata === undefined) {
      $(".modal-title").html("");
      $(".modal-body").html("<p class=class_p>* Please upload a valid and clear document</p>");
      $(".modal-footer").html("");
      $(".modal-footer").html("<button id=closebtn>Continue</button>");
      $("#closebtn").addClass('btn btn-block');
      $("#closebtn").on("click", function () {
        $("#modalDialog").toggle('hide');
      });
      $("#modalDialog").toggle('show');
    }
    else if (profiledata !== undefined) {
      //uploadreceipt();
      topup("201");
    }
    else {
      topup("201");
    }
  }

  const submitTransactiondetails = (event) => {
    // var accNo = document.getElementById("rxaccount").value;
    // var Re_acc = document.getElementById("re-acno.").value;
    // var headers = {
    //   "Content-Type": "application/json",
    //   "Access-Token": secureLocalStorage.getItem('AT_'),
    //   "Refresh-Token": secureLocalStorage.getItem('RT_')
    // };
    var amt = parseFloat(document.getElementById("Transferamount").value);
    var data = {
      "publicid": secureLocalStorage.getItem('PI_'),
      "amount": amt,
      "currency": document.getElementById("from_account").value,
      "txaccount": $("#accountFrom").find("option:selected").attr('data-accountno'),
      "rxaccount": document.getElementById("rxaccount").value,
      // // "re-accountno":document.getElementById("re-acno.").value,
      "bankname": "",
      "bankcode": "",
      "transactionref": "",
      "transferdate": "",
      "txnotes": document.getElementById("remarks1").value,
      "txtypeid": event.target.id,
      "isconfirmed": true,
      "pin": document.getElementById("pin").value
    }
    // data,
    // currency:""
// console.log("preview data for transaction", data);
    axios
      .post(url_local.fiattransfer,
        data,
        { headers })
      .then(function (response) {
// console.log("fiat_transfer", response.data);
        if (response.data.Status === 1) {
          setpreviewData(response.data.Data);
          // $("#myModal").toggle('show');
          document.querySelector('#view-right-sidebar').classList.toggle('open');
          $(".modal-title").html("");
          $(".modal-body").html('<p class=class_p>' + response.data.Message + '.</p>');
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=ok>OK</button>");
          $("#ok").addClass("btn btn-block");
          $("#ok").on("click", function () {
            history(routeCodes.NEWTRANSFER);
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        else if (response.data.Status === 0) {
          if (response.data.Code === 3102) {
            $(".modal-title").html("");
            $(".modal-body").html('<p class=class_p>' + response.data.Message + '.</p>');
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=cancelredirect11>Got it</button>");
            $("#cancelredirect11").addClass("btn btn-block");
            $("#cancelredirect11").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
          else {
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=cancelredirect12>Got it</button>");
            $("#cancelredirect12").addClass("btn btn-block");
            $("#cancelredirect12").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
        }
        else {
          $(".modal-title").html("");
          $(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=cancelredirect>Got it</button>");
          $("#cancelredirect").addClass("btn btn-block");
          $("#cancelredirect").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        return;
      })
      .catch(function (err) {
        // setGetdata(true);
        if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
        // secureLocalStorage.clear();
        $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("<button id=redirect>Got it</button>");
        $("#redirect").addClass("btn btn-block");
        $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
        }
        else{
            console.warn(err,"NewTransaction");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn1>ok</button>");
            $("#closebtn1").addClass('btn btn-block');
            $("#closebtn1").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
        }
        return;
      });
  }

  const topup = (id) => {
    var reader = new FileReader();
    // console.log(profiledata, event.target.id)
    reader.readAsDataURL(profiledata);
    reader.onloadend = function () {
      // var headers = {
      //   "Content-Type": "application/json",
      //   "Access-Token": secureLocalStorage.getItem('AT_'),
      //   "Refresh-Token": secureLocalStorage.getItem('RT_')
      // };
      var amt = parseFloat(document.getElementById("Topupamount").value);
      var data = {
        "publicid": secureLocalStorage.getItem('PI_'),
        "amount": amt,
        "currency": secureLocalStorage.getItem("CS_"),
        "txaccount": secureLocalStorage.getItem("AN_"),
        "user_image": reader.result,
        "file_type": alt,
        "bankname": document.getElementById("bankName").value,
        "bankcode": document.getElementById("bankCode").value,
        "transactionref": document.getElementById("TransferRef").value,
        "transferdate": document.getElementById("txdate").value,
        "txnotes": document.getElementById("remarks").value,
        "txtypeid": id,
        "isconfirmed": true,
      }
      axios.post(url_local.creditrequest, data, { headers })
        .then(function (response) {
          if (response.data.Status === 1) {
            document.querySelector('#view-right-sidebar').classList.remove('open');
            document.getElementsByClassName("form-control").value = " ";
            $(".modal-title").html("");
            $(".modal-footer").html("");
            $(".modal-body").html('<p class=class_p>' + response.data.Message + '.</p>');
            $(".modal-footer").html("<button id=cancelredirect>Got it</button>");
            $("#cancelredirect").addClass("btn btn-block");
            $("#cancelredirect").on("click", function () {
              history(routeCodes.CREATEFIATACCOUNT);
              secureLocalStorage.setItem("CS_", "");
              secureLocalStorage.setItem("AN_", "");
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
          else if (response.data.Status === 0) {
            if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
              $(".modal-title").html("");
              $(".modal-body").html("<p class=class_p>Your session expired</p>");
              $(".modal-footer").html("");
              $(".modal-footer").html("<button id=signoutbtntopup>ok</button>");
              $("body").addClass('no-select'); secureLocalStorage.clear();
              $("#signoutbtntopup").addClass('btn btn-block');
              $("#signoutbtntopup").on('click', function () {
                $("#modalDialog").toggle('hide');
                window.location.href = "/";
                return;
                // // var headers = {
                // //   "Content-Type": "application/json",
                // //   'Access-Token': secureLocalStorage.getItem('AT_'),
                // //   'Refresh-Token': secureLocalStorage.getItem('RT_')
                // // }
                // axios.post(url_local.logout, { publicid: secureLocalStorage.getItem('PI_') },
                //   { headers })
                // .then(function (response) {
                //   if (response.data.Status === 1) {
                //     $("#modalDialog").toggle('hide');
                //     window.location.href = "/";
                //   }
                //   else if (response.data.Status === 0) {
                //     $(".modal-title").html("")
                //     $(".modal-body").html("<p class=class_p" > +response.data.Message + "</p>");
                //     $(".modal-title").html("")
                //     $(".modal-footer").html("");
                //     $(".modal-footer").html("<button id=ok_close>ok</button>");
                //     $("#ok_close").addClass("btn btn-block");
                //     $("#ok_close").on("click", function () {
                //       $("#modalDialog").toggle('hide');
                //     });
                //     $("#modalDialog").toggle('show');
                //   }
                //   else {
                //     $(".modal-title").html("")
                //     $(".modal-body").html("<p class='class_p text-danger'>Something went wrong. Try again.</p>");
                //     $(".modal-title").html("")
                //     $(".modal-footer").html("");
                //     $(".modal-footer").html("<button id=ok_>ok</button>");
                //     $("#ok_").addClass("btn btn-block");
                //     $("#ok_").on("click", function () {
                //       $("#modalDialog").toggle('hide');
                //     });
                //     $("#modalDialog").toggle('show');
                //   }
                // })
                // .catch(function (error) {
                //   return;
                // });
              });
              $("#modalDialog").toggle('show');
            }
           else{
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=cancelredirect11>Got it</button>");
            $("#cancelredirect11").addClass("btn btn-block");
            $("#cancelredirect11").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
           }
          }
          else {
            $(".modal-title").html("");
            $(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=cancelredirect>Got it</button>");
            $("#cancelredirect").addClass("btn btn-block");
            $("#cancelredirect").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
          return;
        })
        .catch(function (err) {
          // setGetdata(true);
          if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
          // secureLocalStorage.clear();
          $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
          $(".modal-title").html("")
          $(".modal-footer").html("<button id=redirect>Got it</button>");
          $("#redirect").addClass("btn btn-block");
          $("#redirect").on("click", function () {
              $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
          }
          else{
              console.warn(err,"NewTransaction");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
              $(".modal-title").html("")
              $(".modal-footer").html("");
              $(".modal-footer").html("<button id=closebtn1>ok</button>");
              $("#closebtn1").addClass('btn btn-block');
              $("#closebtn1").on("click", function () {
                  $("#modalDialog").toggle('hide');
              });
              $("#modalDialog").toggle('show');
          }
          return;
        });
    }
  }

  const viewcloseRightSidebar = () => {
    document.querySelector('#view-right-sidebar').classList.remove('open');
  }

  const getPinsection = () => {
    document.getElementById('pinsection').style.display = "block";
    document.getElementById('btnsection').style.display = "none";
  }

  function fileHandlerbtn_click(e) {
    // alert(2);
    e.target.style.display = "none";
    document.getElementById("fileHandler").style.display = "flex";
  }

  function fileHandlerbtnclear_click(e) {
    document.getElementById("fileHandlerbtn").style.display = "block";
    document.getElementById("fileHandler").style.display = "none";
    setPreview({ src: '' });
  }

  var array = [];
  array.push(previewData);

  var transactionData = array.map((item, index) => {
    return (
      <div key={index} id={index} className=" pt-4">
        <h4 className='mb-3'>Confirm payment</h4>
        <div className='row mt-5'>
          <div className='col-md-4 col-xs-12'>
            <p className='text-center'>{item.Debit_Currency}</p>
            <p className='class_p'>{secureLocalStorage.getItem("AN_")}</p>
            <div className='mt-2 text-left'>
              <p>Amount transferred : {-item.Debited_Amount}</p>
              <p>Service Charge : {item.Service_Charge}</p>
            </div>
          </div>
          <div className='col-md-3 col-xs-12'>
            <i className='mdi mdi-arrow-right'></i>
          </div>
          <div className='col-md-4 col-xs-12'>
            <p className='text-center'>{item.Credit_Currency}</p>
            <p className='class_p'>{item.Credit_Account}</p>
            <div className='mt-2 text-left'>
              <p>Credited Amount : {item.Credit_Amount}</p>
            </div>
          </div>
        </div>
        <h4 style={{ borderTop: "1px solid #242736" }} className="mb-3 pt-3">Total : {-item.Debited_Amount}</h4>
      </div>
    )
  })


  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    var dateReceived = new Date(date);
    return (
      [
        dateReceived.getFullYear(),
        padTo2Digits(dateReceived.getMonth() + 1),
        padTo2Digits(dateReceived.getDate()),
      ].join('-')
    );
  }

  // var storedfiat_datas = JSON.parse(secureLocalStorage.getItem("FL_"));
  function getFiatAccounts() {
    return accountData.length > 0 && accountData.map((item, i) => {
      if(item.account === undefined || item.account === "" ||  item.account === null){
        return (
          <option key={i} disabled>No accounts created</option>
        )
      }
      else{
        let text = item.type;
        const type = String(text.split("account")).replace(',','').toUpperCase();
        return (
          <option key={i} value={type} id={item.account} data-accountno={item.account}>
              {type}
          </option>
        )
      }
    }, this);
  }
  function getFiatAccountNo() {
    return accountData.length > 0 && accountData.map((item, i) => {
      if(item.account === undefined || item.account === "" ||  item.account === null){
        return (
          <option key={i} disabled>No accounts created</option>
        )
      }
      else{
          let text = item.type;
          const type = String(text.split("account")).replace(',','').toUpperCase();
          return (
              <option key={i} value={item.account} id={item.account}>
                  {type} - {item.account}
              </option>
          )
      }
    }, this);
    // return storedfiat_datas.length > 0 && storedfiat_datas.map((item, i) => {
    //   if (item.account === undefined || item.account === "" || item.account === null) {
    //     return (
    //       <option key={i} disabled>No accounts created</option>
    //     )
    //   }
    //   else {
    //     let text = item.type;
    //     const type = String(text.split("account")).replace(',', '').toUpperCase();
    //     return (
    //       <option key={i} value={item.account} id={item.account}>
    //         {type} - {item.account}
    //       </option>
    //     )
    //   }
    // });
  }

  const handleChange = (e) => {
    secureLocalStorage.setItem("CS_", "");
    secureLocalStorage.setItem("AN_", "");
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index]
    const option = el.getAttribute('id');
    secureLocalStorage.setItem("CS_", e.target.value);
    secureLocalStorage.setItem("AN_", option);
    document.getElementById("acno.").innerHTML = "Account No. :" + option;
    document.getElementById("acno.").style.display = "block";
  }

  function getBankAccounts() {
    return bankData.length > 0 && bankData.map((item, i) => {
      if (item === undefined || item === "" || item === null) {
        return (
          <option key={i} disabled>No Bank Account</option>
        )
      }
      else {
        return (
          <option key={i} value={item.account_no}>{item.bank_name} - {item.account_no}</option>
        )
      }
    });
  }

  // function getBankData() {
  //   // let headers = {
  //   //   'Content-Type': 'application/json',
  //   //   'Access-Token': secureLocalStorage.getItem('AT_'),
  //   //   "Refresh-Token": secureLocalStorage.getItem('RT_')
  //   // };
  //   let payload = {
  //     "publicid": secureLocalStorage.getItem('PI_'),
  //     "purpose": "list",
  //   }
  //   // console.log("post to ->>", url_local.userbank);
  //   // console.log("payload --> ",payload)
  //   axios.post(url_local.userbank, payload, { headers })
  //     .then(function (response) {
  //       // console.log("response ->>", response);
  //       if (response.data.Status === 1) {
  //         setBankData(response.data.Data);
  //       }
  //       return;
  //     })
  //     .catch(function () {
  //       return
  //   });
  // }

  const handleWithdraw = (event) => {
    event.preventDefault();
    const purpose = event.target.elements.purpose;
// console.log(purpose.value);
    // let headers = {
    //   'Content-Type': 'application/json',
    //   'Access-Token': secureLocalStorage.getItem('AT_'),
    //   "Refresh-Token": secureLocalStorage.getItem('RT_')
    // };
    if( purpose.value === "withdraw"){
      const accountno = event.target.elements.accountno;
      const amount = event.target.elements.amount;
      const to_bank_accoun = event.target.elements.to_bank_accoun;
      let payload = {
        "publicid": secureLocalStorage.getItem('PI_'),
        "accountno": accountno.value,
        "amount": amount.value,
        "to_bank_accoun": to_bank_accoun.value,
      }
// console.log("payload",payload)
// console.log("post to ->>", url_local.withdrawalrequest);
      axios.post(url_local.withdrawalrequest, payload, { headers })
        .then(function (response) {
// console.log("response ->>", response);
          if (response.data.Status === 1) {
            $(".modal-body").html(response.data.Data.Response);
            $(".modal-title").html("<h3>Successfully Submitted!</h3>")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn>Continue</button>");
            $("#closebtn").addClass('btn btn-block');
            $("#closebtn").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
          else if (response.data.Status === 0) {
            $(".modal-body").html(response.data.Data.Response);
            $(".modal-title").html("<h3>Request Failed!</h3>")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn>Continue</button>");
            $("#closebtn").addClass('btn btn-block');
            $("#closebtn").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
          else {
            $(".modal-body").html("<p className='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("<h3>Update Failed!</h3>")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtnerr>Continue</button>");
            $("#closebtnerr").addClass('btn btn-block');
            $("#closebtnerr").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
          return;
        })
        .catch(function () {
          return;
      });
    }

};
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {/* internal container is div.card below */}
          <div className="card px-4">
            <div className="page-header w-100">
              <h3 className="page-title w-100 row">
                <div className='col-5'>
                  <span className="page-title-icon bg-success me-2">
                    <i className="mdi mdi-cash"></i>
                  </span> Transactions
                </div>
                <div className='col'>
                  <div className="float-end">
                    <div className='text-lastlogin text-end fs-6'>
                      <i className="mdi mdi-account px-2"></i>Last login time
                      <label className='d-block'>{secureLocalStorage.getItem('DT_')}</label>
                    </div>
                  </div>
                </div>
              </h3>
            </div>
            {/* here content is placed */}
            <div className='p-0' id="paymentsection">
              <Tab.Container defaultActiveKey={currentTab} activeKey={currentTab}>
                <div className="px-0">
                  <Nav variant="tabs" className="text-white b-0" >
                    <Nav.Link eventKey="account_details" className='d-flex justify-content-end' onClick={()=> setCurrentTab("account_details")}>Topup</Nav.Link>
                    <Nav.Link eventKey="Fiat" onClick={()=> setCurrentTab("Fiat")}>Fiat Transfer</Nav.Link>
                    <Nav.Link eventKey="Crypto" onClick={()=> setCurrentTab("Crypto")}>Crypto Transfer</Nav.Link>
                    <Nav.Link eventKey="Withdraw" onClick={()=> setCurrentTab("Withdraw")}>Bank Withdraw</Nav.Link>
                  </Nav>
                </div>
                <Tab.Content className='mt-1'>
                  <Tab.Pane eventKey="Fiat">
                    <div className="rounded-legend legend-bottom-left pt-2">
                      {
                        loading?<div className="spinner-border spinner-border-sm " role="status"></div>:
                        <FiatTransfer accdata={accountData} />
                      }
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Crypto">
                    <div className="rounded-legend legend-bottom-left pt-2">
                      {
                        loading?<div className="spinner-border spinner-border-sm " role="status"></div>:
                        <CryptoTransaction accdata={cryptoData} />
                      }
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="account_details">
                    <BankAccountDetails />
                    <div className='row rounded-legend legend-bottom-left pt-2 mx-1'>
                      <div className='col-md-6 col-xs-12'>
                        <label className="card-description text-label font-bold">Select Currency</label>
                        {/* <GetFiatAccounts id="accountFrom"
                          className="form-control form-control-sm intro-form-input" /> */}
                        {/* Fiat accounts */}
                        <div className='row'>
                          <div className='col-3'>
                            {
                              loading?<div className="spinner-border spinner-border-sm " role="status"></div>:
                              <select defaultValue=""
                                className="form-select form-select-sm"
                                aria-label="Floating label select example"
                                onChange={handleChange} style={{width:"80px"}}>
                                <option value="" disabled>
                                  - - -
                                </option>
                                {getFiatAccounts()}
                              </select>
                            }
                          </div>
                          <div className='col-sm-9'><code id="acno." className='class_p card-description' style={{ display: 'none' }}> -- : -- </code></div>


                        </div>

                        <a className='auth-link text-white' href={routeCodes.CREATEFIATACCOUNT}>View all accounts
                          <i className='mdi mdi-open-in-new mx-2'></i></a>
                      </div>
                      <div className="col-md-6 col-xs-12 ">
                        <label className="card-description text-label font-bold">Accountant Name</label>
                        <p id="name">{secureLocalStorage.getItem('FN_')}</p>
                      </div>
                    </div>
                    <div className="card-body rounded-legend legend-bottom-left pt-2">
                      <form className='needs-validation'>
                      <div className="row">
                        <div className="col-md-6 col-xs-12">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label class_p">Bank Name *</label>
                            <div className="col-sm-9">
                              {/* <input type="text" name="bankName" className="form-control form-control-sm" id="bankName" required maxLength={150} pattern="/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/" title='Must proper and not be longer than 150 characters' /> */}
                              {/* <input type="text" name="bankName" className="form-control form-control-sm" id="bankName" required maxLength={150} pattern="/([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-])+/g" title='Must proper and not be longer than 150 characters' /> */}
                              <input type="text" name="bankName" className="form-control form-control-sm" id="bankName" required maxLength={50} pattern="([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+){3,}" title='Must proper and not be longer than 50 characters' />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label class_p">Bank Code *</label>
                            <div className="col-sm-9">
                              <input type="text" name="bankCode" id="bankCode" className="form-control form-control-sm" required maxLength={50} pattern="([a-zA-Z0-9 .-]){1,50}" title='Must proper and not be longer than 50 characters'/>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label class_p">Transfer Date *</label>
                            <div className="col-sm-9">
                              <input type="date" id="txdate" placeholder="" max={formatDate(new Date())} className="form-control form-control-sm mb-2" name="txdate" required />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label class_p">Transferred Amount *</label>
                            <div className="col-sm-9">
                              <input type="number" id="Topupamount" placeholder="" min="1" max={9999} className="form-control form-control-sm" name="Topupamount" required />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label class_p">Reference No. *</label>
                            <div className="col-sm-9">
                              <input type="text" id="TransferRef" placeholder="" className="form-control form-control-sm mb-2" name="TransferRef" required maxLength={50} pattern="([a-zA-Z0-9 ,'.-]){3,50}" title='Must proper and not be longer than 50 characters'/>
                            </div>
                          </div>

                        </div>
                        <div className="col-md-6 col-xs-12">
                          <div className="mb-3">
                            <label className="col-form-label class_p">Upload Receipt *</label>
                            <div>
                              <img id="profile_photo" alt='' className="img-thumbnail mx-auto mb-3" style={{ maxHeight: "150px" }}
                                onError={(e) => {
                                  e.target.src = 'https://via.placeholder.com/150'
                                  e.target.style = 'outline: 0px; margin: 0px; max-height:150px;' // inline styles in html format
                                }}
                                src={src}
                              />
                            </div>
                            <div className="addNew">
                              <button type="button" className="btn btn-sm" id="fileHandlerbtn" onClick={fileHandlerbtn_click} >+ Add</button>
                              <div className="input-group" id="fileHandler" style={{ display: 'none' }}>
                                <input accept="image/*" type="file" onChange={fileHandler} className="form-control h-100" readOnly />
                                <button type="button" className="btn btn-outline-secondary image-preview-clear h-100"
                                  id="fileHandlerbtnclear" onClick={fileHandlerbtnclear_click}>
                                  <i className="fa fa-remove"></i> Clear
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group row">
                            <label className="col-form-label class_p">Remarks(optional)</label>
                            <div className="col-12">
                              <textarea col="10" rows="4" type="text" id="remarks" className="form-control form-control-sm" maxLength={200} pattern="([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{0,200})" title='Must proper and not be longer than 200 characters'></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center">
                        <button type="submit" className="btn btn-sm btn-yodigi-buy mb-3" onClick={validation} id="201">Continue</button>
                      </div>
                      </form>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Withdraw">
                    <div className="rounded-legend legend-bottom-left pt-2">
                      <div className="px-2 mx-3 rounded-legend legend-bottom-left">
                        <p className='card-description pt-3 px-4'>Withdraw from Meezan account</p>
                        <div className="mt-2 px-4">
                          <form className="needs-validation" onSubmit={handleWithdraw}>
                            <input type='hidden' name='purpose' value='withdraw'/>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <div className="form-group row">
                                  <label className="col-4 col-form-label class_p">Account No<sup>*</sup></label>
                                  <div className="col-8">
                                    {
                                        loading?<div className="spinner-border spinner-border-sm " role="status"></div>:
                                        <select defaultValue="" className="form-select" name='accountno' id='accountno' required>
                                          <option defaultValue="" value="" disabled>-- Select one --</option>
                                          {getFiatAccountNo()}
                                        </select>
                                    }
                                  </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <div className="form-group row">
                                  <label className="col-4 col-form-label class_p">Amount<sup>*</sup></label>
                                  <div className="col-8">
                                    <input type="number" name="amount" id="amount" className="form-control form-control-sm" required />
                                  </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <div className="form-group row">
                                  <label className="col-4 col-form-label class_p">To Bank Account<sup>*</sup></label>
                                  <div className="col-8">
                                    <select defaultValue="" className="form-select" name='to_bank_accoun' id='to_bank_accoun' required>
                                      <option value="" disabled>-- Select one --</option>
                                      {getBankAccounts()}
                                    </select>
                                  </div>
                                </div>
                            </div>
                            <div className="text-center mt-3 mb-3" >
                              <button className="btn btn-sm btn-yodigi-buy" type="submit">Submit</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>

            <div id="view-right-sidebar"
              className="px-4 settings-panel right-sidebar">
              <i className="settings-close mdi mdi-arrow-left justify-content-left"
                onClick={viewcloseRightSidebar}></i>
              <div className="" id="uncontrolled-tab-example ">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="px-3">
                      <ul >
                        {transactionData}
                      </ul>
                      <div id="btnsection" className='p-2' style={{ display: 'block' }}>
                        <button className='btn-sm btn-defaultr' onClick={viewcloseRightSidebar}>Cancel</button>
                        <button className='btn-sm btn-yodigi-buy btn-fw' onClick={getPinsection}>Confirm payment</button>
                      </div>
                      <div id="pinsection" style={{ display: 'none' }} className="mt-3">
                        <p className='class_p'>Enter your Security PIN</p>
                        <FormProvider methods={methods} onSubmit={handleSubmit(submitTransactiondetails)}>
                          <div className="col-12">
                            <RHFTextField name="pin"
                              id="pin" placeholder=""
                              type={showPassword ? 'text' : 'password'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end"><Iconify style={{ color: '#fff' }} icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} /></IconButton>
                                  </InputAdornment>
                                )
                              }} />
                            <p className="card-description font-bold text-label" style={{ display: 'none' }} id="successmsg" >
                              <label style={{ color: 'green' }}>PIN generated.</label>
                            </p>
                          </div>
                          <div id="btnsection" className='p-2 text-center' style={{ display: 'block' }}>
                            <button className='btn-sm btn-default' onClick={viewcloseRightSidebar}>Cancel</button>
                            <LoadingButton size="small" type="submit" loading={isSubmitting} className="btn-sm btn-yodigi-buy">Pay</LoadingButton>
                            {/* <button type="submit" className="btn-sm btn-yodigi-buy" id="202" onClick={submitTransactiondetails}>Send</button> */}
                          </div>
                        </FormProvider>


                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>



    </>
  )
}

