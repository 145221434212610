import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import $ from 'jquery';
//import css
// import '../../web/web.scss';
// import '../../../assets/styles/web/css/style.css';
import routeCodes from '../../../Paths';
import logo from '../../../assets/images/web/logo.svg';
import logo_black from '../../../assets/images/web/logo_black.svg';
export default function WebHeaderHome()
{
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  const [img_logo, setImg_logo] = React.useState(logo);
  const [theme_toggle, setTheme_toggle] = React.useState('mdi mdi-weather-sunny fs-3 px-2');

  React.useEffect(()=>
  {
      let current = localStorage.getItem("theme");
      if (current === "light-theme"){
        setImg_logo(logo_black);
        setTheme_toggle('mdi mdi-weather-night fs-3 px-2');
        delete document.documentElement.dataset.bsTheme;
      }
      else{
        setImg_logo(logo);
        setTheme_toggle('mdi mdi-weather-sunny fs-3 px-2');
        document.documentElement.dataset.bsTheme = "dark";
      }
      document.getElementById("mySidebar").style.width = "0";
      document.getElementById("mySidebar").style.marginRight = "0";
  },[]);
  const handleClose=()=>
      {
          document.getElementById('menulist').classList.remove('show');
      };

  const openNav=()=> {
      document.getElementById("mySidebar").style.width = "320px";
      document.getElementById("mySidebar").style.marginRight = "320px";
      document.getElementById("togglerbtn").style.display = "none";
    }

  const closeNav=()=> {
      document.getElementById("mySidebar").style.width = "0";
      document.getElementById("mySidebar").style.marginRight = "0";
      document.getElementById("togglerbtn").style.display = "block";
    }
    const toggleTheme = () => {
      let current = localStorage.getItem("theme")
      const body = document.body
      if (current === "light-theme"){
        localStorage.setItem('theme', "dark-theme");
        body.classList.add('dark-mode');
        setImg_logo(logo);
        setTheme_toggle('mdi mdi-weather-sunny fs-3 px-2')
        $("#bottom_logo").attr("src", logo);
      }
      else if (current === "dark-theme"){
        localStorage.setItem('theme', "light-theme");
        body.classList.remove('dark-mode');
        setImg_logo(logo_black);
        setTheme_toggle('mdi mdi-weather-night fs-3 px-2')
        $("#bottom_logo").attr("src", logo_black);
      }
      else if (current === null){
        localStorage.setItem('theme', "dark-theme");
        body.classList.add('dark-mode');
        setImg_logo(logo);
        setTheme_toggle('mdi mdi-weather-sunny fs-3 px-2');
        $("#bottom_logo").attr("src", logo);
      }
      else{
        localStorage.setItem('theme', "dark-theme");
        body.classList.add('dark-mode');
        setImg_logo(logo);
        setTheme_toggle('mdi mdi-weather-sunny fs-3 px-2');
        $("#bottom_logo").attr("src", logo);
      }
    }

    return(
      <>
          <Navbar id="nav" expand="lg" className="navdark">
              <Link className="navbar-brand" to={'/'}>
                  <img className='image' src={img_logo} alt="Meezan" />
              </Link>
              {/* <Navbar.Toggle className="toggle" aria-controls="basic-navbar-nav" id="togglerbtn" onClick={openNav} /> */}
              <button className="d-lg-none d-block btn class_p"
                  onClick={openNav} id="togglerbtn"><span className='mdi mdi-menu'></span></button>
              <Navbar.Collapse id="menulist" className='d-lg-block d-none'>
                  <Nav className="ms-left ms-auto" >
                      <Nav.Item><Nav.Link className="nav-Nav.link mainPagelinks px-3" href="/">Home</Nav.Link></Nav.Item>
                      <Nav.Item><Nav.Link className="nav-Nav.link mainPagelinks px-3" href="#market_rates">Market Rates</Nav.Link></Nav.Item>
                      <Nav.Item><Nav.Link className="nav-Nav.link mainPagelinks px-3" href={"#"} onClick={handleClose}>News</Nav.Link></Nav.Item>
                      <Nav.Item><Nav.Link className="nav-Nav.link mainPagelinks px-3" href={"#secure_apps"} onClick={handleClose}>Wallet</Nav.Link></Nav.Item>
                      <Nav.Item><Nav.Link className="nav-Nav.link mainPagelinks px-3" href={routeCodes.EXCHANGE}>Exchange</Nav.Link></Nav.Item>
                      <Nav.Item><Nav.Link className="btn-sm btn-yodigi-buy fw-bold" href={routeCodes.SIGNUP}>Sign Up</Nav.Link></Nav.Item>
                      <Nav.Item><Nav.Link className="nav-Nav.link mainPagelinks p-0 m-0" href={"#"} onClick={toggleTheme}><span className={theme_toggle}></span></Nav.Link></Nav.Item>
                  </Nav>
              </Navbar.Collapse>
          </Navbar>
          <div id="mySidebar" className="sidebarhome d-lg-none">
              {/* <Link className="navbar-brand brand-logo-mini" to={routeCodes.HOMELOGGED}>
                  <img  src={miniLogo}  alt="logo"/></Link> */}
              <span className="closebtn" onClick={closeNav} style={{cursor:"pointer"}}>&times;</span>
              <div className="ms-left" onClick={closeNav}>
                  <a className="nav-Nav.link  p-3 mx-2" href="/"><i className="mdi mdi-home mx-1 "></i>Home</a>
                  <a className="nav-Nav.link  p-3 mx-2" href="#market_rates"><i className="mdi mdi-finance mx-1 "></i>Market Rates</a>
                  <a className="nav-Nav.link  p-3 mx-2" href="/" onClick={handleClose}><i className="mdi mdi-newspaper mx-1 "></i>News</a>
                  <a className="nav-Nav.link  p-3 mx-2" href={"#secure_apps"} onClick={handleClose}><i className="mdi mdi-wallet mx-1 "></i>Wallet</a>
                  <a className="nav-Nav.link  p-3 mx-2" href={routeCodes.EXCHANGE}><i className="mdi mdi-transfer mx-1 "></i>Exchange</a>
                  <a className="nav-Nav.link  p-3 mx-2" href={routeCodes.LOGIN}><i className="mdi mdi-account-key mx-1 "></i>Sign in</a>
              </div>
          </div>
      </>
    )
}