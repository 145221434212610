
import React from 'react';
import $ from 'jquery';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import url_local from '../../url_local';

function GetFiatAccounts(props){
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  const [accountData,setAccountData] =  React.useState([])
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
      let headers = {
      	'Access-Token': secureLocalStorage.getItem('AT_'),
      	"Refresh-Token": secureLocalStorage.getItem('RT_')
      };
      let payload = { publicid: secureLocalStorage.getItem('PI_')}
      axios.post(url_local.accountlist, payload, { headers })
      .then(function (response) {
        setLoading(false);
      	if (response.data.Status === 1) {
          setAccountData(response.data.Data.fiat.list)
      	}
        else if (response.data.Status === 0) {
          if (response.data.Code === 3 || response.data.code === 7 || response.data.Data.Status === 600) {
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>Your session expired.</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closesessionclosebtncrypto>ok</button>");
            $("body").addClass('no-select'); secureLocalStorage.clear();
            $("#closesessionclosebtncrypto").addClass('btn btn-block');
            $("#closesessionclosebtncrypto").on("click", function () {
              $("#modalDialog").toggle('hide');
              window.location.href = "/";
            });
            if($("#modalDialog" ).hasClass( "show" )){
              $(".main-panel").css("filter","blur(8px)");
             }
             else{
                 $(".main-panel").css("filter","blur(8px)");
                 $("#modalDialog").toggle('show');
             }
          }
        }
      })
      .catch(function () {
      });
  },[]);
  const handleonChange = (event) => {
        var value = $(event.target.id +'option:selected', this).attr('value');
        var accountno =  $(event.target.id +'option:selected', this).attr('data-accountno');
// console.log(value,accountno)
        secureLocalStorage.setItem("CS_",value);
        secureLocalStorage.setItem("AN_",accountno);
    };
    let accountLists = accountData.length > 0 && accountData.map((item, i) => {
      if(item.account === undefined || item.account === "" ||  item.account === null)
      {
        return (
          <option key={i} disabled>No accounts created</option>
          )
      }
      else
        {
          let text = item.type;
          const type = String(text.split("account")).replace(',','').toUpperCase();
      return (
              <option key={i} value={type} data-accountno={item.account}>
                  {type}
              </option>
      )
      }
    }, this);

    return(
      <>
      {
        loading?<div className="spinner-border spinner-border-sm " role="status"></div>:
        <select className={props.className} id={props.id} defaultValue='' onChange={handleonChange}>
          <option disabled>Select one</option>
             {accountLists}
        </select>
      }
      </>
    )
}


export default GetFiatAccounts;