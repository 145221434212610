// App.js
import React from 'react';
import $ from 'jquery';
// import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import { Tabs, Tab } from 'react-bootstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { CopyToClipboard } from "react-copy-to-clipboard";
import secureLocalStorage from 'react-secure-storage';

import url_local from '../../url_local';

//import components
import routeCodes from '../../../Paths';

// import '../../web/web.scss';

var products = [];
export default function FiatAccountlistTables() {
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  const history = useNavigate();
  const [reports, setReportdata] = React.useState([]);
  const [getdata, setGetdata] =  React.useState(false);
  // const [Copy,setCopy] = React.useState(false);
//   const handleCopyText = (e) => {
//     // document.getElementsByClassName("copiedtext").innerHTML = "bvhgh";
//     document.getElementById(e).innerHTML = "Copied";
//  }
  const format = num =>
    String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');


  React.useEffect(() => {
    secureLocalStorage.setItem("CS_", "");
    secureLocalStorage.setItem("AN_", "");
    var headers = {
      "Content-Type": "application/json",
      "Access-Token": secureLocalStorage.getItem('AT_'),
      "Refresh-Token": secureLocalStorage.getItem('RT_')
    };
    products = [];
    axios
      .post(url_local.accountlist,
        {
          "publicid": secureLocalStorage.getItem('PI_'),
        },
        { headers })
      .then(function (response) {
        setGetdata(true);
// console.log("accountlists", response.data)
        if (response.data.Status === 1) {
          for (let i = 0; i < response.data.Data.fiat.list.length; i++) {
            // let text = response.data.Data.fiat.list[i].type;
            // var type = String(text.split("account")).replace(',', '').toUpperCase();
            // var balanceNeedApproval,inusd,balance;

            // if(response.data.Data.fiat.list[i].balance_need_approval < 0)
            // {
            //   balanceNeedApproval = -(response.data.Data.fiat.list[i].balance_need_approval);
            // }
            // else{
            //   balanceNeedApproval = response.data.Data.fiat.list[i].balance_need_approval;
            // }

            // if(response.data.Data.fiat.list[i].inusd < 0)
            // {
            //   inusd = -(response.data.Data.fiat.list[i].inusd);
            // }
            // else{
            //   inusd = response.data.Data.fiat.list[i].inusd;
            // }

            // if(response.data.Data.fiat.list[i].balance < 0)
            // {
            //   balance = -(response.data.Data.fiat.list[i].balance);
            // }
            // else{
            //   balance = response.data.Data.fiat.list[i].balance;
            // }
            // var totalbalance = response.data.Data.fiat.list[i].balance + response.data.Data.fiat.list[i].balance_need_approval;
            var data = {
              id: i,
              type: response.data.Data.fiat.list[i].currency,
              nmbr: response.data.Data.fiat.list[i].account,
              inusd: format(response.data.Data.fiat.list[i].inusd.toFixed(2)),
              total: format(response.data.Data.fiat.list[i].balance.toFixed(2)),
              balance: format(response.data.Data.fiat.list[i].balance.toFixed(2)),
              balanceNeedApproval: format(response.data.Data.fiat.list[i].balance_need_approval.toFixed(2))
            }
            products.push(data)
          }

          // secureLocalStorage.setItem("FL_", JSON.stringify(response.data.Data.fiat.list));
          // secureLocalStorage.setItem("CRL_", JSON.stringify(response.data.Data.crypto.list));
          setReportdata(products);

          // loadData();
        }
        else if (response.data.Status === 0) {
          if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>Your session expired.</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closesessionclosebtn>ok</button>");
            $("body").addClass('no-select'); secureLocalStorage.clear();
            $("#closesessionclosebtn").addClass('btn btn-block');
            $("#closesessionclosebtn").on("click", function () {
              $("#modalDialog").toggle('hide');
              window.location.href = "/";
            });
            if($("#modalDialog" ).hasClass( "show" )){
              $(".main-panel").css("filter","blur(8px)");
              $("#modalDialog").toggle('show');
             }
             else{
                 $(".main-panel").css("filter","blur(8px)");
                 $("#modalDialog").toggle('show');
             }
          }
          else if (response.data.Code === 4101) {
// console.log(response.data.Message)
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>"+response.data.Message+".</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=redirectf>Activate now?</button><button id=cancelredirectf>Cancel</button>");
            $("#redirectf").addClass("btn btn-block");
            $("#cancelredirectf").addClass("btn btn-block");
            $("#redirectf").on("click", function () {
              history(routeCodes.WALLET);
              $("#modalDialog").toggle('hide');
            });
            $("#cancelredirectf").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            // $("#modalDialog").toggle('show');
          }
          else {
            $(".modal-body").html('<p class=class_p>' + response.data.Message + '</p>');
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=laterbtn>Got it</button>");
            $("#redirect").addClass("btn btn-block");
            $("#laterbtn").addClass("btn btn-block");
            $("#laterbtn").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
        }
        else {
          $(".modal-body").html("<p className='text-danger'>Something went wrong. Try again.</p>");
          $(".modal-title").html("")
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=got_it>Got it</button>");
          $("#got_it").addClass("btn btn-block");
          $("#got_it").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
      })
      .catch(function (err) {
        setGetdata(true);
        if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
        // secureLocalStorage.clear();
        $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("<button id=redirect>Got it</button>");
        $("#redirect").addClass("btn btn-block");
        $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
        }
        else{
            console.warn(err,"FiatAccountlistTables");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn1>ok</button>");
            $("#closebtn1").addClass('btn btn-block');
            $("#closebtn1").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
        }
        return;
      })
  }, [history]);

  function handleOnClickForTopup(item) {
    // document.getElementById('transfer-right-sidebar').classList.remove('open');
    secureLocalStorage.setItem("CS_", item.type);
    secureLocalStorage.setItem("AN_", item.nmbr);
    history(routeCodes.NEWTOPUP, secureLocalStorage.getItem("CS_"), secureLocalStorage.getItem("AN_"))
  }

  function handleOnClickForTransfer(item) {
    secureLocalStorage.setItem("CS_", item.type);
    secureLocalStorage.setItem("AN_", item.nmbr);
    history(routeCodes.NEWTRANSFER, secureLocalStorage.getItem("CS_"), secureLocalStorage.getItem("AN_"))
  }


  function handleOnClickForRequest(item) {
    secureLocalStorage.setItem("CS_", item.type);
    secureLocalStorage.setItem("AN_", item.nmbr);
    history(routeCodes.NEWREQUESTACC_FIAT, secureLocalStorage.getItem("CS_"), secureLocalStorage.getItem("AN_"))
  }

  const CopyFiatVAlue = (event) => {
    $(".modal-body").html('<p class=class_p>Copied : '+event+'</p>');
    $(".modal-footer").html("");
    $(".modal-footer").html("<button id=copiedfiat class=btn-success>Got it</button>");
    $("#copiedfiat").addClass("btn btn-block");
    $("#copiedfiat").on("click", function () {
      $("#modalDialog").toggle('hide');
    });
    $("#modalDialog").toggle('show');
  }

  return (
    <>
      {getdata ?
        <>
          {reports.length > 0 ?
            <div className="table-responsive">
              <table className="table table-borderless text-white">
                <thead>
                  <tr>
                    <th>Currency</th>
                    <th>Account No.</th>
                    <th>Total balance</th>
                    <th>Current Balance</th>
                    <th>Pending approval</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {reports.map((item, index) =>
                    <tr key={index} id={item.type} className="text-white">
                      <td className='text-center'>{item.type}</td>
                      <td className="card-description text-label font-bold d-flex" >{item.nmbr}
                        <CopyToClipboard text={item.nmbr} onCopy={CopyFiatVAlue}>
                          <button className='mx-2 btn btn-sm settingsBtn text-success' data-toggle="tooltip" data-placement="top" title="Copy to clipboard">
                            <i className='mdi mdi-content-copy mdi-32px'></i>
                          </button>
                        </CopyToClipboard>
                        </td>
                      <td className='text-end'>{item.total}</td>
                      <td className='text-end'>{item.balance}</td>
                      <td className='text-end'>{item.balanceNeedApproval}</td>
                      <td className='text-center'>
                        <button className="btn btn-sm text-success" onClick={() => handleOnClickForTopup(item)} >Topup</button>
                        <button className="btn btn-sm text-success" onClick={() => handleOnClickForTransfer(item)}>Transfer</button>
                        <button className="btn btn-sm text-success" onClick={() => handleOnClickForRequest(item)}>Request</button>
                      </td>
                    </tr>
                  )}

                </tbody>
              </table>
            </div>
            :
            <div className="table-borderless table-responsive mt-2">
              <table className="table table-hover col-12">
                <thead>
                  <tr>
                    <th>Account type</th>
                    <th>Address</th>
                    <th>Balance</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody className='form-label text-center'>
                  <tr>
                    <td colSpan="6" className="mt-3">
                      <h1 className='mt-5 card-description'><i className='mdi mdi-wallet mdi-48px'></i></h1>
                      <h4 className="text-center mt-5 card-description">
                        No accounts created</h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          }</> :
        <div className='table-responsive'>
          <table className="table table-borderless table-hover text-white" id="table1">
            <tbody className='form-label text-center mt-5'>
              <tr className='mt-4'>
                <td colSpan={6}>
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>}
      {/* <div id="delete-right-sidebar" className="settings-panel right-sidebar">
          <i className="settings-close mdi mdi-close"  onClick={deletecloseRightSidebar}></i>
          <Tabs defaultActiveKey="DELETE" className="text-left" id="">
            <Tab eventKey="DELETE" >
              <div className='p-4'>
                <div className="d-flex align-items-center justify-content-center">
                  <i className='mdi mdi-alert' style={{ fontSize: '66px',color:'yellow' }}></i>
                </div>
                <h4 className="text-center">Disable Account</h4>
                <p>Disabling account will cause you the following : </p>
                <ul className="todo-list">
                  <li>* All trading capacities and login for your account will be disabled.</li>
                  <li>* All API keys for your account will be disabled.</li>
                  <li>* All pending transactions will be cancelled.</li>
                  <li>* All open orders will be cancelled.</li>
                </ul>
                <p className='mt-4 class_span'>
                    Once your account is disabled, you will be unable to begin the reactivation process until at least two hours have passed.</p>
                    Enter security PIN :
                  <input className='form-control form-control-sm' id="pin" />
                  <a className='text-success' href={routeCodes.SECURITY}>Create new?</a>
                <div className='d-flex align-items-center justify-content-center'>
                  <button className='btn btn-sm btn-yodigi-buy' onClick={deletecloseRightSidebar}>Cancel</button>
                  <button className='btn btn-sm btn-yodigi-danger'>Delete</button>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div> */}
    </>
  )
}