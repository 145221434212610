import React from "react";

import './App.scss';
import './app/web/web.scss';
import App from './App';
// import secureLocalStorage from 'react-secure-storage';

// import "./i18n";
// import { BrowserRouter as Router } from "react-router-dom";
// import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';
// import { BrowserRouter } from 'react-router-dom';

// const container = document.getElementById('root');
const root = createRoot(document.getElementById('root'));
root.render(<App />);
// root.render(
// 	<React.StrictMode>
// 		<App  />
// 	</React.StrictMode>
// );

// serviceWorker.unregister();