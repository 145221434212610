import React from 'react';
import $ from 'jquery';
import * as Yup from 'yup';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import  image from '../../assets/images/web/logo.svg';
import { FormProvider, RHFTextField } from '../components/hook-form';
import { Stack } from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//mui
import { LoadingButton } from '@mui/lab';

//import components
import url_local from '../url_local'
import routeCodes from '../../Paths';

// -----------------------------------------7
// var timeoutHandle;
const countdown = (minutes) => {
	var seconds = 60;
	var mins = minutes

	function tick() {
	  $("#resendbtn").prop('disabled', true);
	  var counter = document.getElementById("taoTimer");
	  var current_minutes = mins - 1
	  seconds--;
	  counter.innerHTML =
		current_minutes.toString() + ":" + (seconds < 10 ? "0" : "") + String(seconds);
	  if (seconds <= 0) {
		if (mins > 1) {

		  $('#taoTimer').addClass('taoIncreaseUrgency');

		  // countdown(mins-1);   never reach “00″ issue solved:Contributed by Victor Streithorst
		  setTimeout(function() {
			countdown(mins - 1);
		  }, 1000);

		} else if (seconds === 0) {
		  $("#resendbtn").prop('disabled', false);
		  // Add page overlay, preventing user interaction
		  // with the rest of the page.
		  $('<span id="taoPageOverlay"></span>').prependTo('#guestInfo');

		  // TEMPORARILY REMOVE THE SESSION TIMEOUT FOR VALIDATION THAT REMOVING IT WORKS
		  $('#sessionTimeoutCounter').remove();

		  // Click event for users to remove CTA and continue reservation
		  $('#taoClose, #taoContBtn, #taoPageOverlay').on('click', function() {
			$('#taoTimeOut, #taoPageOverlay').remove();
			window.location.reload(); // reload page
		  });

		}
	  }
	}

	tick();
  }

 function EmailOTP() {
	const [verifysuccess,setverifysuccess] = React.useState(false);
	const [getdata,setGetdata] =  React.useState(false);



    const history = useNavigate();
	React.useEffect(() =>
	{
// console.log(localStorage.getItem("resetemail"))
		if(localStorage.getItem("resetemail") === " " || localStorage.getItem("resetemail") === null)
		{
			setverifysuccess(true);
		}
		else{
			setverifysuccess(false);
			// countdown("ten-countdown", 10, 0 );
			countdown(10);
		}
	},[])

	const OtpSchema = Yup.object().shape({
		emailCode: Yup.string().required('This field is required').matches(/^\S*$/, 'Whitespace is not allowed'),
	  });

	  const defaultValues = {
		emailCode: '',
	  };

	  const methods = useForm({
		resolver: yupResolver(OtpSchema),
		defaultValues,
	  });

	  const {
		handleSubmit,
		formState: { isSubmitting },
	  } = methods;


  const resendotp = () =>
  {
	  document.getElementById('emailCode').value = "";
	   var headers = { "Content-Type": "application/json"};
		  axios
			.post(url_local.activateCode,
			  {
			  "email" : localStorage.getItem('resetemail')
			  },
			  {headers})
			  .then(function(response) {
			  //   _this.setState({data: response.data});
			  //   console.log(_this.state)
// console.log(response.data)
				if(response.data.Status === 1)
				{
				  countdown(10);
				}
				else if(response.data.Status === 0){
					$(".modal-title").html("");
					$(".modal-body").html("<p class=class_p>"+response.data.Message+"</p><br /><a id=close>OK</a>");
					$("#close").addClass('mb-3 btn btn-block');
					$("#close").on("click",function()
					{
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
				}
				else{
					$(".modal-body").html("<p class='class_p text-danger'>Something went wrong. Try again.</p>");
					$(".modal-title").html("<h3>Oops!</h3>");
					$("#close").addClass('mb-3 btn btn-block');
					$("#close").on("click",function()
					{
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
				}
			  return;
			  })
			  .catch(function() {
				return;
			  });
   }

  const ActivateAccount = () =>
	{
	setGetdata(true);
	var headers = { "Content-Type": "application/json" };
	var data = {
		"email" :localStorage.getItem('resetemail'),
		"email-code" : document.getElementById('emailCode').value,
	}
// console.log("data",data)
			axios
			.post(url_local.reset,
				data
				,{headers})
			.then(function(response) {
// console.log(response.data)
			  if(response.data.Status===1){
				setGetdata(false);
// console.log(response.data);
				$(".modal-title").html("");
				$(".modal-body").html("<p class=class_p>A temporary password has been sent to your registered email. Please use this password to login, change your password once logged in.</p>");
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=redirect_to_login>Login</button>");
				$("#redirect_to_login").addClass("btn btn-block");
				$("#redirect_to_login").on("click",function()
				{
					history(routeCodes.LOGIN);
					$("#modalDialog").toggle('hide');
				});
				$("#modalDialog").toggle('show');
			  }
			  else if(response.data.Status===0){
				setGetdata(false);
				$(".modal-title").html("");
				$(".modal-body").html("<p class=class_p>"+response.data.Message+"</p>");
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=close>Got it</button>");
				$("#close").addClass('mb-3 btn btn-block');
				$("#close").on("click",function()
					{
						$("#modalDialog").toggle('hide');
					});
				$("#modalDialog").toggle('show');
				}
			else{
				setGetdata(false);
				$(".modal-title").html("");
				$(".modal-body").html("<p class='class_p'>Something went wrong. Try again.</p>");
				$(".modal-footer").html("");
				$(".modal-footer").html("<button id=close>Got it</button>");
				$("#close").addClass('mb-3 btn btn-block');
				$("#close").on("click",function()
					{
						$("#modalDialog").toggle('hide');
					});
					$("#modalDialog").toggle('show');
				}
			return;
			})
			.catch(function(response) {
			  return;
			});
	}

	const CancelReset = () =>
	{
		localStorage.getItem("resetemail"," ");
		history(routeCodes.LOGIN);
	}
	// render()
	// {
		if (verifysuccess) {
			return <Navigate to={{pathname: routeCodes.LOGIN}}/>
		}
		else{
		return (
			<div className="d-flex align-items-center auth px-0 pt-5 mt-5">
			<div className="row w-100 mx-0">
			<div className="brand-logo text-center mb-3" >
					<div onClick={CancelReset} style={{cursor:'pointer'}}>
					  <img className='image' src={image} alt="Meezan" />
					</div>
					{/* <Link className="navbar-brand" to={routeCodes.HOME}>
					  <img className='image' src={image} alt="Meezan" />
					</Link> */}
				  </div>
				  <div className="col-md-6 col-xs-12 mx-auto card">
				  <div className="card-body text-left py-3 ">
					<div className="mt-3 d-flex flex-column justify-content-center">
						<div><h4 className='text-center text-white'>OTP Verification</h4></div>
						<div><h6 className="text-center font-weight-light class_p">
						Make sure you received your OTP in your registered email. If not, request a new otp.</h6>
						</div>
					</div>
					<FormProvider methods={methods} onSubmit={handleSubmit(ActivateAccount)}>
						<div className="mb-3 mt-3">
							<RHFTextField name="emailCode" label="Email OTP" id="emailCode" inputProps={{ className: "mt-2" }} placeholder="Email OTP" />
							{/* <RHFTextField name="mobileCode" label="Mobile OTP" id="mobileCode" className="mt-3" placeholder="Mobile OTP"/> */}
							<div className="info mt-3">
								<p className="class_p text-white">OTP will be valid only for
									{/* <span id="ten-countdown" className='text-danger mx-1'>00:00</span> */}
									<span id="taoTimer" className='text-danger mx-1'></span>
								</p>
							</div>
							{!getdata ?
								<div className="text-center mb-2">
									<LoadingButton type="submit" loading={isSubmitting} className="btn btn-sm btn-yodigi-buy mt-3">Verify</LoadingButton>
								</div>
								:
								<div className="text-center mb-2">
									<div className="spinner-border" role="status">
									<span className="sr-only">Loading...</span>
									</div>
								</div>
							}
						</div>

					</FormProvider>
					<Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
						<label className='class_p'>Don't get code?
							<button className="btn btn-sm text-success" id="resendbtn" onClick={resendotp} disabled>Resend</button>
						</label>
					</Stack>
            </div>
          </div>
        </div>
      </div>
		)
	// }

	}
}
export default EmailOTP;