

import React, { useState, useContext,useRef } from 'react';
import $ from 'jquery';
// import axios from 'axios';
import { BrowserRouter, Route, Routes, } from 'react-router-dom';

import Home from './app/web/Home';
import HomeLoggedIn from './app/web/HomeLoggedIn';
import Exchange from './app/Exchange/Exchange';
import ExchangeDashboard from './app/Exchange/ExchangeDashboard';
import Overview from './app/dashboard/Overview';

import WalletOverview from './app/users/wallet/Overview';
import AccountStatement from './app/dashboard/AccountStatement';
import Reset from './app/user-pages/Reset';
import NewFiatTransfer from './app/transactions/NewFiatTransfer';

import Settings from './app/users/Settings';
import Security from './app/users/Security';
import BankDetails from './app/users/BankDetails';
import EmailOTP from './app/user-pages/EmailOTP';
import MobileOTP from './app/user-pages/MobileOTP';

import Identification from './app/users/wallet/Verify';
import LoginForm from './app/user-pages/LoginForm';
import Register from './app/user-pages/RegisterForm';
import Statements from './app/dashboard/TransactionHistory';
import NewTransferCryptoAcc from './app/transactions/NewTradeCrypto'
import NewTop from './app/transactions/NewTopup';
import NewTransaction from './app/transactions/NewTransaction';
import Navbar from './app/shared/Navbar';
import Sidebar from './app/shared/Sidebar';
import routeCodes from './Paths';
// import url_local from './app/url_local';
import PinOtp from './app/users/wallet/PinOtp';
import ActivateEmail from './app/user-pages/ActivateEmail';
import ActivateMobile from './app/user-pages/ActivateMobile';
import AddSecEmail from './app/users/kyc/AddSecEmail';
import AddSecMobile from './app/users/kyc/AddSecMobile';
import IdCardSubmit from './app/users/kyc/IdCardSubmit';
import CreateAndListFiat from './app/users/wallet/CreateAndListFiat';
import CreateAndListCrypto from './app/users/wallet/CreateAndListCrypto';
import NewRequestCrypto from './app/transactions/NewRequestCrypto';
import NewRequestFiat from './app/transactions/NewRequestFiat';
import { GlobalContext } from './app/shared/useForm';
import secureLocalStorage from 'react-secure-storage';

function App() {
	const [Logged, setLogged] = useState(false);
	const userData = useRef();
	const userKYC = useRef();
	const data_context = React.useMemo(() => ({
		init: () => {
		},
		signout: () => {
			setLogged(false);
			userData.current = null;
			Logout();
		},
		user: (data, write = false) => {
			if (write) {
				userData.current = data;
				setLogged(true);
			}
			else
				return userData.current;
		},
		kyc: (data, write = false) => {
			if (write)
				userKYC.current = data;
			else
				return userKYC.current;
		},
		accountlist: (data, write = false) => {
			if (write)
				userKYC.current = data;
			else
				return userKYC.current;
		},
	}), []);
	const gContext = useContext(GlobalContext);
	React.useEffect(() => {
		$("#root").css("filter","");
		let current = localStorage.getItem("theme");
		if (current === "light-theme"){
			document.body.classList.remove('dark-mode')
			delete document.documentElement.dataset.bsTheme;
		}
		else if (current === "dark-theme"){
			document.body.classList.add('dark-mode');
			document.documentElement.dataset.bsTheme = "dark";
		}
		else if (current === null){
			localStorage.setItem('theme', "dark-theme");
			document.body.classList.add('dark-mode');
			document.documentElement.dataset.bsTheme = "dark";
		}
		else{
			localStorage.setItem('theme', "dark-theme");
			document.body.classList.add('dark-mode');
			document.documentElement.dataset.bsTheme = "dark";
		}
		let public_ID = secureLocalStorage.getItem('PI_');

		if (public_ID === null) {
			secureLocalStorage.setItem('LS_', false);
		}
	}, [Logged,gContext]);


	function Logout() {
		secureLocalStorage.clear();
		window.location.href = "/";
		return;
		// let public_ID = secureLocalStorage.getItem('PI_');

		// let headers = {
		// 	"Content-Type": "application/json",
		// 	'Access-Token': secureLocalStorage.getItem('AT_'),
		// 	'Refresh-Token': secureLocalStorage.getItem('RT_')
		// }

		// axios.post(url_local.logout, { publicid: public_ID },
		// 	{ headers })
		// 	.then(function (response) {
		// 		if (response.data.Status === 1) {
		// 			window.location.href = routeCodes.HOME;
		// 			secureLocalStorage.clear();
		// 			$("#modalDialog").toggle('hide');
		// 		}
		// 		else if (response.data.Status === 0) {
		// 			$(".modal-title").html("")
		// 			$(".modal-body").html("<p class=class_p" > +response.data.Message + "</p>");
		// 			$(".modal-title").html("")
		// 			$(".modal-footer").html("");
		// 			$(".modal-footer").html("<button id=ok>OK</button>");
		// 			$("#ok").addClass("btn btn-block");
		// 			$("#ok").on("click", function () {
		// 				$("#modalDialog").toggle('hide');
		// 			});
		// 			$("#modalDialog").toggle('show');
		// 		}
		// 		else {
		// 			$(".modal-title").html("")
		// 			$(".modal-body").html("<p class=class_p text-danger>Something went wrong. Try again.</p>");
		// 			$(".modal-title").html("")
		// 			$(".modal-footer").html("");
		// 			$(".modal-footer").html("<button id=ok>OK</button>");
		// 			$("#ok").addClass("btn btn-block");
		// 			$("#ok").on("click", function () {
		// 				$("#modalDialog").toggle('hide');
		// 			});
		// 			$("#modalDialog").toggle('show');
		// 		}
		// 	})
		// 	.catch(function () {
		// 		return;
		// 	});
	}

	return (
		<GlobalContext.Provider value={data_context}>
			<BrowserRouter>
				{secureLocalStorage.getItem('LS_') ?
					// {?"":""}
					<div className="container-scroller">
						<Navbar />
						<div className="page-body-wrapper">
							<Sidebar />
							<div className="main-panel">
								<div className='content-wrapper'>
									<Routes>
										<Route exact path={routeCodes.WALLET} element={<WalletOverview  />} />
										<Route exact path={routeCodes.HOMELOGGED} element={<HomeLoggedIn />} />
										<Route exact path={routeCodes.CREATEFIATACCOUNT} element={<CreateAndListFiat  />} />
										<Route exact path={routeCodes.CREATECRYPTOACCOUNT} element={<CreateAndListCrypto  />} />
										<Route exact path={routeCodes.NEWTRANSFER} element={<NewTransaction  />} />
										<Route exact path={routeCodes.NEWFIAT} element={<NewFiatTransfer  />} />
										<Route exact path={routeCodes.NEWTRANSFERACC_CRYPTO} element={<NewTransferCryptoAcc  />} />
										<Route exact path={routeCodes.NEWREQUESTACC_CRYPTO} element={<NewRequestCrypto  />} />
										<Route exact path={routeCodes.NEWREQUESTACC_FIAT} element={<NewRequestFiat  />} />
										<Route exact path={routeCodes.TRANSACTIONS} element={<Statements />} />
										<Route exact path={routeCodes.REPORTS} element={<AccountStatement />} />
										<Route exact path={routeCodes.SETTINGS} element={<Settings />} />
										<Route exact path={routeCodes.ACTIVE_SECEMAILOTP} element={<AddSecEmail />} />
										<Route exact path={routeCodes.ACTIVE_SECMOBILEOTP} element={<AddSecMobile />} />
										<Route exact path={routeCodes.IDVERIFY} element={<IdCardSubmit />} />
										<Route exact path={routeCodes.PINOTP} element={<PinOtp />} />
										<Route exact path={routeCodes.SECURITY} element={<Security />} />
										<Route exact path={routeCodes.NEWTOPUP} element={<NewTop />} />
										<Route exact path={routeCodes.NEWFIAT} element={<NewFiatTransfer />} />
										<Route exact path={routeCodes.VERIFY} element={<Identification />} />
										<Route exact path={routeCodes.EXCHANGEDASHBOARD} element={<ExchangeDashboard  />} />
										<Route exact path={routeCodes.OVERVIEW} element={<Overview />} />
										<Route exact path={routeCodes.DASHBOARD} element={<Overview />} />
										<Route exact path={routeCodes.BANKDETAILS} element={<BankDetails />} />
										<Route exact path="*" element={<Overview />} />
									</Routes>
								</div>
							</div>
						</div>
					</div>
					:
					<div id="main-wrapper">
						<Routes>
							<Route exact path="*" element={<LoginForm />} />
							<Route exact path={routeCodes.HOME} element={<Home />} />
							<Route exact path={routeCodes.LOGIN} element={<LoginForm />} />
							<Route exact path={routeCodes.SIGNUP} element={<Register />} />
							<Route exact path={routeCodes.RESET} element={<Reset />} />
							<Route exact path={routeCodes.EMAILOTP} element={<EmailOTP />} />
							<Route exact path={routeCodes.ACTIVEOTP} element={<MobileOTP />} />
							<Route exact path={routeCodes.ACTIVE_EMAILOTP} element={<ActivateEmail />} />
							<Route exact path={routeCodes.ACTIVE_MOBILEOTP} element={<ActivateMobile />} />
							<Route exact path={routeCodes.EXCHANGE} element={<Exchange />} />
						</Routes>
					</div>
				}
			</BrowserRouter>
		</GlobalContext.Provider>
	);

	// function ErrorFound() {
	// 	return <Pagenotfound />
	// }
}
export default App;