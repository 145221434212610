import React from 'react';
import $ from 'jquery';
// import axios from 'axios';
import { Link } from 'react-router-dom';
import image from '../../../assets/images/web/logo.svg';
import { Dropdown } from 'react-bootstrap';
import miniLogo from '../../../assets/images/web/icon.svg';

//import css
import '../../web/web.scss';
import '../../../assets/styles/web/css/style.css';
// import url_local from '../../url_local';
import routeCodes from '../../../Paths';
// import { GlobalContext } from '../../../app/shared/useForm';
import secureLocalStorage from 'react-secure-storage';

export default function WebHeaderLoggedInHome()
{
  // const history=useNavigate();
  // const gContext = React.useContext(GlobalContext);
  React.useEffect(()=>
  {
      // let current = localStorage.getItem("theme");
      // const body = document.body
      // if (current == "light-theme"){
      //   localStorage.setItem('theme', "dark-theme");
      //   body.classList.add('dark-mode');
      // }
      // else if (current == null){
      //     localStorage.setItem('theme', "dark-theme");
      //     body.classList.add('dark-mode');
      // }
      // else{
      //   localStorage.setItem('theme', "light-theme");
      //   body.classList.remove('dark-mode')
      // }
  },[]);

  const LogoutAccount = () =>
  {
    $(".modal-title").html("Confirm Logout");
    $(".modal-body").html("<p class=class_p>Are you sure you want to log-off?</p>");
    $(".modal-footer").html("");
    $(".modal-footer").html("<button id=logoutbtn>Logout</button><button id=cancel_logout>Cancel</button>");
    $("#logoutbtn").addClass('btn btn-sm btn-yodigi-danger');
    $("#cancel_logout").addClass('btn btn-sm btn-default');
    $("#logoutbtn").on('click',function(){
      secureLocalStorage.clear();
      $("#modalDialog").toggle('hide');
      window.location.href = "/";
      return;
//       localStorage.setItem('referTodashboard',false);
//       history(routeCodes.HOME);
//       $("#modalDialog").toggle('hide');
//       if(gContext) {
//         // localStorage.setItem('referTodashboard',false);
//         gContext.signout();
//         // gContext.user(null,true);
//         return;
//       }
//       else{
//         secureLocalStorage.clear();
//         $("#modalDialog").toggle('hide');
//         window.location.href = "/";
//         return;
//       }

//         var headers = {
//           "Content-Type": "application/json",
//           'Access-Token': secureLocalStorage.getItem('AT_'),
//           'Refresh-Token': secureLocalStorage.getItem('RT_')
//         }
//         axios
//       .post(url_local.logout,{publicid:	secureLocalStorage.getItem('PI_')},
//       {headers})
//       .then(function(response) {
// // console.log(response.data);
//         if(gContext) {gContext.signout(); return;}
//         // if(response.data.Status === 1)
//         // {
//           // localStorage.setItem('referTodashboard',false);
//           // window.location.href="/";
//       //   }
//       //   else if(response.data.Status === 0){
//       //       localStorage.setItem('rootPage',false);
//       //       localStorage.setItem('referTodashboard',true);
//       //       $(".modal-body").html("<p class=class_p">+response.data.Message+"</p>");
//       //       $(".modal-title").html("")
//       //       $("#modalDialog").toggle('show');
//       //       }
//       //       else{
//       //         $(".modal-title").html("");
//       //         $(".modal-body").html("<p class='class_p text-danger'>Something went wrong. Try again.</p>");
//       //         $("#modalDialog").toggle('show');
//       //       }
//       //   })
//       //     .catch(function(error) {
//       //       localStorage.setItem('referTodashboard',true);
//       //       return;
//       });

    });
    $('#cancel_logout').on('click',function(){
      $("#modalDialog").toggle('hide');
    });
    $("#modalDialog").toggle('show');
  }


    return(
      <>
        <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row" style={{borderBottom: "1px solid #292c37"}}>
          <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            <Link className="navbar-brand brand-logo" to={routeCodes.OVERVIEW}><img src={image} alt="" /></Link>
            <Link className="navbar-brand brand-logo-mini" to={routeCodes.OVERVIEW}><img  src={miniLogo}  alt=""/></Link>
          </div>
          <div className="navbar-menu-wrapper d-flex align-items-stretch">
            {/* <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
              <span className="mdi mdi-menu"></span>
            </button> */}
            <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-profile">
              <Link className="nav-link mb-2" to={routeCodes.EXCHANGEDASHBOARD}>Exchange</Link>
            </li>
              <li className="nav-item nav-profile">
                <Dropdown alignRight>
                  <Dropdown.Toggle className="nav-link">
                      <p className="class_p mb-1">Wallet</p>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="navbar-dropdown">
                    <Dropdown.Item href={routeCodes.WALLET}>
                      <i className="mdi mdi-view-dashboard mx-1 class_p"></i><p className="class_p m-1 ">Overview</p>
                    </Dropdown.Item>
                    {/* <Dropdown.Item href={routeCodes.ACCOUNTS}>
                      <i className="mdi mdi-wallet mx-1 class_p"></i><p className="class_p m-1">Funding Wallet</p>
                    </Dropdown.Item> */}
                    <Dropdown.Item href={routeCodes.CREATEFIATACCOUNT}>
                      <i className="mdi mdi-cash mx-1 class_p"></i><p className="class_p m-1">Fiat</p>
                    </Dropdown.Item>
                    <Dropdown.Item href={routeCodes.CREATECRYPTOACCOUNT}>
                      <i className="mdi mdi-coin mx-1 class_p"></i><p className="class_p m-1">Crypto</p>
                    </Dropdown.Item>

                    <Dropdown.Item href={routeCodes.TRANSACTIONS}>
                      <i className="mdi mdi-history mx-1 class_p"></i><p className="class_p m-1">Transaction History</p>
                    </Dropdown.Item>
                    <Dropdown.Item href={routeCodes.REPORTS}>
                      <i className="mdi mdi-file-document-box mx-1 class_p"></i><p className="class_p m-1">Account Statement</p>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="nav-item nav-profile">
                <Dropdown alignRight>
                  <Dropdown.Toggle className="nav-link">
                    <div className="nav-profile-img">
                      <i className='mdi mdi-account-circle img3'></i>
                      {/* <img src={require("../../assets/images/faces/user.png")} alt="user"/> */}
                      <span className="availability-status online"></span>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="navbar-dropdown">
                  {/* <div className="nav-profile-text p-2">
                      <p className="mb-1 text-white"><Trans>
                        {secureLocalStorage.getItem('EM_')}</Trans>
                        </p>
                    </div> */}
                    <Dropdown.Item href={routeCodes.DASHBOARD}>
                      <i className="mdi mdi-view-dashboard mx-1 class_p"></i><p className="class_p m-1">Dashboard</p>
                    </Dropdown.Item>
                    <Dropdown.Item href={routeCodes.SETTINGS}>
                      <i className="mdi mdi-settings mx-1 class_p"></i><p className="class_p m-1">Settings</p>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={LogoutAccount}>
                      <i className="mdi mdi-logout mx-1 class_p"></i><p className="class_p m-1">Signout</p>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
            {/* <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
              <span className="mdi mdi-menu"></span>
            </button> */}
          </div>
        </nav>
      </>
        )
}