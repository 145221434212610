import React,{  } from 'react';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField,} from '@mui/material';
// import { makeStyles } from "@mui/styles";
// import TextField from '@mui/joy/TextField';
// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextField({ name,id,label,type,value,readOnly, ...other }) {
  const { control } = useFormContext();
  // const handleChange = (e) => {
  //   e.preventDefault();
  // };
  return (
     <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField fullWidth className='div-control'
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "1px solid #242736;",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "1px solid #242736;",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "1px solid #242736;",
            },
          },
          'input': {
            '&::placeholder': {
              textOverflow: 'ellipsis !important',
              color: 'blue'
            },
            color:"#fff"
          },
          }}
          inputProps={{
            className:"div-control",
            // readOnly:{readOnly},
            sx: {
              textAlign: "left",
              "&::placeholder": {
                textAlign: "left",
                color: '#7a7c80'
              },
            },
          }}
          size="small" id={id} type={type}
          // InputLabelProps={{style : {color : '#fff'}}}
          // label={labelText}
          // key={labelText}
          {...field}
          value={typeof field.value === 'number' && field.value === 0 ? value : field.value}
          // onCut={handleChange}
          // onCopy={handleChange}
          // onPaste={handleChange}
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  );
}
