import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import * as Yup from 'yup';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FormProvider, RHFTextField } from '../components/hook-form';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';

import '../web/web.scss';
import url_local from '../url_local';
import routeCodes from '../../Paths';
import secureLocalStorage from 'react-secure-storage';

// var lists = [], tradelists = [];
function BuyMarketOrder(props) {
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  // const [getdata, setGetdata] = useState(false);
  // const [accountData, setAccountdata] = useState([]);
  // const [tradeData, setTradedata] = useState([]);
  // const [Resp, setResponse] = useState();
  const [ChangeBtnstate, setChangeBtnstate] = useState(false);

  // const history = useNavigate();

  useEffect(() => {
    if (secureLocalStorage.getItem('PI_') !== "") {
      setChangeBtnstate(true);
    }
    else {
      setChangeBtnstate(false);
    }
  }, [])

  const TradeSchema = Yup.object().shape({
    // buytradeprice: Yup.number()
    // .typeError('you must specify a number')
    // .min(0, 'Min value 0.')
    // .required("This field is required"),
    buytradeqntyMarket: Yup.number()
      .typeError('you must specify a number')
      .min(0, 'Min value 0.')
      .required("This field is required"),
  });

  const defaultValues = {
    // buytradeprice: '',
    buytradeqntyMarket: '',
  };

  const methods = useForm({
    resolver: yupResolver(TradeSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;



  const buyCryptoMarket = () => {
    var headers = {
      "content-Type": "application/json",
      "Access-Token": secureLocalStorage.getItem('AT_'),
      "Refresh-Token": secureLocalStorage.getItem('RT_')
    }

    var data = {
      "publicid": secureLocalStorage.getItem('PI_'),
      "currency": secureLocalStorage.getItem("CS_"),
      "accountno": secureLocalStorage.getItem("AN_"),
      // "security_symbol":document.getElementById('securitysymbol').value,
      "security_symbol": String((props.symbol).split("/USDT")).replace(',', '').toUpperCase(),
      "trade_side": "BID",
      "trade_price": props.symbol,
      // "trade_price":document.getElementById('buytradeprice').value,
      "trade_quantity": document.getElementById('buytradeqntyMarket').value,
    }


    // console.log(data)

    axios
      .post(url_local.marketTrades,
        data,
        { headers })
      .then(function (response) {
        document.getElementById('trade-right-sidebar').classList.remove('open');
        if (response.data.Status === 1) {
          document.getElementById('buytradeqntyMarket').value = "";
          document.getElementById('buytradeprice').value = "";
          $(".modal-body").html("<p class=class_p>" + response.data.Response + "</p>");
          $(".modal-title").html("Order ID :  " + response.data.Data.Order_id);
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=redirectbuym class=btn-success>Got it</button>");
          $("#redirectbuym").addClass("btn btn-block");
          $("#redirectbuym").on("click", function () {
            // history(routeCodes.EXCHANGEDASHBOARD);
            window.location.href = routeCodes.DASHBOARD;
            $("#modalDialog").toggle('hide');
            secureLocalStorage.setItem("CS_", "");
            secureLocalStorage.setItem("AN_", "");
          });
          $("#modalDialog").toggle('show');
        }
        else if (response.data.Status === 0) {
          if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>Your session expired</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=signoutbtn>ok</button>");
            $("#signoutbtn").addClass('btn btn-block');
            $("#modalDialog").toggle('show');
            $("#signoutbtn").on('click', function () {
                var headers = {
                  "Content-Type": "application/json",
                  'Access-Token': secureLocalStorage.getItem('AT_'),
                  'Refresh-Token': secureLocalStorage.getItem('RT_')
                }
                axios.post(url_local.logout, { publicid: secureLocalStorage.getItem('PI_') },
                    { headers })
                  .then(function (response) {
                    if (response.data.Status === 1) {
                      window.location.href = routeCodes.HOME;
                      secureLocalStorage.clear()
                    }
                    else if (response.data.Status === 0) {
                      $(".modal-title").html("")
                      $(".modal-body").html("<p class=class_p" > +response.data.Message + "</p>");
                      $(".modal-title").html("")
                      $(".modal-footer").html("");
                      $(".modal-footer").html("<button id=ok>OK</button>");
                      $("#ok").addClass("btn btn-block");
                      $("#ok").on("click", function () {
                        $("#modalDialog").toggle('hide');
                      });
                      $("#modalDialog").toggle('show');
                    }
                    else {
                      $(".modal-title").html("")
                      $(".modal-body").html("<p class='class_p text-danger'>Something went wrong. Try again.</p>");
                      $(".modal-title").html("")
                      $(".modal-footer").html("");
                      $(".modal-footer").html("<button id=ok>OK</button>");
                      $("#ok").addClass("btn btn-block");
                      $("#ok").on("click", function () {
                        $("#modalDialog").toggle('hide');
                      });
                      $("#modalDialog").toggle('show');
                    }
                  })
                  .catch(function (error) {
                    return;
                  });
            });
          }
          else {
            $(".modal-body").html("<p class=class_p>" + response.data.Response + "</p>");
            $(".modal-title").html("Your ASK order not placed!")
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=redirectb0>Got it</button>");
            $("#redirectb0").addClass("btn btn-block");
            $("#redirectb0").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
        }
        else {
          console.warn("BuyMarketOrder");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
          $(".modal-title").html("")
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=redirectb1>Got it</button>");
          $("#redirectb1").addClass("btn btn-block");
          $("#redirectb1").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        return;
      })
      .catch(function (err) {
        // setGetdata(true);
        if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
        // secureLocalStorage.clear();
        $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("<button id=redirect>Got it</button>");
        $("#redirect").addClass("btn btn-block");
        $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
        }
        else{
            console.warn(err,"BuyMarketOrder");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn1>ok</button>");
            $("#closebtn1").addClass('btn btn-block');
            $("#closebtn1").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
        }
        return;
      });
  }


  return (
    <div className="card-body">
      <div className="d-flex flex-sm-row flex-column">
        <div className="mr-auto p-2"><h4 className='class_p'>BUY</h4></div>
        <div className="p-2"> <p className='class_p'>{String((props.symbol).split("/USDT")).replace(',', '').toUpperCase()}</p></div>
      </div>
      <FormProvider methods={methods} onSubmit={handleSubmit(buyCryptoMarket)}>
        {/* <RHFTextField id="buytradeprice" name="buytradeprice" label="Amount" className="mb-3"
          inputProps={
            { readOnly: true, }
          }
          placeholder={props.placeholder != "" ? (props.placeholder.toString() + " $") : ""} /> */}
        <p className='class_p mb-3' id="buytradeprice">
          Market Price : {props.placeholder !== "" ? (props.placeholder.toString() + " $") : ""} </p>
        <RHFTextField id="buytradeqntyMarket" name="buytradeqntyMarket" label="Volume" placeholder="Enter Size" />
        {/* <div className="d-flex justify-content-around mt-2">
														<p className='class_p'>Time In Force Policy</p>
														<p className='class_p'>Good Till Cancelled</p>
													</div> */}
        <div className="col-md-12 text-center mt-3">
          {!ChangeBtnstate ?
            <Link to={routeCodes.LOGIN} className="btn btn-sm btn-block btn-yodigi-buy">Login/ Register</Link> :
            <LoadingButton size="small" type="submit" className="btn btn-sm btn-block btn-yodigi-buy"
              loading={isSubmitting}>Buy now</LoadingButton>
          }
        </div>
      </FormProvider>
    </div>
  )
}


export default BuyMarketOrder;