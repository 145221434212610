const publicPath = '/';

const routeCodes = {
  HOME: publicPath,
  HOMELOGGED: `${ publicPath }user`,
  LOGIN: `${ publicPath }signin`,
  SIGNUP: `${ publicPath }signup`,
  RESET:`${ publicPath }reset`,
  EMAILOTP:`${ publicPath }verifyemailotp`,
  MOBILEOTP:`${ publicPath }verifymobileotp`,
  PINOTP:`${ publicPath }mobileotp`,
  ACTIVE_EMAILOTP:`${ publicPath }verifyemail`,
  ACTIVE_MOBILEOTP:`${ publicPath }verifymobile`,
  ACTIVE_SECEMAILOTP:`${ publicPath }verify_email`,
  ACTIVE_SECMOBILEOTP:`${ publicPath }verify_mobile`,
  DASHBOARD: `${ publicPath }dashboard`,
  OVERVIEW:`${ publicPath }overview`,
  NEWS:`${ publicPath }news`,
  EXCHANGE: `${ publicPath }exchange`,
  EXCHANGEDASHBOARD: `${ publicPath }dashboard/exchange`,
  EXCHANGEMOB: `${ publicPath }dashboard/exchangeview`,
  WALLET:`${ publicPath }overview`,
  CREATEFIATACCOUNT:`${ publicPath }fiat`,
  CREATECRYPTOACCOUNT:`${ publicPath }crypto`,
  ACCOUNTS:`${ publicPath }accounts`,
  VERIFY:`${ publicPath }verifyaccount`,
  NEWTRANSFER:`${ publicPath }transfer`,
  NEWTRANSFERACC:`${ publicPath }transferto`,
  NEWTOPUP:`${ publicPath }topup`,
  NEWFIAT:`${ publicPath }fiattransaction`,
  NEWTRANSFERACC_CRYPTO:`${ publicPath }trade`,
  NEWREQUESTACC_CRYPTO:`${ publicPath }request`,
  NEWREQUESTACC_FIAT:`${ publicPath }requestfiat`,
  TRANSACTIONS:`${ publicPath }transactions`,
  SETTINGS:`${ publicPath }settings`,
  IDVERIFY:`${ publicPath }verification`,
  SECURITY:`${ publicPath }security`,
  REPORTS:`${ publicPath }statement`,
  BUYANDSELL:`${ publicPath }exchange`,
  BANKDETAILS:`${ publicPath }bank`
}
export default routeCodes;