import React from 'react';
import miniLogo from '../../assets/images/web/icon.svg';
// import  image from '../../assets/images/web/logo.svg';

export default function Spinner() {
    return (
        <div className="loader m-auto">
          <div className="ring text-center">
            <img src={miniLogo} className="d-inline-block" alt="loader" />
            <span></span>
          </div>
        </div>
    )
  }
