// App.js
import React from 'react';

import $ from 'jquery';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { CopyToClipboard } from "react-copy-to-clipboard";
import securesecureLocalStorage from 'react-secure-storage';
import secureLocalStorage from 'react-secure-storage';

// components
import routeCodes from '../../../Paths';
import url_local from '../../url_local';

//import css
import '../../web/web.scss';


var products = [];
export default function CryotpAccountTables() {
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  const history = useNavigate();
  const [reports, setReportdata] = React.useState([]);
  // const [loading, setLoading] = React.useState(true);
  const [getdata, setGetdata] = React.useState(false);

//   const handleCopyTextonCrypto = (e) => {
//     document.getElementsByClassName("copiedtext").style.display = "none";
//     document.getElementById(e).style.display = "block";
//  }

  React.useEffect(() => {
    var headers = {
      "Content-Type": "application/json",
      "Access-Token": securesecureLocalStorage.getItem('AT_'),
      "Refresh-Token": securesecureLocalStorage.getItem('RT_')
    };
    products = [];
    axios
      .post(url_local.accountlist,
        {
          "publicid": securesecureLocalStorage.getItem('PI_'),
        },
        { headers })
      .then(function (response) {
        setGetdata(true);
        if (response.data.Status === 1) {
          for (let i = 0; i < response.data.Data.crypto.list.length; i++) {
            // let text = response.data.Data.crypto.list[i].type;
            // var type = String(text.split("account")).replace(',', '').toUpperCase();
            let data = {
              id: i,
              type: response.data.Data.crypto.list[i].type,
              nmbr: response.data.Data.crypto.list[i].account,
              balance: response.data.Data.crypto.list[i].balance,
            }
            products.push(data);
          }
          setReportdata(products);

          // secureLocalStorage.setItem("FL_", JSON.stringify(response.data.Data.fiat.list));
          // secureLocalStorage.setItem("CRL_", JSON.stringify(response.data.Data.crypto.list));
          // loadData();
        }
        else if (response.data.Status === 0) {
          if (response.data.Code === 3 || response.data.code === 7 || response.data.Data.Status === 600) {
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>Your session expired.</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closesessionclosebtncrypto>ok</button>");
            $("body").addClass('no-select'); secureLocalStorage.clear();
            $("#closesessionclosebtncrypto").addClass('btn btn-block');
            $("#closesessionclosebtncrypto").on("click", function () {
              // window.location.href = routeCodes.HOME;
              $("#modalDialog").toggle('hide');
              window.location.href = "/";
            });
            if($("#modalDialog" ).hasClass( "show" )){
              $(".main-panel").css("filter","blur(8px)");
             }
             else{
                 $(".main-panel").css("filter","blur(8px)");
                 $("#modalDialog").toggle('show');
             }
          }
          else if (response.data.Code === 4101) {
            $(".modal-title").html("");
            $(".modal-body").html('<p class=class_p>' + response.data.Message + '.</p>');
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=redirectcr>Activate now?</button><button id=cancelredirectcr>Cancel</button>");
            $("#redirectcr").addClass("btn btn-block");
            $("#cancelredirectcr").addClass("btn btn-block");
            $("#redirectcr").on("click", function () {
              history(routeCodes.WALLET);
              $("#modalDialog").toggle('hide');
            });
            $("#cancelredirectcr").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            // $("#modalDialog").toggle('show');
          }
          else {
            $(".modal-body").html('<p class=class_p>' + response.data.Message + '</p>');
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=laterbtn1>Got it</button>");
            $("#laterbtn1").addClass("btn btn-block");
            $("#laterbtn1").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
        }
        else {
          $(".modal-body").html("<p className='text-danger'>Something went wrong. Try again.</p>");
          $(".modal-title").html("")
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=got_it>Got it</button>");
          $("#got_it").addClass("btn btn-block");
          $("#got_it").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        return;
      })
      .catch(function (err) {
        setGetdata(true);
        if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
        // secureLocalStorage.clear();
        $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("<button id=redirect>Got it</button>");
        $("#redirect").addClass("btn btn-block");
        $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
        }
        else{
            console.warn(err,"CryptoAccountTables");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn1>ok</button>");
            $("#closebtn1").addClass('btn btn-block');
            $("#closebtn1").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
        }
        return;
      })
  }, [history]);


  const checkBalance = (event) => {
    var data = {
      "publicid": securesecureLocalStorage.getItem('PI_'),
      "address": event.target.id,
      "symbol": $(event.target).closest('tr').attr('id')
    }

    var headers = {
      "Content-Type": "application/json",
      "Access-Token": securesecureLocalStorage.getItem('AT_'),
      "Refresh-Token": securesecureLocalStorage.getItem('RT_')
    };
    axios
      .post(url_local.checkcrbalance,
        data,
        { headers })
      .then(function (response) {
// console.log(response.data)
        if (response.data.Status === 1) {
          // $(".modal-body").html(response.data.Response);
          // $(".modal-title").html("")
          // $("#modalDialog").toggle('show');
          // window.location.reload();
          // document.getElementById(symbol).innerHTML = response.data.Response;
          $(".modal-title").html("");
          $(".modal-body").html("<p class=class_p>Address : " + response.data.Data.address + "</p><br /><p class=class_p>Balance : " + format(response.data.Data.balance) + "</p>");
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=closebtn>ok</button>");
          $("#closebtn").addClass('btn btn-block');
          $("#closebtn").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        else if (response.data.Status === 0) {
          if (response.data.Data.Status === 600) {
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>Your session expired.</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn>ok</button>");
            $("body").addClass('no-select'); secureLocalStorage.clear();
            $("#closebtn").addClass('btn btn-block');
            $("#closebtn").on("click", function () {
              $("#modalDialog").toggle('hide');
              window.location.href = "/";
            });
            if($("#modalDialog" ).hasClass( "show" )){
              $(".main-panel").css("filter","blur(8px)");
              $("#modalDialog").toggle('show');
             }
             else{
                 $(".main-panel").css("filter","blur(8px)");
                 $("#modalDialog").toggle('show');
             }
          }
          else if (response.data.Data.Status === 4106) {
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>" + response.data.Data.Response + "</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn>ok</button>");
            $("#closebtn").addClass('btn btn-block');
            $("#closebtn").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
          else {
            $(".modal-title").html("");
            $(".modal-body").html(response.data.Message);
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn>ok</button>");
            $("#closebtn").addClass('btn btn-block');
            $("#closebtn").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
        }
        return;
      })
      .catch(function (err) {
        setGetdata(true);
        if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
        // secureLocalStorage.clear();
        $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("<button id=redirect>Got it</button>");
        $("#redirect").addClass("btn btn-block");
        $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
        }
        else{
            console.warn(err,"CryptoAccountTables");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn1>ok</button>");
            $("#closebtn1").addClass('btn btn-block');
            $("#closebtn1").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
        }
        return;
      })
  }

  function trade(address,value) {
    secureLocalStorage.setItem("AD_", address);
    secureLocalStorage.setItem("CV_", value);
    history(routeCodes.NEWTRANSFER, secureLocalStorage.getItem("AD_"),secureLocalStorage.getItem("CV_"))
  }


  function request(address,value) {
    secureLocalStorage.setItem("AD_", address);
    secureLocalStorage.setItem("CV_", value);
    history(routeCodes.NEWREQUESTACC_CRYPTO, secureLocalStorage.getItem("AD_"),secureLocalStorage.getItem("CV_"))
  }


  const CopiedText = (event) => {
    $(".modal-body").html('<p class=class_p>Copied : '+event+'</p>');
    $(".modal-footer").html("");
    $(".modal-footer").html("<button id=copied class=btn-success>Got it</button>");
    $("#copied").addClass("btn btn-block");
    $("#copied").on("click", function () {
      $("#modalDialog").toggle('hide');
    });
    $("#modalDialog").toggle('show');
  }

  const deletecloseRightSidebar = () => {
    document.querySelector('#delete-right-sidebar').classList.remove('open');
  }


  const format = num =>
    String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');

  //   if (loading) {
  //     return (<Spinner />)
  // }
  // else{
  return (
    <>
      {
        getdata ?
          <>
            {reports.length > 0 ?
              <>
                <div className="table-responsive">
                  <table className="table table-borderless text-white">
                    <thead>
                      <tr>
                        <th>Account type</th>
                        <th>Address</th>
                        <th>Balance</th>
                        <th>Actions</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {reports.map((item, index) =>
                        <tr key={index} id={item.type} >
                          <td className='text-center'>{item.type}</td>
                          <td className="card-description text-label font-bold d-flex">
                            {item.nmbr}
                            <CopyToClipboard text={item.nmbr} onCopy={CopiedText}>
                              <button className='mx-2 btn btn-sm settingsBtn text-success' data-toggle="tooltip" data-placement="top" title="Copy to Clipboard">
                                <i className='mdi mdi-content-copy mdi-32px'></i>
                              </button>
                            </CopyToClipboard>
                            {/* <span className="class_p text-danger copiedtext" id={item.nmbr} style={{ display:'none'}}>
                              Copied.</span> */}
                          </td>
                          <td className='text-end'>
                            <span id={item.index}>{item.balance.toFixed(8)}</span>
                            <button className="text-white ml-1 btn btn-sm"
                              onClick={(item) => checkBalance(item)}
                            >
                              <i className='mdi mdi-refresh text-success' id={item.nmbr} value={item.type}></i>
                            </button>
                          </td>
                          <td className='text-center'>
                            <button className="btn btn-sm mx-1 text-success" onClick={() => trade(item.nmbr,item.type)}>Send</button>
                            <button className="btn btn-sm mx-1 text-success" data-accountno={item.nmbr} onClick={() => request(item.nmbr,item.type)}>Request</button>
                          </td>
                          <td></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
              :
              <div className="table-borderless table-responsive mt-2 ">
                <table className="table table-hover col-12">
                  <thead>
                    <tr>
                      <th>Account type</th>
                      <th>Address</th>
                      <th>Balance</th>
                      <th>Actions</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className='form-label text-center'>
                    <tr>
                      <td colSpan="6">
                        <h1 className='mt-5 card-description'><i className='mdi mdi-wallet mdi-48px'></i></h1>
                        <h4 className="text-center card-description">No accounts created</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            }</> :
          <div className='table-responsive'>
            <table className="table table-borderless table-hover text-white" id="table1">
              <tbody className='form-label text-center mt-5'>
                <tr className='mt-4'>
                  <td colSpan={6}>
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>}

      <div id="delete-right-sidebar" className="settings-panel right-sidebar">
        <i className="settings-close mdi mdi-close" onClick={deletecloseRightSidebar}></i>
        <Tabs defaultActiveKey="DELETE" className="text-left" id="">
          <Tab eventKey="DELETE" >
            <div className='p-4'>
              <div className="d-flex align-items-center justify-content-center">
                <i className='mdi mdi-alert' style={{ fontSize: '66px', color: 'yellow' }}></i>
              </div>
              <h4 className="text-center">Disable Account</h4>
              <p>Disabling account will cause you the following : </p>
              <ul className="todo-list">
                <li>* All trading capacities and login for your account will be disabled.</li>
                <li>* All API keys for your account will be disabled.</li>
                <li>* All pending transactions will be cancelled.</li>
                <li>* All open orders will be cancelled.</li>
              </ul>

              <p className='mt-4 class_span'>
                Once your account is disabled, you will be unable to begin the reactivation process until at least two hours have passed.</p>
              <div className='d-flex align-items-center justify-content-center'>
                <button className='btn btn-sm btn-success' onClick={deletecloseRightSidebar}>Cancel</button>
                <button className='btn btn-sm btn-danger'>Delete</button>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  )
  // }
}