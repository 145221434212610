import React, { useRef, useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import url_local from '../url_local';
// import routeCodes from "../../Paths";
// import { Navigate } from 'react-router-dom';
import 'datatables.net-responsive';
import '../../App.scss';
import '../web/web.scss';

import "../../assets/styles/web/css/style.css";

import { GlobalContext } from '../shared/useForm';
import secureLocalStorage from 'react-secure-storage';
import { Residencelist } from '../shared/Nationalities';
import { LocalCurrencylist } from '../shared/CurrencyLists';

export default function BankDetails(props) {
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  const userData = useRef();
  // const [isKycApproved, setIsKycApproved] = useState(false);
  const gContext = React.useContext(GlobalContext);
  // const [bankData, setBankData] = useState([]);
  userData.current = gContext.user();
  const validation = () => {
  //   {
  //     "publicid": "{{publicid}}",
  //     "purpose": "create",
  //     "full_name": "Dilip Paliyath",
  //     "account_no": "2341234001",
  //     "iban": "AE1200121200222341234001",
  //     "account_type": "checking",
  //     "swift_code": "AEENB1234",
  //     "ifsc_code": "AE123XYZ001",
  //     "bic": "123456",
  //     "isactive": true,
  //     "bank_name":"UAE BANK",
  //     "branch_name":"Sharjah",
  //     "country":"United Arab Emirates",
  //     "currency":"AED"
  // }
		// var full_name = document.getElementById('full_name').value;
		// var account_no = document.getElementById('account_no').value;
		// var iban = document.getElementById('iban').value;
		// var ifsc_code = document.getElementById('ifsc_code').value;
		// var swift_code = document.getElementById('swift_code').value;
		// var account_type = $("#account_type").find('option:selected', this).val();
    // var country = $("#country").find('option:selected', this).val();
    // var currency = $("#currency").find('option:selected', this).val();
		// var bic = document.getElementById('bic').value;
		// var bank_name = document.getElementById('bank_name').value;
		// var branch_name = document.getElementById('branch_name').value;


    // update_bank_info();

	}

  const handleSubmit = (event) => {
    event.preventDefault();
    const purpose = event.target.elements.purpose;
    // console.log(purpose.value);
    let headers = {
      'Content-Type': 'application/json',
      'Access-Token': secureLocalStorage.getItem('AT_'),
      "Refresh-Token": secureLocalStorage.getItem('RT_')
    };

    if ( purpose.value === "create"){
      const full_name = event.target.full_name;
      const account_no = event.target.elements.account_no;
      const iban = event.target.elements.iban;
      const ifsc_code = event.target.elements.ifsc_code;
      const swift_code = event.target.elements.swift_code;
      const account_type = event.target.elements.account_type;
      const country = event.target.elements.country;
      const currency = event.target.elements.currency;
      const bic = event.target.elements.bic;
      const bank_name = event.target.elements.bank_name;
      const branch_name = event.target.elements.branch_name;
      let payload = {
        "publicid": secureLocalStorage.getItem('PI_'),
        "purpose": "create",
        "full_name": full_name.value,
        "account_no": account_no.value,
        "iban": iban.value,
        "account_type": account_type.value,
        "swift_code": swift_code.value,
        "ifsc_code": ifsc_code.value,
        "bic": bic.value,
        "isactive": true,
        "bank_name":bank_name.value,
        "branch_name":branch_name.value,
        "country":country.value,
        "currency":currency.value
      }
      // console.log("post to ->>", url_local.userbank);
      axios.post(url_local.userbank, payload, { headers })
        .then(function (response) {
          // console.log("response ->>", response);
          if (response.data.Status === 1) {
            $(".modal-body").html(response.data.Data.Response);
            $(".modal-title").html("<h3>Successfully Submitted!</h3>")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn>Continue</button>");
            $("#closebtn").addClass('btn btn-block');
            $("#closebtn").on("click", function () {
              $("#modalDialog").toggle('hide');
              load_banks();
            });
            $("#modalDialog").toggle('show');
          }
          else if (response.data.Data.Status === 0) {
            $(".modal-body").html(response.data.Data.Response);
            $(".modal-title").html("<h3>Update Failed!</h3>")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn>Continue</button>");
            $("#closebtn").addClass('btn btn-block');
            $("#closebtn").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
          else {
            $(".modal-body").html("<p className='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("<h3>Update Failed!</h3>")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtnerr>Continue</button>");
            $("#closebtnerr").addClass('btn btn-block');
            $("#closebtnerr").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
          return;
        })
        .catch(function () {
          return;
      });
    }


};

$.DataTable = require('datatables.net');
useEffect(() => {
  $.fn.dataTableExt.sErrMode = 'none';
    $.extend($.fn.dataTable.defaults, {
      // responsive: true
    });
    load_banks();
  // eslint-disable-next-line
},[])

function load_banks(){
  let headers = {
    'Content-Type': 'application/json',
    'Access-Token': secureLocalStorage.getItem('AT_'),
    "Refresh-Token": secureLocalStorage.getItem('RT_')
  };

  let payload = {
    "publicid": secureLocalStorage.getItem('PI_'),
    "purpose": "list",
  }
  axios.post(url_local.userbank, payload, { headers })
    .then(function (response) {
      // console.log("response ->>", response);
      if (response.data.Status === 1) {
        // setBankData(response.data.Data);
        let table = $('#tbl_banks').DataTable(
          {
          // dom: 'Blfrtip',
          // buttons: ['copyHtml5', 'excelHtml5', 'pdfHtml5', 'csvHtml5',]
          "columnDefs": [
            {
                "targets": [7],
                "className": "center",
                // "render": function ( data, type, full, meta ) {
                //   return "<button class='view_btn' data-id='"+data+"'>view</button>"
                // }
                "data": function (d) {
                    return "<button class='delete_btn btn btn-sm btn-yodigi-buy' data-id='"+d[1]+"'>Delete</button>";
                    // return <a className="delete mx-1" title="Delete" data-toggle="tooltip" onClick={deleteBank}><i className="fa fa-trash"></i></a>;
                },
                // createdCell: (td, cellData, rowData, row, col) =>
                //   render(<button className="btn btn-warning" style={{borderRadius: '30px'}}onClick={deleteBank}>Delete</button>, td),
            }
            ]
          }
        );
        table.clear();
        let bank_list = []
        for (let i = 0; i < response.data.Data.length; i++) {
          var date = new Date(response.data.Data[i].created_on);
          date = date.toString();
          bank_list.push(response.data.Data[i].account_no)
          table.row.add(
            [
              i + 1,
              response.data.Data[i].account_no,
              response.data.Data[i].account_type,
              response.data.Data[i].bank_name,
              response.data.Data[i].beneficiary_full_name,
              response.data.Data[i].country,
              response.data.Data[i].isactive,
            ]
          );
        }
        // setBankData(bank_list);
        table.draw();
        $('#tbl_banks').on('click', '.delete_btn', function(){
          var account_no = $(this).data('id');
          // alert(account_no); // your function
          $(".modal-body").html("You are about to delete<br>Bank Account "+ account_no+"?<br>Enter Security Pin to confirm: <input type='password' class='text-center form-control form-control-sm w-auto mx-auto' id='sec_pin' autocomplete='new-password' placeholder='- Enter Pin -'>");
          $(".modal-title").html("")
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=agreebtn>Continue</button> <button id=closebtn>Cancel</button>");
          $("#agreebtn").addClass('btn btn-block');
          $("#closebtn").addClass('btn btn-block');
          $("#closebtn").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#agreebtn").on("click", function () {
            let pin = $("#sec_pin").val();
            confirm_Delete(account_no,pin);
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');

        });
      }
      else {

      }
      return;
    })
    .catch(function () {
      return;
  });
}

  function confirm_Delete(account_no,pin){
    let headers = {
      'Content-Type': 'application/json',
      'Access-Token': secureLocalStorage.getItem('AT_'),
      "Refresh-Token": secureLocalStorage.getItem('RT_')
    };

    let payload = {
      "publicid": secureLocalStorage.getItem('PI_'),
      "purpose": "delete",
      "account_no": account_no,
      "pin":pin,
    }
    // console.log("post to ->>", url_local.userbank);
    axios.post(url_local.userbank, payload, { headers })
      .then(function (response) {
        // console.log("response ->>", response);
        if (response.data.Status === 1) {
          // console.log("Success ->>", response.data.Data);
          $(".modal-body").html(response.data.Data.Response);
          $(".modal-title").html()
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=closebtn>Continue</button>");
          $("#closebtn").addClass('btn btn-block');
          $("#closebtn").on("click", function () {
            $("#modalDialog").toggle('hide');
            load_banks();
          });
          $("#modalDialog").toggle('show');
        }
        else if (response.data.Data.Status === 0) {
          // console.log("Failed ->>", response.data.Data);
          $(".modal-body").html(response.data.Data.Response);
          $(".modal-title").html()
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=closebtn>Continue</button>");
          $("#closebtn").addClass('btn btn-block');
          $("#closebtn").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        else {
          $(".modal-body").html("<p className='text-danger'>Something went wrong. Try again!</p>");
          $(".modal-title").html("<h3>Delete Failed!</h3>")
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=closebtnerr>Continue</button>");
          $("#closebtnerr").addClass('btn btn-block');
          $("#closebtnerr").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        return;
      })
      .catch(function () {
        return;
    });
  }
    return (
      <div className="row">
        <div className="col-md-12">
          {/* internal container is div.card below */}
          <div className="card px-4">
            <div className="page-header w-100">
              <h3 className="page-title w-100 row">
                <div className='col-5'>
                  <span className="page-title-icon bg-success me-2">
                    <i className="mdi mdi-bank"></i>
                  </span>Bank Details
                </div>
                <div className='col'>
                  <div className="float-end">
                    <div className='text-lastlogin text-end fs-6'>
                      <i className="mdi mdi-account px-2"></i>Last login time
                      <label className='d-block'>{secureLocalStorage.getItem('DT_')}</label>
                    </div>
                  </div>
                </div>
              </h3>
            </div>

            <p className="class_p text-danger mx-2 mb-1">Required( <sup>*</sup> )</p>
            <div className="card-body">
							<h5><i className="mdi mdi-bank mr-1"></i> Bank information</h5>
              <form className="needs-validation" onSubmit={handleSubmit}>
                <input type='hidden' name='purpose' value='create'/>
                <div className='row'>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <div className="form-group row">
                        <label className="col-4 col-form-label class_p">Full Name<sup>*</sup></label>
                        <div className="col-8">
                          <input type="text" name="full_name" className="form-control form-control-sm" id="full_name" required />
                        </div>
                      </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <div className="form-group row">
                        <label className="col-4 col-form-label class_p">Account No<sup>*</sup></label>
                        <div className="col-8">
                          <input type="text" name="account_no" id="account_no" className="form-control form-control-sm" required />
                        </div>
                      </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <div className="form-group row">
                        <label className="col-4 col-form-label class_p">IBAN<sup>*</sup></label>
                        <div className="col-8">
                          <input type="text" name="iban" id="iban" className="form-control form-control-sm" required />
                        </div>
                      </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <div className="form-group row">
                        <label className="col-4 col-form-label class_p">Account Type<sup>*</sup></label>
                        <div className="col-8">
                          <select defaultValue="" className="form-control-sm form-select form-select-sm w-100" id="account_type" name='account_type' required>
                            <option value="" disabled>Select</option>
                            <option value="checking">Checking</option>
                            <option value="saving">Saving</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <div className="form-group row">
                        <label className="col-4 col-form-label class_p">Swift Code</label>
                        <div className="col-8">
                          <input type="text" name="swift_code" id="swift_code" className="form-control form-control-sm"/>
                        </div>
                      </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <div className="form-group row">
                        <label className="col-4 col-form-label class_p">IFSC Code</label>
                        <div className="col-8">
                          <input type="text" name="ifsc_code" id="ifsc_code" className="form-control form-control-sm" />
                        </div>
                      </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <div className="form-group row">
                        <label className="col-4 col-form-label class_p">BIC</label>
                        <div className="col-8">
                          <input type="text" name="bic" id="bic" className="form-control form-control-sm" />
                        </div>
                      </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <div className="form-group row">
                        <label className="col-4 col-form-label class_p">Bank Name<sup>*</sup></label>
                        <div className="col-8">
                          <input type="text" name="bank_name" id="bank_name" className="form-control form-control-sm" required />
                        </div>
                      </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <div className="form-group row">
                        <label className="col-4 col-form-label class_p">Branch Name<sup>*</sup></label>
                        <div className="col-8">
                          <input type="text" name="branch_name" id="branch_name" className="form-control form-control-sm" required />
                        </div>
                      </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <div className="form-group row">
                        <label className="col-4 col-form-label class_p">Country<sup>*</sup></label>
                        <div className="col-8">
                          <Residencelist id="country" name="country" className="form-control-sm form-select form-select-sm w-100" required />
                        </div>
                      </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <div className="form-group row">
                        <label className="col-4 col-form-label class_p">Currency<sup>*</sup></label>
                        <div className="col-8">
                          <LocalCurrencylist id="currency" name="currency" className="form-control-sm form-select form-select-sm w-100" required />
                        </div>
                      </div>
                  </div>
                </div>
                <div className="text-center mt-3 mb-3" >
                  <button className="btn btn-sm btn-yodigi-buy" type="submit" onClick={validation}>Register Bank</button>
                </div>
              </form>
            </div>
            <div className="card-body">
              <div className='border-top border-1 mb-5'></div>
							<h5 className='my-3'><i className="mdi mdi-bank mr-1"></i> Registered Bank</h5>
              <div className="d-flex table-responsive mb-3">
                <table className="table nowrap w-100" id="tbl_banks">
                  <thead>
                    <tr className='text-left'>
                      <th></th>
                      <th>Account No</th>
                      <th>Account Type</th>
                      <th>Bank Name</th>
                      <th>Account Name</th>
                      <th>Country</th>
                      <th>Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>


            </div>
          </div>
        </div>
      </div>
    )
}

