import React from 'react';
import $ from 'jquery';
import * as Yup from 'yup';

import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { FormProvider, RHFTextField } from '../components/hook-form';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//mui
import { LoadingButton } from '@mui/lab';

import image from '../../assets/images/web/logo.svg';

import '../web/web.scss';
import '../../assets/styles/web/css/style.css'

import url_local from '../url_local';
import routeCodes from '../../Paths';
import secureLocalStorage from 'react-secure-storage';

// var _this;
export default function Reset() {
  const [getdata, setGetdata] = React.useState(false);
  const [reset, setreset] = React.useState(false);
  const history = useNavigate();

  const ResetSchema = Yup.object().shape({
    resetemail: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const defaultValues = {
    resetemail: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(ResetSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  const resetpassword = () => {
    setGetdata(true);
    var headers = { "Content-Type": "application/json" };

    axios
      .post(url_local.reset,
        {
          "email": document.getElementById('resetemail').value
        },
        { headers })
      .then(function (response) {
        //   _this.setState({data: response.data});
// console.log("reset response", response.data)
        if (response.data.Status === 1) {
          setGetdata(false);
          localStorage.setItem('resetemail', document.getElementById('resetemail').value)
          history(routeCodes.EMAILOTP, localStorage.getItem('resetemail'));
          setreset(true)
        }
        else if (response.data.Status === 0) {
          setGetdata(false);
// console.log(response.data.Code)
          if (response.data.Code === 1006) {
            secureLocalStorage.getItem('EM_', localStorage.getItem("resetemail"));
            $(".modal-title").html("");
            $(".modal-body").html('<p class=class_p>' + response.data.Message + '<br />Please verify your Email now.</p>');
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=redirect>Verify now</button><button id=cancelredirect>Cancel</button>");
            $("#redirect").addClass("btn btn-block");
            $("#cancelredirect").addClass("btn btn-block");
            $("#redirect").on("click", function () {
              ActivateCode(response.data.Code);
              $("#modalDialog").toggle('hide');
            });
            $("#cancelredirect").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
          else {
            $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=redirect>Got it</button>");
            $("#redirect").addClass("btn btn-block");
            $("#redirect").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
        }
        else {
          setGetdata(false);
          $(".modal-body").html("<p className='text-danger'>Something went wrong. Try again.</p>");
          $(".modal-title").html("")
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=redirect>Got it</button>");
          $("#redirect").addClass("btn btn-block");
          $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        return;
      })
      .catch(function () {
        return;
      });
  }

  const ActivateCode = (code) => {
    setGetdata(true);
    var headers = { "Content-Type": "application/json" };

    axios
      .post(url_local.activateCode,
        {
          "email": localStorage.getItem("resetemail")
        },
        { headers })
      .then(function (response) {
// console.log(response.data)
        if (response.data.Status === 1) {
          setGetdata(false);
          if (code === 1101 || code === 1104 || code === 1006) {
            history(routeCodes.ACTIVE_EMAILOTP, localStorage.getItem("resetemail"));
          }
          else if (code === 1105) {
            history(routeCodes.ACTIVE_MOBILEOTP, localStorage.getItem("resetemail"));
          }
          else {
            history(routeCodes.LOGIN, localStorage.getItem("resetemail"));
          }
        }
        else if (response.data.Status === 0) {
          setGetdata(false);
          $(".modal-body").html("<p class=class_p>" + response.data.Message + "</p>");
          $(".modal-title").html("")
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=redirect>Got it</button>");
          $("#redirect").addClass("btn btn-block");
          $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        else {
          setGetdata(false);
          $(".modal-body").html("<p className='text-danger'>Something went wrong. Try again.</p>");
          $(".modal-title").html("")
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=redirect>Got it</button>");
          $("#redirect").addClass("btn btn-block");
          $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        return;
      })
      .catch(function () {
        return;
      });
  }

  if (reset) {
    return <Navigate to={routeCodes.ACTIVEOTP} />
  }
  else {
    return (
      <div className="d-flex align-items-center auth px-0 pt-5 mt-5">
        <div className="row w-100 mx-0">
          <div className="brand-logo text-center mb-3" >
            {/* <div onClick={CancelReset} style={{curspor:'pointer'}}>
                <img className='image' src={image} alt="Meezan" />
              </div> */}
            <Link className="navbar-brand" to={routeCodes.HOME}>
              <img className='image' src={image} alt="Meezan" />
            </Link>
          </div>
          <div className="col-md-6 col-xs-12 mx-auto card">
            <div className="card-body text-left py-3 ">
              <div className=" d-flex flex-column justify-content-center mb-2">
                <div>
                  <h4 className='text-center text-white'>Reset password</h4>
                </div>
                <div>
                  <h6 className="text-center font-weight-light mt-2 class_p">
                    Lost your password? We will send an one time
                    password to this email.</h6>
                </div>
              </div>
              <FormProvider methods={methods} onSubmit={handleSubmit(resetpassword)}>
                <div className="mb-3 mt-3">
                  <RHFTextField name="resetemail" id='resetemail' label="Email" placeholder="Enter registered email" inputProps={{ className: "text-lowercase text-white" }} />
                </div>
                <div className="text-center mb-2">
                  {!getdata ?
                    <LoadingButton size="medium" type="submit" variant="contained" loading={isSubmitting} className="btn-sm btn-yodigi-buy">Submit</LoadingButton>
                    :
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>}
                  <a className="px-2 mt-5" href={routeCodes.LOGIN}>Return to login</a>
                  {/* <div className="row justify-content-between mt-4 mb-2">
                      <div className="col-auto text-center mt-1 font-weight-light">
                        <Link
                          className="auth-link text-white"
                          to={routeCodes.SIGNUP}>
                          New User Registration
                        </Link>
                      </div>
                      <div className="col-auto text-center mt-1 font-weight-light">
                        <Link className="auth-link text-white px-2 mt-5" to={routeCodes.LOGIN}>SignIn</Link>
                    </div>
                  </div> */}
                </div>
              </FormProvider>
            </div>
          </div>
          <div className="text-center mt-1 font-weight-light">
            <p className='text-white mt-3'>New to Meezan? <a
              href={routeCodes.SIGNUP}> Create an account.</a></p>
          </div>
        </div>
      </div>

    )
  }
}
// }
