
// const rootapi = "https://meezan.yospace.shellfixer.com/";
const rootapi = "https://api.yodigispace.com/";
// const rootapi = "http://192.168.1.23/";
// const rootapi = "http://139.59.50.223:5000/";
const url = {
    "home":rootapi,
    "register":rootapi+"dgxusers/users/",
    "login":rootapi+"dgxusers/users/login/",
    "logout":rootapi+"dgxusers/users/logout/",
    "activateCode":rootapi+"dgxusers/users/activationcode/",
    "reset":rootapi+"dgxusers/users/resetpass/",
    "activateotp":rootapi+"dgxusers/users/activate/",
    "trans":rootapi+"dgxtrans/",
    "fxrates":rootapi+"dgxtrans/fxrates/",
    "accountlist":rootapi+"dgxtrans/accountlist/",
    "managepin":rootapi+"dgxtrans/managepin/",
    "registeraccount":rootapi+"dgxtrans/registeraccount/",
    "deletefiataccount":rootapi+"dgxtrans/deletefiataccount/",
    "createfiataccount":rootapi+"dgxtrans/createfiataccount/",
    "create_eth_cryptoaccount":rootapi+"dgxtrans/createethwallet/",
    "create_btc_cryptoaccount":rootapi+"dgxtrans/createbtcwallet/",
    "create_cryptoaccount":rootapi+"dgxtrans/createcrwallet/",
    "check_eth_cryptobalance":rootapi+"dgxtrans/checkethbalance/",
    "check_btc_cryptobalance":rootapi+"dgxtrans/checkbtcbalance/",
    "checkcrbalance":rootapi+"dgxtrans/checkcrbalance/",
    "cryptotransaction":rootapi+"dgxtrans/crtransactions/",
    "kyc":rootapi+"dgxusers/users/kycupdate/",
    "uploadimage":rootapi+"uploadimage/",
    "uploadimageidverify":rootapi+"uploadimageidverify/",
    "uploadreceipt":rootapi+"dgxtrans/uploadreceipt/",
    "passwordupdate":rootapi+"dgxusers/users/passwordupdate/",
    "accountstatements":rootapi+"dgxtrans/accountstatement/",
    "reports":rootapi+"dgxtrans/statements/",
    "fiatLists":rootapi+"dgxtrans/fiatlist/",
    "accountQR":rootapi+"dgxtrans/accountQR/",
    "fiattransfer":rootapi+"dgxtrans/fiattransfer/",
    "creditrequest":rootapi+"dgxtrans/creditrequest/",
    "getaccountowner":rootapi+"dgxtrans/getaccountowner/",
    "checkactivate":rootapi+"dgxtrans/checkforaccount/",
    "checkuserstatus":rootapi+"dgxusers/users/userstatus/",
    "getbankAccountdetails":rootapi+"dgxtrans/bankdetails/",
    "orderbooks":rootapi+"dgxtrade/orderbooks/",
    "trade":rootapi+"dgxtrade/trade/",
    "marketTrades":rootapi+"dgxtrade/markettrade/",
    "myTrades":rootapi+"dgxtrade/mytrade/",
    "fiatexchange":rootapi+"dgxtrade/fiatexchange/",
    "enableWallet":rootapi+"dgxusers/users/activate/",
    "disableWallet":rootapi+"dgxusers/users/disable/",
    "transadminapproval":rootapi+"dgxadmin/transapproval/",
    "userlists":rootapi+"dgxusers/users/",
    "userbank":rootapi+"dgxtrans/userbank/",
    "withdrawalrequest":rootapi+"dgxtrans/withdrawalrequest/",
    "cr_portfolio_indicator":rootapi+"dgxtrans/cr_portfolio_indicator/",
};

export default url;