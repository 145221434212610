import React, { } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import '../css/index.css';

import image1 from '../../../assets/images/web/Main/customer.jpg';
import image2 from '../../../assets/images/web/testimonial/2.jpg';

import logo1 from '../../../assets/images/web/Main/Tempo_logo.png';
import logo2 from '../../../assets/images/web/brand/3.svg';

import '../../web/web.scss';

function Testimonial() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <>
            <Slider {...settings}>
                <>
                    <div className="row align-items-center " style={{display: "flex!important" }}>
                        <div className="col-xl-6 col-lg-6">
                            <div className="customer-img">
                                <img className="img-fluid" src={ image1 } alt="" />
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5"  >
                            <div className="customer-review "   >
                                <img className="logo_img" src={ logo1 } alt="" />
                                <p className='class_p'>The fees are very competitive and transactions are
                                    very fast.I particularly enjoyed learning about cryptos
                                    while earning some as rewards for doing the courses
                                    for free!</p>
                                <p className='class_p mt-2'>
                                    The multitude of payment methods and the ability to
                                    pay your mining directly to binance is a huge bonus as
                                    you don't end up paying double fees.
                                </p>
                                <div className="customer-info mt-2">
                                    <h6 className='class_p'>Mr John Doe</h6>
                                    <p className='class_p'>CEO, Example Company</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                <>
                    <div className="row align-items-center " style={{ display: "flex!important" }}>
                        <div className="col-xl-6 col-lg-6">
                            <div className="customer-img">
                                <img className="img-fluid" src={ image2 } alt="" />
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5"  >
                            <div className="customer-review justify-content"   >
                                <img className="logo_img" src={ logo2 } alt="" />
                                <p className='class_p'>The fees are very competitive and transactions are
                                    very fast.I particularly enjoyed learning about cryptos
                                    while earning some as rewards for doing the courses
                                    for free!</p>
                                <p className='class_p mt-2'>
                                    The multitude of payment methods and the ability to
                                    pay your mining directly to binance is a huge bonus as
                                    you don't end up paying double fees.
                                </p>
                                <div className="customer-info mt-2">
                                    <h6 className='class_p'>Mr John Doe</h6>
                                    <p className='class_p'>CEO, Example Company</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>

              
            </Slider>
        </>
    )
}

export default Testimonial;