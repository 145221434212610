import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import * as Yup from 'yup';

import axios from 'axios';
import { Link } from 'react-router-dom';
import { FormProvider, RHFTextField } from '../components/hook-form';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
// import { OrderBookContext } from '../Context';
// import { Context } from "../Context";

import '../web/web.scss';
import url_local from '../url_local';
import routeCodes from '../../Paths';
import secureLocalStorage from 'react-secure-storage';

// var lists = [], tradelists = [];
function SellMarketOrder(props) {
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
  // const [getdata, setGetdata] = useState(false);
  // const [accountData, setAccountdata] = useState([]);
  // const [tradeData, setTradedata] = useState([]);
  // const [Resp, setResponse] = useState();
  const [ChangeBtnstate, setChangeBtnstate] = useState(false);

  // const history = useNavigate();

  useEffect(() => {
    if (secureLocalStorage.getItem('PI_') !== "") {
      setChangeBtnstate(true);
    }
    else {
      setChangeBtnstate(false);
    }
  }, [])

  const TradeSchema = Yup.object().shape({
    // selltradepricemarket: Yup.number()
    // .typeError('you must specify a number')
    // .min(0, 'Min value 0.')
    // .required("This field is required"),
    selltradeqntymarket: Yup.number()
      .typeError('you must specify a number')
      .min(0, 'Min value 0.')
      .required("This field is required"),
  });

  const defaultValues = {
    // selltradepricemarket: '',
    selltradeqntymarket: '',
  };

  const methods = useForm({
    resolver: yupResolver(TradeSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;



  const SellCryptoMarket = () => {
    var headers = {
      "content-Type": "application/json",
      "Access-Token": secureLocalStorage.getItem('AT_'),
      "Refresh-Token": secureLocalStorage.getItem('RT_')
    }
    var data = {
      "publicid": secureLocalStorage.getItem('PI_'),
      "currency": secureLocalStorage.getItem("CS_"),
      "accountno": secureLocalStorage.getItem("AN_"),
      // "security_symbol":document.getElementById('securitysymbol').value,
      "security_symbol": String((props.symbol).split("/USDT")).replace(',', '').toUpperCase(),
      "trade_side": "BID",
      // "trade_price":document.getElementById('selltradepricemarket').value,
      "trade_price": props.placeholder,
      "trade_quantity": document.getElementById('selltradeqntymarket').value,
    }
    axios
      .post(url_local.marketTrades,
        data,
        { headers })
      .then(function (response) {
        document.getElementById('trade-right-sidebar').classList.remove('open');
        if (response.data.Status === 1) {
          document.getElementById('selltradeqntymarket').value = "";
          document.getElementById('selltradepricemarket').value = "";
          $(".modal-body").html("<p class=class_p>" + response.data.Response + "</p>");
          $(".modal-title").html("Order ID :  " + response.data.Data.Order_id);
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=redirectsellm class=btn-success>Got it</button>");
          $("#redirectsellm").addClass("btn btn-block");
          $("#redirectsellm").on("click", function () {
            // history(routeCodes.EXCHANGEDASHBOARD);
            $("#modalDialog").toggle('hide');
            window.location.href=routeCodes.DASHBOARD;
            secureLocalStorage.setItem("CS_", "");
            secureLocalStorage.setItem("AN_", "");
          });
          $("#modalDialog").toggle('show');
        }
        else if (response.data.Status === 0) {
          if (response.data.Code === 3 || response.data.Code === 7 || response.data.Data.Status === 600) {
            $(".modal-title").html("");
            $(".modal-body").html("<p class=class_p>Your session expired</p>");
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=signoutbtn>ok</button>");
            $("#signoutbtn").addClass('btn btn-block');
            $("#modalDialog").toggle('show');
            $("#signoutbtn").on('click', function () {
                var headers = {
                  "Content-Type": "application/json",
                  'Access-Token': secureLocalStorage.getItem('AT_'),
                  'Refresh-Token': secureLocalStorage.getItem('RT_')
                }
                axios
                  .post(url_local.logout, { publicid: secureLocalStorage.getItem('PI_') },
                    { headers })
                  .then(function (response) {
                    if (response.data.Status === 1) {
                      window.location.href = routeCodes.HOME;
                      secureLocalStorage.clear()
                    }
                    else if (response.data.Status === 0) {
                      $(".modal-title").html("")
                      $(".modal-body").html("<p class=class_p" > +response.data.Message + "</p>");
                      $(".modal-title").html("")
                      $(".modal-footer").html("");
                      $(".modal-footer").html("<button id=ok>OK</button>");
                      $("#ok").addClass("btn btn-block");
                      $("#ok").on("click", function () {
                        $("#modalDialog").toggle('hide');
                      });
                      $("#modalDialog").toggle('show');
                    }
                    else {
                      $(".modal-title").html("")
                      $(".modal-body").html("<p class='class_p text-danger'>Something went wrong. Try again.</p>");
                      $(".modal-title").html("")
                      $(".modal-footer").html("");
                      $(".modal-footer").html("<button id=ok>OK</button>");
                      $("#ok").addClass("btn btn-block");
                      $("#ok").on("click", function () {
                        $("#modalDialog").toggle('hide');
                      });
                      $("#modalDialog").toggle('show');
                    }
                  })
                  .catch(function (error) {
                    return;
                  });
            });
          }
          else {
            $(".modal-body").html("<p class=class_p>" + response.data.Response + "</p>");
            $(".modal-title").html("Your ASK order not placed!")
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=redirect0>Got it</button>");
            $("#redirect0").addClass("btn btn-block");
            $("#redirect0").on("click", function () {
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
          }
        }
        else {
          console.warn("SellMarketOrder");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
          $(".modal-title").html("")
          $(".modal-footer").html("");
          $(".modal-footer").html("<button id=redirect1>Got it</button>");
          $("#redirect1").addClass("btn btn-block");
          $("#redirect1").on("click", function () {
            $("#modalDialog").toggle('hide');
          });
          $("#modalDialog").toggle('show');
        }
        return;
      })
      .catch(function (err) {
        // setGetdata(true);
        if (err.code ==="ERR_NETWORK" || err.code === 'ECONNABORTED'){
        // secureLocalStorage.clear();
        $(".modal-body").html("<p className='text-danger'>Network Error!</p>");
        $(".modal-title").html("")
        $(".modal-footer").html("<button id=redirect>Got it</button>");
        $("#redirect").addClass("btn btn-block");
        $("#redirect").on("click", function () {
            $("#modalDialog").toggle('hide');
        });
        $("#modalDialog").toggle('show');
        }
        else{
            console.warn(err,"SellMarketOrder");$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn1>ok</button>");
            $("#closebtn1").addClass('btn btn-block');
            $("#closebtn1").on("click", function () {
                $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
        }
        return;
      });
  }

  // function getSellOrderBookMarket(response) {
  //   $(".modal-body").html(response.Response);
  //   $(".modal-title").html("Order ID :  " + response.Order_id)
  //   $("#modalDialog").toggle('show');
  //   if (typeof (EventSource) !== "undefined") {
  //     // console.log("app")
  //     let headers = new Headers();
  //     headers.append('Content-Type', 'application/json');
  //     headers.append('Accept', 'application/json');
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Credentials', 'true');
  //     headers.append('GET', 'POST');
  //     var source = new EventSource(url_local.orderbooks);
  //     source.onopen = function (e) {
  //     }
  //     source.onmessage = function (message) {
  //       var object = message.data;
  //       var json = JSON.stringify(eval("(" + object + ")"));
  //       var data = JSON.parse(json);
  //       // OrderBookContext.Sell.push(data);
  //       OrderBookContext._currentValue.sellmarket.push(data);
  //       // sellOrderBook.push(data)
  //       // console.log(OrderBookContext.Sellmarket);
  //       history(routeCodes.EXCHANGEDASHBOARD)
  //     }
  //     source.onerror = function (e) {
  //     }
  //   }
  //   // console.log(orderBook)

  // }

  return (
    <div className="card-body">
      <div className="d-flex flex-sm-row flex-column">
        <div className="mr-auto p-2"><h4 className='class_p'>SELL</h4></div>
        <div className="p-2"> <p className='class_p'>{String((props.symbol).split("/USDT")).replace(',', '').toUpperCase()}</p></div>
      </div>
      <FormProvider methods={methods} onSubmit={handleSubmit(SellCryptoMarket)}>
        {/* <RHFTextField id="selltradepricemarket" name="selltradepricemarket" label="Amount" className="mb-3"
            inputProps={
              { readOnly: true }
            }
            placeholder={props.placeholder!="" ? (props.placeholder.toString()+" $") : ""}  /> */}
        <p className='class_p mb-3' id="selltradepricemarket">
          Market Price : {props.placeholder !== "" ? (props.placeholder.toString() + " $") : ""} </p>
        <RHFTextField id="selltradeqntymarket" name="selltradeqntymarket" label="Volume" placeholder="Enter Size" />
        {/* <div className="d-flex justify-content-around mt-2">
														<p className='class_p'>Time In Force Policy</p>
														<p className='class_p'>Good Till Cancelled</p>
													</div> */}
        <div className="col-md-12 text-center mt-3">
          {!ChangeBtnstate ?
            <Link to={routeCodes.LOGIN} className="btn btn-sm btn-block btn-yodigi-danger">Login/ Register</Link> :
            <LoadingButton size="small" type="submit" className="btn btn-sm btn-block btn-yodigi-danger"
              loading={isSubmitting}>Sell now</LoadingButton>
          }
        </div>
      </FormProvider>
    </div>
  )
}


export default SellMarketOrder;