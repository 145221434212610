
import React from 'react';
import secureLocalStorage from 'react-secure-storage';

var cryptos = [];

function GetCryptoCurrencies(props){
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
cryptos = JSON.parse(secureLocalStorage.getItem('CRA_'));
let currencyLists = cryptos.length > 0 && cryptos.map((item, i) => {
      return (
      <option key={i} value={item.coin_symbol} className="p-4">{item.coin_symbol}</option>
      )
  }, this);
  return(
    <select className={props.className} id={props.id}>
      {currencyLists}
    </select>
  )
}

export default GetCryptoCurrencies;