import React from 'react';
import { MuiTelInput  } from 'mui-tel-input';
// import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import {  FormHelperText } from '@mui/material';

// import {Typography} from '@mui/material';
import '../../web/web.scss';

// PhoneCodes.propTypes = {
//   name: PropTypes.string,
// };

export default function PhoneCodes({ name,id ,label , ...other }) {
  const { control } = useFormContext();
  // const [value, setValue] = React.useState('');
  // const [validate,setvalidate] = React.useState(false);

  // const handleChange = (newValue) => {
  //   // setIsValid(isValidPhoneNumber(newValue))
  //   setvalidate(isValidPhoneNumber(newValue))
  //   setValue(newValue);
  // }



  return (
     <Controller
      render={({ field: { onChange, value }, fieldState }) => (
        <React.Fragment>
       <MuiTelInput defaultCountry="AE"  id={id}   size="small"

        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
          // "& .MuiOutlinedInput-root": {
          //   "&.Mui-focused fieldset": {
          //     borderColor: "#0000002d",
          //     background:"#0000002d"
          //   },
          //   // "& .MuiOutlinedInput-notchedOutline": {
          //   //   borderColor: "#0000002d",
          //   // },
          // },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#161825",

            },
            "& .MuiOutlinedInput-notchedOutline": {
              // borderColor: "#242736",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#161825",
            }
          },
          input:{color:"#fff"}
          }}
          fullWidth
          required
          onChange={onChange}
          disableFormatting
          value={value}
          error={!!fieldState.error}
          {...other}
          />
        {fieldState.error ? (
          <FormHelperText error className="mt-0 ml-3">{fieldState.error?.message}</FormHelperText>
        ) : null}
      </React.Fragment>
        )}
        control={control}
        name={name}
        rules={{ required: `Please select a ${label}` }}
      />
  )}


