import React from "react";
// import axios from 'axios';
// import $ from 'jquery';
// import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

//import components
// import url_local from '../../url_local';
import routeCodes from "../../../Paths";

function Verify() {
    console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
    // const history = useNavigate();
    // const [loading, setLoading] = React.useState(true);
    // const loadData = async () => {
    //     await new Promise((r) => setTimeout(r, 1000));
    //     setLoading(loading => !loading);
    //     console.log(loading)
    // };
    // useEffect(() => {
    //     var headers = {
    //         "Content-Type": "application/json",
    //         'Access-Token': secureLocalStorage.getItem('AT_'),
    //         "Refresh-Token": secureLocalStorage.getItem('RT_')
    //     };
    //     axios
    //         .post(url_local.checkuserstatus,
    //             {
    //                 publicid: secureLocalStorage.getItem('PI_')
    //             },
    //             { headers })
    //         .then(function (response) {
    //             console.log(response.data, "user status")
    //             if (response.data.Status === 1) {
    //                 // loadData();
    //                 if (response.data.Data.isidverified === true) {
    //                     document.getElementById("notverified").style.display = "none";
    //                     document.getElementById("verified").style.display = "block";
    //                 }
    //                 else {
    //                     document.getElementById("notverified").style.display = "block";
    //                     document.getElementById("verified").style.display = "none";
    //                 }
    //             }
    //             else if (response.data.Status === 0) {
    //                 if (response.data.Code === 3 || response.data.Code === 7) {
    //                      $(".modal-title").html("");
    //                      $(".modal-body").html("<p class=class_p>Your session expired.</p>");
    //                      $(".modal-footer").html("");
    //                      $(".modal-footer").html("<button id=closesessionclosebtn>ok</button>");
    //                      $("#closesessionclosebtn").addClass('btn btn-block');
    //                      $("#closesessionclosebtn").on("click", function () {
    //                           history(routeCodes.HOME);
    //                           $("#modalDialog").toggle('hide');
    //                      });
    //                      $("#modalDialog").toggle('show');
    //                 }
    //                 else {
    //                      $(".modal-title").html("");
    //                      $(".modal-body").html(response.data.Message);
    //                      $(".modal-footer").html("");
    //                      $(".modal-footer").html("<button id=closebtn>ok</button>");
    //                      $("#closebtn").addClass('btn btn-block');
    //                      $("#closebtn").on("click", function () {
    //                           $("#modalDialog").toggle('hide');
    //                      });
    //                      $("#modalDialog").toggle('show');
    //                 }
    //             }
    //             return;
    //         })
    //         .catch(function () {
    //             return;
    //         });
    // }, []);
    // $(document).on("click", "#close", function () {
    //     $("#modalDialog").toggle('hide');
    // });
    // if (loading) {
    //         return (<Spinner />)
    // }
    // else{
    return (
        <div className="row">
            <div className="col-md-12">
                {/* internal container is div.card below */}
                <div className="card px-4">
                    <div className="page-header w-100">
                        <h3 className="page-title w-100 row">
                        <div className='col-5'>
                        <span className="page-title-icon bg-success me-2">
                            <i className="mdi mdi-checkbox-marked-circle-outline"></i>
                        </span> Personal Verification
                        </div>
                        <div className='col'>
                            <div className="float-end">
                            <div className='text-lastlogin text-end fs-6'>
                                <i className="mdi mdi-account px-2"></i>Last login time
                                <label className='d-block'>{secureLocalStorage.getItem('DT_')}</label>
                            </div>
                            </div>
                        </div>
                        </h3>
                    </div>

                    {/* here content is placed */}
                    <div className="card-body  mb-5">
                        <p>Follow these simple steps to verify your account:</p>
                        {/* <div class="row">
                            <div class="col-12">
                                <div class="form-group row">
                                <h5 className="card-description"><i className="mdi mdi-account mr-1"></i>Personal information</h5>
                                <div className="col-auto d-flex justify-content-between">
                                <label className="card-description">Provide with your name, profile picture,
                                    valid email, DOB, Nationality etc.</label>
                                <button className="btn btn-block">Update</button>
                                </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-md-8 col-xs-12">
                                <div id="traffic-chart-legend" className="rounded-legend legend-bottom-left pt-2">
                                    <ul>
                                        <li className="mb-4">
                                            <div className="mt-3 d-flex flex-sm-row flex-column">
                                                <div className="mr-auto p-2">
                                                    <h5><i className="mdi mdi-account me-1"></i>Personal information</h5>
                                                    <label>Provide with your name, profile picture,
                                                        valid email, DOB, Nationality etc.</label></div>
                                                <div className="p-2"><a href={routeCodes.SETTINGS} className="btn-sm btn-yodigi-sell mb-2">Update</a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mb-4">
                                            <div className="mt-3 d-flex flex-sm-row flex-column">
                                                <div className="mr-auto p-2">
                                                    <h5><i className="mdi mdi-account-card-details me-1"></i>Government ID Proof</h5>
                                                    <label>A photo of your Identity Card (both sides),
                                                        Passport or Driving Licence.</label></div>
                                                <div className="p-2"><a href={routeCodes.IDVERIFY} className="btn-sm btn-yodigi-sell mb-2">Update</a>
                                                </div>
                                            </div>
                                        </li>
                                        {/* <li className="mb-4">
                                            <div className="mt-3 d-flex flex-sm-row flex-column">
                                                <div className="mr-auto p-2">
                                                    <h5><i className="mdi mdi-map-marker me-1"></i>Address Proof</h5>
                                                    <label>Provide full address (street and house number,
                                                        postal code etc) issued in the last 3 months.</label></div>
                                                <div className="p-2"><a href={routeCodes.SETTINGS} className="btn-sm btn-yodigi-sell mb-2">Update</a>
                                                </div>
                                            </div>
                                        </li> */}
                                    </ul>

                                    <span className="card-description" id="notverified">Status : <p className="badge bg-danger mx-1">Not verified</p></span>
                                    <span className="card-description" id="verified" style={{ display: 'none' }}>Status :<p className="badge bg-success mx-1" >verified</p></span>
                                    <p className="mt-2">Review time: 2 days</p>
                                </div>
                            </div>
                            <div className="col-6 mt-3 mb-3 p-2">
                                <h4 className="mb-2">Features & Limits</h4>
                                <p className="mt-1 card-description">
                                    <i className="mdi mdi-information-outline mx-1 text-success"></i>Fiat Deposit & Withdrawal Limits</p>
                                <p>$20k daily</p>
                                <p className="mt-1 card-description">
                                    <i className="mdi mdi-information-outline mx-1 text-success"></i>Crypto Withdrawal Limits</p>
                                <p>$20k daily</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    // }
}

export default Verify;