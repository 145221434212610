import React from 'react';
import * as Yup from 'yup';
import $ from 'jquery';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FormProvider, RHFTextField } from '../../components/hook-form';
import { Stack } from '@mui/material';
import secureLocalStorage from 'react-secure-storage';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//mui
import { LoadingButton } from '@mui/lab';

import url_local from '../../url_local'
import routeCodes from '../../../Paths';

// -----------------------------------------

// var timeoutHandle;

function countdown(minutes) {
  var seconds = 60;
  var mins = minutes

  function tick() {
	$("#resendbtn").prop('disabled', true);
    var counter = document.getElementById("taoTimer");
    var current_minutes = mins - 1
    seconds--;
    counter.innerHTML =
      current_minutes.toString() + ":" + (seconds < 10 ? "0" : "") + String(seconds);
    if (seconds <= 0) {
      if (mins > 1) {

        $('#taoTimer').addClass('taoIncreaseUrgency');

        // countdown(mins-1);   never reach “00″ issue solved:Contributed by Victor Streithorst
        setTimeout(function() {
          countdown(mins - 1);
        }, 1000);

      } else if (seconds === 0) {
		  $("#resendbtn").prop('disabled', false);
        // Add page overlay, preventing user interaction
        // with the rest of the page.
        $('<span id="taoPageOverlay"></span>').prependTo('#guestInfo');

        // TEMPORARILY REMOVE THE SESSION TIMEOUT FOR VALIDATION THAT REMOVING IT WORKS
        $('#sessionTimeoutCounter').remove();

        // Click event for users to remove CTA and continue reservation
        $('#taoClose, #taoContBtn, #taoPageOverlay').on('click', function() {
          $('#taoTimeOut, #taoPageOverlay').remove();
          window.location.reload(); // reload page
        });

      }
    }
  }

  tick();
}

 function PinOtp() {
  console.info(`-->`,new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(Date.now()),__filename);
	// const [verifysuccess,setverifysuccess] = React.useState(false);
	React.useEffect(()=>
	{
    if(secureLocalStorage.getItem('PI_')==="" || secureLocalStorage.getItem('PI_')===null)
    {
      // setverifysuccess(true);
    }
    else{
      // setverifysuccess(false);
      countdown(10);
    }
	},[])


    const history = useNavigate();

	const OtpSchema = Yup.object().shape({
		emailOTP: Yup.string().required('Email OTP is required').matches(/^\S*$/, 'Whitespace is not allowed'),
	  });

	const defaultValues = {
		emailOTP: '',
	  };

	  const methods = useForm({
		resolver: yupResolver(OtpSchema),
		defaultValues,
	  });

	  const {
		handleSubmit,
		formState: { isSubmitting },
	  } = methods;


  const submitOTP = ()=>
    {
      // document.getElementById('otpsection').style.display = 'none';
      // calling otp
      var headers = { "Content-Type": "application/json",
      "Refresh-Token": secureLocalStorage.getItem('RT_'),
      "Access-Token": secureLocalStorage.getItem('AT_')
          };
          axios
          .post(url_local.managepin,
          {
            "publicid": secureLocalStorage.getItem('PI_'),
            "purpose":"OTP-VERIFICATION",
            "pin":secureLocalStorage.getItem("PP_"),
            "pinotp":document.getElementById('emailCode').value
          },
          {headers})
          .then(function(response) {
// console.log(response.data)
          if(response.data.Status === 1)
          {
            secureLocalStorage.setItem("PP_","");
            $(".modal-body").html("<p class=class_p>PIN updated</p>");
            $(".modal-footer").html("Success!");
            $(".modal-footer").html("<button id=closebtn>Got it</button>");
            $("#closebtn").addClass('btn btn-block');
            $("#closebtn").on("click",function()
            {
              history(routeCodes.SECURITY);
              $("#modalDialog").toggle('hide');
            });
            $("#modalDialog").toggle('show');
           }
            else if(response.data.Status === 0){
              $(".modal-body").html("<p class=class_p>"+response.data.Response+"</p>");
              $(".modal-title").html("")
              $(".modal-footer").html("");
              $(".modal-footer").html("<button id=delete>Got it</button>");
              $("#delete").addClass("btn btn-block");
              $("#delete").on('click',function()
                {
                  $("#modalDialog").toggle('hide');
                });
              $("#modalDialog").toggle('show');
            }
            else{
              console.error('err');$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
              $(".modal-title").html("")
              $(".modal-footer").html("");
              $(".modal-footer").html("<button id=delete>Got it</button>");
              $("#delete").addClass("btn btn-block");
              $("#delete").on('click',function()
                {
                  $("#modalDialog").toggle('hide');
                });
              $("#modalDialog").toggle('show');
            }
          return;
          })
          .catch(function(response) {
            // document.getElementById('otpvalid').innerHTML = response.data.Message;
          return;
          });
    }

    const resendOTP = () =>
    {
      // calling otp
      var headers = { "Content-Type": "application/json",
      "Refresh-Token": secureLocalStorage.getItem('RT_'),
      "Access-Token": secureLocalStorage.getItem('AT_')
      };
          axios
          .post(url_local.managepin,
          {
            "publicid": secureLocalStorage.getItem('PI_'),
            "purpose":"OTP-REQUEST",
            "pin":secureLocalStorage.getItem("PP_")
          },
          {headers})
          .then(function(response) {
// console.log(response.data)
          if(response.data.Status === 1)
          {
            countdown(10);
          }
          else if(response.data.Status === 0){
            $(".modal-body").html(response.data.Data.Message);
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn>Got it</button>");
            $("#closebtn").addClass('btn btn-block');
            $("#closebtn").on('click',function(){
              $("#modalDialog").toggle('hide');
              });
            $("#modalDialog").toggle('show');
          }
          else
          {
            console.error('err');$(".modal-body").html("<p class='text-danger'>Something went wrong. Try again!</p>");
            $(".modal-title").html("")
            $(".modal-footer").html("");
            $(".modal-footer").html("<button id=closebtn>Got it</button>");
            $("#closebtn").addClass('btn btn-block');
            $("#closebtn").on('click',function(){
              $("#modalDialog").toggle('hide');
              });
            $("#modalDialog").toggle('show');
          }
          return;
          })
          .catch(function() {
          return;
          });
    }
  	if(secureLocalStorage.getItem('PI_')==="" || secureLocalStorage.getItem('PI_')===null)
    {
      return <Navigate to={{pathname:routeCodes.LOGIN}} />
    }
	else{
		return (
      <div className="card p-4 mt-5">
      <div className="page-header px-2">
        <h3 className="page-title">
        <span className="page-title-icon bg-success text-white mr-2">
        <a href={routeCodes.SECURITY}> <i className="mdi mdi-arrow-left text-white"></i></a>
        </span> Security settings</h3>
      </div>
					<div className="card-body text-left py-3 ">

					<div className="mt-3 d-flex flex-column justify-content-center">
						<div><h4 className='text-center text-white'>OTP Verification</h4></div>
						<div><h6 className="text-center font-weight-light text-white">
							Make sure you received your OTP. If not, request a new otp.</h6>
						</div>
					</div>
					<FormProvider methods={methods} onSubmit={handleSubmit(submitOTP)}>
						<Stack spacing={3} className="mt-3">
							<RHFTextField name="emailOTP" type="text" id="emailCode" placeholder="Enter OTP" />
							<p className="class_p text-white mt-3">OTP will be valid only for
              <span id="taoTimer" className='text-danger mx-1'></span></p>
						</Stack>
						<div className="text-center mb-2">
                    		<LoadingButton type="submit" loading={isSubmitting} className="btn btn-sm btn-yodigi-buy mt-3">
							Verify</LoadingButton>

							{/* <div className="row mt-2 mb-2"> */}


							{/* </div> */}
						</div>
            		</FormProvider>
                <div className=" mt-1 font-weight-light">
									<label className='class_p'>Don't get code?
										<button className="btn btn-sm text-success" id="resendbtn" onClick={resendOTP}>
											Resend
										</button>
									</label>
								</div>
            	</div>
              </div>
	)
    }
}
export default PinOtp;